import React from 'react';

import { ExperimentType } from '@domain/enums/ExperimentType';
import { ExpTypeTitleMap } from '@ui/const/ExpTypeTitleMap';

import styles from '@pages/experimentDetails/atoms/labels/Labels.module.scss';

type Props = {
  type: ExperimentType;
};

export function Type({ type }: Props) {
  return (
    <div className={styles.type}>
      <div className={styles.title}>Type:</div>
      <div className={styles.value}>{ExpTypeTitleMap[type]}</div>
    </div>
  );
}
