import React from 'react';
import { VictoryChart, VictoryLine, VictoryAxis } from 'victory';

import { axisTickFormat } from '../../utils/helpers';
import { axisStyle, config } from './utils/config';
import { generateColorMap } from '../../utils/colors';

import ChartLegend from '../chartLegend/ChartLegend';

import styles from './LineChart.module.scss';
import { ChartFullscreenIcon } from '../chartFullscreenIcon/ChartFullscreenIcon';

const { sizeX, sizeY, padding } = config;

type Props = {
  chartData: any;
  title: string;
  yAxisLabel: string;
  sizeModifier?: number;
  formatAxis?: (tick: number) => string;
  handleFullscreen?: () => void;
  fullscreen?: boolean;
};

export function LineChart({
  chartData,
  title,
  yAxisLabel,
  sizeModifier = 4,
  formatAxis,
  handleFullscreen,
  fullscreen
}: Props) {
  if (!chartData || !chartData.data) {
    return null;
  }

  const { data, legend } = chartData;

  const groups = Object.keys(data);
  const colorMap = generateColorMap(groups);

  const lines = groups.map((group) => {
    const points = data[group];
    const color = colorMap[group];

    return (
      <VictoryLine
        key={`${title.slice(0, 10)}_${group}`}
        data={points}
        style={{
          data: {
            stroke: color
          }
        }}
      />
    );
  });

  return (
    <div className={styles.chartWrapper} onClick={handleFullscreen}>
      {!fullscreen && handleFullscreen && <ChartFullscreenIcon />}
      <ChartLegend title={title} colorMap={colorMap} legend={legend} />
      <VictoryChart
        width={sizeX * sizeModifier}
        height={sizeY * sizeModifier}
        padding={padding}
        singleQuadrantDomainPadding={false}
        scale={{
          x: 'time',
          y: 'linear'
        }}
      >
        <VictoryAxis
          dependentAxis
          fixLabelOverlap={true}
          style={axisStyle}
          tickCount={20}
          tickFormat={formatAxis || axisTickFormat}
          label={yAxisLabel}
        />
        <VictoryAxis fixLabelOverlap={true} style={axisStyle} label={yAxisLabel} />
        {lines}
      </VictoryChart>
    </div>
  );
}
