import React from 'react';
import { useController } from 'react-hook-form';
import { DropdownInput } from 'crazy-ui-next';

import { GoalConfigParams } from '@domain/models/createExperiment/GoalConfigParams';
import { ExperimentPeriodicity } from '@domain/enums/ExperimentPeriodicity';
import { FormComponent, getInputProps } from '@ui/hooks/form';

import styles from '@pages/createExperiment/goalConfig/GoalConfig.module.scss';

const name: keyof GoalConfigParams = 'checkPeriod';

type Props = Pick<FormComponent<GoalConfigParams>, 'control' | 'register'>;

const options = [
  { label: 'Daily', value: ExperimentPeriodicity.DAILY },
  { label: 'Twice per day', value: ExperimentPeriodicity.TWICE_PER_DAY }
];

export function CheckPeriodInput({ control, register }: Props) {
  const { field, formState } = useController({ control, name });

  return (
    <DropdownInput
      labelText="Check Period"
      placeholder="Select periodicity"
      value={field.value}
      options={options}
      className={styles.input}
      handleChange={(event) => field.onChange(event.value)}
      {...getInputProps<GoalConfigParams>(name, register, formState.errors)}
    />
  );
}
