import { Service } from 'typedi';
import { configureStore, AnyAction, Store } from '@reduxjs/toolkit';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import { persistStore, Persistor } from 'redux-persist';

import { rootReducer } from './rootReducer';
import { RootSagaWatcher } from '@infrastructure/sagas/RootSagaWatcher';
import { pushRestoreSession } from '@infrastructure/store/auth/authActions';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware]
});

@Service()
export class ReduxStore {
  protected readonly store: Store;
  protected readonly persistor: Persistor;
  protected readonly sagaMiddleware: SagaMiddleware;

  constructor(private readonly sagaWatcher: RootSagaWatcher) {
    this.sagaMiddleware = sagaMiddleware;
    this.store = store;
    this.persistor = persistStore(this.store);
  }

  public getState(): AppState {
    return this.store.getState();
  }

  public getStore(): Store {
    return this.store;
  }

  public getPersistor(): Persistor {
    return this.persistor;
  }

  public dispatch(action: AnyAction): void {
    this.store.dispatch(action);
  }

  public runSaga(): void {
    const saga = this.sagaWatcher.getRootSaga();
    this.sagaMiddleware.run(saga);
    this.dispatch(pushRestoreSession.trigger());
  }
}

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
