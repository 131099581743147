import React from 'react';
import ReactSelect from 'react-select';
import { DropdownOption, InputLabel } from 'crazy-ui-next';
import cn from 'classnames';

import { DropdownIndicator } from '@components/lib/Select/atoms/DropdownIndicator';
import { MultiValueLabel } from '@components/lib/Select/atoms/MultiValueLabel';

import styles from './SelectInput.module.scss';

type Props = {
  handleChange: (e?) => void;
  options: DropdownOption[];
  isDisabled?: boolean;
  value?: string | DropdownOption[];
  labelText: string;
  error?: string;
  className?: string;
  placeholder?: string;
  name?: string;
  isMulti?: boolean;
  isClearable?: boolean;
};

export function SelectInput({
  value,
  options,
  isDisabled,
  labelText,
  error,
  className,
  handleChange,
  placeholder,
  name,
  isMulti,
  isClearable
}: Props) {
  const option = typeof value === 'string' ? { value, label: value } : value;

  return (
    <>
      <InputLabel labelText={labelText} error={error} />
      <ReactSelect
        id={name}
        name={name}
        value={option}
        onChange={handleChange}
        options={options}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isMulti={isMulti}
        components={{ MultiValueLabel, DropdownIndicator }}
        className={cn(styles.select, className)}
        classNamePrefix="select"
        isClearable={isClearable}
      />
    </>
  );
}
