import React from 'react';
import useModal from '@ui/hooks/useModal';
import { Button, ButtonVariant } from 'crazy-ui-next';
import { useSelector } from '@ui/hooks/redux';

import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { GenericConfigValue } from '@domain/models/GenericConfigValue';

import { CellText } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/atoms/editableCell/CellText';
import { EditableConfigModal } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/configModal/EditableConfigModal';
import { ReactComponent as EditSvg } from '@assets/svg/edit.svg';

import styles from './EditableCell.module.scss';

type Props = {
  entry: GenericConfigValue;
  region: string;
  configIndex: number;
  variableKey: string;
  groupName: string;
};

export function EditableCell({ entry, region, configIndex, variableKey, groupName }: Props) {
  const { showModal, handleOpenModal, handleCloseModal } = useModal(false);
  const { isRecommendedProfile } = useSelector(createExperimentSelectors.getForm)[CreateExperimentForm.BASIC_INFO];

  const { type, configValue } = entry;
  const isPlain = type === ExperimentObjectiveSessionType.PLAIN;

  return (
    <td className={styles.editableCell}>
      <CellText configValue={configValue} isPlain={isPlain} />

      {!isRecommendedProfile && (
        <div className={styles.controls}>
          <Button variant={ButtonVariant.TERTIARY} onClick={handleOpenModal} icon={<EditSvg />} />
        </div>
      )}

      {showModal && (
        <EditableConfigModal
          handleCloseModal={handleCloseModal}
          entry={entry}
          region={region}
          configIndex={configIndex}
          variableKey={variableKey}
          groupName={groupName}
        />
      )}
    </td>
  );
}
