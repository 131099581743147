export const config = {
  sizeX: 300,
  sizeY: 40,
  padding: { top: 5, bottom: 30, left: 50, right: 5 },

  axisColor: '#546e7a',
  fontFamily: `'Roboto Mono', monospace`
};

export const axisStyle = {
  axisLabel: {
    fill: config.axisColor,
    padding: 50,
    fontSize: '14px',
    fontWeight: 600
  },
  axis: {
    stroke: config.axisColor,
    strokeWidth: 1
  },
  grid: {
    stroke: config.axisColor,
    strokeOpacity: 0.075
  },
  tickLabels: {
    fill: config.axisColor,
    fontSize: '14px'
  },
  ticks: { stroke: config.axisColor, size: 5 }
};
