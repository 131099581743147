import React from 'react';
import { RequestStage } from '@infrastructure/store/types/actions';

import styles from './AppliedConfigList.module.scss';
import { UniqueId } from '@domain/types';
import { GameRowContainer } from '@pages/appliedConfig/list/GameRowContainer';
import { SkeletonRow } from '@pages/appliedConfig/list/SkeletonRow';

type Props = {
  idList: UniqueId[];
  status?: RequestStage;
};

export function List({ idList, status }: Props) {
  const isFetching = status === RequestStage.REQUEST;
  const rows = idList.map((id) => <GameRowContainer key={id} gameId={id} />);

  return (
    <ul id="applied-config-list" className={styles.list}>
      {rows}
      {isFetching && (
        <>
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
        </>
      )}
    </ul>
  );
}
