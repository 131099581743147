import { Service } from 'typedi';

import { setAuthHeader } from '@infrastructure/api/api';
import { AuthApi } from '@infrastructure/api/AuthApi';
import { UserApi } from '@infrastructure/api/UserApi';
import { BackendHttpClient } from '@infrastructure/http/BackendHttpClient';
import { LocalStorageRepo, LocalStorageKey } from '@infrastructure/repositories/LocalStorageRepo';
import { LoginRequestDto } from '@domain/models/user/LoginRequestDto';
import { MyUserDto } from '@domain/models/user/MyUserDto';
import { AuthorizationUseCase } from '@domain/useCases/AuthorizationUseCase';
import { Routing } from '@infrastructure/routing';
import { HistoryService } from '@infrastructure/browser/HistoryService';
import { HttpHeader } from '@infrastructure/api/types';

@Service()
export class AuthService implements AuthorizationUseCase {
  constructor(
    private authApi: AuthApi,
    private userApi: UserApi,
    private http: BackendHttpClient,
    private localStorageRepo: LocalStorageRepo,
    private historyService: HistoryService
  ) {}

  private setAuthHeader(token: string | null): void {
    setAuthHeader({ token }); // Backward compatibility
    this.http.setHeader(HttpHeader.Authorization, `Bearer ${token}`);
  }

  navigateToMainPage() {
    if (this.historyService.isMatch(Routing.getLogin())) {
      this.historyService.goTo(Routing.getExperimentList());
    }
  }

  async authorizeByCreds(username: string, password: string): Promise<MyUserDto> {
    const dto: LoginRequestDto = { username, password };
    const { token } = await this.authApi.loginByCredentials(dto);
    this.setAuthHeader(token);
    this.localStorageRepo.upsertOne(LocalStorageKey.ACCESS_TOKEN, token);
    return this.userApi.getCurrentUser();
  }

  async getPreviousSession(): Promise<MyUserDto | null> {
    const { token: googleToken, userIcon } = this.historyService.getSearchParams();
    const storageToken = this.localStorageRepo.findOne(LocalStorageKey.ACCESS_TOKEN);
    const token = googleToken || storageToken;

    if (!token) {
      return null;
    }

    this.setAuthHeader(token);
    this.localStorageRepo.upsertOne(LocalStorageKey.ACCESS_TOKEN, token);

    if (userIcon) {
      this.localStorageRepo.upsertOne(LocalStorageKey.ICON_PATH, userIcon);
    }

    return this.userApi.getCurrentUser();
  }

  logout() {
    this.localStorageRepo.deleteOne(LocalStorageKey.ACCESS_TOKEN);
    this.localStorageRepo.deleteOne(LocalStorageKey.ICON_PATH);
  }
}
