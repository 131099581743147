import React from 'react';

import { StatsView } from '@pages/experimentDetails/components/stats/types/StatsView';
import { AuditContainer } from '@pages/experimentDetails/components/stats/components/audit/AuditContainer';
import { LogsContainer } from '@pages/experimentDetails/components/stats/components/logs/LogsContainer';
import { ResultContainer } from '@pages/experimentDetails/components/stats/components/result/ResultContainer';

type Props = {
  view: StatsView;
};
export function Stats({ view }: Props) {
  switch (view) {
    case StatsView.AUDIT:
      return <AuditContainer />;
    case StatsView.LOGS:
      return <LogsContainer />;
    case StatsView.RESULT:
    default:
      return <ResultContainer />;
  }
}
