import React from 'react';

import { AuditDto } from '@domain/models/experiment/AuditDto';
import { AuditKey, AuditKeysTitle } from '@domain/enums/AuditEntityType';
import { Cell } from '@pages/experimentDetails/components/stats/components/audit/Cell';

import styles from '@pages/experimentDetails/components/stats/components/audit/Audit.module.scss';

type Props = {
  audit: AuditDto[];
};

const keys = [AuditKey.EVENT_DATE, AuditKey.USERNAME, AuditKey.SOURCE_METHOD, AuditKey.CHANGES];

export function Audit({ audit }: Props) {
  const headings = keys.map((key) => <th key={key}>{AuditKeysTitle[key]}</th>);

  const content = audit.map((entity) => (
    <tr key={entity.id}>
      {keys.map((key) => (
        <Cell key={key} auditKey={key} data={entity[key]} />
      ))}
    </tr>
  ));

  return (
    <table className={styles.audit}>
      <thead>
        <tr>{headings}</tr>
      </thead>
      <tbody>{content}</tbody>
    </table>
  );
}
