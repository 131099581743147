import React, { useCallback } from 'react';
import { memoize } from 'proxy-memoize';

import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { setGameStatsFilter } from '@infrastructure/store/createExperiment/createExperimentActions';
import { GameStats } from '@pages/createExperiment/targetConfig/components/gameStats/GameStats';
import { GameStatsPlaceholder } from '@pages/createExperiment/targetConfig/components/gameStats/GameStatsPlaceholder';
import { useDispatch, useSelector } from '@ui/hooks/redux';

export function GameStatsContainer() {
  const dispatch = useDispatch();
  const gameStats = useSelector(memoize(createExperimentSelectors.getGameStatsByPlatform));
  const filter = useSelector(createExperimentSelectors.getGameStatsFilter);

  const handleSetFilter = useCallback(
    (value) => {
      dispatch(setGameStatsFilter(value));
    },
    [dispatch]
  );

  if (!gameStats.length) {
    return <GameStatsPlaceholder />;
  }

  return <GameStats gameStats={gameStats} filter={filter} handleSetFilter={handleSetFilter} />;
}
