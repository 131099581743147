import { useEffect, useState, useCallback } from 'react';

type Props = {
  watch: any;
};

/**
 * This hook shows pending status until next component update (based on "watch" prop)
 * Use "setStart" when you will trigger async call
 * This hook automatically reset its own state once component update will be triggered
 */
export function useLoader({ watch }: Props) {
  const [isPending, setIsPending] = useState(false);

  const setStart = useCallback(() => setIsPending(true), [setIsPending]);
  const setFinish = useCallback(() => setIsPending(false), [setIsPending]);

  useEffect(() => {
    setFinish();
  }, [setFinish, watch]);

  return { isPending, setStart };
}
