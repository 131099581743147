import React from 'react';
import { useSelector } from 'react-redux';
import { memoize } from 'proxy-memoize';

import { UsersPerDayChart } from '@pages/experimentDetails/components/stats/components/result/components/charts/usersPerDay/UsersPerDayChart';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { ChartType } from '@domain/enums/ChartType';

export function UserPerDayChartContainer() {
  const rawData = useSelector(memoize(experimentDetailsSelectors.getFilteredChartByType(ChartType.USERS_PER_DAY)));

  return <UsersPerDayChart rawData={rawData} />;
}
