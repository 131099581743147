import React from 'react';

import { TableResultContainer } from '@pages/experimentDetails/components/stats/components/result/components/tableResult/TableResultContainer';
import { ChartsContainer } from '@pages/experimentDetails/components/stats/components/result/components/charts/ChartsContainer';
import { FiltersContainer } from '@pages/experimentDetails/components/stats/components/result/components/filters/FiltersContainer';

export function Result() {
  return (
    <>
      <FiltersContainer />
      <TableResultContainer />
      <ChartsContainer />
    </>
  );
}
