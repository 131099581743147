import React from 'react';
import { FieldArray } from 'react-hook-form';

import { ExpGroupsConfigParams } from '@domain/models/experimentDetails/ExpGroupsConfigParams';

type Props = {
  field: FieldArray<ExpGroupsConfigParams>;
};

export function DescriptionCell({ field }: Props) {
  const { description } = field;

  return (
    <td data-wider={true}>
      <span>{description}</span>
    </td>
  );
}
