import React from 'react';
import { InputLabel, TextInput } from 'crazy-ui-next';
import { Controller } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';
import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/controlGroup/ControlGroup.module.scss';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'control'> & {
  index: number;
  name: string;
  labelText: string;
  min: number | boolean | string;
  max: number | boolean | string;
};

export function ControlledIntegerInput({ control, name, labelText, index, min, max }: Props) {
  return (
    <Controller
      name={`controlGroup.${index}.${name}`}
      control={control}
      render={({ field }) => {
        const handleBlurTweak = () => {
          const integer = field.value?.split('.')[0];
          if (integer < min) {
            field.onChange(min.toString());
            return;
          }
          if (integer > max) {
            field.onChange(max.toString());
            return;
          }
          field.onChange(integer);
        };
        return (
          <>
            <InputLabel labelText={labelText} className={styles.label} />
            <TextInput
              name={field.name}
              type="number"
              value={field.value}
              className={styles.input}
              onChange={field.onChange}
              onBlur={handleBlurTweak}
            />
          </>
        );
      }}
    />
  );
}
