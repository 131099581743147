import { createReducer } from '@reduxjs/toolkit';

import { pushLogin } from '@infrastructure/store/auth/authActions';
import { MyUserDto } from '@domain/models/user/MyUserDto';

export type ProfileState = MyUserDto | null;
const initialState = null as ProfileState;

export const profileReducer = createReducer(initialState, (qb) =>
  qb.addCase(pushLogin.success, (state, action) => {
    return action.payload;
  })
);
