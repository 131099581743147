import React from 'react';
import { useController } from 'react-hook-form';

import { DateConfigParams } from '@domain/models/createExperiment/DateConfigParams';
import { DatePicker } from '@components/lib/DatePicker/DatePicker';
import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/dateConfig/DateConfig.module.scss';

const name = 'minInstallDate';

type Props = Pick<FormComponent<DateConfigParams>, 'control'>;

export function MinInstallsDateInput({ control }: Props) {
  const { field } = useController({ control, name });

  return (
    <DatePicker
      name={name}
      showTimeSelect
      labelText="Min Installs Date"
      selected={field.value}
      onChange={field.onChange}
      className={styles.input}
    />
  );
}
