import { useSelector } from '@ui/hooks/redux';

import { UserRoleType } from '@domain/enums/UserRoleType';
import { authSelectors } from '@infrastructure/store/auth/authSelectors';

export type PermissionProps = {
  required?: UserRoleType | UserRoleType[];
  admin?: boolean;
  analyst?: boolean;
  qa?: boolean;
  owner?: boolean;
};

function resolveShorthandPermissions({ required, admin, analyst, qa, owner }: PermissionProps) {
  if (required) {
    if (Array.isArray(required)) {
      return required;
    }

    return [required];
  }

  const rv: UserRoleType[] = [];

  if (admin) {
    rv.push(UserRoleType.ADMIN);
  }

  if (analyst) {
    rv.push(UserRoleType.ANALYST);
  }

  if (qa) {
    rv.push(UserRoleType.QA);
  }

  if (owner) {
    rv.push(UserRoleType.GAME_OWNER);
  }

  return rv;
}

export default function usePermission({ required, admin, analyst, qa, owner }: PermissionProps) {
  const currentUserRole = useSelector(authSelectors.getCurrentRole);
  const requiredRbacRoles = resolveShorthandPermissions({
    required,
    admin,
    analyst,
    qa,
    owner
  });

  if (requiredRbacRoles.length === 0) {
    return true;
  }

  return currentUserRole ? requiredRbacRoles.includes(currentUserRole) : false;
}
