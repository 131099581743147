import React from 'react';
import { CheckboxInput } from 'crazy-ui-next';

import { BasicInfoParams } from '@domain/models/createExperiment/BasicInfoParams';
import { FormComponent, getInputProps } from '@ui/hooks/form';

import styles from '@pages/createExperiment/basicInfo/BasicInfo.module.scss';

type Props = Pick<FormComponent<BasicInfoParams>, 'register'>;

export function RecommendedProfileInput({ register }: Props) {
  return (
    <CheckboxInput
      labelText="Use Recommended Profile"
      className={styles.checkbox}
      {...getInputProps<BasicInfoParams>(`isRecommendedProfile`, register, {})}
    />
  );
}
