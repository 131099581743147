import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { isAfter, isBefore } from 'date-fns';

import {
  clearAll,
  setDateFilter,
  setPlatformFilter,
  setSearchPhraseFilter,
  setStatusFilter,
  setTypeFilter
} from '@infrastructure/store/appliedConfig/appliedConfigActions';
import { LocalStorageKey } from '@infrastructure/repositories/LocalStorageRepo';
import { filterMigration } from '@infrastructure/store/appliedConfig/migrations/filterMigration';
import { ACFilter } from '@infrastructure/store/appliedConfig/types/filters';

const searchPhraseInitial: string = '';

const searchPhraseFilter = createReducer(searchPhraseInitial, (qb) => {
  qb.addCase(setSearchPhraseFilter, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return searchPhraseInitial;
  });
});

const statusInitial = ACFilter.Status.APPLIED;

const statusFilter = createReducer(statusInitial, (qb) => {
  qb.addCase(setStatusFilter, (state, { payload }) => {
    return payload;
  });
});

const typeInitial = ACFilter.Type.ALL;

const typeFilter = createReducer(typeInitial, (qb) => {
  qb.addCase(setTypeFilter, (state, { payload }) => {
    return payload;
  });
});

const platformInitial = ACFilter.Platform.ALL;

const platformFilter = createReducer(platformInitial, (qb) => {
  qb.addCase(setPlatformFilter, (state, { payload }) => {
    return payload;
  });
});

const getDateFilterInitial = () => {
  const now = new Date();
  const initialStartDate = new Date('2021-01-01');

  now.setHours(0, 0, 0, 0);
  return {
    startDate: initialStartDate,
    endDate: now
  };
};

const dateFilterInitial: { startDate: Date | null; endDate: Date | null } = getDateFilterInitial();

const dateFilter = createReducer(dateFilterInitial, (qb) => {
  qb.addCase(setDateFilter, (state, { payload }) => {
    const { isStartChanged, isEndChanged, startDate, endDate } = payload;

    if (startDate instanceof Date) {
      startDate.setHours(0, 0, 0, 0);
    }

    if (endDate instanceof Date) {
      endDate.setHours(0, 0, 0, 0);
    }

    const isStartOverlapsEnd = startDate && state.endDate && isAfter(startDate, state.endDate);
    const isEndOverlapsStart = endDate && state.startDate && isBefore(endDate, state.startDate);

    if (isStartChanged) {
      state.startDate = startDate;

      if (isStartOverlapsEnd) {
        state.startDate = startDate;
        state.endDate = null;
      }
    }

    if (isEndChanged) {
      state.endDate = endDate;

      if (isEndOverlapsStart) {
        state.startDate = endDate;
        state.endDate = null;
      }
    }

    return state;
  });
});

const combinedReducer = combineReducers({
  searchPhrase: searchPhraseFilter,
  status: statusFilter,
  type: typeFilter,
  platform: platformFilter,
  dates: dateFilter
});

export const filterReducer = persistReducer(
  {
    key: LocalStorageKey.APPLIED_CONFIG_FILTERS,
    whitelist: ['status', 'type', 'platform'],
    version: 1,
    migrate: filterMigration,
    storage
  },
  combinedReducer
);
