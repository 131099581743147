import { createReducer } from '@reduxjs/toolkit';
import { clearAll, fetchDefaultRegions } from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { RegionDto } from '@domain/models/RegionDto';

const regionsInitial: RegionDto[] = [] as RegionDto[];

export const defaultRegionsDataReducer = createReducer(regionsInitial, (qb) => {
  qb.addCase(fetchDefaultRegions.success, (state, { payload }) => {
    return payload;
  });

  qb.addCase(clearAll, () => {
    return regionsInitial;
  });
});
