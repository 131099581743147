import React from 'react';
import { CheckboxInput } from 'crazy-ui-next';

import { ExpObjectiveConfigParams } from '@domain/models/experimentDetails/ExpObjectiveConfigParams';
import { FormComponent, getInputProps } from '@ui/hooks/form';

type Props = Pick<FormComponent<ExpObjectiveConfigParams>, 'register' | 'errors'>;

export function AdjustablePercentInput({ register, errors }: Props) {
  return (
    <>
      <p>Adjustable Percent of Users:</p>
      <CheckboxInput {...getInputProps<ExpObjectiveConfigParams>('adjustableUsersAllocation', register, errors)} />
    </>
  );
}
