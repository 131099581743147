import React from 'react';

type Props = {
  value?: string | boolean;
};

export function PlainValueText({ value }: Props) {
  if (typeof value === 'undefined') {
    return <span>-</span>;
  }

  if (value === null) {
    return <span>{'<empty string>'}</span>;
  }

  if (typeof value === 'boolean') {
    return <span>{value ? 'True' : 'False'}</span>;
  }

  return <span>{value}</span>;
}
