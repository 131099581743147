import React from 'react';
import { TextInput } from 'crazy-ui-next';

import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { FormComponent, getInputProps } from '@ui/hooks/form';

type Props = Pick<FormComponent<GLDConfigParams>, 'register'>;

export function DefaultValueInput({ register }: Props) {
  return (
    <TextInput
      labelText="Default value"
      placeholder="Default value"
      {...getInputProps<GLDConfigParams>('defaultValue', register, {})}
    />
  );
}
