import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { SvgLoader } from 'crazy-ui';
import { RequestStage } from '@infrastructure/store/types/actions';

import { EnvConfig } from '@app/config';

import styles from './InfiniteScroll.module.scss';

type Props = {
  isNextAvailable: boolean;
  handleFetchNext: () => void;
  status: RequestStage;
};

export function InfiniteScrollAnchor({ isNextAvailable, handleFetchNext, status }: Props) {
  const isSuccess = status === RequestStage.SUCCESS;

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '300px 0px 0px 0px'
  });

  useEffect(() => {
    if (EnvConfig.USE_LAZY_LOAD && inView && isSuccess && isNextAvailable) {
      handleFetchNext();
    }
  }, [inView, isSuccess, isNextAvailable, handleFetchNext]);
  return (
    <div
      ref={ref}
      data-name="infinite-scroll-anchor"
      className={styles.anchor}
      style={{ opacity: isNextAvailable && !isSuccess ? 1 : 0 }}
    >
      <SvgLoader />
    </div>
  );
}
