import React, { useCallback } from 'react';
import { CheckboxInput, TextInput } from 'crazy-ui-next';
import { ControllerRenderProps } from 'react-hook-form';

import { ExperimentVariableWrapper } from '@domain/enums/ExperimentVariableWrapper';
import { ConfigsParams } from '@domain/models/experiment/ConfigsParams';
import { getLabelText } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/configModal/form/ValueInputsContainer';

import { ExperimentConfigsMapper } from '@app/mappers/experiment/ExperimentConfigsMapper';
import { IntListValueInput } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/configModal/form/IntListValueInput';

import styles from '../EditableConfigModal.module.scss';
import cn from 'classnames';

type Props = {
  field: ControllerRenderProps<ConfigsParams, `configs.${number}.entry`>;
  index: number;
  variableKey: string;
  session: string | null;
};

export function ValueInputWrapper({ field, index, variableKey, session }: Props) {
  const { name, value: entryValue, onChange } = field;
  const { value, type } = entryValue;
  const strValue = String(value);
  const isPlain = session === null;
  const isFirstInput = index === 0;

  const handleChange = useCallback(
    ({ currentTarget }) => {
      onChange({
        value: ExperimentConfigsMapper.mapInputValueByType(
          { value: currentTarget.value, checked: currentTarget.checked },
          type
        ),
        type
      });
    },
    [onChange, type]
  );

  switch (type) {
    case ExperimentVariableWrapper.BOOLEAN:
      return (
        <CheckboxInput
          labelText="Enable"
          name={name}
          onChange={handleChange}
          checked={Boolean(value)}
          className={cn(!isPlain && isFirstInput && styles.checkboxWithMargin, isPlain && styles.plainCheckbox)}
        />
      );
    case ExperimentVariableWrapper.INT_LIST:
      return (
        <IntListValueInput
          variableKey={variableKey}
          onChange={onChange}
          index={index}
          type={type}
          value={strValue}
          name={name}
        />
      );
    default:
      return (
        <TextInput
          labelText={getLabelText(index, 'Value')}
          name={name}
          value={strValue}
          onChange={handleChange}
          className={styles.textInput}
        />
      );
  }
}
