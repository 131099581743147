import React from 'react';
import { TextInput, TextInputEvent } from 'crazy-ui-next';

import { ReactComponent as SearchSvg } from '@assets/svg/search.svg';

type Props = {
  value: string;
  handleChange: (e: TextInputEvent) => void;
};

export function SearchFilter({ handleChange, value }: Props) {
  return (
    <TextInput
      name="searchPhrase"
      value={value}
      onChangeCustom={handleChange}
      labelText="Search"
      inputIcon={<SearchSvg />}
      placeholder="Test or App name"
      clear
    />
  );
}
