import { ArrayMinSize, IsArray, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

import { Dto } from '@domain/models/Dto';
import { ConfigValue } from '@domain/models/GenericConfigValue';

class Config {
  session: string | null;
  entry: ConfigValue;
}

export class ConfigsParams extends Dto {
  @IsArray()
  @ValidateNested({ each: true })
  @ArrayMinSize(0)
  @Type(() => Config)
  configs: Config[];
}
