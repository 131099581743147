import React from 'react';

import { AccurateStatus, AccurateStatusTitle } from '@domain/enums/AccurateStatus';

import styles from '@pages/experimentDetails/components/stats/components/result/components/filters/Filters.module.scss';

type Props = {
  status: AccurateStatus;
};

export function DataAccurateLabel({ status }: Props) {
  switch (status) {
    case AccurateStatus.ACCURATE:
      return <span className={styles.accurate}>{AccurateStatusTitle[AccurateStatus.ACCURATE]}</span>;
    case AccurateStatus.INACCURATE:
      return <span className={styles.inaccurate}>{AccurateStatusTitle[AccurateStatus.INACCURATE]}</span>;
    case AccurateStatus.UNKNOWN:
    default:
      return <span>{AccurateStatusTitle[AccurateStatus.UNKNOWN]}</span>;
  }
}
