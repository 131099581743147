import { ActionFactory } from '@infrastructure/store/helpers/ActionFactory';
import { AppPropertyDto } from '@domain/models/appProperty/AppPropertyDto';
import { AppPropertyForm } from '@domain/models/appProperty/AppPropertyInput';

const namespace = 'applicationProperties';

export const fetchAppProperties = ActionFactory.createRequestRoutine<void, AppPropertyDto[]>(
  'APP_PROPS_FETCH',
  namespace
);

export const updateAppProperties = ActionFactory.createRequestRoutine<AppPropertyForm, AppPropertyDto[]>(
  'APP_PROPS_UPDATE',
  namespace
);
