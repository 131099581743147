import React from 'react';
import { useForm } from '@ui/hooks/form';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { CreateUserParams } from '@domain/models/user/UserReqParams';
import { UserFormMode } from '@pages/userManagement/const/UserFormMode';
import { RequestStage } from '@infrastructure/store/types/actions';
import { pushUserCreate } from '@infrastructure/store/userManagement/userManagementActions';
import { userManagementSelectors } from '@infrastructure/store/userManagement/userManagementSelectors';
import { UserForm } from './UserForm';

const getInitialState = () => new CreateUserParams().setDefaultValues();

export function CreateUserContainer() {
  const dispatch = useDispatch();

  const status = useSelector(userManagementSelectors.getEditUserStatus);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<CreateUserParams>({ schema: CreateUserParams, defaultValues: getInitialState() });

  const handleFormSubmit = handleSubmit((form) => dispatch(pushUserCreate.trigger(form)));
  const isLoading = status === RequestStage.REQUEST;

  return (
    <UserForm
      mode={UserFormMode.CREATE}
      handleSubmit={handleFormSubmit}
      errors={errors}
      register={register}
      control={control}
      isLoading={isLoading}
    />
  );
}
