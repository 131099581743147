import React from 'react';
import cn from 'classnames';

import styles from '@pages/experimentDetails/components/summary/config/ExperimentConfig.module.scss';

type Props = {
  label?: string;
  children: React.ReactElement;
  className?: string;
};

export function ConfigSection({ label, children, className }: Props) {
  return (
    <section>
      <div className={cn(styles.configSection, className)}>
        <div>{label && <h3>{label}</h3>}</div>
        {children}
      </div>
    </section>
  );
}
