import { Dto } from '@domain/models/Dto';
import { AppPropertyType } from '@domain/enums/AppPropertyType';

export type AppPropertyValue = string | string[] | number | number[];

export class AppPropertyDto extends Dto {
  name: string;
  value: AppPropertyValue;
  description: string;
  enabled: boolean;
  type: AppPropertyType;
}
