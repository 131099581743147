import React from 'react';
import { RadioInput } from 'crazy-ui-next';
import { Controller } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';

import { ExpDefinitionParams } from '@domain/models/experimentDetails/ExpDefinitionParams';

import { ExperimentPeriodicity, ExpPeriodicityTitle } from '@domain/enums/ExperimentPeriodicity';
import styles from '@pages/experimentDetails/ExperimentDetails.module.scss';

type Props = Pick<FormComponent<ExpDefinitionParams>, 'control'>;

export function CheckPeriodInput({ control }: Props) {
  return (
    <>
      <div className={styles.editorInputLabel}>
        <p>Check Period:</p>
      </div>
      <Controller
        name="checkPeriod"
        control={control}
        render={({ field }) => {
          return (
            <>
              <RadioInput
                name={field.name}
                checked={field.value === ExperimentPeriodicity.DAILY}
                labelText={ExpPeriodicityTitle[ExperimentPeriodicity.DAILY]}
                value={ExperimentPeriodicity.DAILY}
                onChange={({ currentTarget }) => {
                  field.onChange(currentTarget.value);
                }}
              />
              <RadioInput
                name={field.name}
                checked={field.value === ExperimentPeriodicity.TWICE_PER_DAY}
                labelText={ExpPeriodicityTitle[ExperimentPeriodicity.TWICE_PER_DAY]}
                value={ExperimentPeriodicity.TWICE_PER_DAY}
                onChange={({ currentTarget }) => {
                  field.onChange(currentTarget.value);
                }}
              />
            </>
          );
        }}
      />
    </>
  );
}
