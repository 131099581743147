import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import Wrapper from '../wrappers/Wrapper';

type Props = {
  pageName?: string;
} & RouteProps;

export function WrappedRoute({ pageName = '', ...rest }: Props) {
  return (
    <Wrapper pageName={pageName}>
      <Route {...rest} />
    </Wrapper>
  );
}
