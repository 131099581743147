import { createReducer } from '@reduxjs/toolkit';

import { pushLogin } from '@infrastructure/store/auth/authActions';

export type AuthState = boolean;

const initialState: AuthState = false;

export const isAuthReducer = createReducer(initialState, (qb) =>
  qb.addCase(pushLogin.success, () => {
    return true;
  })
);
