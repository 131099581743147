import React, { useCallback, useEffect } from 'react';

import { Logs } from '@pages/experimentDetails/components/stats/components/logs/Logs';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import {
  fetchExperimentLogList,
  pushExperimentLogName
} from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { RequestStage } from '@infrastructure/store/types/actions';
import { BlockLoader } from '@components/layout/Loader';
import { NoData } from '@components/layout/noData/NoData';
import { createSimpleOptions } from '@infrastructure/utils/createOptions.utils';

export function LogsContainer() {
  const dispatch = useDispatch();

  const logList = useSelector(experimentDetailsSelectors.getLogList);
  const logName = useSelector(experimentDetailsSelectors.getSelectedLogName);
  const log = 'useSelector(experimentDetailsSelectors.getSelectedLog)';
  const status = useSelector(experimentDetailsSelectors.getLogListStatus);

  const handleChange = useCallback(
    (e) => {
      dispatch(pushExperimentLogName(e.value));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchExperimentLogList.trigger());
  }, [dispatch]);

  if (status !== RequestStage.SUCCESS) {
    return <BlockLoader />;
  }

  if (!logList.length) {
    return <NoData subject="experiment logs" />;
  }

  const options = createSimpleOptions(logList);

  return <Logs options={options} handleChange={handleChange} selectedLog={logName} log={log} />;
}
