import { AppState } from '@infrastructure/store';
import { ConfigMapper } from '@app/mappers/ConfigMapper';
import { AppPropertyType } from '@domain/enums/AppPropertyType';
import { AppPropertyDto } from '@domain/models/appProperty/AppPropertyDto';
import { AppPropertyForm } from '@domain/models/appProperty/AppPropertyInput';
import { configSelectors } from '@infrastructure/store/config/configSelectors';

const getList = (state: AppState) => state.applicationProperties.list;
const getStatus = (state: AppState) => state.applicationProperties.status;

const getFormList = (state: AppState): AppPropertyForm => {
  const list = getList(state).map((dto) => ConfigMapper.mapDtoToAppPropertyInput(dto));
  return ConfigMapper.mapAppPropertiesListToParams(list);
};

const getReportingArpu = (state: AppState): number[] => {
  const defaultValue = [1, 3, 7];
  const valueFromProperties = getList(state).find(
    (property: AppPropertyDto) => property.type === AppPropertyType.ARPU_ARRAY
  )?.value;

  return (valueFromProperties as number[]) || defaultValue;
};

const getMinUsersPerGroup = (state: AppState): number => {
  const userInputName = 'users-required';

  const valueFromConfig = configSelectors.getMinUsersPerGroup(state);
  const valueFromProperties = getList(state).find((property: AppPropertyDto) =>
    property.name.includes(userInputName)
  )?.value;

  return Number(valueFromProperties) || valueFromConfig;
};

export const appPropertiesSelectors = {
  getList,
  getStatus,
  getFormList,
  getReportingArpu,
  getMinUsersPerGroup
};
