import { AppPropertyDto } from '@domain/models/appProperty/AppPropertyDto';
import { combineReducers, createReducer } from '@reduxjs/toolkit';
import {
  fetchAppProperties,
  updateAppProperties
} from '@infrastructure/store/applicationProperties/appPropertiesActions';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';

const appPropsInitial: AppPropertyDto[] = [];

const propertiesList = createReducer(appPropsInitial, (qb) => {
  qb.addCase(fetchAppProperties.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(updateAppProperties.success, (state, { payload }) => {
    return payload;
  });
});

export const applicationProperties = combineReducers({
  status: ReducerFactory.makeStatus([fetchAppProperties, updateAppProperties]),
  list: propertiesList
});
