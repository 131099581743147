import { ActionFactory } from '@infrastructure/store/helpers/ActionFactory';
import { Paginate } from '@domain/models/Paginate';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import {
  ExpBusinessLineFilterValue,
  ExpPlatformFilterValue,
  ExpSortByPayload,
  ExpStatusFilterValue,
  ExpTypeFilterValue
} from '@infrastructure/store/experimentList/types/filters';
import { DateRangeEvent } from '@components/lib/DateRangePicker/DateRangePicker';

const namespace = 'experimentList';

export const fetchExperiments = ActionFactory.createRequestRoutine<number | undefined, Paginate<ExperimentDto>>(
  'EXPERIMENTS_FETCH',
  namespace
);

export const fetchNextExperiments = ActionFactory.createAction<void>('EXPERIMENTS_NEXT_FETCH', namespace);

export const handleSearchPhraseFilter = ActionFactory.createAction<string>('EXPERIMENT_SEARCH_FILTER', namespace);
export const handleStatusFilter = ActionFactory.createAction<ExpStatusFilterValue>(
  'EXPERIMENT_STATUS_FILTER',
  namespace
);
export const handleTypeFilter = ActionFactory.createAction<ExpTypeFilterValue>('EXPERIMENT_TYPE_FILTER', namespace);
export const handleBusinessLinesFilter = ActionFactory.createAction<ExpBusinessLineFilterValue>(
  'EXPERIMENT_GENRE_FILTER',
  namespace
);
export const handlePlatformFilter = ActionFactory.createAction<ExpPlatformFilterValue>(
  'EXPERIMENT_PLATFORM_FILTER',
  namespace
);
export const handleDateFilter = ActionFactory.createAction<DateRangeEvent>('EXPERIMENT_DATE_FILTER', namespace);
export const handleSortBy = ActionFactory.createAction<ExpSortByPayload>('EXPERIMENT_SORT_BY', namespace);

export const clearAll = ActionFactory.createAction('CLEAR_ALL', namespace);
