import React from 'react';
import { useController, Control, UseFormWatch } from 'react-hook-form';
import { DropdownInput } from 'crazy-ui-next';

import { UserProperty } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';

type Props = {
  inputIndex: number;
  control: Control<TargetConfigParams>;
  userProperties: UserProperty[];
  watch: UseFormWatch<TargetConfigParams>;
  watchName: string;
};

export function UserPropertiesDropdownInput({ inputIndex, control, userProperties, watch, watchName }: Props) {
  const inputName = `${watchName}.${inputIndex}.userPropertyId`;
  const options = userProperties.map((userProperty) => ({
    value: String(userProperty.id),
    label: userProperty.displayName
  }));

  const { field } = useController({
    // @ts-ignore-next-line
    name: inputName,
    control
  });

  return (
    <DropdownInput
      options={options}
      name={inputName}
      value={field.value}
      handleChange={(event) => field.onChange(event.value)}
    />
  );
}
