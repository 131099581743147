import { useState, useCallback } from 'react';

export default function useModal(initialValue = false) {
  const [showModal, handleModal] = useState(initialValue);

  const handleOpenModal = useCallback(() => {
    handleModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    handleModal(false);
  }, []);

  return { showModal, handleOpenModal, handleCloseModal };
}
