import { ChartDto } from '@domain/models/ChartDto';

export class ChartMapper {
  static filterRawChartDataByGroup(chart: ChartDto, groups: string[]): ChartDto {
    const { config = {} } = chart;
    const data = {};
    const legend = {};

    if (!chart || !chart.data || !groups || !groups.length) {
      return {
        config,
        data,
        legend
      };
    }

    groups.forEach((group) => {
      if (chart.data[group]) {
        data[group] = chart.data[group];
        legend[group] = chart.legend[group];
      }
    });

    return {
      config,
      data,
      legend
    };
  }
}
