import React, { useCallback } from 'react';
import { Button, ButtonSize, ButtonVariant, ModalWrapper } from 'crazy-ui-next';

import styles from './DeleteConfigModal.module.scss';

type Props = {
  handleCloseModal: () => void;
  handleCancelWinner: () => void;
  experimentName: string;
};

export function DeleteConfigModal({ handleCloseModal, handleCancelWinner, experimentName }: Props) {
  const handleCancel = useCallback(() => {
    handleCancelWinner();
    handleCloseModal();
  }, [handleCancelWinner, handleCloseModal]);

  return (
    <ModalWrapper handleModal={handleCloseModal} className={styles.wrapper}>
      <div className={styles.header}>
        <p>Delete Winner</p>
      </div>

      <p>Are you sure you want to delete applied configuration for "{experimentName}"</p>

      <div className={styles.controls}>
        <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.LARGE} onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button size={ButtonSize.LARGE} onClick={handleCancel}>
          Accept
        </Button>
      </div>
    </ModalWrapper>
  );
}
