import React from 'react';
import { Button } from 'crazy-ui-next';
import cn from 'classnames';

import { Routing } from '@infrastructure/routing';
import styles from '@pages/common/ErrorPage.module.scss';

export function NotFound() {
  return (
    <main className={cn('container', styles.wrapper, styles.warn)}>
      <div className="paper">
        <h2>404</h2>
        <h3>Not Found</h3>
        <p>We couldn't find what you were looking for.</p>
        <div className={styles.actionBlock}>
          <p>Fortunately, you can go back to:</p>
          <Button href={Routing.getRoot()}>Dashboard</Button>
        </div>
      </div>
    </main>
  );
}
