import React from 'react';

import styles from '@pages/appliedConfig/list/AppliedConfigList.module.scss';

export function SkeletonRow() {
  return (
    <li data-loading="true" data-kind="row" className={styles.row}>
      <div className={styles.icon}>
        <span />
      </div>
      <p />
      <div className={styles.stats}>
        <span />
        <label />
      </div>
      <div className={styles.stats}>
        <span />
        <label />
      </div>
      <div className={styles.platform}>
        <label />
        <span />
      </div>
      <div className={styles.region}>
        <label />
        <span />
      </div>
      <div className={styles.control}>
        <span />
      </div>
    </li>
  );
}
