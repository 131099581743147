import { createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  pushTargetConfigForm,
  pushExperimentClone,
  setGame,
  generateTargetConfig
} from '@infrastructure/store/createExperiment/createExperimentActions';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';

const targetConfigInitial: TargetConfigParams = TargetConfigParams.ofInitial();

export const targetConfigReducer = createReducer(targetConfigInitial, (qb) => {
  qb.addCase(pushTargetConfigForm, (state, { payload: form }) => {
    return form;
  });
  qb.addCase(pushExperimentClone.success, (state, { payload }) => {
    if (payload) {
      return payload.targetConfig;
    }

    return state;
  });
  qb.addCase(setGame, () => {
    return targetConfigInitial;
  });
  qb.addCase(generateTargetConfig.success, (state, { payload }) => {
    return payload.form;
  });
  qb.addCase(clearAll, () => {
    return TargetConfigParams.ofInitial();
  });
});
