import React, { useEffect } from 'react';
import { Control, useController, UseFormResetField, UseFormWatch } from 'react-hook-form';
import { DropdownInput } from 'crazy-ui-next';

import { createSimpleOptions } from '@infrastructure/utils/createOptions.utils';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';

import styles from '@pages/createExperiment/targetConfig/TargetingConfig.module.scss';

const inputName = 'primaryRegion';
const watchName = 'regions';

type Props = {
  control: Control<TargetConfigParams>;
  watch: UseFormWatch<TargetConfigParams>;
  resetField: UseFormResetField<TargetConfigParams>;
};

export function PrimaryRegionInput({ control, watch, resetField }: Props) {
  const regions = watch(watchName);
  const { field, formState } = useController({ control, name: inputName });

  const options = createSimpleOptions(regions.map(({ value }) => value));

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name !== watchName && type !== 'change') {
        return;
      }

      if (value.regions) {
        const regionsNames = value.regions.map((option) => option?.value);

        !regionsNames.includes(value.primaryRegion) && resetField(inputName);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, resetField, regions, field]);

  return (
    <DropdownInput
      labelText="Decisioning region"
      placeholder="Select region"
      options={options}
      className={styles.input}
      value={field.value}
      handleChange={(event) => field.onChange(event.value)}
      name={inputName}
      error={formState.errors?.primaryRegion?.message}
    />
  );
}
