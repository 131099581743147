import React, { useEffect } from 'react';

import { useDispatch } from '@ui/hooks/redux';
import { fetchAppliedConfigList, clearAll } from '@infrastructure/store/appliedConfig/appliedConfigActions';
import { AppliedConfigPage } from './AppliedConfigPage';

export function AppliedConfigPageContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppliedConfigList.trigger());

    return () => {
      dispatch(clearAll());
    };
  }, [dispatch]);

  return <AppliedConfigPage />;
}
