import React, { useCallback } from 'react';
import cn from 'classnames';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useFieldArray, useWatch } from 'react-hook-form';

import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { ObjectiveConfigParams, ControlGroupSessionForm } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { AddSessionBtn } from '@pages/createExperiment/objectiveConfig/atoms/AddSessionBtn';
import { Session } from '@pages/createExperiment/objectiveConfig/forms/AB/components/controlGroup/Session';
import { EditableSessionIndex } from '@pages/createExperiment/objectiveConfig/atoms/EditableSessionIndex';
import { useSelector } from '@ui/hooks/redux';
import { FormComponent } from '@ui/hooks/form';
import { ReactComponent as CrossSvg } from '@assets/svg/cross.svg';

import styles from './ControlGroup.module.scss';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'control'> & {
  defaultControlGroup: ControlGroupSessionForm;
};

const maxSessions = 7;

export function ControlGroup({ control, defaultControlGroup }: Props) {
  const params = useWatch({
    control,
    name: 'params'
  });
  const watchSessions = useWatch({
    control,
    name: 'controlGroup'
  });
  const {
    fields: sessions,
    append,
    remove,
    update
  } = useFieldArray({
    control,
    name: 'controlGroup'
  });
  const addSessionDisabled = sessions.length >= maxSessions;

  const variables = useSelector(configSelectors.getVariableList);
  const filteredVariables = variables.filter(({ name }) => params.includes(name));

  const handleAdd = useCallback(() => {
    const sessionIndexes = watchSessions.map(({ sessionIndex }) => Number(sessionIndex)).sort((a, b) => a - b);
    const maxSessionIndex = Math.max(...sessionIndexes);
    const newSessionIndex = maxSessionIndex + 1;

    append({ ...defaultControlGroup, sessionIndex: newSessionIndex.toString() });
  }, [append, defaultControlGroup, watchSessions]);

  const handleRemove = useCallback(
    (e) => {
      const { sessionIndex } = e.target.dataset;

      if (sessionIndex) {
        const index = sessions.map((session) => session.sessionIndex).indexOf(sessionIndex);

        remove(index);
      }
    },
    [remove, sessions]
  );

  const handleChangeSessionIndex = useCallback(
    (index, sessionIndex) => {
      if (!parseInt(sessionIndex)) {
        return;
      }

      const formattedSessionIndex = parseInt(sessionIndex).toString();
      const sessionIndexes = watchSessions.map((session) => session.sessionIndex);

      if (sessionIndexes.includes(formattedSessionIndex)) {
        return;
      }

      const field = watchSessions[index];
      const updatedField = { ...field, sessionIndex: formattedSessionIndex };

      update(index, updatedField);
    },
    [update, watchSessions]
  );

  const showRemoveBtn = sessions.length > 1;
  const maskTitle = sessions.length > 4;

  const tabs = sessions.map((field, index) => {
    return (
      <Tab
        key={`tab_${field.id}`}
        className={styles.tab}
        onClick={handleRemove}
        tabIndex={field.sessionIndex}
        style={{ order: Number(field.sessionIndex) }}
      >
        <span className={cn(styles.tabTitle, maskTitle && styles.masked)}>Session</span>
        <EditableSessionIndex
          index={index}
          sessionIndex={field.sessionIndex}
          viewOnly={index === 0}
          handleSave={handleChangeSessionIndex}
        />
        {showRemoveBtn && (
          <button type="button" data-session-index={field.sessionIndex}>
            <CrossSvg />
          </button>
        )}
      </Tab>
    );
  });
  const form = sessions.map((field, index) => {
    return (
      <TabPanel key={`tab-panel_${field.id}`} className={styles.form} selectedClassName={styles.selected}>
        <Session control={control} sessionIndex={index} variables={filteredVariables} />
      </TabPanel>
    );
  });

  return (
    <Tabs className={styles.controlGroup}>
      <TabList className={styles.tabList}>
        {tabs}
        <li>
          <AddSessionBtn handleClick={handleAdd} disabled={addSessionDisabled} />
        </li>
      </TabList>
      {form}
    </Tabs>
  );
}
