import { Link } from '@domain/types';

const s3Domain = 's3.amazonaws.com';

export class ResourceMapper {
  /**
   * @example
   * Input: http://com.tabtale.psdk.appsdb.resources.prod.s3.amazonaws.com/1024x1024bb_ece4071d.png
   * Output: https://s3.amazonaws.com/com.tabtale.psdk.appsdb.resources.prod/1024x1024bb_ece4071d.png
   */
  static mapS3UrlToCDN(s3Url?: string): Link {
    if (!s3Url) {
      return '';
    }

    try {
      const { hostname, pathname } = new URL(s3Url);

      if (!hostname.includes(s3Domain)) {
        return s3Url;
      }

      const bucketName = hostname.replace(`.${s3Domain}`, '');
      const resourceName = pathname.replace('/', '');

      const cdnUrl = new URL(`https://${s3Domain}/${bucketName}/${resourceName}`);
      return cdnUrl.toString();
    } catch (err) {
      console.warn(err);
      return '';
    }
  }
}
