import React from 'react';

import { useForm } from '@ui/hooks/form';

import { WinnerConfigMapper } from '@app/mappers/WinnerConfigMapper';
import { UpdateWinnerConfigParams } from '@domain/models/winnerConfig/WinnerConfigFormParams';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';
import { UniqueId } from '@domain/types';
import { WinnerStatus } from '@domain/enums/WinnerStatus';
import { GamePlatform } from '@domain/enums/GamePlatform';
import { Form } from '@pages/winnerConfigModal/form/Form';

type Props = {
  objective: ExperimentObjectiveDto;
  experimentId: UniqueId;
  handleCloseModal: () => void;
  handleApplyConfig: (form: UpdateWinnerConfigParams) => void;
  handleEditWinner: (form: UpdateWinnerConfigParams) => void;
  platform: GamePlatform;
  isLoading: boolean;
};

export function FormContainer({
  handleCloseModal,
  handleApplyConfig,
  handleEditWinner,
  objective,
  experimentId,
  platform,
  isLoading
}: Props) {
  const formData = WinnerConfigMapper.mapFieldsToFormParams(objective, experimentId);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<UpdateWinnerConfigParams>({
    schema: UpdateWinnerConfigParams,
    defaultValues: formData,
    mode: 'onChange'
  });
  const handleSubmitConfig = objective.winnerStatus === WinnerStatus.APPLIED ? handleEditWinner : handleApplyConfig;

  const handleFormSubmit = handleSubmit((form: UpdateWinnerConfigParams) => handleSubmitConfig(form));

  return (
    <Form
      control={control}
      handleSubmit={handleFormSubmit}
      register={register}
      errors={errors}
      handleCloseModal={handleCloseModal}
      controlsDisabled={!isValid}
      objective={objective}
      isLoading={isLoading}
      platform={platform}
    />
  );
}
