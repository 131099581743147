import { ConfigsParams } from '@domain/models/experiment/ConfigsParams';
import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { ExperimentVariableWrapper } from '@domain/enums/ExperimentVariableWrapper';
import { ConfigValue, GenericConfigValue } from '@domain/models/GenericConfigValue';

export class ExperimentConfigsMapper {
  static mapEntryToFormParams(data: GenericConfigValue): ConfigsParams {
    const params = new ConfigsParams();

    const { type, configValue } = data;
    const isPlain = type === ExperimentObjectiveSessionType.PLAIN;

    if (isPlain) {
      const entry = configValue as ConfigValue;
      params.configs = [{ session: null, entry }];
    } else {
      const configs = configValue as Record<string, ConfigValue>;
      params.configs = Object.keys(configs).map((session) => ({ session, entry: configs[session] }));
    }

    return params;
  }

  static mapParamsToConfigValue(params: ConfigsParams): ConfigValue | Record<string, ConfigValue> {
    if (params.configs[0].session === null) {
      return params.configs[0].entry;
    }

    const configValue: Record<string, ConfigValue> = {};

    params.configs.forEach(({ session, entry }) => {
      if (session) {
        const { type, value } = entry;
        const strValue = String(value);

        switch (type) {
          case ExperimentVariableWrapper.INT:
            configValue[session] = {
              value: Number(strValue),
              type
            };
            break;
          case ExperimentVariableWrapper.INT_LIST:
            configValue[session] = {
              value: strValue.split(',').map((str) => Number(str)),
              type
            };
            break;
          case ExperimentVariableWrapper.BOOLEAN:
          case ExperimentVariableWrapper.STRING:
          default:
            configValue[session] = entry;
            break;
        }
      }
    });

    return configValue;
  }

  static mapInputValueByType(
    input: { value: string; checked: boolean },
    type: ExperimentVariableWrapper
  ): boolean | string[] | string {
    const { value, checked } = input;

    switch (type) {
      case ExperimentVariableWrapper.BOOLEAN:
        return checked;
      case ExperimentVariableWrapper.INT_LIST:
        return value.split(',');
      default:
        return value;
    }
  }
}
