import React from 'react';
import { FieldArrayWithId, UseFormSetFocus } from 'react-hook-form';

import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { ConfigName } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/atoms/ConfigName';
import { EditableDescription } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/atoms/EditableDescription';
import { EditableCell } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/atoms/EditableCell';
import { FormComponent } from '@ui/hooks/form';

type Props = Pick<FormComponent<GLDConfigParams>, 'control'> & {
  setFocus: UseFormSetFocus<GLDConfigParams>;
  configs: FieldArrayWithId<GLDConfigParams, 'config'>[];
  handleRemoveConfig: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export function TBody({ configs, handleRemoveConfig, control, setFocus }: Props) {
  return (
    <tbody>
      {configs.map((config, configIndex) => (
        <tr key={config.id}>
          <ConfigName name={config.name} configIndex={configIndex} handleRemoveConfig={handleRemoveConfig} />
          <EditableDescription control={control} configIndex={configIndex} setFocus={setFocus} />
          {config.input.map((input, inputIndex) => (
            <EditableCell key={input.key} control={control} configIndex={configIndex} inputIndex={inputIndex} />
          ))}
        </tr>
      ))}
    </tbody>
  );
}
