import { registerDecorator, ValidationOptions, ValidationArguments } from 'class-validator';

export function IsGreaterThan(targetName: string, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'IsGreaterThan',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [targetName],
      options: validationOptions,
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          const relatedValue = (args.object as any)[relatedPropertyName];

          return Number(value) > Number(relatedValue);
        },
        defaultMessage(): string {
          return `${propertyName} must be greater than ${targetName}`;
        }
      }
    });
  };
}

export function IsGreaterThanOrEqual(targetName: string, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'IsGreaterThanOrEqual',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [targetName],
      options: validationOptions,
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          const relatedValue = (args.object as any)[relatedPropertyName];

          return Number(value) >= Number(relatedValue);
        },
        defaultMessage(): string {
          return `${propertyName} must be greater than or equal to ${targetName}`;
        }
      }
    });
  };
}

export function IsLessThan(targetName: string, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'IsLessThan',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [targetName],
      options: validationOptions,
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          const relatedValue = (args.object as any)[relatedPropertyName];

          return Number(value) < Number(relatedValue);
        },
        defaultMessage(): string {
          return `${propertyName} must be less than ${targetName}`;
        }
      }
    });
  };
}

export function IsLessThanOrEqual(targetName: string, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'IsLessThanOrEqual',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [targetName],
      options: validationOptions,
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          const relatedValue = (args.object as any)[relatedPropertyName];

          return Number(value) <= Number(relatedValue);
        },
        defaultMessage(): string {
          return `${propertyName} must be less than or equal to ${targetName}`;
        }
      }
    });
  };
}
