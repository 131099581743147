import React from 'react';
import { ModalWrapper } from 'crazy-ui';

type Props = {
  handleModal: () => void;
  chartName?: string;
  Chart: any;
};

export function FullScreenChartModal({ handleModal, chartName, Chart }: Props) {
  return (
    <ModalWrapper title={chartName} handleModal={handleModal} size="big">
      {Chart}
    </ModalWrapper>
  );
}
