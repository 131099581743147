import React from 'react';

import { GamePlatform } from '@domain/enums/GamePlatform';
import { ReactComponent as AppleSvg } from '@assets/svg/apple.svg';
import { ReactComponent as GooglePlaySvg } from '@assets/svg/google-play.svg';
import { ReactComponent as AsteriskSvg } from '@assets/svg/asterisk.svg';

type Props = {
  platform: GamePlatform;
  className?: string;
};

export function PlatformIcon({ platform, className }: Props) {
  switch (platform) {
    case GamePlatform.IOS: {
      return <AppleSvg className={className} />;
    }
    case GamePlatform.ANDROID: {
      return <GooglePlaySvg className={className} />;
    }
    default: {
      return <AsteriskSvg className={className} />;
    }
  }
}
