import React, { useCallback, useEffect } from 'react';
import { Button } from 'crazy-ui';

import { useDispatch, useSelector } from '@ui/hooks/redux';
import { fetchUsers, updateUserStatus, clearAll } from '@infrastructure/store/userManagement/userManagementActions';
import { Routing } from '@infrastructure/routing';
import { PageTitle } from '@components/layout/PageTitle';
import { UserManagementRow } from './UserManagementRow';
import { UserManagementFilterContainer } from './filters/UserManagementFilterContainer';
import { userManagementSelectors } from '@infrastructure/store/userManagement/userManagementSelectors';

export function UserManagementContainer() {
  const dispatch = useDispatch();
  const users = useSelector(userManagementSelectors.getProcessedUserList);

  useEffect(() => {
    dispatch(fetchUsers.trigger());
    return () => {
      dispatch(clearAll());
    };
  }, [dispatch]);

  const handleSetStatus = useCallback(
    (e: { id: number; isEnabled: boolean }) => {
      dispatch(updateUserStatus(e));
    },
    [dispatch]
  );

  const list = users.map((user) => (
    <UserManagementRow key={`u_${user.id}`} user={user} handleSetStatus={handleSetStatus} />
  ));

  return (
    <>
      <PageTitle label="Internal Users">
        <Button type={Button.NAV_LINK} to={Routing.getCreateUser()} variant={Button.FILL} color={Button.BLUE}>
          Add User
        </Button>
      </PageTitle>
      <main className="container pb-4">
        <UserManagementFilterContainer />
        <ul id="user-list">{list}</ul>
      </main>
    </>
  );
}
