import React, { useCallback } from 'react';
import { DropdownOption, InputLabel } from 'crazy-ui-next';
import cn from 'classnames';
import CreatableSelect from 'react-select/creatable';

import { DropdownIndicator } from '@components/lib/Select/atoms/DropdownIndicator';
import { MultiValueLabel } from '@components/lib/Select/atoms/MultiValueLabel';

import styles from './SelectCreatable.module.scss';

type Props = {
  handleChange: (e?) => void;
  handleCreateOption: (e?) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  options: DropdownOption[];
  isDisabled?: boolean;
  value: string;
  labelText: string;
  error?: string;
  className?: string;
  placeholder?: string;
  name?: string;
  isMulti?: boolean;
  isClearable?: boolean;
  disabledOptions?: string[];
};

export function SelectCreatable({
  name,
  value,
  options,
  handleChange,
  onFocus,
  onBlur,
  handleCreateOption,
  placeholder,
  labelText,
  error,
  className,
  disabledOptions
}: Props) {
  const formattedValue = value ? { value, label: value } : null;
  const isOptionDisabled = useCallback(
    (option: DropdownOption) => {
      if (!disabledOptions || !Boolean(disabledOptions.length)) {
        return false;
      }
      return disabledOptions.includes(option.value);
    },
    [disabledOptions]
  );

  return (
    <div className={styles.wrapper}>
      <InputLabel labelText={labelText} error={error} />
      <CreatableSelect
        name={name}
        options={options}
        className={cn(styles.creatableSelect, className)}
        components={{ MultiValueLabel, DropdownIndicator }}
        classNamePrefix="creatableSelect"
        onChange={handleChange}
        onCreateOption={handleCreateOption}
        placeholder={placeholder}
        value={formattedValue}
        isOptionDisabled={isOptionDisabled}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
}
