import React from 'react';

import { PlainValueText } from '@pages/winnerConfigModal/atoms/PlainValueText';
import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';

type Props = {
  data?: {
    type: string;
    configValue: Record<string, any>;
  };
};

export function ValueText({ data }: Props) {
  if (!data || data.type === ExperimentObjectiveSessionType.PLAIN) {
    return <PlainValueText value={data?.configValue.value} />;
  }

  const { type, configValue } = data;
  const sessionNames = Object.keys(configValue);

  return (
    <div>
      {sessionNames.map((sessionName, index) => (
        <p key={`${type}_${index}`}>
          <span>{sessionName}:</span> {`${configValue[sessionName].value}`}
        </p>
      ))}
    </div>
  );
}
