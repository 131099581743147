import { Service } from 'typedi';

import { BackendHttpClient } from '@infrastructure/http/BackendHttpClient';
import { LoginRequestDto } from '@domain/models/user/LoginRequestDto';

@Service()
export class AuthApi {
  constructor(protected readonly http: BackendHttpClient) {}

  async loginByCredentials(dto: LoginRequestDto): Promise<{ token: string }> {
    return this.http.post('/intops/public/signin', dto);
  }
}
