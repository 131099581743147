import { Dto } from '@domain/models/Dto';
import { UserRole } from '@domain/entities/UserRole';
import { UniqueId, Email } from '@domain/types';
import { Type } from 'class-transformer';

export class MyUserDto extends Dto {
  id?: UniqueId;
  username: string;
  email: Email;

  @Type(() => UserRole)
  authorities: UserRole[];

  public getCurrentRole() {
    return this.authorities[0].authority;
  }

  public isAdmin() {
    return this.authorities.some((role) => role.isAdminType());
  }
}
