import React from 'react';
import { DropdownEvent, DropdownInput, DropdownOption } from 'crazy-ui-next';

import { ExpBusinessLineFilterValue } from '@infrastructure/store/experimentList/types/filters';
import { ReactComponent as PathSvg } from '@assets/svg/path.svg';

const options: DropdownOption<ExpBusinessLineFilterValue>[] = [
  { value: ExpBusinessLineFilterValue.ALL, label: 'All' },
  { value: ExpBusinessLineFilterValue.CASUAL, label: 'Casual' },
  { value: ExpBusinessLineFilterValue.HYPER_CASUAL, label: 'Hyper Casual' }
];

type Props = {
  selected: ExpBusinessLineFilterValue;
  onChange: (e: DropdownEvent<ExpBusinessLineFilterValue>) => void;
};

export function BusinessLinesFilter({ onChange, selected }: Props) {
  return (
    <DropdownInput
      name="businessLine"
      inputIcon={<PathSvg />}
      value={selected}
      options={options}
      handleChange={onChange}
      labelText="Business Line"
    />
  );
}
