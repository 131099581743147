import { Service } from 'typedi';

import api from './api';
import { BackendHttpClient } from '@infrastructure/http/BackendHttpClient';
import { UniqueId } from '@domain/types';
import { IterationRequestParams } from '@domain/models/iteration/IterationRequestParams';
import { ChartDto } from '@domain/models/ChartDto';

@Service()
export class ChartApi {
  constructor(protected readonly http: BackendHttpClient) {}

  async getLossOverTimeChart(iterationId: UniqueId, params: IterationRequestParams): Promise<ChartDto> {
    return this.http.get(`/intops/admin/chart/lossovertime/${iterationId}`, params);
  }
  async getKPIWithConfidenceChart(iterationId: UniqueId, params: IterationRequestParams): Promise<ChartDto> {
    return this.http.get(`/intops/admin/chart/kpiwithconfidence/${iterationId}`, params);
  }
  async getKPIChart(iterationId: UniqueId, params: IterationRequestParams): Promise<ChartDto> {
    return this.http.get(`/intops/admin/chart/kpi/${iterationId}`, params);
  }
  async getUsersPerDayChart(iterationId: UniqueId, params: IterationRequestParams): Promise<ChartDto> {
    return this.http.get(`/intops/admin/chart/usersperday/${iterationId}`, params);
  }
  async getKPISanityChart(iterationId: UniqueId, params: IterationRequestParams): Promise<ChartDto> {
    return this.http.get(`/intops/admin/chart/abstatskpi/${iterationId}`, params);
  }
  async getUsersSanityChart(iterationId: UniqueId, params: IterationRequestParams): Promise<ChartDto> {
    return this.http.get(`/intops/admin/chart/abstatsusers/${iterationId}`, params);
  }
}

const getFilters = ({ arpu, region, version }) => {
  const filters = {
    arpu: undefined,
    region: undefined,
    version: undefined
  };

  if (arpu) {
    filters.arpu = arpu;
  }

  if (region) {
    filters.region = region;
  }

  if (version) {
    filters.version = version;
  }

  return filters;
};

/**
 * @deprecated use ChartApi
 */
export default class OldChartApi {
  static getPrecisionChart({ iterationId, arpu, region, version }) {
    return api.get(`/intops/admin/chart/precisionvscontrol/${iterationId}`, {
      params: getFilters({ arpu, region, version })
    });
  }

  static getLossOverTimeChart({ iterationId, arpu, region, version }) {
    return api.get(`/intops/admin/chart/lossovertime/${iterationId}`, {
      params: getFilters({ arpu, region, version })
    });
  }

  static getKPIwithConfidenceChart({ iterationId, arpu, region, version }) {
    return api.get(`/intops/admin/chart/kpiwithconfidence/${iterationId}`, {
      params: getFilters({ arpu, region, version })
    });
  }

  static getKPIChart({ iterationId, arpu, region, version }) {
    return api.get(`/intops/admin/chart/kpi/${iterationId}`, {
      params: getFilters({ arpu, region, version })
    });
  }

  static getUsersPerDayChart({ iterationId, arpu, region, version }) {
    return api.get(`/intops/admin/chart/usersperday/${iterationId}`, {
      params: getFilters({ arpu, region, version })
    });
  }

  static getKPISanityChart({ iterationId }) {
    return api.get(`/intops/admin/chart/abstatskpi/${iterationId}`);
  }

  static getUsersSanityChart({ iterationId }) {
    return api.get(`/intops/admin/chart/abstatsusers/${iterationId}`);
  }
}
