import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import { Service } from 'typedi';

import { WinnerConfigService } from '@app/services/WinnerConfigService';
import { GameService } from '@app/services/GameService';
import { ExperimentService } from '@app/services/ExperimentService';
import { RegionService } from '@app/services/RegionService';
import { ChartService } from '@app/services/ChartService';
import { LogsService } from '@app/services/LogsService';

import { RootSagaWatcher } from '@infrastructure/sagas/RootSagaWatcher';
import { BaseSagaWatcher } from '@infrastructure/sagas/BaseSagaWatcher';
import { Notification } from '@infrastructure/api/notifications';
import {
  deleteExperimentWinner,
  pushStopGroups,
  pushUpdateExperimentDefinition,
  pushUpdateExpObjective,
  updateExperimentWinner,
  fetchGameStats,
  pushRestoreObjective,
  pushStopObjective,
  applyExperimentWinner,
  fetchExperiment,
  pushUpdateDevPhase,
  fetchDefaultRegions,
  fetchDetailsView,
  fetchExperimentIterations,
  fetchIterationResult,
  setCacheIterationResult,
  setIterationFilters,
  setSelectedIteration,
  fetchCharts,
  setCacheCharts,
  fetchExperimentAudit,
  fetchExperimentLogList,
  fetchExperimentLog,
  pushExperimentLogName,
  pushRequestAnalysis,
  pushScheduleGLDExperiment,
  pushCancelExperiment
} from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';

import { ExperimentType } from '@domain/enums/ExperimentType';
import { ExperimentState } from '@domain/enums/ExperimentState';
import { fetchUserProperties } from '@infrastructure/store/config/configActions';
import { UserPropertiesDto } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';

@Service()
export class ExperimentDetailsSaga extends BaseSagaWatcher {
  constructor(
    private experimentService: ExperimentService,
    private winnerConfigService: WinnerConfigService,
    private gameService: GameService,
    private regionService: RegionService,
    private chartService: ChartService,
    private logsService: LogsService,
    rootWatcher: RootSagaWatcher
  ) {
    super(rootWatcher);
  }

  public getEffects() {
    return [
      takeLatest(fetchDetailsView.trigger, this.fetchDetailsView.bind(this)),

      takeLatest(pushUpdateExperimentDefinition.TRIGGER, this.updateExperimentDefinition.bind(this)),
      takeLatest(pushUpdateExpObjective.TRIGGER, this.updateExperimentObjective.bind(this)),
      takeLatest(pushStopGroups.TRIGGER, this.stopGroups.bind(this)),

      takeLatest(pushRestoreObjective.TRIGGER, this.restoreObjective.bind(this)),
      takeLatest(pushStopObjective.TRIGGER, this.stopObjective.bind(this)),

      takeLatest(applyExperimentWinner.TRIGGER, this.applyExperimentWinner.bind(this)),
      takeLatest(updateExperimentWinner.TRIGGER, this.updateExperimentWinner.bind(this)),
      takeLatest(deleteExperimentWinner.TRIGGER, this.deleteExperimentWinner.bind(this)),

      takeLatest(pushUpdateDevPhase.trigger, this.updateDevPhase.bind(this)),

      takeLatest(fetchExperiment.TRIGGER, this.fetchExperiment.bind(this)),
      takeLatest(pushRestoreObjective.SUCCESS, this.fetchExperiment.bind(this)),
      takeLatest(pushStopObjective.SUCCESS, this.fetchExperiment.bind(this)),
      takeLatest(applyExperimentWinner.SUCCESS, this.fetchExperiment.bind(this)),
      takeLatest(updateExperimentWinner.SUCCESS, this.fetchExperiment.bind(this)),
      takeLatest(deleteExperimentWinner.SUCCESS, this.fetchExperiment.bind(this)),

      takeLatest(pushUpdateExperimentDefinition.SUCCESS, this.fetchExperiment.bind(this)),
      takeLatest(pushUpdateExpObjective.SUCCESS, this.fetchExperiment.bind(this)),
      takeLatest(pushStopGroups.SUCCESS, this.fetchExperiment.bind(this)),

      takeLatest(fetchExperimentIterations.trigger, this.fetchExperimentIterations.bind(this)),
      takeLatest(fetchExperimentIterations.success, this.setSelectedIterationWithResults.bind(this)),

      takeLatest(setIterationFilters, this.setSelectedIterationWithResults.bind(this)),
      takeLatest(setSelectedIteration, this.fetchIterationResult.bind(this)),

      takeLatest(fetchIterationResult.success, this.fetchCharts.bind(this)),
      takeLatest(fetchExperimentAudit.trigger, this.fetchAudit.bind(this)),

      takeLatest(fetchExperimentLogList.trigger, this.fetchExperimentLogList.bind(this)),
      takeLatest(fetchExperimentLog.trigger, this.fetchExperimentLog.bind(this)),
      takeLatest(pushExperimentLogName, this.fetchExperimentLog.bind(this)),
      takeLatest(pushRequestAnalysis.trigger, this.requestAnalysis.bind(this)),

      takeLatest(pushUpdateDevPhase.success, this.fetchExperiment.bind(this)),

      takeLatest(pushScheduleGLDExperiment.trigger, this.pushScheduleGLDExperiment.bind(this)),
      takeLatest(pushCancelExperiment.trigger, this.pushCancelExperiment.bind(this)),
      takeLatest(pushScheduleGLDExperiment.success, this.fetchExperiment.bind(this)),
      takeLatest(pushCancelExperiment.success, this.fetchExperiment.bind(this))
    ];
  }

  public *fetchDetailsView(action: ReturnType<typeof fetchDetailsView.trigger>) {
    const experimentId = action.payload;

    try {
      yield put(fetchDetailsView.request());

      yield call(this.fetchExperiment.bind(this), { payload: experimentId });
      yield call(this.fetchUserProperties.bind(this));

      const { experimentType, state } = yield select(experimentDetailsSelectors.getExperiment);

      const isGLD = experimentType === ExperimentType.GLD_TEST;
      const isInDev = state === ExperimentState.IN_DEV;

      if (isGLD && isInDev) {
        yield all([call(this.fetchDefaultRegions.bind(this)), call(this.fetchGameStats.bind(this))]);
      }

      yield put(fetchDetailsView.success());
    } catch (err) {
      Notification.error(err);
      yield put(fetchDetailsView.failure());
    }
  }

  public *fetchExperiment(action: ReturnType<typeof fetchExperiment.trigger>) {
    const experimentId = action.payload;

    try {
      yield put(fetchExperiment.request());

      const experiment = yield call([this.experimentService, this.experimentService.getExperiment], experimentId);

      yield put(fetchExperiment.success(experiment));
    } catch (err) {
      Notification.error(err);
      yield put(fetchExperiment.failure());
    }
  }

  public *fetchUserProperties() {
    try {
      yield put(fetchUserProperties.request());

      const result: UserPropertiesDto = yield call([
        this.experimentService,
        this.experimentService.fetchUserProperties
      ]);

      yield put(fetchUserProperties.success(result));
    } catch (err) {
      yield put(fetchUserProperties.failure());
      Notification.error(err);
    }
  }

  public *fetchDefaultRegions() {
    try {
      yield put(fetchDefaultRegions.request());

      const defaultRegions = yield call([this.regionService, this.regionService.getDefaultRegions]);

      yield put(fetchDefaultRegions.success(defaultRegions));
    } catch (err) {
      Notification.error(err);
      yield put(fetchDefaultRegions.failure());
    }
  }

  public *updateDevPhase(action: ReturnType<typeof pushUpdateDevPhase.trigger>) {
    try {
      yield put(pushUpdateDevPhase.request());

      const { experimentId } = yield select(experimentDetailsSelectors.getExperiment);

      yield call([this.experimentService, this.experimentService.updateDevPhase], action.payload);

      Notification.success('The experiment config has been updated.');
      yield put(pushUpdateDevPhase.success(experimentId));
    } catch (err) {
      Notification.error(err);
      yield put(pushUpdateDevPhase.failure());
    }
  }

  public *updateExperimentDefinition(action: ReturnType<typeof pushUpdateExperimentDefinition.trigger>) {
    try {
      yield put(pushUpdateExperimentDefinition.request());

      const { experimentId } = yield select(experimentDetailsSelectors.getExperiment);

      yield call([this.experimentService, this.experimentService.updateDefinition], action.payload);

      yield put(pushUpdateExperimentDefinition.success(experimentId));
      Notification.success('The experiment config has been updated.');
    } catch (err) {
      Notification.error(err);
      yield put(pushUpdateExperimentDefinition.failure());
    }
  }

  public *updateExperimentObjective(action: ReturnType<typeof pushUpdateExpObjective.trigger>) {
    try {
      yield put(pushUpdateExpObjective.request());

      const { experimentId } = yield select(experimentDetailsSelectors.getExperiment);

      yield call([this.experimentService, this.experimentService.updateObjective], action.payload);

      yield put(pushUpdateExpObjective.success(experimentId));
      Notification.success('The experiment config has been updated.');
    } catch (err) {
      Notification.error(err);
      yield put(pushUpdateExpObjective.failure());
    }
  }

  public *stopGroups(action: ReturnType<typeof pushStopGroups.trigger>) {
    try {
      yield put(pushStopGroups.request());

      const { experimentId } = yield select(experimentDetailsSelectors.getExperiment);

      yield call([this.experimentService, this.experimentService.stopGroups], action.payload);

      yield put(pushStopGroups.success(experimentId));
      Notification.success('The experiment config has been updated.');
    } catch (err) {
      Notification.error(err);
      yield put(pushStopGroups.failure());
    }
  }

  public *updateExperimentWinner(action: ReturnType<typeof updateExperimentWinner.trigger>) {
    try {
      yield put(updateExperimentWinner.request());

      yield call([this.winnerConfigService, this.winnerConfigService.update], action.payload);

      yield put(updateExperimentWinner.success(action.payload.experimentId));
    } catch (err) {
      Notification.error(err);
      yield put(updateExperimentWinner.failure());
    }
  }

  public *applyExperimentWinner(action: ReturnType<typeof applyExperimentWinner.trigger>) {
    try {
      yield put(applyExperimentWinner.request());

      yield call([this.winnerConfigService, this.winnerConfigService.apply], action.payload);

      yield put(applyExperimentWinner.success(action.payload.experimentId));
    } catch (err) {
      Notification.error(err);
      yield put(applyExperimentWinner.failure());
    }
  }

  public *deleteExperimentWinner(action: ReturnType<typeof deleteExperimentWinner.trigger>) {
    try {
      yield put(deleteExperimentWinner.request());

      yield call(
        [this.winnerConfigService, this.winnerConfigService.delete],
        action.payload.experimentId,
        action.payload.objectiveId
      );

      yield put(deleteExperimentWinner.success(action.payload.experimentId));
    } catch (err) {
      Notification.error(err);
      yield put(deleteExperimentWinner.failure());
    }
  }

  public *fetchGameStats() {
    try {
      yield put(fetchGameStats.request());

      const { id } = yield select(experimentDetailsSelectors.getGameApp);
      const result = yield call([this.gameService, this.gameService.fetchGameStats], id);

      yield put(fetchGameStats.success(result));
    } catch (err) {
      yield put(fetchGameStats.failure());
      Notification.warning('Failed to fetch game stats');
    }
  }

  public *restoreObjective(action: ReturnType<typeof pushRestoreObjective.trigger>) {
    try {
      yield put(pushRestoreObjective.request());

      yield call(
        [this.experimentService, this.experimentService.restoreObjective],
        action.payload.experimentId,
        action.payload.objectiveId
      );

      yield put(pushRestoreObjective.success(action.payload.experimentId));
    } catch (err) {
      Notification.error(err);
      yield put(pushRestoreObjective.failure());
    }
  }

  public *stopObjective(action: ReturnType<typeof pushStopObjective.trigger>) {
    try {
      yield put(pushStopObjective.request());

      yield call(
        [this.experimentService, this.experimentService.stopObjective],
        action.payload.experimentId,
        action.payload.objectiveId
      );

      yield put(pushStopObjective.success(action.payload.experimentId));
    } catch (err) {
      Notification.error(err);
      yield put(pushStopObjective.failure());
    }
  }

  public *fetchExperimentIterations(action: ReturnType<typeof fetchExperimentIterations.trigger>) {
    try {
      yield put(fetchExperimentIterations.request());

      const result = yield call(
        [this.experimentService, this.experimentService.getExperimentIterations],
        action.payload
      );

      yield put(fetchExperimentIterations.success(result));
    } catch (err) {
      Notification.error(err);
      yield put(fetchExperimentIterations.failure());
    }
  }

  public *setSelectedIterationWithResults() {
    try {
      const iteration = yield call([this.experimentService, this.experimentService.getLastDoneIterationWithResultsId]);

      if (iteration) {
        yield put(setSelectedIteration(iteration));
      }
    } catch (err) {
      Notification.error(err);
    }
  }

  public *fetchIterationResult() {
    try {
      yield put(fetchIterationResult.request());

      const { result, cacheKey, isNew } = yield call([
        this.experimentService,
        this.experimentService.getIterationResult
      ]);

      if (isNew) {
        yield put(setCacheIterationResult({ [cacheKey]: result }));
      }

      yield put(fetchIterationResult.success(result));
    } catch (err) {
      Notification.error(err);
      yield put(fetchIterationResult.failure());
    }
  }

  public *fetchCharts() {
    try {
      yield put(fetchCharts.request());

      const { result, cacheKey, isNew } = yield call([this.chartService, this.chartService.getIterationCharts]);

      if (isNew) {
        yield put(setCacheCharts({ [cacheKey]: result }));
      }

      yield put(fetchCharts.success(result));
    } catch (err) {
      Notification.error(err);
      yield put(fetchCharts.failure());
    }
  }

  public *fetchAudit() {
    try {
      yield put(fetchExperimentAudit.request());

      const result = yield call([this.experimentService, this.experimentService.getAudit]);

      yield put(fetchExperimentAudit.success(result));
    } catch (err) {
      Notification.error(err);
      yield put(fetchExperimentAudit.failure());
    }
  }

  public *fetchExperimentLogList() {
    try {
      yield put(fetchExperimentLogList.request());

      const result = yield call([this.logsService, this.logsService.getExperimentLogList]);

      yield put(fetchExperimentLogList.success(result));
    } catch (err) {
      Notification.error(err);
      yield put(fetchExperimentLogList.failure());
    }
  }

  public *fetchExperimentLog() {
    try {
      yield put(fetchExperimentLog.request());

      const result = yield call([this.logsService, this.logsService.getExperimentLog]);

      yield put(fetchExperimentLog.success(result));
    } catch (err) {
      Notification.error(err);
      yield put(fetchExperimentLog.failure());
    }
  }

  public *requestAnalysis() {
    try {
      yield put(pushRequestAnalysis.request());

      yield call([this.experimentService, this.experimentService.requestAnalysis]);

      yield put(pushRequestAnalysis.success());
      Notification.success('Analysis was requested successfully');
    } catch (err) {
      Notification.error(err);
      yield put(pushRequestAnalysis.failure());
    }
  }

  public *pushScheduleGLDExperiment(action: ReturnType<typeof pushScheduleGLDExperiment.trigger>) {
    const experimentId = action.payload;

    try {
      yield put(pushScheduleGLDExperiment.request());

      yield call([this.experimentService, this.experimentService.scheduleGLD], experimentId);

      yield put(pushScheduleGLDExperiment.success(experimentId));
      Notification.success('The experiment has been scheduled');
    } catch (err) {
      Notification.error(err);
      yield put(pushScheduleGLDExperiment.failure());
    }
  }

  public *pushCancelExperiment(action: ReturnType<typeof pushCancelExperiment.trigger>) {
    const experimentId = action.payload;

    try {
      yield put(pushCancelExperiment.request());

      yield call([this.experimentService, this.experimentService.cancelExperiment], experimentId);

      yield put(pushCancelExperiment.success(experimentId));
      Notification.success('The experiment has been canceled');
    } catch (err) {
      Notification.error(err);
      yield put(pushCancelExperiment.failure());
    }
  }
}
