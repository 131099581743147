import { DropdownInput } from 'crazy-ui';
import React from 'react';

import { DropdownEvent, DropdownOption } from 'crazy-ui-next';
import { UserSortValue } from '@infrastructure/store/userManagement/types/filters';

const options: DropdownOption<UserSortValue>[] = [
  { value: UserSortValue.NAME_ASC, label: 'Name ASC' },
  { value: UserSortValue.NAME_DESC, label: 'Name DESC' }
];

type Props = {
  handleChange: (e: DropdownEvent<UserSortValue>) => void;
  value: UserSortValue;
};

export function UserSort({ handleChange, value }: Props) {
  return (
    <DropdownInput id="user-sort-by" labelText="Sort By" options={options} value={value} handleChange={handleChange} />
  );
}
