import { call, put, takeLatest } from 'redux-saga/effects';
import { Service } from 'typedi';

import { RootSagaWatcher } from '@infrastructure/sagas/RootSagaWatcher';
import { BaseSagaWatcher } from '@infrastructure/sagas/BaseSagaWatcher';
import { Notification } from '@infrastructure/api/notifications';
import { UserManagementService } from '@app/services/UserManagementService';
import {
  fetchUsers,
  pushUserCreate,
  pushUserUpdate,
  updateUserStatus
} from '@infrastructure/store/userManagement/userManagementActions';
import { UserDto } from '@domain/models/user/UserDto';

@Service()
export class UserManagementSaga extends BaseSagaWatcher {
  constructor(private userManagementService: UserManagementService, protected rootWatcher: RootSagaWatcher) {
    super(rootWatcher);
  }

  getEffects() {
    return [
      takeLatest(fetchUsers.TRIGGER, this.getUsers.bind(this)),
      takeLatest(pushUserCreate.TRIGGER, this.createNewUser.bind(this)),
      takeLatest(pushUserCreate.SUCCESS, this.afterEdit.bind(this)),
      takeLatest(pushUserUpdate.TRIGGER, this.updateUser.bind(this)),
      takeLatest(pushUserUpdate.SUCCESS, this.afterEdit.bind(this)),
      takeLatest(updateUserStatus, this.updateUserStatus.bind(this))
    ];
  }

  *afterEdit() {
    yield call([this.userManagementService, this.userManagementService.navigateToMainPage]);
  }

  *getUsers() {
    try {
      yield put(fetchUsers.request());
      const result: UserDto[] = yield call([this.userManagementService, this.userManagementService.getUsers]);
      yield put(fetchUsers.success(result));
    } catch (err) {
      yield put(fetchUsers.failure());
      Notification.error(err);
    }
  }

  *updateUserStatus(action: ReturnType<typeof updateUserStatus>) {
    try {
      const { id, isEnabled } = action.payload;
      yield put(pushUserUpdate.request());
      const result: UserDto = yield call(
        [this.userManagementService, this.userManagementService.setUserStatus],
        id,
        isEnabled
      );
      yield put(pushUserUpdate.success(result));
    } catch (err) {
      yield put(pushUserUpdate.failure());
      Notification.error(err);
    }
  }

  *updateUser(action: ReturnType<typeof pushUserUpdate.trigger>) {
    try {
      yield put(pushUserUpdate.request());
      const result: UserDto = yield call(
        [this.userManagementService, this.userManagementService.updateUser],
        action.payload
      );
      yield put(pushUserUpdate.success(result));
      Notification.success('User has been updated');
    } catch (err) {
      yield put(pushUserUpdate.failure());
      Notification.error(err);
    }
  }

  *createNewUser(action: ReturnType<typeof pushUserCreate.trigger>) {
    try {
      yield put(pushUserCreate.request());
      const result: UserDto = yield call(
        [this.userManagementService, this.userManagementService.createUser],
        action.payload
      );
      yield put(pushUserCreate.success(result));
      Notification.success('User has been created');
    } catch (err) {
      yield put(pushUserCreate.failure());
      Notification.error(err);
    }
  }
}
