import React from 'react';
import { uniq } from 'lodash-es';
import { memoize } from 'proxy-memoize';

import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';
import { ValueText } from '@pages/winnerConfigModal/atoms/ValueText';
import { useSelector } from '@ui/hooks/redux';

import styles from '@pages/winnerConfigModal/list/List.module.scss';

type Props = {
  isHeaderRow: boolean;
  selectedGroup: string;
  objective: ExperimentObjectiveDto;
};

export function Variables({ selectedGroup, isHeaderRow, objective }: Props) {
  const configs = objective.configs.find(({ name }) => name === selectedGroup);
  const variableTitleMap = useSelector(memoize(configSelectors.getVariableTitleMap));
  const variableKeys: string[] = [];

  objective.configs.forEach(({ entry }) => variableKeys.push(...Object.keys(entry)));
  const uniqVariableKeys = uniq(variableKeys);

  const variables = uniqVariableKeys.map((varName) => {
    return (
      <div className={styles.col} key={`${varName}_data`}>
        <ValueText data={configs?.entry[varName]} />
        {isHeaderRow && (
          <div className={styles.heading}>
            <p>{variableTitleMap[varName] || varName}</p>
          </div>
        )}
      </div>
    );
  });

  return <>{variables}</>;
}
