import React from 'react';

import { Summary } from '@pages/experimentDetails/components/summary/Summary';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { useSelector } from '@ui/hooks/redux';
import { useToggle } from '@ui/hooks/useToggle';

export function SummaryContainer() {
  const { isActive, handleToggle } = useToggle(false);

  const experiment = useSelector(experimentDetailsSelectors.getExperiment);

  return <Summary experiment={experiment} isActive={isActive} handleToggle={handleToggle} />;
}
