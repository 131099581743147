import { ActionFactory } from '@infrastructure/store/helpers/ActionFactory';
import { UserDto } from '@domain/models/user/UserDto';
import {
  UserRoleFilterValue,
  UserSortValue,
  UserStatusFilterValue
} from '@infrastructure/store/userManagement/types/filters';
import { UniqueId } from '@domain/types';
import { CreateUserParams, UpdateUserParams } from '@domain/models/user/UserReqParams';

const namespace = 'userManagement';

export const fetchUsers = ActionFactory.createRequestRoutine<void, UserDto[]>('USERS_FETCH', namespace);
export const pushUserCreate = ActionFactory.createRequestRoutine<CreateUserParams, UserDto>(
  'USER_CREATE_PUSH',
  namespace
);
export const pushUserUpdate = ActionFactory.createRequestRoutine<UpdateUserParams, UserDto>(
  'USER_UPDATE_PUSH',
  namespace
);

export const updateUserStatus = ActionFactory.createAction<{ id: UniqueId; isEnabled: boolean }>(
  'USER_STATUS_UPDATE',
  namespace
);

export const setSearchPhraseFilter = ActionFactory.createAction<string>('USER_SEARCH_FILTER', namespace);
export const setStatusFilter = ActionFactory.createAction<UserStatusFilterValue>('USER_STATUS_FILTER', namespace);
export const setRoleFilter = ActionFactory.createAction<UserRoleFilterValue>('USER_ROLE_FILTER', namespace);
export const setSortBy = ActionFactory.createAction<UserSortValue>('USER_SORT_BY', namespace);

export const resetFilters = ActionFactory.createAction('RESET_FILTERS', namespace);
export const clearAll = ActionFactory.createAction('CLEAR_ALL', namespace);
