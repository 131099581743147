import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { experimentListSelectors } from '@infrastructure/store/experimentList/experimentListSelectors';

import {
  handleBusinessLinesFilter,
  handlePlatformFilter,
  handleDateFilter,
  handleSearchPhraseFilter,
  handleStatusFilter,
  handleTypeFilter
} from '@infrastructure/store/experimentList/experimentListActions';

import ExperimentFilter from './ExperimentFilter';

export default function ExperimentFilterContainer() {
  const dispatch = useDispatch();
  const {
    searchPhrase,
    type,
    statuses,
    businessLines,
    platform,
    dates: { startDate, endDate }
  } = useSelector(experimentListSelectors.getFilters);

  const handleSearch = useCallback(
    ({ value }) => {
      dispatch(handleSearchPhraseFilter(value));
    },
    [dispatch]
  );

  const handleStatuses = useCallback(
    ({ value }) => {
      dispatch(handleStatusFilter(value));
    },
    [dispatch]
  );

  const handleType = useCallback(
    ({ value }) => {
      dispatch(handleTypeFilter(value));
    },
    [dispatch]
  );

  const handleBusinessLines = useCallback(
    ({ value }) => {
      dispatch(handleBusinessLinesFilter(value));
    },
    [dispatch]
  );

  const handlePlatform = useCallback(
    ({ value }) => {
      dispatch(handlePlatformFilter(value));
    },
    [dispatch]
  );

  const handleDateRange = useCallback(
    (e) => {
      dispatch(handleDateFilter(e));
    },
    [dispatch]
  );

  return (
    <ExperimentFilter
      handleDateRange={handleDateRange}
      handleSearch={handleSearch}
      handleStatuses={handleStatuses}
      handleType={handleType}
      handlePlatform={handlePlatform}
      searchPhrase={searchPhrase}
      type={type}
      statuses={statuses}
      platform={platform}
      handleBusinessLines={handleBusinessLines}
      businessLines={businessLines}
      startDate={startDate}
      endDate={endDate}
    />
  );
}
