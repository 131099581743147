/**
 * Represents api response with the list of records for particular dto
 * @see PageablePayload.java
 */
export interface Paginate<T> {
  thisPageItems: T[];
  totalNumber: number;
  moreAvailable: boolean;
  lastPageIndex: number;
}

export type PageReqParams = {
  page: number;
  size: number;
};

export enum PageSortDir {
  ASC = 'asc',
  DESC = 'desc'
}

/**
 * @example PageSortValue<{ id: number; name: string}> or PageSortValue<'id', 'name'>
 * @return {string} "name" or "name,asc" or "name,desc"
 * */
export type PageSortValue<T> = T extends string
  ? T | `${T},${PageSortDir}`
  : `${string & keyof T}` | `${string & keyof T},${PageSortDir}`;
