import React from 'react';
import { GamePlatform } from '@domain/enums/GamePlatform';

import { PlatformIcon } from '@components/layout/platformIcon/PlatformIcon';
import { getTitleForPlatforms } from '@modules/config/utils/getTitleFromKey';

import styles from '@pages/experimentDetails/atoms/labels/Labels.module.scss';

type Props = {
  platform: GamePlatform;
};

export function Platform({ platform }: Props) {
  return (
    <div className={styles.platform}>
      <PlatformIcon platform={platform} className={styles.icon} />
      <div className={styles.title}>Platform:</div>
      <div className={styles.value}>{getTitleForPlatforms(platform)}</div>
    </div>
  );
}
