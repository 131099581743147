export enum AuditEntityType {
  EXPERIMENT = 'EXPERIMENT',
  CONFIG = 'CONFIG',
  USER = 'USER',
  REGION = 'REGION',
  APP_CONFIGURATION = 'APP_CONFIGURATION',
  AUTOMATIC_CONFIG_UPDATE = 'AUTOMATIC_CONFIG_UPDATE',
  AUTOMATIC_EXPERIMENT_STOP = 'AUTOMATIC_EXPERIMENT_STOP',
  PERCENT_ALLOCATION_UPDATE = 'PERCENT_ALLOCATION_UPDATE'
}

export enum AuditKey {
  CHANGES = 'changes',
  EVENT_DATE = 'eventDate',
  SOURCE_METHOD = 'sourceMethod',
  USERNAME = 'username'
}

export const AuditKeysTitle = {
  [AuditKey.CHANGES]: 'Changes',
  [AuditKey.EVENT_DATE]: 'Date',
  [AuditKey.USERNAME]: 'Username',
  [AuditKey.SOURCE_METHOD]: 'Action'
};
