import { Dto } from '@domain/models/Dto';
import { IsNotEmpty, IsNumber, IsString, IsBoolean, IsInt } from 'class-validator';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';

export class NewExperimentObjectiveDto extends Dto {
  @IsString()
  public experimentType: ExperimentType;

  @IsString()
  public description: string;

  @IsNotEmpty()
  public configs: GenericConfigEntry[];

  @IsNotEmpty()
  public controlGroup: Record<string, object>;

  @IsBoolean()
  @IsNotEmpty()
  public newUsers: boolean;

  @IsBoolean()
  public sticky: boolean;

  @IsNumber()
  public usersAllocationPercent: number;

  @IsBoolean()
  public adjustableUsersAllocation: boolean;

  public input: object[];

  @IsBoolean()
  public cloneControlGroup: boolean;

  @IsBoolean()
  public builtInGroup: boolean;

  @IsNumber()
  @IsNotEmpty()
  public primaryRegion: number; // Region Id

  @IsInt({ each: true })
  @IsNotEmpty()
  public regions: number[];

  @IsInt({ each: true })
  @IsNotEmpty()
  public regionsForInDev: number[];

  @IsString({ each: true })
  public appVersions: string[];
}
