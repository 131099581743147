import { BackendHttpClient } from '@infrastructure/http/BackendHttpClient';
import { Service } from 'typedi';
import { PageReqParams, Paginate } from '@domain/models/Paginate';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import { ExperimentListReqParams } from '@domain/models/experiment/ExperimentListReqParams';
import { OffsetPagination } from '@domain/models/local/OffsetPagination';
import { ResponseMapper } from '@app/mappers/ResponseMapper';
import { UniqueId } from '@domain/types';
import { UpdateExperimentDto } from '@domain/models/experiment/UpdateExperimentDto';
import { UpdateExperimentObjectiveDto } from '@domain/models/experiment/UpdateExperimentObjectiveDto';
import { MessageResponseDto } from '@domain/models/MessageResponseDto';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { NewExperimentDto } from '@domain/models/experiment/NewExperimentDto';
import { GenericConfigResponseDto } from '@domain/models/experiment/GenericConfigResponseDto';
import { NewExperimentObjectiveDto } from '@domain/models/experiment/NewExperimentObjectiveDto';
import { OverlappedConfigDto } from '@domain/models/experiment/OverlappedConfigDto';
import { ProfileRecommendationResponse } from '@domain/models/RecommendedProfile';
import { RecommendationReqParams } from '@domain/models/experiment/RecommendationReqParams';
import { IterationRequestParams } from '@domain/models/iteration/IterationRequestParams';
import { IterationResultResponseDto } from '@domain/models/iteration/IterationResultResponseDto';
import { AuditDto } from '@domain/models/experiment/AuditDto';

@Service()
export class ExperimentApi {
  constructor(protected readonly http: BackendHttpClient) {}

  async getExperimentList(
    filters?: ExperimentListReqParams,
    pagination?: OffsetPagination
  ): Promise<Paginate<ExperimentDto>> {
    let params: ExperimentListReqParams | PageReqParams = filters || {};

    if (pagination) {
      params = { ...params, ...pagination.getParams() };
    }

    return this.http.get('/intops/admin/experiment', params).then(ResponseMapper.mapToPageExperimentDto);
  }

  async getExperiment(experimentId: UniqueId): Promise<ExperimentDto> {
    return this.http.get(`/intops/admin/experiment/${experimentId}`).then(ResponseMapper.mapToExperimentDto);
  }

  async createExperiment(body: NewExperimentDto): Promise<ExperimentDto> {
    return this.http.post('/intops/admin/experiment', body);
  }

  async updateExperiment(experimentId: UniqueId, body: UpdateExperimentDto): Promise<MessageResponseDto> {
    return this.http.put(`/intops/admin/experiment/${experimentId}`, body);
  }

  async updateExperimentObjective(
    experimentId: UniqueId,
    body: UpdateExperimentObjectiveDto
  ): Promise<MessageResponseDto> {
    return this.http.put(`/intops/admin/experiment/${experimentId}/objective/`, body);
  }

  async scheduleGldExperiment(experimentId: UniqueId): Promise<MessageResponseDto> {
    return this.http.put(`/intops/admin/experiment/${experimentId}/scheduleGld`);
  }

  async cancelExperiment(experimentId: UniqueId): Promise<MessageResponseDto> {
    return this.http.delete(`/intops/admin/experiment/${experimentId}`);
  }

  async runNextConfig(
    experimentId: UniqueId,
    objectiveId: UniqueId,
    stoppedGroups: string[]
  ): Promise<MessageResponseDto> {
    return this.http.put(`/intops/admin/experiment/${experimentId}/objective/${objectiveId}/nextconfig`, {
      stoppedGroups
    });
  }

  async validateConfig(payload: NewExperimentObjectiveDto): Promise<GenericConfigResponseDto> {
    return this.http.post('/intops/admin/experiment/estimateGenericConfig', payload);
  }

  async validateOverlappingExperiment(
    gameAppId: UniqueId,
    countriesToCheck: string[],
    versionsToCheck: string[],
    experimentType: ExperimentType
  ): Promise<OverlappedConfigDto[]> {
    return this.http
      .get(`/intops/admin/experiment/overlapped/${gameAppId}`, { countriesToCheck, versionsToCheck, experimentType })
      .then((list) => list?.map(ResponseMapper.mapToOverlappedConfigDto) || []);
  }

  async getRecommendedProfile(payload: RecommendationReqParams): Promise<ProfileRecommendationResponse> {
    return this.http.post('/intops/recommendation/profile', payload);
  }

  async restoreObjective(experimentId: UniqueId, objectiveId: UniqueId): Promise<MessageResponseDto> {
    return this.http.post(`/intops/admin/experiment/${experimentId}/objective/${objectiveId}/restore`, {});
  }

  async stopObjective(experimentId: UniqueId, objectiveId: UniqueId): Promise<MessageResponseDto> {
    return this.http.delete(`/intops/admin/experiment/${experimentId}/objective/${objectiveId}`);
  }

  async getExperimentIterations(expId: UniqueId): Promise<any> {
    return this.http.get(`/intops/admin/iteration/${expId}`);
  }

  async getIterationResult(
    experimentType: ExperimentType,
    iterationId: UniqueId,
    params: IterationRequestParams
  ): Promise<IterationResultResponseDto> {
    return this.http.get(`/intops/admin/iteration/${experimentType}/${iterationId}/results`, params);
  }

  async getExperimentAudit(experimentId: UniqueId): Promise<AuditDto[]> {
    return this.http.get(`/intops/admin/experiment/${experimentId}/audit`);
  }

  async requestAnalysis(iterationId: UniqueId): Promise<MessageResponseDto> {
    return this.http.get(`/intops/admin/analyze/${iterationId}`);
  }
}
