import { UserRoleType } from '@domain/enums/UserRoleType';

export const RoleMetaMap = {
  [UserRoleType.ADMIN]: {
    title: 'Admin',
    color: '#FEF4C6'
  },
  [UserRoleType.QA]: {
    title: 'QA',
    color: '#E7F4C6'
  },
  [UserRoleType.ANALYST]: {
    title: 'Analyst',
    color: '#DFEBEE'
  },
  [UserRoleType.GAME_OWNER]: {
    title: 'Game Owner',
    color: '#FEC6C6'
  }
};
