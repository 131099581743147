import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Result } from '@pages/experimentDetails/components/stats/components/result/Result';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { RouteParams } from '@infrastructure/routing';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { fetchExperimentIterations } from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { RequestStage } from '@infrastructure/store/types/actions';
import { BlockLoader } from '@components/layout/Loader';

export function ResultContainer() {
  const dispatch = useDispatch();
  const { experimentId } = useParams<RouteParams.GetExperiment>();
  const expId = Number(experimentId);

  const status = useSelector(experimentDetailsSelectors.getIterationsStatus);

  useEffect(() => {
    dispatch(fetchExperimentIterations.trigger(expId));
  }, [dispatch, expId]);

  if (status !== RequestStage.SUCCESS) {
    return <BlockLoader />;
  }

  return <Result />;
}
