import React from 'react';
import { useSelector } from '@ui/hooks/redux';
import { Table } from '@pages/experimentVariables/table/Table';

import { configSelectors } from '@infrastructure/store/config/configSelectors';

export function VariableTableContainer() {
  const status = useSelector(configSelectors.getConfigStatus);
  const list = useSelector(configSelectors.getVariableList);

  return <Table list={list} status={status} />;
}
