import React from 'react';

import useModal from '@ui/hooks/useModal';

import { LineChart } from '../common/lineChart/LineChart';
import { FullScreenChartModal } from '@pages/experimentDetails/components/modals/FullScreenChartModal';

const chartName = 'Users Per Day';
const yAxisLabel = 'Number';

function axisTickFormat(tick: number) {
  return tick.toFixed(0) + 'k';
}

type Props = {
  rawData: any;
};

export function UsersPerDayChart({ rawData }: Props) {
  const { showModal, handleOpenModal, handleCloseModal } = useModal();

  if (!rawData.data || Object.keys(rawData.data).length === 0) {
    return null;
  }

  return (
    <>
      <LineChart
        title={chartName}
        yAxisLabel={yAxisLabel}
        chartData={rawData}
        sizeModifier={2}
        handleFullscreen={handleOpenModal}
        formatAxis={axisTickFormat}
      />
      {showModal && (
        <FullScreenChartModal
          Chart={
            <LineChart title={chartName} yAxisLabel={yAxisLabel} chartData={rawData} sizeModifier={4} fullscreen />
          }
          handleModal={handleCloseModal}
        />
      )}
    </>
  );
}
