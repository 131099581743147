import React from 'react';
import { DropdownEvent } from 'crazy-ui-next';

import { TextEvent } from '@ui/types/shared';
import { UserStatusFilter } from '@pages/userManagement/filters/UserStatusFilter';

import {
  UserRoleFilterValue,
  UserSortValue,
  UserStatusFilterValue
} from '@infrastructure/store/userManagement/types/filters';
import { UserRoleFilter } from '@pages/userManagement/filters/UserRoleFilter';
import { UserSearchFilter } from '@pages/userManagement/filters/UserSearchFilter';
import { UserSort } from '@pages/userManagement/filters/UserSort';
import { RefreshButton } from '@pages/userManagement/atoms/RefreshFiltersButton';

type Props = {
  searchFilter: string;
  sortBy: UserSortValue;
  roleFilter: UserRoleFilterValue;
  statusFilter: UserStatusFilterValue;
  handleSearch: (e: TextEvent) => void;
  handleSortBy: (e: DropdownEvent<UserSortValue>) => void;
  handleChangeStatus: (e: DropdownEvent<UserStatusFilterValue>) => void;
  handleChangeRole: (e: DropdownEvent<UserRoleFilterValue>) => void;
  handleReset: () => void;
};

export function UserManagementFilter({
  sortBy,
  searchFilter,
  roleFilter,
  statusFilter,
  handleSearch,
  handleSortBy,
  handleChangeStatus,
  handleChangeRole,
  handleReset
}: Props) {
  return (
    <div className="row mt-3">
      <div className="col-xl-3">
        <UserSearchFilter handleChange={handleSearch} value={searchFilter} />
      </div>
      <div className="col-xl-3 offset-xl-5">
        <UserRoleFilter handleChange={handleChangeRole} value={roleFilter} />
      </div>
      <div className="col-xl-2">
        <UserStatusFilter handleChange={handleChangeStatus} value={statusFilter} />
      </div>
      <div className="col-xl-2">
        <UserSort handleChange={handleSortBy} value={sortBy} />
      </div>
      <div className="col-xl-1 d-flex justify-content-end">
        <span className="mt-4">
          <RefreshButton onClick={handleReset} tooltip="Set filters to default state" />
        </span>
      </div>
    </div>
  );
}
