import React from 'react';
import { InputLabel } from 'crazy-ui-next';

import { GameStatsDto } from '@domain/models/experiment/GameStatsDto';
import { GameStatsType } from '@domain/enums/GameStatsType';
import MapChart from '@pages/gameStats/MapChart';
import { GameStatsList } from '@pages/createExperiment/targetConfig/components/gameStats/GameStatsList';

import styles from '@pages/createExperiment/targetConfig/components/gameStats/GameStats.module.scss';

type Props = {
  gameStats: GameStatsDto[];
  filter: GameStatsType;
  handleSetFilter: (GameStatsType) => void;
};

export function GameStats({ gameStats, filter, handleSetFilter }: Props) {
  const stats = gameStats
    .map((stat) => ({ country: stat.country, value: stat[filter] }))
    .sort((one, two) => (one.value > two.value ? -1 : 1));
  return (
    <div className={styles.section}>
      <div className={styles.mapWrapper}>
        <InputLabel labelText="Game Stats Chart" />
        <MapChart gameStatsConfig={stats} className={styles.mapView} />
      </div>
      <GameStatsList stats={stats} handleSetFilter={handleSetFilter} filter={filter} />
    </div>
  );
}
