import React from 'react';

import { WinnerStatus } from '@domain/enums/WinnerStatus';

import { WinnerStatusIcon } from '@pages/winnerConfigModal/atoms/WinnerStatusIcon';
import { PlatformIcon } from '@components/layout/platformIcon/PlatformIcon';

// TODO: get rid of infrastructure/modules usages
import { getTitleForPlatforms } from '@modules/config/utils/getTitleFromKey';

import styles from '../WinnerConfigModal.module.scss';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';
import { GamePlatform } from '@domain/enums/GamePlatform';

function getWinnerTitleText(winnerStatus?: WinnerStatus | null) {
  switch (winnerStatus) {
    case WinnerStatus.APPLIED:
      return 'Edit Winner';
    default:
      return 'Apply Winner';
  }
}

type Props = {
  objective: ExperimentObjectiveDto;
  platform: GamePlatform;
};

export function Header({ objective, platform }: Props) {
  const { winnerStatus } = objective;

  if (!platform) {
    return null;
  }

  const displayedStatus = winnerStatus === WinnerStatus.APPLIED ? WinnerStatus.APPLIED : WinnerStatus.FOUND;

  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <WinnerStatusIcon winnerStatus={displayedStatus} />
        <p>{getWinnerTitleText(winnerStatus)}</p>
      </div>

      <div className={styles.platform}>
        <PlatformIcon platform={platform} />
        <span>{getTitleForPlatforms(platform)}</span>
      </div>
    </div>
  );
}
