import { combineReducers, createReducer } from '@reduxjs/toolkit';
import {
  clearAll,
  fetchExperimentLogList,
  fetchExperimentLog,
  pushExperimentLogName
} from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';

const logsListInitial: string[] = [];

const logsListDataReducer = createReducer(logsListInitial, (qb) => {
  qb.addCase(fetchExperimentLogList.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return logsListInitial;
  });
});

const selectedLogInitial = '';

const selectedLogDataReducer = createReducer(selectedLogInitial, (qb) => {
  qb.addCase(fetchExperimentLog.success, (state, { payload }) => {
    return payload.content;
  });
  qb.addCase(clearAll, () => {
    return selectedLogInitial;
  });
});

const selectedLogNameInitial = '';

const selectedLogNameReducer = createReducer(selectedLogNameInitial, (qb) => {
  qb.addCase(fetchExperimentLogList.success, (state, { payload }) => {
    const [name] = payload;
    return name || state;
  });
  qb.addCase(pushExperimentLogName, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return selectedLogNameInitial;
  });
});

const logsListReducer = combineReducers({
  data: logsListDataReducer,
  status: ReducerFactory.makeStatus(fetchExperimentLogList, clearAll)
});
const selectedLogReducer = combineReducers({
  data: selectedLogDataReducer,
  name: selectedLogNameReducer,
  status: ReducerFactory.makeStatus(fetchExperimentLog, clearAll)
});

export const logsReducer = combineReducers({
  list: logsListReducer,
  selected: selectedLogReducer
});
