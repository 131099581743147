import React, { useCallback } from 'react';
import { Button, ButtonSize, ButtonVariant, ModalWrapper } from 'crazy-ui-next';

import styles from '../GroupsConfig.module.scss';

type Props = {
  handleCloseModal: () => void;
  handleStopGroups: () => void;
  closeEditor: () => void;
  groups: string[];
};

export function StopModal({ handleCloseModal, handleStopGroups, groups, closeEditor }: Props) {
  const handleStop = useCallback(() => {
    handleStopGroups();
    handleCloseModal();
  }, [handleStopGroups, handleCloseModal]);

  const handleCancel = useCallback(() => {
    closeEditor();
    handleCloseModal();
  }, [closeEditor, handleCloseModal]);

  return (
    <ModalWrapper handleModal={handleCloseModal} className={styles.modal}>
      <div className={styles.header}>
        <p>Stop Groups</p>
      </div>

      <p>Are you sure?</p>
      <p>
        Following groups will be stopped: <span>{groups.join(', ')}</span>
      </p>

      <div className={styles.controls}>
        <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.LARGE} onClick={handleCancel}>
          Cancel
        </Button>
        <Button size={ButtonSize.LARGE} onClick={handleStop}>
          Stop
        </Button>
      </div>
    </ModalWrapper>
  );
}
