import { Service } from 'typedi';

import { BackendHttpClient } from '@infrastructure/http/BackendHttpClient';
import { ResponseMapper } from '@app/mappers/ResponseMapper';
import { AppliedConfigDto } from '@domain/models/appliedConfig/AppliedConfigDto';
import { AppliedConfigStatsDto } from '@domain/models/appliedConfig/AppliedConfigStatsDto';
import { AppliedConfigListReqParams } from '@domain/models/appliedConfig/AppliedConfigListReqParams';

@Service()
export class AppliedConfigApi {
  constructor(protected readonly http: BackendHttpClient) {}

  async getConfigList(filters?: AppliedConfigListReqParams): Promise<AppliedConfigDto[] | null> {
    return this.http
      .get('/intops/admin/experiment/appliedconfig', filters)
      .then((list) => list?.map(ResponseMapper.mapToAppliedConfigDto));
  }

  async getConfigStats(): Promise<AppliedConfigStatsDto[] | null> {
    return this.http
      .get('/intops/admin/experiment/appliedconfig/stats')
      .then((list) => list?.map(ResponseMapper.mapToAppliedConfigStatsDto));
  }
}
