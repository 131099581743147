import React, { useState, useCallback, useEffect } from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';
import { useController, UseFormSetFocus } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';
import { ReactComponent as EditSvg } from '@assets/svg/edit.svg';

import { DevObjConfigParams } from '@domain/models/experimentDetails/devPhase/DevObjConfigParams';
import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';

import styles from '../ConfigInput.module.scss';

type Props = Pick<FormComponent<DevPhaseParams>, 'control'> & {
  configIndex: number;
  setFocus: UseFormSetFocus<DevObjConfigParams>;
};

export function EditableDescription({ configIndex, control, setFocus }: Props) {
  const [isEdit, setIsEdit] = useState(true);

  const { field } = useController({ control, name: `objectiveConfig.config.${configIndex}.description` });
  const { value, onChange } = field;

  const handleEdit = useCallback(() => {
    setIsEdit(true);
  }, [setIsEdit]);

  const handleBlur = useCallback(() => {
    setIsEdit(false);
  }, [setIsEdit]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Escape'].includes(event.key)) {
      setIsEdit(false);
    }
  }, []);

  useEffect(() => {
    if (isEdit && !value) {
      setFocus(`config.${configIndex}.description`);
    }
  }, [isEdit, value, setFocus, configIndex]);

  return (
    <td className={styles.editableCell} data-edit={isEdit}>
      {isEdit ? (
        <input
          onKeyDown={handleKeyDown}
          ref={field.ref}
          type="text"
          value={value}
          onBlur={handleBlur}
          onChange={onChange}
          placeholder="Group description"
        />
      ) : (
        <span>{value}</span>
      )}
      <div className={styles.controls}>
        <Button variant={ButtonVariant.TERTIARY} onClick={handleEdit} icon={<EditSvg />} />
      </div>
    </td>
  );
}
