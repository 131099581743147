import { Dto } from '@domain/models/Dto';
import { UniqueId } from '@domain/types';

export interface UserPropertyOperator {
  id: UniqueId;
  displayName: string;
}

export class UserPropertyOperatorsDto extends Dto {
  operators: UserPropertyOperator[];
}
