import React from 'react';
import { Tooltip } from 'crazy-ui';
import JSONPretty from 'react-json-pretty';

import { StringUtils } from '@app/utils/StringUtils';
import { AuditKey } from '@domain/enums/AuditEntityType';
import { useToggle } from '@ui/hooks/useToggle';

import 'react-json-pretty/themes/acai.css';

type Props = {
  data: any;
  auditKey: AuditKey;
};

export function Cell({ data, auditKey }: Props) {
  const { isActive: expanded, handleToggle: toggleExpand } = useToggle(false);

  switch (auditKey) {
    case AuditKey.CHANGES:
      const stringify = JSON.stringify(data);

      const value = expanded ? <JSONPretty space={2} data={stringify} /> : StringUtils.ellipsis(stringify);

      return (
        <Tooltip text={!expanded ? 'double click to see more' : null}>
          <td onDoubleClick={toggleExpand}>{value}</td>
        </Tooltip>
      );
    case AuditKey.EVENT_DATE:
      return <td>{data}</td>;
    case AuditKey.SOURCE_METHOD:
    case AuditKey.USERNAME:
    default:
      return <td>{data}</td>;
  }
}
