import React, { useCallback } from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';
import { Controller, UseFieldArrayRemove } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';
import { ConfigsParams } from '@domain/models/experiment/ConfigsParams';
import { ValueInputWrapper } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/configModal/form/ValueInputWrapper';
import { ReactComponent as MinusSvg } from '@assets/svg/minus.svg';

import { SessionNumberInput } from './SessionNumberInput';

import styles from '../EditableConfigModal.module.scss';

type Props = Pick<FormComponent<ConfigsParams>, 'control' | 'register' | 'errors'> & {
  index: number;
  session: string | null;
  remove: UseFieldArrayRemove;
  variableKey: string;
};

export function getLabelText(index: number, label: string) {
  // todo: add null type to labelText in TextInput
  return index === 0 ? label : undefined;
}

export function ValueInputsContainer({ session, control, index, register, remove, errors, variableKey }: Props) {
  const handleRemoveField = useCallback(() => {
    remove(index);
  }, [remove, index]);

  return (
    <div className={styles.inputsWrapper}>
      {session && <SessionNumberInput index={index} control={control} />}

      <Controller
        control={control}
        name={`configs.${index}.entry`}
        render={({ field }) => (
          <ValueInputWrapper field={field} index={index} variableKey={variableKey} session={session} />
        )}
      />

      {index !== 0 && (
        <Button
          variant={ButtonVariant.TERTIARY}
          icon={<MinusSvg />}
          onClick={handleRemoveField}
          className={styles.removeSessionBtn}
        />
      )}
    </div>
  );
}
