import React from 'react';
import { TextAreaInput } from 'crazy-ui-next';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

import { BasicInfoParams } from '@domain/models/createExperiment/BasicInfoParams';
import { getInputProps } from '@ui/hooks/form';
import styles from '@pages/createExperiment/basicInfo/BasicInfo.module.scss';

type Props = {
  register: UseFormRegister<BasicInfoParams>;
  errors: FieldErrors<BasicInfoParams>;
};

export function DescriptionInput({ register, errors }: Props) {
  return (
    <TextAreaInput
      labelText="Description"
      placeholder="Description is optional"
      className={styles.input}
      {...getInputProps<BasicInfoParams>('description', register, errors)}
    />
  );
}
