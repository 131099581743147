import { ArrayMaxSize, IsEmail, IsNumberString, IsString, ValidateNested, ValidateIf } from 'class-validator';
import { Type, Transform } from 'class-transformer';

import { AppPropertyType } from '@domain/enums/AppPropertyType';
import { AppPropertyValue } from '@domain/models/appProperty/AppPropertyDto';

export class AppPropertyInput {
  name: string;
  description: string;
  enabled: boolean;
  value: AppPropertyValue;
  type: AppPropertyType;

  setDefaultValues() {
    this.name = '';
    this.value = '';
    this.description = '';
    this.enabled = false;
    this.type = AppPropertyType.INT;
    return this;
  }
}

export class AppPropertyIntInput extends AppPropertyInput {
  type: AppPropertyType.INT;

  @IsNumberString({}, { message: 'Value should be numeric' })
  value: string;
}

export class AppPropertySetLongInput extends AppPropertyInput {
  type: AppPropertyType.SET_LONG;

  @Transform(({ value }) => value.split(','))
  @ValidateIf((e: AppPropertySetLongInput) => {
    return e.value.length === 1 && e.value[0] !== '';
  })
  @IsNumberString({}, { each: true, message: 'Each value should be numeric' })
  value: string[];
}

export class AppPropertyPeriodicityInput extends AppPropertyInput {
  type: AppPropertyType.PERIODICITY_STRING;

  @IsString()
  value: string;
}

export class AppPropertyEmailInput extends AppPropertyInput {
  type: AppPropertyType.EMAILS_ARRAY;

  @Transform(({ value }) => value.split(','))
  @IsEmail({}, { each: true, message: 'Each value should be email' })
  value: string;
}

const ArpuInputSize = 5;

export class AppPropertyArpuArrayInput extends AppPropertyInput {
  type: AppPropertyType.ARPU_ARRAY;

  @Transform(({ value }) => value.split(','))
  @IsNumberString({}, { each: true, message: 'Each value should be numeric' })
  @ArrayMaxSize(ArpuInputSize)
  value: string[];
}

export class AppPropertyForm {
  @ValidateNested({ each: true })
  @Type(() => AppPropertyInput, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'type',
      subTypes: [
        {
          value: AppPropertyIntInput,
          name: AppPropertyType.INT
        },
        {
          value: AppPropertySetLongInput,
          name: AppPropertyType.SET_LONG
        },
        {
          value: AppPropertyPeriodicityInput,
          name: AppPropertyType.PERIODICITY_STRING
        },
        {
          value: AppPropertyArpuArrayInput,
          name: AppPropertyType.ARPU_ARRAY
        },
        {
          value: AppPropertyEmailInput,
          name: AppPropertyType.EMAILS_ARRAY
        }
      ]
    }
  })
  property:
    | AppPropertyIntInput[]
    | AppPropertySetLongInput[]
    | AppPropertyPeriodicityInput[]
    | AppPropertyArpuArrayInput[]
    | AppPropertyEmailInput[];

  setDefaultValues() {
    this.property = [];
    return this;
  }
}
