import { toast } from 'react-toastify';
import { getErrorMessage } from '@infrastructure/utils/libs/getErrorMessage';

export class Notification {
  static success(message, config) {
    console.log('Notification:', message);
    return toast.success(message, config);
  }

  static error(message, config) {
    let formattedMessage = message;
    if (message instanceof Error) {
      formattedMessage = getErrorMessage(message);
    }

    console.error('Notification:', formattedMessage);
    return toast.error(formattedMessage || 'Something went wrong', config);
  }

  static info(message, config) {
    console.log('Notification:', message);
    return toast.info(message, config);
  }

  static warning(message, config) {
    console.log('Notification:', message);
    return toast.warn(message, config);
  }
}

export default Notification;
