import React from 'react';
import { useController } from 'react-hook-form';
import { DropdownInput } from 'crazy-ui-next';

import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { createSimpleOptions } from '@infrastructure/utils/createOptions.utils';
import { IterationRequestParams } from '@domain/models/iteration/IterationRequestParams';
import { ReactComponent as PinSvg } from '@assets/svg/pin.svg';
import { FormComponent } from '@ui/hooks/form';
import { useSelector } from '@ui/hooks/redux';

import styles from '@pages/experimentDetails/components/stats/components/result/components/filters/Filters.module.scss';

type Props = Pick<FormComponent<IterationRequestParams>, 'control'> & { disabled: boolean };

export function RegionInput({ control, disabled }: Props) {
  const { field } = useController({ control, name: 'region' });
  const { availableRegions } = useSelector(experimentDetailsSelectors.getIterationFiltersMeta);
  const options = createSimpleOptions(availableRegions);

  return (
    <DropdownInput
      labelText="Region"
      placeholder="Select region"
      name="region"
      value={field.value}
      options={options}
      className={styles.input}
      inputIcon={<PinSvg />}
      handleChange={(event) => field.onChange(event.value)}
      disabled={disabled}
    />
  );
}
