import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Button, ButtonVariant, ModalWrapper } from 'crazy-ui-next';
import { memoize } from 'proxy-memoize';

import { useForm } from '@ui/hooks/form';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { updateConfigEntry } from '@infrastructure/store/createExperiment/createExperimentActions';
import { ConfigValue, GenericConfigValue } from '@domain/models/GenericConfigValue';
import { ConfigsParams } from '@domain/models/experiment/ConfigsParams';
import { ExperimentVariableWrapper } from '@domain/enums/ExperimentVariableWrapper';
import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { ExperimentConfigsMapper } from '@app/mappers/experiment/ExperimentConfigsMapper';

import { FormControls } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/configModal/form/FormControls';
import { ValueInputsContainer } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/configModal/form/ValueInputsContainer';
import DividerLine from '@components/layout/dividerLine/DividerLine';
import { ReactComponent as PlusSvg } from '@assets/svg/plus.svg';

import styles from './EditableConfigModal.module.scss';
import { configSelectors } from '@infrastructure/store/config/configSelectors';

function generateNewSessionField(
  type: ExperimentVariableWrapper,
  index: number
): { session: string; entry: ConfigValue } {
  let value: number | number[] | boolean | string | null = null;

  switch (type) {
    case ExperimentVariableWrapper.BOOLEAN:
      value = false;
      break;
    case ExperimentVariableWrapper.STRING:
      value = '';
      break;
    case ExperimentVariableWrapper.INT:
    case ExperimentVariableWrapper.INT_LIST:
      value = '0';
      break;
    default:
      value = null;
  }

  return {
    session: String(index + 1),
    entry: {
      type: type,
      value
    }
  };
}

type Props = {
  handleCloseModal: () => void;
  region: string;
  configIndex: number;
  variableKey: string;
  groupName: string;
  entry: GenericConfigValue;
};

export function EditableConfigModal({ handleCloseModal, region, configIndex, variableKey, groupName, entry }: Props) {
  const dispatch = useDispatch();

  const config = ExperimentConfigsMapper.mapEntryToFormParams(entry);
  const variableTitle = useSelector(memoize(configSelectors.getVariableTitleMap));

  const {
    control,
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<ConfigsParams>({
    schema: ConfigsParams,
    defaultValues: config
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: `configs`
  });

  const valueType = fields[0].entry?.type;

  const handleAddField = useCallback(() => {
    append(generateNewSessionField(valueType, fields.length));
  }, [append, valueType, fields]);

  const handleFormSubmit = handleSubmit((form) => {
    const data = ExperimentConfigsMapper.mapParamsToConfigValue(form);
    return dispatch(updateConfigEntry({ configValue: data, region, configIndex, variableKey }));
  });

  const handleModal = useCallback(() => {
    handleFormSubmit();
    handleCloseModal();
  }, [handleCloseModal, handleFormSubmit]);

  return (
    <ModalWrapper handleModal={handleCloseModal} className={styles.wrapper}>
      <form onSubmit={handleModal}>
        <h4>Edit {variableTitle[variableKey]}</h4>

        <DividerLine />

        <h3>Group: {groupName}</h3>

        {fields.map((field, index) => (
          <ValueInputsContainer
            key={field.id}
            index={index}
            control={control}
            session={field.session}
            register={register}
            remove={remove}
            errors={errors}
            variableKey={variableKey}
          />
        ))}

        {entry.type === ExperimentObjectiveSessionType.SESSION && (
          <Button
            variant={ButtonVariant.TERTIARY}
            icon={<PlusSvg />}
            onClick={handleAddField}
            className={styles.addSessionBtn}
          >
            New Session
          </Button>
        )}

        <DividerLine />

        <FormControls handleCloseModal={handleCloseModal} />
      </form>
    </ModalWrapper>
  );
}
