function roundByRank(number, dischargeRank = 0) {
  const rank = 10 ** dischargeRank;

  return Math.round(number / rank) * rank;
}

export function generateDomainWithExtra({ points, key, extraPercent = 0, fallback, roundRank }) {
  const max = Math.max(...points.map((rv) => rv[key]));

  const maxWithPercent = max + (max * extraPercent) / 100;
  const maxWithRound = !roundRank ? maxWithPercent : roundByRank(maxWithPercent, roundRank);

  if (isNaN(maxWithRound) || !isFinite(maxWithRound)) {
    return fallback;
  }

  return maxWithRound;
}
