import React, { useCallback } from 'react';
import { useFieldArray, UseFormSetFocus, UseFormWatch } from 'react-hook-form';
import { cloneDeep } from 'lodash-es';

import { FormComponent } from '@ui/hooks/form';
import { StringUtils } from '@app/utils/StringUtils';

import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';
import { DevObjFormConfig, DevObjInput } from '@domain/models/experimentDetails/devPhase/DevObjConfigParams';

import { Thead } from '@pages/experimentDetails/components/summary/config/devPhase/sections/objectiveConfig/configInput/atoms/Thead';
import { TBody } from '@pages/experimentDetails/components/summary/config/devPhase/sections/objectiveConfig/configInput/atoms/TBody';
import { AddParamBtn } from '@pages/experimentDetails/components/summary/config/devPhase/sections/objectiveConfig/configInput/atoms/AddParamBtn';
import { AddConfigBtn } from '@pages/experimentDetails/components/summary/config/devPhase/sections/objectiveConfig/configInput/atoms/AddConfigBtn';

import styles from './ConfigInput.module.scss';

type Props = Pick<FormComponent<DevPhaseParams>, 'control'> & {
  watch: UseFormWatch<DevPhaseParams>;
  setFocus: UseFormSetFocus<DevPhaseParams>;
};

const MAX_CONFIGS_LIMIT = 26;
const MIN_CONFIGS_LIMIT = 2;

export function ConfigInputContainer({ control, watch, setFocus }: Props) {
  /*
    nested field change doesn't trigger re-render of parent component,
    so in this case we need to watch configs to be sure we are working with updated data
    */
  const [defaultValue, watchConfigs] = watch(['objectiveConfig.defaultValue', 'objectiveConfig.config']);
  const {
    fields: configs,
    append: appendConfig,
    remove: removeConfig,
    replace: replaceConfig
  } = useFieldArray({
    control,
    name: 'objectiveConfig.config',
    rules: {
      minLength: MIN_CONFIGS_LIMIT,
      maxLength: MAX_CONFIGS_LIMIT
    }
  });
  const {
    fields: params,
    append: appendParam,
    remove: removeParam,
    replace: replaceParams
  } = useFieldArray({
    control,
    name: 'objectiveConfig.params'
  });

  const isNewConfigAllowed = configs.length < MAX_CONFIGS_LIMIT;

  // add new config on add a new row in a table
  const handleAddConfig = useCallback(() => {
    const lastConfig = configs[configs.length - 1];
    const newName = StringUtils.getNextChar(lastConfig.name);

    const inputs = params.map((param) => {
      return new DevObjInput(param.value, defaultValue);
    });

    const config = new DevObjFormConfig(newName, inputs, '', true);

    appendConfig(config);
  }, [appendConfig, configs, defaultValue, params]);

  // remove particular config on remove a particular row in a table
  const handleRemoveConfig = useCallback(
    ({ currentTarget }) => {
      const { index } = currentTarget.dataset;

      removeConfig(index);
    },
    [removeConfig]
  );

  // update(add) all configs on add a new param in a table header
  const updateConfig = useCallback(
    (param: string) => {
      const deepCopy = cloneDeep(watchConfigs);
      const updatedConfigs = deepCopy.map((config) => {
        const newInput = new DevObjInput(param, defaultValue);
        config.input.push(newInput);
        return config;
      });

      replaceConfig(updatedConfigs);
    },
    [watchConfigs, replaceConfig, defaultValue]
  );

  // update param name in config on update param name
  const updateConfigParam = useCallback(
    (newParam: string, index: number) => {
      const deepCopy = cloneDeep(watchConfigs);
      const updatedConfigs = deepCopy.map((config) => {
        config.input[index].key = newParam;
        return config;
      });

      replaceConfig(updatedConfigs);
    },
    [watchConfigs, replaceConfig]
  );

  // update(remove) all configs on remove a param in a table header
  const cleanUpConfig = useCallback(
    (param: string) => {
      const deepCopy = cloneDeep(configs);
      const updatedConfigs = deepCopy.map((config) => {
        config.input = config.input.filter(({ key }) => key !== param);
        return config;
      });

      replaceConfig(updatedConfigs);
    },
    [configs, replaceConfig]
  );

  // add a new param
  const handleAddParam = useCallback(
    (data) => {
      appendParam(data);
      updateConfig(data.value);
    },
    [appendParam, updateConfig]
  );

  // update param on edit
  const handleUpdateParam = useCallback(
    (newParam: string, index: number) => {
      const updatedParams = cloneDeep(params);
      updatedParams[index].value = newParam;

      replaceParams(updatedParams);
      updateConfigParam(newParam, index);
    },
    [params, replaceParams, updateConfigParam]
  );

  // remove param
  const handleRemoveParam = useCallback(
    ({ currentTarget }) => {
      const { index, value } = currentTarget.dataset;

      removeParam(index);
      cleanUpConfig(value);
    },
    [removeParam, cleanUpConfig]
  );

  return (
    <div className={styles.inputGroup}>
      <table className={styles.table}>
        <Thead
          watch={watch}
          params={params}
          handleRemoveParam={handleRemoveParam}
          handleUpdateParam={handleUpdateParam}
        />
        <TBody control={control} configs={configs} handleRemoveConfig={handleRemoveConfig} setFocus={setFocus} />
      </table>
      <AddParamBtn handleAddParam={handleAddParam} watch={watch} />
      <AddConfigBtn handleAddConfig={handleAddConfig} isShown={isNewConfigAllowed} />
    </div>
  );
}
