import { Type } from 'class-transformer';

import { Dto } from '@domain/models/Dto';
import { AppliedConfigDto } from '@domain/models/appliedConfig/AppliedConfigDto';
import { AppliedConfigStatsDto } from '@domain/models/appliedConfig/AppliedConfigStatsDto';
import { GameApplicationDto } from '@domain/models/game/GameApplicationDto';

export class AppliedConfigViewDto extends Dto {
  @Type(() => GameApplicationDto)
  game: GameApplicationDto;

  @Type(() => AppliedConfigDto)
  configs: AppliedConfigDto[];

  @Type(() => AppliedConfigStatsDto)
  stats: AppliedConfigStatsDto;
}
