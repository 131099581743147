import React from 'react';

import styles from './SideMenu.module.scss';

type Props = {
  appVersion: string;
};

export function AppVersion({ appVersion }: Props) {
  if (!appVersion) {
    return null;
  }

  return (
    <div className={styles.appVersion}>
      <p>Version: {appVersion}</p>
    </div>
  );
}
