import { Dto } from '@domain/models/Dto';
import { UniqueId } from '@domain/types';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { GamePlatform } from '@domain/enums/GamePlatform';

export class AppliedConfigStatsDto extends Dto {
  gameId: UniqueId;
  platform: GamePlatform;
  countMap: Partial<Record<ExperimentType, number>>;
}
