import { createReducer } from '@reduxjs/toolkit';

import { fetchUsers, clearAll } from '@infrastructure/store/userManagement/userManagementActions';
import { UniqueId } from '@domain/types';
import { UserDto } from '@domain/models/user/UserDto';

const byIdInitial: Record<UniqueId, UserDto> = {};

export const byIdReducer = createReducer(byIdInitial, (qb) => {
  qb.addCase(clearAll, () => {
    return byIdInitial;
  });
  qb.addCase(fetchUsers.success, (state, { payload }) => {
    payload.forEach((user) => {
      state[user.id] = user;
    });
  });
});

const idListInitial: UniqueId[] = [];

export const idListReducer = createReducer(idListInitial, (qb) => {
  qb.addCase(clearAll, () => {
    return idListInitial;
  });
  qb.addCase(fetchUsers.success, (state, { payload }) => {
    return payload.map((user) => user.id);
  });
});
