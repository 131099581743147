import { IsDate } from 'class-validator';

import { ExpFormSummary } from '@pages/createExperiment/atoms/Summary/Summary';
import { addDays, format } from 'date-fns';
import { DateUtils } from '@app/utils/DateUtils';

const deadlineDaysGap = 30;

export class DateConfigParams {
  @IsDate()
  startDateTime: Date;

  @IsDate()
  minInstallDate: Date;

  @IsDate()
  deadline: Date;

  static ofInitial() {
    const form = new DateConfigParams();
    const now = new Date();

    form.startDateTime = now;
    form.minInstallDate = now;
    form.deadline = addDays(now, deadlineDaysGap);

    return form;
  }

  static getDefault(arpu: number) {
    const form = new DateConfigParams();
    const now = new Date();

    form.startDateTime = now;
    form.minInstallDate = now;
    form.deadline = addDays(now, deadlineDaysGap + arpu);

    return form;
  }

  getSummary(): ExpFormSummary {
    return [
      { title: 'Start Date', value: format(this.startDateTime, DateUtils.getDateWithHoursPattern()) },
      { title: 'Min Installs Date', value: format(this.minInstallDate, DateUtils.getDateWithHoursPattern()) },
      { title: 'Deadline Date', value: format(this.deadline, DateUtils.getDateWithHoursPattern()) }
    ];
  }
}
