import React from 'react';
import { CheckboxInput, PageTitle } from 'crazy-ui';
import { Button, ButtonVariant, DropdownInput, DropdownOption, TextInput } from 'crazy-ui-next';
import { Controller, UseFormWatch } from 'react-hook-form';

import FormBottomControls from '@components/wrappers/bottomControls/FormBottomControls';
import { Routing } from '@infrastructure/routing';
import { FormComponent, getInputProps } from '@ui/hooks/form';
import { CancelBtnAttr, SubmitBtnAttr } from '@ui/const/DataAttributes';
import { ExpVariableParams } from '@domain/models/experimentVariable/ExperimentVariableParams';
import { ExpVariableFormMode } from '@pages/experimentVariables/types/ExpVariableFormMode';
import { ExperimentVariableType } from '@domain/enums/ExperimentVariableType';

const options: DropdownOption<ExperimentVariableType>[] = [
  { value: ExperimentVariableType.INTEGER, label: 'Integer' },
  { value: ExperimentVariableType.INT_LIST, label: 'Integer List' },
  { value: ExperimentVariableType.STRING, label: 'String' },
  { value: ExperimentVariableType.BOOLEAN, label: 'Boolean' }
];

type Props = FormComponent<ExpVariableParams> & {
  watch: UseFormWatch<ExpVariableParams>;
  mode: ExpVariableFormMode;
  isLoading?: boolean;
};

export function ExpVariableForm({ mode, isLoading, handleSubmit, register, errors, watch, control }: Props) {
  const selectedType = watch('type');
  const showMinMax = [ExperimentVariableType.INT_LIST, ExperimentVariableType.INTEGER].includes(selectedType);

  const showDefault = [
    ExperimentVariableType.INT_LIST,
    ExperimentVariableType.INTEGER,
    ExperimentVariableType.STRING
  ].includes(selectedType);

  return (
    <>
      <PageTitle
        label={mode === ExpVariableFormMode.CREATE ? 'Create Ad Profile Variable' : 'Edit Ad Profile Variable'}
      />
      <main className="container d-flex justify-content-center">
        <form className="paper paper-75 form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-6">
              <TextInput
                labelText="Key *"
                placeholder="Enter variable key"
                disabled={isLoading}
                {...getInputProps<ExpVariableParams>('name', register, errors)}
              />
              <TextInput
                labelText="Display name *"
                placeholder="Enter name for UI"
                disabled={isLoading}
                {...getInputProps<ExpVariableParams>('displayName', register, errors)}
              />

              <Controller
                name="type"
                control={control}
                render={({ field }) => {
                  return (
                    <DropdownInput
                      name={field.name}
                      labelText="Type"
                      value={field.value}
                      options={options}
                      handleChange={(event) => field.onChange(event.value)}
                    />
                  );
                }}
              />

              {showMinMax && (
                <>
                  <TextInput
                    labelText="Minimum Value"
                    placeholder="Enter value"
                    disabled={isLoading}
                    {...getInputProps<ExpVariableParams>('minValue', register, errors)}
                  />
                  <TextInput
                    labelText="Maximum Value"
                    placeholder="Enter value"
                    disabled={isLoading}
                    {...getInputProps<ExpVariableParams>('maxValue', register, errors)}
                  />
                </>
              )}

              {showDefault && (
                <TextInput
                  labelText="Default Value"
                  placeholder="Enter value"
                  disabled={isLoading}
                  {...getInputProps<ExpVariableParams>('defaultDisplayValue', register, errors)}
                />
              )}

              <CheckboxInput
                labelText="Sessions supported"
                {...getInputProps<ExpVariableParams>('sessionsSupported', register, errors)}
              />
            </div>
          </div>
          <FormBottomControls>
            <Button href={Routing.getExpVariables()} variant={ButtonVariant.SECONDARY} {...CancelBtnAttr}>
              Cancel
            </Button>
            <Button type="submit" disabled={isLoading} {...SubmitBtnAttr}>
              {mode === ExpVariableFormMode.CREATE ? 'Create' : 'Save'}
            </Button>
          </FormBottomControls>
        </form>
      </main>
    </>
  );
}
