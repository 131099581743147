import React, { useCallback, useState } from 'react';

import UsersSanityCheckChartContainer from '@pages/experimentDetails/components/stats/components/result/components/charts/usersSanityCheck/UsersSanityCheckChartContainer';
import KpiSanityCheckChartContainer from '@pages/experimentDetails/components/stats/components/result/components/charts/kpiSanityCheck/KpiSanityCheckChartContainer';
import { SanityChart, SanityChartTitle } from '@pages/experimentDetails/components/sanityChecks/types/ChartType';

import styles from '@pages/experimentDetails/components/sanityChecks/SanityChecks.module.scss';

function getChart(type: SanityChart) {
  switch (type) {
    case SanityChart.KPI:
      return <KpiSanityCheckChartContainer />;
    case SanityChart.USERS:
      return <UsersSanityCheckChartContainer />;
    default:
      return null;
  }
}

export function SanityChecks() {
  const [mode, setMode] = useState(SanityChart.KPI);

  const handleChange = useCallback((e) => {
    setMode(e.target.value);
  }, []);

  const chart = getChart(mode);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headings}>
        <h5>Sanity Checks</h5>
        <select value={mode} onChange={handleChange} className={styles.select}>
          <option value={SanityChart.KPI}>{SanityChartTitle[SanityChart.KPI]}</option>
          <option value={SanityChart.USERS}>{SanityChartTitle[SanityChart.USERS]}</option>
        </select>
      </div>
      <div className={styles.charts}>{chart}</div>
    </div>
  );
}
