import { IterationAvailableMeta } from '@domain/models/iteration/IterationAvailableMeta';

export class IterationRequestParams {
  arpu: number;
  region: string;
  version: string;

  static getInitial() {
    const params = new IterationRequestParams();
    params.arpu = 7;
    params.region = 'United states';
    params.version = '1.0.1';

    return params;
  }

  static getFromMeta(meta: IterationAvailableMeta) {
    const params = new IterationRequestParams();

    const versions = meta.availableVersions.length === 1 ? meta.availableVersions[0] : '';
    params.arpu = meta.defaultARPU;
    params.region = meta.defaultRegion;
    params.version = versions;

    return params;
  }
}
