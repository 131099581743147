import { Dto } from '@domain/models/Dto';
import { UniqueId } from '@domain/types';

export class RegionDto extends Dto {
  id: UniqueId;
  name: string;
  countries: string[];
  defaultRegion: boolean;
  countryCodes: string[];
}
