import React, { useEffect } from 'react';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { clearAll, pushExperimentClone } from '@infrastructure/store/createExperiment/createExperimentActions';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { RequestStage } from '@infrastructure/store/types/actions';

import { CreateExperiment } from '@pages/createExperiment/CreateExperiment';
import CheckPermission from '@components/layout/CheckPermissions';
import { PageLoader } from '@components/layout/Loader';

export function CreateExperimentContainer() {
  const dispatch = useDispatch();
  const cloneStatus = useSelector(createExperimentSelectors.cloneStatus);

  useEffect(() => {
    dispatch(pushExperimentClone.trigger());
    return () => {
      dispatch(clearAll());
    };
  }, [dispatch]);

  if (cloneStatus !== RequestStage.SUCCESS) {
    return <PageLoader />;
  }

  // todo extend RouteByRole and move this logic
  return (
    <CheckPermission admin analyst qa>
      <main className="container pb-4">
        <CreateExperiment />
      </main>
    </CheckPermission>
  );
}
