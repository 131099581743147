import { DropdownInput } from 'crazy-ui';
import React from 'react';
import { DropdownEvent, DropdownOption } from 'crazy-ui-next';

import { UserStatusFilterValue } from '@infrastructure/store/userManagement/types/filters';

const options: DropdownOption<UserStatusFilterValue>[] = [
  { value: UserStatusFilterValue.ALL, label: 'All' },
  { value: UserStatusFilterValue.ACTIVE, label: 'Active' },
  { value: UserStatusFilterValue.BLOCKED, label: 'Blocked' }
];

type Props = {
  handleChange: (e: DropdownEvent<UserStatusFilterValue>) => void;
  value: UserStatusFilterValue;
};

export function UserStatusFilter({ handleChange, value }: Props) {
  return (
    <DropdownInput
      id="user-status"
      labelText="User Status"
      options={options}
      value={value}
      handleChange={handleChange}
    />
  );
}
