import React from 'react';
import { useSelector } from '@ui/hooks/redux';
import { List } from '@pages/appliedConfig/list/List';

import { appliedConfigSelectors } from '@infrastructure/store/appliedConfig/appliedConfigSelectors';

export function AppliedConfigListContainer() {
  const status = useSelector(appliedConfigSelectors.getConfigStatus);
  const idList = useSelector(appliedConfigSelectors.getGameIdList);

  return <List status={status} idList={idList} />;
}
