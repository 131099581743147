import React, { useCallback } from 'react';
import { InputLabel, TextInput } from 'crazy-ui-next';
import { Controller, useFieldArray } from 'react-hook-form';

import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { FormComponent } from '@ui/hooks/form';

import { MultiTextInputContainer } from '@pages/winnerConfigModal/form/inputs/multiTextInput/MultiTextInputContainer';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/inputGroup/InputGroup.module.scss';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'control'> & {
  sessionIndex: number;
  sectionIndex: number;
  name: string;
  labelText: string;
  min: number | boolean | string;
  max: number | boolean | string;
  defaultValue: number | boolean | string;
};

const maxInputsLength = 5;

export function ControlledIntegerInput({
  control,
  name,
  labelText,
  sessionIndex,
  sectionIndex,
  min,
  max,
  defaultValue
}: Props) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `input.${sectionIndex}.section.${sessionIndex}.${name}`
  });

  const onAdd = useCallback(() => {
    // @ts-ignore react-hook-form doesn't support dynamic name path and return type never
    append({ value: defaultValue.toString() });
  }, [append, defaultValue]);

  const onRemove = useCallback(() => {
    remove(fields.length - 1);
  }, [remove, fields]);

  const inputs = fields.map((input, index) => {
    return (
      <Controller
        name={`input.${sectionIndex}.section.${sessionIndex}.${name}.${index}.value`}
        // @ts-ignore react-hook-form doesn't support dynamic name path and return type never
        key={input.id}
        control={control}
        render={({ field }) => {
          const handleBlurTweak = () => {
            // @ts-ignore react-hook-form doesn't support dynamic name path and return type never
            const integer = field.value?.split('.')[0];

            if (integer < min || !integer) {
              field.onChange(min.toString());
              return;
            }
            if (integer > max) {
              field.onChange(max.toString());
              return;
            }
            field.onChange(integer);
          };
          return (
            <TextInput
              className={styles.input}
              name={field.name}
              type="number"
              value={field.value}
              onChange={field.onChange}
              onBlur={handleBlurTweak}
            />
          );
        }}
      />
    );
  });

  return (
    <>
      <InputLabel labelText={labelText} className={styles.label} />
      <MultiTextInputContainer
        className={styles.intInput}
        inputs={inputs}
        onAddItem={onAdd}
        onRemoveItem={onRemove}
        maxItems={maxInputsLength}
      />
    </>
  );
}
