import React from 'react';

import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { TRow } from './TRow';

type Props = { configList: GenericConfigEntry[]; region: string };

export function TBody({ configList, region }: Props) {
  return (
    <tbody>
      {configList.map((config, index) => (
        <TRow key={config.name} config={config} region={region} index={index} />
      ))}
    </tbody>
  );
}
