import React, { useEffect } from 'react';
import { plainToInstance } from 'class-transformer';
import { memoize } from 'proxy-memoize';

import { initGoalConfig, pushGoalConfigForm } from '@infrastructure/store/createExperiment/createExperimentActions';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { GoalConfigParams } from '@domain/models/createExperiment/GoalConfigParams';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { GoalConfig } from '@pages/createExperiment/goalConfig/GoalConfig';
import { Summary } from '@pages/createExperiment/atoms/Summary/Summary';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { useForm } from '@ui/hooks/form';

type Props = {
  isCompleted?: boolean;
};

export function GoalConfigContainer({ isCompleted }: Props) {
  const dispatch = useDispatch();

  const defaultValues = useSelector(memoize(createExperimentSelectors.getGoalConfigInitialValues));
  const { goalConfig } = useSelector(createExperimentSelectors.getForm);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty }
  } = useForm<GoalConfigParams>({
    schema: GoalConfigParams,
    defaultValues
  });

  useEffect(() => {
    dispatch(initGoalConfig());
  }, [dispatch, isDirty]);

  useEffect(() => {
    !isCompleted && isDirty && reset(goalConfig);
  }, [reset, goalConfig, isCompleted, isDirty]);

  const handleNext = handleSubmit((body) => {
    const form = plainToInstance(GoalConfigParams, body);
    dispatch(pushGoalConfigForm(form));
  });

  if (isCompleted) {
    return <Summary step={CreateExperimentForm.GOAL_CONFIG} />;
  }
  return <GoalConfig register={register} control={control} handleSubmit={handleNext} errors={errors} />;
}
