import 'reflect-metadata';
import { Container } from 'typedi';
import React from 'react';
import ReactDOM from 'react-dom/client';

import './subscribers';
import './ui/scss/index.scss';

import { App } from '@ui/App';
import { REACT_ROOT } from '@domain/constants';
import { ReduxStore } from '@infrastructure/store';
import { HistoryService } from '@infrastructure/browser/HistoryService';

const reduxStore = Container.get(ReduxStore);
const historyService = Container.get(HistoryService);

reduxStore.runSaga();

const root = ReactDOM.createRoot(document.getElementById(REACT_ROOT) as HTMLElement);
root.render(
  <App store={reduxStore.getStore()} persistor={reduxStore.getPersistor()} history={historyService.getHistory()} />
);
