import React, { useCallback } from 'react';
import { InputLabel, TextInput } from 'crazy-ui-next';
import { Controller, useFieldArray } from 'react-hook-form';

import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/inputGroup/InputGroup.module.scss';
import { MultiTextInputContainer } from '@pages/winnerConfigModal/form/inputs/multiTextInput/MultiTextInputContainer';
import { DIGITS_AND_COMMA_REGEXP, EMPTY_STRING_REGEXP } from '@domain/constants';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'control'> & {
  sessionIndex: number;
  sectionIndex: number;
  name: string;
  labelText: string;
  min: number | boolean | string;
  max: number | boolean | string;
  defaultValue: number | boolean | string;
};

const maxInputsLength = 5;

export function ControlledIntegerListInput({
  control,
  name,
  labelText,
  sessionIndex,
  sectionIndex,
  max,
  min,
  defaultValue
}: Props) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `input.${sectionIndex}.section.${sessionIndex}.${name}`
  });

  const onAdd = useCallback(() => {
    // @ts-ignore react-hook-form doesn't support dynamic name path and return type never
    append({ value: defaultValue.toString() });
  }, [append, defaultValue]);

  const onRemove = useCallback(() => {
    remove(fields.length - 1);
  }, [remove, fields]);

  const inputs = fields.map((input, index) => {
    return (
      <Controller
        // @ts-ignore react-hook-form doesn't support dynamic name path and return type never
        key={input.id}
        name={`input.${sectionIndex}.section.${sessionIndex}.${name}.${index}.value`}
        control={control}
        render={({ field }) => {
          const handleBlurTweak = () => {
            // @ts-ignore react-hook-form doesn't support dynamic name path and return type never
            const integers = field.value?.split(',').filter(Boolean);

            const formattedIntegers = integers.map((int) => {
              if (!int) {
                return min.toString();
              }
              if (int < min) {
                return min.toString();
              }
              if (int > max) {
                return max.toString();
              }
              return int;
            });
            const formattedValue = formattedIntegers.join(',');
            field.onChange(formattedValue);
          };

          const handleChange = (e) => {
            const { value } = e.currentTarget;

            (DIGITS_AND_COMMA_REGEXP.test(value) || EMPTY_STRING_REGEXP.test(value)) && field.onChange(value);
          };

          return (
            <TextInput
              className={styles.input}
              name={field.name}
              type="text"
              value={field.value}
              onChange={handleChange}
              onBlur={handleBlurTweak}
            />
          );
        }}
      />
    );
  });

  return (
    <>
      <InputLabel labelText={labelText} className={styles.label} />
      <MultiTextInputContainer
        className={styles.intInput}
        inputs={inputs}
        onAddItem={onAdd}
        onRemoveItem={onRemove}
        maxItems={maxInputsLength}
      />
    </>
  );
}
