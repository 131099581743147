import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { isAfter, isBefore, subMonths } from 'date-fns';

import {
  clearAll,
  handleBusinessLinesFilter,
  handleDateFilter,
  handlePlatformFilter,
  handleSearchPhraseFilter,
  handleSortBy,
  handleStatusFilter,
  handleTypeFilter
} from '@infrastructure/store/experimentList/experimentListActions';
import {
  ExpBusinessLineFilterValue,
  ExpPlatformFilterValue,
  ExpSortByPayload,
  ExpStatusFilterValue,
  ExpTypeFilterValue
} from '@infrastructure/store/experimentList/types/filters';
import { LocalStorageKey } from '@infrastructure/repositories/LocalStorageRepo';
import { filterMigration } from '@infrastructure/store/experimentList/migrations/filterMigration';
import { PageSortDir } from '@domain/models/Paginate';

const searchPhraseInitial: string = '';

const searchPhraseFilter = createReducer(searchPhraseInitial, (qb) => {
  qb.addCase(handleSearchPhraseFilter, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return searchPhraseInitial;
  });
});

const statusInitial: ExpStatusFilterValue[] = [ExpStatusFilterValue.RUNNING];
const statusFilterValuesLength = Object.keys(ExpStatusFilterValue).length;

const statusFilter = createReducer(statusInitial, (qb) => {
  qb.addCase(handleStatusFilter, (state, { payload }) => {
    if (payload === ExpStatusFilterValue.ALL) {
      const statuses = Object.values(ExpStatusFilterValue);
      return state.length === statusFilterValuesLength ? [] : statuses;
    }

    if (state.includes(payload)) {
      return state.filter((item) => item !== payload && item !== ExpStatusFilterValue.ALL);
    }

    state.push(payload);

    if (state.length === statusFilterValuesLength - 1) {
      state.push(ExpStatusFilterValue.ALL);
    }

    return state;
  });
});

const typeInitial = ExpTypeFilterValue.AB_TESTING;

const typeFilter = createReducer(typeInitial, (qb) => {
  qb.addCase(handleTypeFilter, (state, { payload }) => {
    return payload;
  });
});

const businessLinesInitial = ExpBusinessLineFilterValue.CASUAL;

const businessLinesFilter = createReducer(businessLinesInitial, (qb) => {
  qb.addCase(handleBusinessLinesFilter, (state, { payload }) => {
    return payload;
  });
});

const platformInitial = ExpPlatformFilterValue.ALL;

const platformFilter = createReducer(platformInitial, (qb) => {
  qb.addCase(handlePlatformFilter, (state, { payload }) => {
    return payload;
  });
});

const getDateFilterInitial = () => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return {
    startDate: subMonths(now, 3),
    endDate: now
  };
};

const dateFilterInitial: { startDate: Date | null; endDate: Date | null } = getDateFilterInitial();

const dateFilter = createReducer(dateFilterInitial, (qb) => {
  qb.addCase(handleDateFilter, (state, { payload }) => {
    const { isStartChanged, isEndChanged, startDate, endDate } = payload;

    if (startDate instanceof Date) {
      startDate.setHours(0, 0, 0, 0);
    }

    if (endDate instanceof Date) {
      endDate.setHours(0, 0, 0, 0);
    }

    if (isStartChanged) {
      if (startDate && state.endDate && isAfter(startDate, state.endDate)) {
        state.endDate = null;
      }

      state.startDate = startDate;
    }

    if (isEndChanged) {
      if (endDate && state.startDate && isBefore(endDate, state.startDate)) {
        state.endDate = null;
      }

      state.endDate = endDate;
    }

    return state;
  });
});

const sortByInitial: ExpSortByPayload = {
  key: 'state',
  order: PageSortDir.ASC
};

const sortByReducer = createReducer(sortByInitial, (qb) => {
  qb.addCase(handleSortBy, (state, { payload }) => {
    return payload;
  });
});

const combinedReducer = combineReducers({
  searchPhrase: searchPhraseFilter,
  statuses: statusFilter,
  type: typeFilter,
  businessLines: businessLinesFilter,
  platform: platformFilter,
  dates: dateFilter,
  sortBy: sortByReducer
});

export const filterReducer = persistReducer(
  {
    key: LocalStorageKey.EXPERIMENT_FILTERS,
    whitelist: ['statuses', 'type', 'businessLines', 'sortBy', 'winnerStatus'],
    version: 6,
    migrate: filterMigration,
    storage
  },
  combinedReducer
);
