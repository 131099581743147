import React from 'react';

import { Button, ModalWrapper } from 'crazy-ui';

export default function StopExperimentModal({ handleModal, handleConfirm, experimentName }) {
  return (
    <ModalWrapper
      title="Stop Experiment?"
      subtitle={`Are you sure you want to stop experiment "${experimentName}"?`}
      handleModal={handleModal}
    >
      <div className="m-btn-list">
        <Button variant={Button.OUTLINE} color={Button.GRAY} withMargin={false} onClick={handleModal} className="m-btn">
          Cancel
        </Button>
        <Button variant={Button.Fill} color={Button.BLUE} withMargin={false} onClick={handleConfirm} className="m-btn">
          Confirm
        </Button>
      </div>
    </ModalWrapper>
  );
}
