import React from 'react';
import { CheckboxInput } from 'crazy-ui';
import { Link } from 'react-router-dom';

import { Routing } from '@infrastructure/routing';
import { AppliedConfigDto } from '@domain/models/appliedConfig/AppliedConfigDto';
import { StringUtils } from '@app/utils/StringUtils';
import { ExpTypeTitleMap } from '@ui/const/ExpTypeTitleMap';
import { EditConfigCellContainer } from '@pages/appliedConfig/table/editConfigCell/EditConfigCellContainer';
import { TableCellType } from '@components/layout/table/types/TableCellType';
import { DateLabel } from '@components/layout/dateLabel/DateLabel';
import { CountryLabel } from '@components/layout/countryLabel/CountryLabel';
import UserCircle from '@components/layout/userFilters/UserCircle';

type Props = {
  config: AppliedConfigDto;
  haveFullAccess: boolean;
};

export function Row({ config, haveFullAccess }: Props) {
  const type = ExpTypeTitleMap[config.experiment.type] || 'Unknown';
  const versions = config.version.join(', ');
  const userName = StringUtils.getInitialsFromEmail(config.appliedBy);
  const experimentId = config.experiment.id;
  const objectiveId = config.experiment.experimentObjectiveId;
  const experimentName = config.experiment.name;
  const isActiveConfig = config.isActive;
  const countryCode = config.region.countryCodes[0] || config.region.name;

  return (
    <tr data-id={config.id}>
      <td data-cell={TableCellType.TITLE}>
        <Link to={Routing.getExperiment(experimentId)}>{config.experiment.name}</Link>
      </td>
      <td data-cell={TableCellType.TEXT}>{type}</td>
      <td data-cell={TableCellType.TEXT}>{config.appliedGroup}</td>
      <td data-cell={TableCellType.LABEL}>
        <CountryLabel countryCode={countryCode} />
      </td>
      <td data-cell={TableCellType.TEXT}>{versions}</td>
      <td data-cell={TableCellType.DATE}>
        <p>
          <DateLabel date={config.appliedDate} />
        </p>
        <p>{config.deactivationDate ? <DateLabel date={config.deactivationDate} /> : <span>-</span>}</p>
      </td>
      <td data-cell={TableCellType.FLAG}>
        <CheckboxInput checked={config.isActive} readOnly />
      </td>
      <td data-cell={TableCellType.LABEL}>
        {config.appliedBy && <UserCircle name={userName} tooltipText={config.appliedBy} />}
      </td>
      {haveFullAccess && (
        <td data-cell={TableCellType.CONTROL}>
          <EditConfigCellContainer
            experimentId={experimentId}
            experimentName={experimentName}
            isActiveConfig={isActiveConfig}
            objectiveId={objectiveId}
          />
        </td>
      )}
    </tr>
  );
}
