import React from 'react';

import { ConfigValue } from '@domain/models/GenericConfigValue';

import styles from './EditableCell.module.scss';

type Props = {
  configValue: ConfigValue | Record<string, ConfigValue>;
  isPlain: boolean;
};

export function CellText({ configValue, isPlain }: Props) {
  if (isPlain) {
    return (
      <div className={styles.editableCell}>
        <p>{configValue?.value?.toString()}</p>
      </div>
    );
  }

  const list = Object.keys(configValue).map((session) => (
    <p key={session}>
      {session}: {String(configValue[session].value)}
    </p>
  ));

  return <div className={styles.editableCell}>{list}</div>;
}
