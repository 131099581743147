import { IsBoolean, IsEmail, IsEnum, IsNotEmpty, IsNumber, IsString } from 'class-validator';

import { Dto } from '@domain/models/Dto';
import { UserRoleType } from '@domain/enums/UserRoleType';
import { AtLeast } from '@domain/types';

export class UserDto extends Dto {
  @IsNumber()
  @IsNotEmpty()
  id: number;

  @IsString()
  @IsNotEmpty()
  firstName: string;

  @IsString()
  @IsNotEmpty()
  lastName: string;

  @IsString()
  @IsNotEmpty()
  username: string;

  @IsEmail()
  @IsNotEmpty()
  email: string;

  @IsEnum(UserRoleType)
  @IsNotEmpty()
  roles: UserRoleType[];

  @IsBoolean()
  enabled: boolean;

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}

export type CreateUserDto = Omit<UserDto, 'id' | 'fullName'>;
export type UpdateUserDto = AtLeast<UserDto, 'id'>;
