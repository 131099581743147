import { createReducer } from '@reduxjs/toolkit';

import { clearAll, pushNextStep, pushSetStep } from '@infrastructure/store/createExperiment/createExperimentActions';
import { UniqueId } from '@domain/types';
import { steps } from '@pages/createExperiment/steps/const/Steps';
import { cloneDeep } from 'lodash-es';

type StateType = {
  step: UniqueId;
  finalStep: UniqueId;
};

const stepInitial: StateType = {
  step: 1,
  finalStep: steps.length
};

export const formStepReducer = createReducer(stepInitial, (qb) => {
  qb.addCase(pushNextStep, (state) => {
    const clone = cloneDeep(state);
    clone.step = state.step + 1;

    return clone;
  });
  qb.addCase(pushSetStep, (state, { payload: step }) => {
    const clone = cloneDeep(state);
    clone.step = step;

    return clone;
  });
  qb.addCase(clearAll, () => {
    return stepInitial;
  });
});
