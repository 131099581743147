import React, { useEffect } from 'react';
import { Control, useController, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { DropdownInput } from 'crazy-ui-next';
import { memoize } from 'proxy-memoize';

import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { BasicInfoParams } from '@domain/models/createExperiment/BasicInfoParams';
import { useSelector } from '@ui/hooks/redux';

import styles from '@pages/createExperiment/basicInfo/BasicInfo.module.scss';

const inputName = 'firebaseProjectId';
const watchNames = ['gameName', 'platform', 'bundleId'];

type Props = {
  control?: Control<BasicInfoParams>;
  watch: UseFormWatch<BasicInfoParams>;
  setValue: UseFormSetValue<BasicInfoParams>;
};

export function FirebaseIdInput({ control, watch, setValue }: Props) {
  const [gameName, platform, bundleId] = watch(['gameName', 'platform', 'bundleId']);
  const options = useSelector(memoize(createExperimentSelectors.getFirebaseOptions(gameName, platform, bundleId)));
  const { field, formState } = useController({ control, name: inputName });
  const disabled = !(gameName && platform && bundleId);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name && watchNames.includes(name) && type === 'change') {
        setValue(inputName, BasicInfoParams.ofInitial().firebaseProjectId);
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);

  useEffect(() => {
    if (options.length === 1 && !field.value) {
      setValue('firebaseProjectId', options[0].value);
    }
  }, [setValue, options, field]);

  return (
    <DropdownInput
      name={inputName}
      labelText="Firebase Project"
      placeholder="Select Firebase ID"
      value={field.value}
      options={options}
      className={styles.input}
      disabled={disabled}
      handleChange={(event) => field.onChange(event.value)}
      error={formState.errors?.[inputName]?.message}
    />
  );
}
