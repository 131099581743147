import React from 'react';
import { FallbackUI } from '@pages/common/FallbackUI';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, stack: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, stack: error.stack };
  }

  /* eslint-disable-next-line */
  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.info(info);
    console.error(error);
  }

  render() {
    const { hasError, stack } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return <FallbackUI stack={stack} />;
    }

    return children;
  }
}
