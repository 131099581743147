import React from 'react';
import { Button, TextInput } from 'crazy-ui-next';
import { IsEmail, IsString } from 'class-validator';
import { Tooltip } from 'crazy-ui';

import { ReactComponent as PlusSvg } from '@assets/svg/plus.svg';
import { ReactComponent as HintSvg } from '@assets/svg/hint.svg';
import { getInputProps, useForm } from '@ui/hooks/form';

class Email {
  @IsEmail()
  @IsString()
  value: string;

  static getInitial() {
    const input = new Email();

    input.value = '';

    return input;
  }
}

type Props = {
  label: string;
  hint: string;
  handleAdd: (email: string) => void;
  emails: string[];
};
export function EmailAddInput({ label, emails, handleAdd, hint }: Props) {
  const {
    register,
    setError,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<Email>({ schema: Email, defaultValues: Email.getInitial() });

  const handleSubmitEmail = handleSubmit((form: Email) => {
    if (emails.includes(form.value)) {
      setError('value', { type: 'custom', message: 'Value must be unique' });
    } else {
      handleAdd(form.value);
      reset(Email.getInitial());
    }
  });

  return (
    <div>
      <TextInput labelText={label} placeholder="Email" {...getInputProps<Email>('value', register, errors)} />
      <Button onClick={handleSubmitEmail} icon={<PlusSvg />} />
      <Tooltip text={hint}>
        <HintSvg />
      </Tooltip>
    </div>
  );
}
