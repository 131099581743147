import React, { useCallback } from 'react';
import { Control, useController } from 'react-hook-form';
import { ButtonGroupEvent, DropdownOption } from 'crazy-ui-next';

import ButtonGroup from '@components/layout/buttonGroup/ButtonGroup';
import { UserFormParams } from '@domain/models/user/UserReqParams';

const options: DropdownOption<boolean>[] = [
  { value: true, label: 'Active' },
  { value: false, label: 'Block' }
];

const name = 'enabled';

type Props = {
  control?: Control<UserFormParams, 'enabled'>;
  disabled?: boolean;
};

export function UserStatusInput({ control, disabled }: Props) {
  const { field } = useController({ control, name });
  const { value, onChange } = field;

  const handleToggle = useCallback(
    (e: ButtonGroupEvent<'true' | 'false'>) => {
      const isEnabled = e.value === 'true';
      onChange(isEnabled);
    },
    [onChange]
  );

  return <ButtonGroup name={name} selected={value} handleChange={handleToggle} options={options} disabled={disabled} />;
}
