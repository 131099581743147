import React, { useCallback, useEffect } from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';

import {
  submitObjectivesStep,
  initABObjectiveConfig
} from '@infrastructure/store/createExperiment/createExperimentActions';
import { RequestStage } from '@infrastructure/store/types/actions';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { ABFormSummary } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/ABFormSummary';
import { ABForm } from '@pages/createExperiment/objectiveConfig/forms/AB/ABForm';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { ReactComponent as ArrowSvg } from '@assets/svg/arrow-up-circle.svg';
import { steps } from '@pages/createExperiment/steps/const/Steps';

import styles from './ABForm.module.scss';

export function ABFormContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initABObjectiveConfig());
  }, [dispatch]);

  const controlGroups = useSelector(createExperimentSelectors.getControlGroups);
  const { targetConfig } = useSelector(createExperimentSelectors.getForm);
  const { regions } = targetConfig;
  const status = useSelector(createExperimentSelectors.getControlGroupsStatus);
  const configList = useSelector(createExperimentSelectors.getConfigList);
  const isAllSubmitted = useSelector(createExperimentSelectors.getIsAllABConfigsSubmitted);

  // todo: update steps enum
  const isObjectiveConfigStep = useSelector(createExperimentSelectors.isMatchStep(steps[2].index));

  const isRequest = [RequestStage.REQUEST, RequestStage.NONE].includes(status);
  const showNextButton = isAllSubmitted && isObjectiveConfigStep;

  const handleNextStep = useCallback(() => {
    dispatch(submitObjectivesStep.trigger());
  }, [dispatch]);

  if (isRequest) {
    return null;
  }

  return (
    <section>
      {regions.map((region, index) => {
        if (configList[region.value]) {
          return (
            <ABFormSummary
              key={`${region.value}_summary`}
              region={region.value}
              isObjectiveConfigStep={isObjectiveConfigStep}
            />
          );
        }

        return (
          <ABForm
            key={region.value}
            regionName={region.value}
            prevRegion={regions[index - 1]?.value}
            controlGroups={controlGroups}
            index={index}
          />
        );
      })}
      {showNextButton && (
        <Button onClick={handleNextStep} variant={ButtonVariant.PRIMARY} icon={<ArrowSvg />} className={styles.nextBtn}>
          Next
        </Button>
      )}
    </section>
  );
}
