import React from 'react';

import { VersionInputContainer } from '@pages/winnerConfigModal/form/inputs/VersionInputContainer';
import { FormComponent } from '@ui/hooks/form';
import { UpdateWinnerConfigParams } from '@domain/models/winnerConfig/WinnerConfigFormParams';

import styles from '@pages/winnerConfigModal/WinnerConfigModal.module.scss';

type Props = FormComponent<UpdateWinnerConfigParams>;

export function AudienceInputs({ control, register, errors, handleSubmit }: Props) {
  return (
    <div className={styles.audience}>
      <div className={styles.inputWrapper}>
        <div className={styles.label}>
          <span>Version</span>
        </div>
        <VersionInputContainer control={control} register={register} errors={errors} handleSubmit={handleSubmit} />
      </div>
    </div>
  );
}
