const BLUE = '#00a3c6';
const GREEN = '#33c46c';
const RED = '#ff6187';
const YELLOW = '#ffb22d';
const DEEP_PURPLE = '#786cd9';
const TEAL = '#00897B';
const PINK = '#EC407A';
const LIGHT_BLUE = '#64B5F6';
const BLUE_GRAY = '#607D8B';

export const colors = [GREEN, YELLOW, RED, BLUE, DEEP_PURPLE, TEAL, PINK, LIGHT_BLUE, BLUE_GRAY];

export const colorMap = {
  BLUE,
  GREEN,
  RED,
  DEEP_PURPLE,
  TEAL,
  YELLOW,
  PINK,
  LIGHT_BLUE,
  BLUE_GRAY
};

export function generateColorMap(keys) {
  const map = {};

  keys.forEach((key, index) => {
    map[key] = colors[index % colors.length];
  });

  return map;
}
