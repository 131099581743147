import React from 'react';
import { InputLabel } from 'crazy-ui-next';

import { ExperimentVariableType } from '@domain/enums/ExperimentVariableType';
import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { ControlledIntegerListInput } from '@pages/createExperiment/objectiveConfig/forms/AB/components/controlGroup/inputs/ControlledIntegerListInput';
import { ControlledIntegerInput } from '@pages/createExperiment/objectiveConfig/forms/AB/components/controlGroup/inputs/ControlledIntegerInput';
import { ControlledBooleanInput } from '@pages/createExperiment/objectiveConfig/forms/AB/components/controlGroup/inputs/ControlledBooleanInput';
import { ControlledTextInput } from '@pages/createExperiment/objectiveConfig/forms/AB/components/controlGroup/inputs/ControlledTextInput';
import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/controlGroup/ControlGroup.module.scss';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'control'> & {
  sessionIndex: number;
  variables: ExperimentVariableDto[];
};

export function Session({ control, sessionIndex, variables }: Props) {
  // session index 0 will be used if sessions not supported
  const inputs = variables.map(({ type, name, displayName, minValue, maxValue, sessionsSupported }) => {
    switch (type) {
      case ExperimentVariableType.INT_LIST:
        return (
          <div className={styles.inputRow} key={name}>
            <ControlledIntegerListInput
              control={control}
              name={name}
              index={sessionIndex}
              labelText={displayName}
              min={minValue}
              max={maxValue}
            />
          </div>
        );
      case ExperimentVariableType.INTEGER:
        return (
          <div className={styles.inputRow} key={name}>
            <ControlledIntegerInput
              control={control}
              name={name}
              index={sessionsSupported ? sessionIndex : 0}
              labelText={displayName}
              min={minValue}
              max={maxValue}
            />
          </div>
        );
      case ExperimentVariableType.BOOLEAN:
        return (
          <div className={styles.inputRow} key={name}>
            <ControlledBooleanInput
              control={control}
              name={name}
              index={sessionsSupported ? sessionIndex : 0}
              labelText={displayName}
            />
          </div>
        );
      default:
        return (
          <div className={styles.inputRow} key={name}>
            <ControlledTextInput control={control} name={name} index={sessionIndex} labelText={displayName} />
          </div>
        );
    }
  });
  return (
    <>
      <InputLabel labelText="Control Group" />
      <fieldset>{inputs}</fieldset>
    </>
  );
}
