import {
  CreateExpVariableParams,
  UpdateExpVariableParams
} from '@domain/models/experimentVariable/ExperimentVariableParams';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { ExperimentVariableType } from '@domain/enums/ExperimentVariableType';

const isNumeric = (type: ExperimentVariableType) =>
  [ExperimentVariableType.INT_LIST, ExperimentVariableType.INTEGER].includes(type);

export class ExpVariableMapper {
  static mapCreateParamsToDto(body: CreateExpVariableParams): ExperimentVariableDto {
    const dto = new ExperimentVariableDto();

    dto.name = body.name;
    dto.type = body.type;
    dto.displayName = body.displayName;
    dto.sessionsSupported = Boolean(body.sessionsSupported);
    dto.enabled = Boolean(body.enabled);

    if (isNumeric(body.type)) {
      dto.minValue = String(body.minValue);
      dto.maxValue = String(body.maxValue);
      dto.defaultDisplayValue = String(body.defaultDisplayValue);
    } else if (body.type === ExperimentVariableType.BOOLEAN) {
      dto.minValue = 'false';
      dto.maxValue = 'true';
      dto.defaultDisplayValue = 'true';
    } else if (body.type === ExperimentVariableType.STRING) {
      dto.minValue = '';
      dto.maxValue = '';
      dto.defaultDisplayValue = String(body.defaultDisplayValue);
    }

    return dto;
  }

  static mapUpdateParamsToDto(
    body: Partial<UpdateExpVariableParams>,
    target: ExperimentVariableDto
  ): ExperimentVariableDto {
    const dto = new ExperimentVariableDto();

    dto.id = target.id;
    dto.name = body.name || target.name;
    dto.type = body.type || target.type;
    dto.displayName = body.displayName || target.displayName;
    dto.sessionsSupported =
      typeof body.sessionsSupported === 'boolean' ? body.sessionsSupported : target.sessionsSupported;
    dto.enabled = typeof body.enabled === 'boolean' ? body.enabled : target.enabled;

    if (isNumeric(dto.type)) {
      dto.minValue = String(body.minValue || target.minValue);
      dto.maxValue = String(body.maxValue || target.maxValue);
      dto.defaultDisplayValue = body.defaultDisplayValue || target.defaultDisplayValue;
    } else if (dto.type === ExperimentVariableType.BOOLEAN) {
      dto.minValue = 'false';
      dto.maxValue = 'true';
      dto.defaultDisplayValue = 'true';
    } else if (dto.type === ExperimentVariableType.STRING) {
      dto.minValue = '';
      dto.maxValue = '';
      dto.defaultDisplayValue = String(body.defaultDisplayValue);
    }

    return dto;
  }

  static mapDtoToUpdateParams(dto: ExperimentVariableDto | null): UpdateExpVariableParams {
    const body = new UpdateExpVariableParams();

    if (!dto) {
      return body.setDefaultValues();
    }

    body.id = dto.id;
    body.name = dto.name;
    body.displayName = dto.displayName;
    body.type = dto.type;
    body.defaultDisplayValue = String(dto.defaultDisplayValue);
    body.minValue = String(dto.minValue);
    body.maxValue = String(dto.maxValue);
    body.sessionsSupported = dto.sessionsSupported;
    body.enabled = dto.enabled;

    return body;
  }
}
