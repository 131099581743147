import { createReducer } from '@reduxjs/toolkit';

import { fetchExperiments, clearAll } from '@infrastructure/store/experimentList/experimentListActions';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import { UniqueId } from '@domain/types';

const byIdInitial: Record<UniqueId, ExperimentDto> = {};

export const byIdReducer = createReducer(byIdInitial, (qb) => {
  qb.addCase(clearAll, () => {
    return byIdInitial;
  });
  qb.addCase(fetchExperiments.trigger, (state, { payload }) => {
    if (payload) {
      return state;
    }

    // when we fetch the first page
    return byIdInitial;
  });
  qb.addCase(fetchExperiments.success, (state, { payload }) => {
    payload.thisPageItems.forEach((exp) => {
      state[exp.experimentId] = exp;
    });
  });
});

const idListInitial: UniqueId[] = [];

export const idListReducer = createReducer(idListInitial, (qb) => {
  qb.addCase(clearAll, () => {
    return idListInitial;
  });
  qb.addCase(fetchExperiments.trigger, (state, { payload }) => {
    if (payload) {
      return state;
    }

    // when we fetch the first page
    return idListInitial;
  });
  qb.addCase(fetchExperiments.success, (state, { payload }) => {
    const ids = payload.thisPageItems.map((exp) => exp.experimentId);
    state.push(...ids);
  });
});
