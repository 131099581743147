import React from 'react';

type Props = {
  label: string;
  children?: React.ReactNode;
};

// TODO: Replace styling with css modules
export function PageTitle({ label, children }: Props) {
  return (
    <section className="page-title">
      <div className={`container ${children ? 'page-title__block' : ''}`}>
        <h1 className="page-title__text">{label || 'Page'}</h1>
        {children && <div className="page-title__custom">{children}</div>}
      </div>
    </section>
  );
}
