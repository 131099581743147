import { components } from 'react-select';
import { ReactComponent as ArrowsSvg } from '@assets/svg/arrows.svg';
import React from 'react';

export function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowsSvg />
    </components.DropdownIndicator>
  );
}
