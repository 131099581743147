import React from 'react';

import { ExpDefinitionContainer } from '@pages/experimentDetails/components/summary/config/definition/ExpDefinitionContainer';
import { ExpObjectiveConfigContainer } from '@pages/experimentDetails/components/summary/config/objective/ExpObjectiveConfigContainer';
import { GroupsConfigContainer } from '@pages/experimentDetails/components/summary/config/groups/GroupsConfigContainer';
import { ConfigSection } from '@pages/experimentDetails/components/summary/config/atoms/ConfigSection';

import styles from './ExperimentConfig.module.scss';

export function ExperimentConfig() {
  return (
    <div className={styles.config}>
      <main>
        <ConfigSection label="Experiment Definition:" children={<ExpDefinitionContainer />} />
        <ConfigSection label="Objective Config:" children={<ExpObjectiveConfigContainer />} />
        <ConfigSection children={<GroupsConfigContainer />} />
      </main>
    </div>
  );
}
