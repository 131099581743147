export class StringUtils {
  static getFirstLetters(str: any): string {
    if (!str || typeof str !== 'string') {
      return '';
    }

    const words = str
      .replace(/[_\-,\s]/gim, ' ')
      .split(' ')
      .filter((el) => el.length > 0)
      .slice(0, 2);

    if (words.length === 1) {
      return words[0].slice(0, 2).toUpperCase();
    }

    return words.map((el) => el[0].toUpperCase()).join('');
  }

  static capitalizeFirstLetter(string: string): string {
    return string[0].toUpperCase() + string.slice(1);
  }

  static formatToStartCase(string: string): string {
    const words = string.split(' ');
    const lowerCaseWords = words.map((word) => word.toLowerCase());
    const capitalizedWords = lowerCaseWords.map((word) => StringUtils.capitalizeFirstLetter(word));

    return capitalizedWords.join(' ');
  }

  static getInitialsFromEmail(str: any): string {
    if (!str || typeof str !== 'string') {
      return '';
    }

    const [name] = str.split('@');

    const firstLetter = name[0];
    const lastLetter = name[name.length - 1];

    return [firstLetter, lastLetter].join('').toUpperCase();
  }

  static getNextChar(char: string): string {
    return String.fromCharCode(char.charCodeAt(0) + 1);
  }

  static ellipsis(text: string): string {
    if (text.length > 35) {
      return `${text.substring(0, 35)} ... ${text.substring(text.length - 35, text.length)}`;
    }
    return text;
  }

  static getNameFromPath(path: string): string {
    const name = path.split('/').pop();

    return name || path;
  }
}
