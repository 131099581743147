import React from 'react';
import { useController } from 'react-hook-form';
import { DropdownInput } from 'crazy-ui-next';

import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { IterationRequestParams } from '@domain/models/iteration/IterationRequestParams';
import { FormComponent } from '@ui/hooks/form';
import { useSelector } from '@ui/hooks/redux';
import { ReactComponent as LineSvg } from '@assets/svg/stack-line.svg';

import styles from '@pages/experimentDetails/components/stats/components/result/components/filters/Filters.module.scss';

type Props = Pick<FormComponent<IterationRequestParams>, 'control'> & { disabled: boolean };

export function ARPUInput({ control, disabled }: Props) {
  const { field } = useController({ control, name: 'arpu' });
  const { availableARPU } = useSelector(experimentDetailsSelectors.getIterationFiltersMeta);
  const options = availableARPU.map((value) => ({
    label: value === 1 ? '1 Day' : `${value} Days`,
    value
  }));

  return (
    <DropdownInput
      labelText="ARPU"
      placeholder="Select ARPU"
      name="arpu"
      value={Number(field.value)}
      options={options}
      className={styles.input}
      inputIcon={<LineSvg />}
      handleChange={(event) => field.onChange(event.value)}
      disabled={disabled}
    />
  );
}
