import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { PageLoader } from 'crazy-ui';

import ErrorBoundary from '@components/wrappers/ErrorBoundary';
import { RouterSwitch } from '@ui/RouterSwitch';

export function App({ store, persistor, history }) {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={<PageLoader />} persistor={persistor}>
        <Router history={history}>
          <ErrorBoundary>
            <ToastContainer hideProgressBar position={toast.POSITION.TOP_RIGHT} autoClose={20 * 1000} />
            <RouterSwitch />
          </ErrorBoundary>
        </Router>
      </PersistGate>
    </ReduxProvider>
  );
}
