import React from 'react';

import { Header } from '@pages/appliedConfig/table/Header';
import { Row } from '@pages/appliedConfig/table/Row';
import { UniqueId } from '@domain/types';
import { AppliedConfigDto } from '@domain/models/appliedConfig/AppliedConfigDto';
import usePermission from '@ui/hooks/usePermission';

type Props = {
  gameId: UniqueId;
  configs: AppliedConfigDto[];
};

export function InnerTable({ gameId, configs }: Props) {
  const haveFullAccess = usePermission({
    admin: true,
    analyst: true
  });

  const id = `inner-exp-list-${gameId}`;

  const rows = configs.map((config) => (
    <Row key={`${gameId}_${config.id}`} config={config} haveFullAccess={haveFullAccess} />
  ));

  return (
    <table id={id}>
      <thead>
        <Header haveFullAccess={haveFullAccess} />
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}
