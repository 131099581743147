import axios from 'axios';
import qs from 'qs';

const api = axios.create({
  baseURL: '/api/',
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
});

api.interceptors.response.use((res) => res.data);

/**
 * @deprecated
 * @param token
 */
export function setAuthHeader({ token }) {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function removeCredentials() {
  localStorage.removeItem('authToken');
  localStorage.removeItem('userIconUrl');
}

export default api;
