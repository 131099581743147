import { useCallback, useState } from 'react';

type Props = {
  isActive: boolean;
  handleToggle: () => void;
  handleClose: () => void;
  handleOpen: () => void;
};

export function useToggle(initialValue = false): Props {
  const [isActive, handleExpand] = useState(initialValue);

  const handleToggle = useCallback(() => {
    handleExpand(!isActive);
  }, [isActive, handleExpand]);

  const handleClose = useCallback(() => {
    handleExpand(false);
  }, [handleExpand]);

  const handleOpen = useCallback(() => {
    handleExpand(true);
  }, [handleExpand]);

  return { isActive, handleToggle, handleClose, handleOpen };
}
