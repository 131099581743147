import { Dto } from '@domain/models/Dto';

export class ExpObjectiveConfigParams extends Dto {
  public primaryRegion: number; // Region Id
  public newUsers: boolean;
  public sticky: boolean;
  public adjustableUsersAllocation: boolean;
  public usersAllocationPercent: number;

  static ofInitial() {
    const form = new ExpObjectiveConfigParams();

    form.primaryRegion = 0;
    form.newUsers = false;
    form.sticky = true;
    form.adjustableUsersAllocation = false;
    form.usersAllocationPercent = 100;

    return form;
  }

  getSummary(): { title: string; value: string; key?: string }[] {
    return [
      { title: 'Decisioning Region', value: String(this.primaryRegion), key: 'primaryRegion' },
      { title: 'New Users', value: String(this.newUsers) },
      { title: 'Sticky', value: String(this.sticky) },
      { title: 'Adjustable Percent of Users', value: String(this.adjustableUsersAllocation) },
      { title: 'Engaged Users', value: String(this.usersAllocationPercent), key: 'usersAllocationPercent' }
    ];
  }
}
