import { IsBoolean, IsEmail, IsEnum, IsNotEmpty, IsNumber, IsString } from 'class-validator';
import { UserRoleType } from '@domain/enums/UserRoleType';

export class UserFormParams {
  @IsString()
  @IsNotEmpty()
  firstName: string;

  @IsString()
  @IsNotEmpty()
  lastName: string;

  @IsEmail()
  @IsNotEmpty()
  email: string;

  @IsEnum(UserRoleType)
  @IsNotEmpty()
  role: UserRoleType;

  @IsBoolean()
  enabled: boolean;

  setDefaultValues() {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.role = UserRoleType.ADMIN;
    this.enabled = true;
    return this;
  }
}

export class CreateUserParams extends UserFormParams {}

export class UpdateUserParams extends UserFormParams {
  @IsNumber()
  @IsNotEmpty()
  id?: number;
}
