import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './SideMenu.module.scss';

export function SideMenuItem({ text, icon, to }) {
  return (
    <NavLink exact className={styles.item} activeClassName={styles.selected} to={to}>
      <div className={styles.icon}>{icon}</div>
      <p>{text}</p>
    </NavLink>
  );
}
