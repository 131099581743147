export enum DataAttrKey {
  ID = 'data-id',
  ACTION = 'data-action',
  ROLE = 'data-role',
  USER_EMAIL = 'data-user-email',
  USER_NAME = 'data-user-name'
}

export enum FormAttrValue {
  SUBMIT = 'submit',
  CANCEL = 'cancel'
}

/* Aliases */
export const SubmitBtnAttr = { [DataAttrKey.ACTION]: FormAttrValue.SUBMIT };
export const CancelBtnAttr = { [DataAttrKey.ACTION]: FormAttrValue.CANCEL };
