import React from 'react';

import { ReactComponent as PlusSvg } from '@assets/svg/plus-fill.svg';

import styles from './AddSectionBtn.module.scss';

type Props = {
  handleClick: () => void;
  disabled?: boolean;
};

export function AddSectionBtn({ handleClick, disabled }: Props) {
  return (
    <button className={styles.button} type="button" name="add-section-btn" onClick={handleClick} disabled={disabled}>
      <PlusSvg />
      New Section
    </button>
  );
}
