import React from 'react';

import { IterationResultDto } from '@domain/models/iteration/IterationResultDto';
import { metricKeyOrder } from '@pages/experimentDetails/components/stats/components/result/components/filters/components/toggleColumns/utils/metricKeys';
import { TRow } from '@pages/experimentDetails/components/stats/components/result/components/tableResult/TRow';

type Props = {
  result: IterationResultDto[];
  metricKeys: string[];
  isAB: boolean;
};
export function TBody({ result, metricKeys, isAB }: Props) {
  const metricKeysOrdered = metricKeyOrder.filter((key) => metricKeys.includes(key));

  const rows = result.map((iterationResult) => (
    <TRow result={iterationResult} metricKeys={metricKeysOrdered} key={iterationResult.group} isAB={isAB} />
  ));

  return <tbody>{rows}</tbody>;
}
