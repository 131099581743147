import React from 'react';
import cn from 'classnames';
import { Button } from 'crazy-ui-next';

import { useSelector } from '@ui/hooks/redux';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';

import styles from '../../../../ExperimentDetails.module.scss';
import { ExperimentMapper } from '@app/mappers/ExperimentMapper';

type Props = {
  handleSet: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export function ExpObjectiveSelect({ handleSet }: Props) {
  const { experimentObjectives } = useSelector(experimentDetailsSelectors.getExperiment);
  const objectiveId = useSelector(experimentDetailsSelectors.getSelectedObjectiveId);
  const regions = ExperimentMapper.extractRegions(experimentObjectives, true);

  const options = regions
    .map((regionName) => {
      const objective = experimentObjectives.find((obj) => obj.primaryRegion.name === regionName);

      return {
        label: regionName,
        value: objective?.id
      };
    })
    .filter((option) => Boolean(option.value));

  return (
    <div className={styles.objectiveSelect}>
      {options.map(({ label, value }) => (
        <Button
          className={cn(value !== objectiveId && styles.unselected)}
          key={label}
          onClick={handleSet}
          value={value}
        >
          {label}
        </Button>
      ))}
    </div>
  );
}
