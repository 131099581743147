import { Service } from 'typedi';

import { BackendHttpClient } from '@infrastructure/http/BackendHttpClient';

import { UniqueId } from '@domain/types';
import { ApplyConfigRequest } from '@domain/models/winnerConfig/ApplyConfigRequest';
import { MessageResponseDto } from '@domain/models/MessageResponseDto';

@Service()
export class WinnerConfigApi {
  constructor(protected readonly http: BackendHttpClient) {}

  async applyConfig(
    experimentId: UniqueId,
    objectiveId: UniqueId,
    params: ApplyConfigRequest
  ): Promise<MessageResponseDto> {
    return this.http.post(`/intops/admin/experiment/${experimentId}/objective/${objectiveId}/appliedconfig`, params);
  }

  async updatedConfig(
    experimentId: UniqueId,
    objectiveId: UniqueId,
    params: ApplyConfigRequest
  ): Promise<MessageResponseDto> {
    return this.http.put(`/intops/admin/experiment/${experimentId}/objective/${objectiveId}/appliedconfig`, params);
  }

  async deleteConfig(experimentId: UniqueId, objectiveId: UniqueId): Promise<MessageResponseDto> {
    return this.http.delete(`/intops/admin/experiment/${experimentId}/objective/${objectiveId}/appliedconfig`);
  }
}
