import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { clearAll, fetchExperimentAudit } from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import { AuditDto } from '@domain/models/experiment/AuditDto';

const auditInitial: AuditDto[] = [];

const auditDataReducer = createReducer(auditInitial, (qb) => {
  qb.addCase(fetchExperimentAudit.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return auditInitial;
  });
});

export const auditReducer = combineReducers({
  data: auditDataReducer,

  status: ReducerFactory.makeStatus(fetchExperimentAudit, clearAll)
});
