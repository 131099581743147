import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'crazy-ui';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/controlGroup/ControlGroup.module.scss';

type Props = {
  sessionIndex: string;
  index: number;
  handleSave: (index: number, sessionIndex: string) => void;
  viewOnly: boolean;
};

export function EditableSessionIndex({ index, handleSave, sessionIndex, viewOnly }: Props) {
  const ref = useRef<HTMLInputElement>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(sessionIndex);

  const handleChange = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  const handleEdit = useCallback(() => {
    setIsEdit(true);
  }, [setIsEdit]);

  const handleBlur = useCallback(() => {
    setIsEdit(false);
    handleSave(index, value);
  }, [setIsEdit, value, index, handleSave]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        setIsEdit(false);
        handleSave(index, sessionIndex);
      }
      if (event.key === 'Enter') {
        setIsEdit(false);
        handleSave(index, value);
      }
    },
    [handleSave, index, value, sessionIndex]
  );

  useEffect(() => {
    if (isEdit && ref.current !== null) {
      ref.current.focus();
    }
  }, [isEdit, ref]);

  if (viewOnly) {
    return <span>{sessionIndex}</span>;
  }

  return isEdit ? (
    <input
      onKeyDown={handleKeyDown}
      ref={ref}
      type="number"
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      className={styles.editableSession}
    />
  ) : (
    <Tooltip text="double click to see more">
      <span onDoubleClick={handleEdit}>{sessionIndex}</span>
    </Tooltip>
  );
}
