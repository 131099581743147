import React from 'react';
import { InputLabel, RadioInput } from 'crazy-ui-next';
import { Controller } from 'react-hook-form';

import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/inputGroup/InputGroup.module.scss';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'control'> & {
  sectionIndex: number;
  sessionIndex: number;
  name: string;
  labelText: string;
};

export function ControlledBooleanInput({ control, name, labelText, sectionIndex, sessionIndex }: Props) {
  return (
    <Controller
      name={`input.${sectionIndex}.section.${sessionIndex}.${name}`}
      control={control}
      render={({ field }) => {
        return (
          <>
            <InputLabel labelText={labelText} className={styles.label} />
            <RadioInput
              name={field.name}
              checked={field.value === '1'}
              labelText="On"
              value="1"
              onChange={({ currentTarget }) => {
                field.onChange(currentTarget.value);
              }}
            />
            <RadioInput
              name={field.name}
              checked={field.value === '0'}
              labelText="Off"
              value="0"
              onChange={({ currentTarget }) => {
                field.onChange(currentTarget.value);
              }}
            />
            <RadioInput
              name={field.name}
              checked={field.value === '2'}
              labelText="Both"
              value="2"
              onChange={({ currentTarget }) => {
                field.onChange(currentTarget.value);
              }}
            />
          </>
        );
      }}
    />
  );
}
