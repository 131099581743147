import React from 'react';
import { DropdownEvent, DropdownMultiInput, DropdownOption } from 'crazy-ui-next';

import { ExpStatusFilterValue } from '@infrastructure/store/experimentList/types/filters';
import { ReactComponent as CheckListSvg } from '@assets/svg/check-list.svg';

const options: DropdownOption<ExpStatusFilterValue>[] = [
  { value: ExpStatusFilterValue.ALL, label: 'All' },
  { value: ExpStatusFilterValue.RUNNING, label: 'Running' },
  { value: ExpStatusFilterValue.IN_DEV, label: 'In Dev' },
  { value: ExpStatusFilterValue.STOPPED, label: 'Stopped' },
  { value: ExpStatusFilterValue.CANCELED, label: 'Canceled' }
];

type Props = {
  selected: ExpStatusFilterValue[];
  onChange: (e: DropdownEvent<ExpStatusFilterValue>) => void;
};

export function StatusFilter({ onChange, selected }: Props) {
  return (
    <DropdownMultiInput
      name="status"
      inputIcon={<CheckListSvg />}
      value={selected}
      options={options}
      handleChange={onChange}
      labelText="Status"
    />
  );
}
