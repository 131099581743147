import React from 'react';
import { DropdownInput, DropdownOption } from 'crazy-ui-next';

import { LogViewContainer } from '@pages/experimentDetails/components/stats/components/logs/LogViewContainer';
import { ReactComponent as FileSvg } from '@assets/svg/file.svg';

import styles from '@pages/experimentDetails/components/stats/components/logs/Logs.module.scss';

type Props = {
  options: DropdownOption[];
  selectedLog: string;
  handleChange: (e) => void;
  log: string;
};

export function Logs({ options, handleChange, selectedLog, log }: Props) {
  return (
    <div className={styles.logs}>
      <DropdownInput
        name="experiment-log-name"
        labelText="Log File"
        value={selectedLog}
        options={options}
        handleChange={handleChange}
        inputIcon={<FileSvg />}
        className={styles.dropdown}
      />
      <LogViewContainer />
    </div>
  );
}
