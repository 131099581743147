import React from 'react';

import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { Header } from '@pages/experimentDetails/components/header/Header';
import { useSelector } from '@ui/hooks/redux';

export function HeaderContainer() {
  const experiment = useSelector(experimentDetailsSelectors.getExperiment);
  const { experimentName, state, gameApp } = experiment;

  return <Header name={experimentName} state={state} game={gameApp} />;
}
