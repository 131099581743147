import React, { useCallback } from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';

import { Routing } from '@infrastructure/routing';
import { pushExperimentCreate } from '@infrastructure/store/createExperiment/createExperimentActions';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { ReactComponent as CancelSvg } from '@assets/svg/cancel.svg';
import { ReactComponent as AcceptSvg } from '@assets/svg/accept.svg';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { RequestStage } from '@infrastructure/store/types/actions';

import styles from '@pages/createExperiment/CreateExperiment.module.scss';

export function FormControls() {
  const dispatch = useDispatch();
  const isFormReady = useSelector(createExperimentSelectors.getIsFormReady);
  const status = useSelector(createExperimentSelectors.getStatus);
  const isLoading = isFormReady && status === RequestStage.REQUEST;

  const handleComplete = useCallback(() => {
    dispatch(pushExperimentCreate.trigger());
  }, [dispatch]);

  return (
    <fieldset className={styles.controls}>
      <Button href={Routing.getExperimentList()} variant={ButtonVariant.SECONDARY} icon={<CancelSvg />}>
        Cancel
      </Button>
      <Button
        variant={ButtonVariant.PRIMARY}
        icon={<AcceptSvg />}
        onClick={handleComplete}
        disabled={!isFormReady}
        isLoading={isLoading}
      >
        Complete
      </Button>
    </fieldset>
  );
}
