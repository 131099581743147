import React from 'react';

import { Link } from '@domain/types';

type Props = {
  href: Link;
  labelText?: string;
  className?: string;
};

export function ExternalLink({ href, labelText, className }: Props) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className={className}>
      {labelText || href}
    </a>
  );
}
