import React from 'react';
import cn from 'classnames';

import styles from '@pages/winnerConfigModal/list/List.module.scss';
import { CountryLabel } from '@components/layout/countryLabel/CountryLabel';

type Props = { region: string; isHeaderRow: boolean };

export function Region({ region, isHeaderRow }: Props) {
  return (
    <div className={cn(styles.col, styles.region)}>
      <CountryLabel countryCode={region} />

      {isHeaderRow && (
        <div className={styles.heading}>
          <p>Region</p>
        </div>
      )}
    </div>
  );
}
