import React from 'react';
import { DropdownInput } from 'crazy-ui-next';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { AppPropertyForm } from '@domain/models/appProperty/AppPropertyInput';
import { FormComponent } from '@ui/hooks/form';

type Props = FormComponent<AppPropertyForm> & {
  index: number;
  labelText: string;
  className: string;
};

export function AppPropertyPeriodicityInput({ className, labelText, control, register, errors, index }: Props) {
  const { options } = useSelector(configSelectors.getPeriodicityConfig);

  return (
    <div className={className}>
      <Controller
        name={`property.${index}.value`}
        control={control}
        render={({ field }) => {
          return (
            <DropdownInput
              name={field.name}
              labelText={labelText}
              value={String(field.value)}
              options={options}
              handleChange={(event) => field.onChange(event.value)}
            />
          );
        }}
      />
    </div>
  );
}
