import { call, put, takeLatest } from 'redux-saga/effects';
import { Service } from 'typedi';

import { RootSagaWatcher } from '@infrastructure/sagas/RootSagaWatcher';
import { BaseSagaWatcher } from '@infrastructure/sagas/BaseSagaWatcher';
import { pushLogin } from '@infrastructure/store/auth/authActions';
import { AppConfigDto } from '@domain/models/local/AppConfig';
import { Notification } from '@infrastructure/api/notifications';
import { ConfigService } from '@app/services/ConfigService';

import {
  fetchAppConfig,
  fetchUserPropertyOperators,
  pushCreateVariable,
  pushUpdateVariable
} from '@infrastructure/store/config/configActions';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import {
  fetchAppProperties,
  updateAppProperties
} from '@infrastructure/store/applicationProperties/appPropertiesActions';
import { AppPropertyDto } from '@domain/models/appProperty/AppPropertyDto';
import { UserPropertyOperatorsDto } from '@domain/models/createExperiment/userProperties/UserPropertyOperatorsDto';

@Service()
export class ConfigSaga extends BaseSagaWatcher {
  constructor(private configService: ConfigService, protected rootWatcher: RootSagaWatcher) {
    super(rootWatcher);
  }

  public getEffects() {
    return [
      takeLatest(pushCreateVariable.TRIGGER, this.createNewVariable.bind(this)),
      takeLatest(pushCreateVariable.SUCCESS, this.fetchConfig.bind(this)),
      takeLatest(pushCreateVariable.SUCCESS, this.afterEdit.bind(this)),

      takeLatest(pushUpdateVariable.TRIGGER, this.updateVariable.bind(this)),
      takeLatest(pushUpdateVariable.SUCCESS, this.fetchConfig.bind(this)),
      takeLatest(pushUpdateVariable.SUCCESS, this.afterEdit.bind(this)),

      takeLatest(pushLogin.SUCCESS, this.fetchConfig.bind(this)),
      takeLatest(pushLogin.SUCCESS, this.fetchAppProperties.bind(this)),
      takeLatest(pushLogin.SUCCESS, this.fetchUserPropertyOperators.bind(this)),

      takeLatest(fetchAppProperties.TRIGGER, this.fetchAppProperties.bind(this)),
      takeLatest(updateAppProperties.TRIGGER, this.updateAppProperties.bind(this))
    ];
  }

  *afterEdit() {
    yield call([this.configService, this.configService.navigateToMainPage]);
  }

  *fetchConfig() {
    try {
      yield put(fetchAppConfig.request());
      const config: AppConfigDto = yield call([this.configService, this.configService.getConfig]);
      yield put(fetchAppConfig.success(config));
    } catch (err) {
      yield put(fetchAppConfig.failure());
      Notification.error(err);
    }
  }

  *updateVariable(action: ReturnType<typeof pushUpdateVariable.trigger>) {
    try {
      yield put(pushUpdateVariable.request());
      const result: ExperimentVariableDto = yield call(
        [this.configService, this.configService.updateVariable],
        action.payload
      );
      yield put(pushUpdateVariable.success(result));
      Notification.success('Experiment variable has been updated');
    } catch (err) {
      yield put(pushUpdateVariable.failure());
      Notification.error(err);
    }
  }

  *createNewVariable(action: ReturnType<typeof pushCreateVariable.trigger>) {
    try {
      yield put(pushCreateVariable.request());
      const result: ExperimentVariableDto = yield call(
        [this.configService, this.configService.createVariable],
        action.payload
      );
      yield put(pushCreateVariable.success(result));
      Notification.success('Experiment variable has been created');
    } catch (err) {
      yield put(pushCreateVariable.failure());
      Notification.error(err);
    }
  }

  *fetchAppProperties() {
    try {
      yield put(fetchAppProperties.request());
      const appProperties: AppPropertyDto[] = yield call([
        this.configService,
        this.configService.getGlobalAppProperties
      ]);

      yield put(fetchAppProperties.success(appProperties));
    } catch (err) {
      yield put(fetchAppProperties.failure());
      Notification.error(err);
    }
  }

  *updateAppProperties(action: ReturnType<typeof updateAppProperties.trigger>) {
    try {
      yield put(updateAppProperties.request());
      const appProperties: AppPropertyDto[] = yield call(
        [this.configService, this.configService.updateGlobalAppProperties],
        action.payload
      );
      yield put(updateAppProperties.success(appProperties));
      Notification.success('Global application configuration has been updated.');
    } catch (err) {
      yield put(updateAppProperties.failure());
      Notification.error(err);
    }
  }

  *fetchUserPropertyOperators() {
    try {
      yield put(fetchUserPropertyOperators.request());

      const result: UserPropertyOperatorsDto = yield call([
        this.configService,
        this.configService.getUserPropertyOperators
      ]);

      yield put(fetchUserPropertyOperators.success(result));
    } catch (err) {
      yield put(fetchUserPropertyOperators.failure());
      Notification.error(err);
    }
  }
}
