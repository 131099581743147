import { Service } from 'typedi';
// eslint-disable-next-line import/no-extraneous-dependencies
import crypto from 'crypto-js';

import { ChartApi } from '@infrastructure/api/ChartApi';
import { ChartUseCases } from '@domain/useCases/ChartUseCases';
import { ReduxRepo } from '@infrastructure/repositories/ReduxRepo';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { ChartDto } from '@domain/models/ChartDto';
import { ChartType } from '@domain/enums/ChartType';
import { IterationRequestParams } from '@domain/models/iteration/IterationRequestParams';
import { UniqueId } from '@domain/types';

@Service()
export class ChartService implements ChartUseCases {
  constructor(private readonly reduxRepo: ReduxRepo, private readonly chartApi: ChartApi) {}

  async getLossOverTimeChart(filters: IterationRequestParams, iterationId: UniqueId): Promise<ChartDto> {
    return this.chartApi.getLossOverTimeChart(iterationId, filters);
  }

  async getKPIWithConfidenceChart(filters: IterationRequestParams, iterationId: UniqueId): Promise<ChartDto> {
    return this.chartApi.getKPIWithConfidenceChart(iterationId, filters);
  }

  async getUsersPerDayChart(filters: IterationRequestParams, iterationId: UniqueId): Promise<ChartDto> {
    return this.chartApi.getUsersPerDayChart(iterationId, filters);
  }

  async getKPISanityChart(filters: IterationRequestParams, iterationId: UniqueId): Promise<ChartDto> {
    return this.chartApi.getKPISanityChart(iterationId, filters);
  }

  async getUsersSanityChart(filters: IterationRequestParams, iterationId: UniqueId): Promise<ChartDto> {
    return this.chartApi.getUsersSanityChart(iterationId, filters);
  }

  async getKPIChart(filters: IterationRequestParams, iterationId: UniqueId): Promise<ChartDto> {
    return this.chartApi.getKPIChart(iterationId, filters);
  }

  async getIterationCharts(): Promise<{ result: Record<ChartType, ChartDto>; cacheKey: string; isNew: boolean }> {
    const filters = this.reduxRepo.findBy(experimentDetailsSelectors.getIterationFilters);
    const { iterationId } = this.reduxRepo.findBy(experimentDetailsSelectors.getSelectedIteration);

    const cacheKey = crypto.SHA256(`${iterationId}${filters.region}${filters.version}${filters.arpu}`).toString();
    const cacheResult = this.reduxRepo.findBy(experimentDetailsSelectors.getChartFromCache(cacheKey));

    if (cacheResult) {
      return { cacheKey, result: cacheResult, isNew: false };
    }

    const [kpiRate, kpiConfidence, lossOverTime, kpiSanity, userSanity, usersPerDay] = await Promise.all([
      this.getKPIChart(filters, iterationId),
      this.getKPIWithConfidenceChart(filters, iterationId),
      this.getLossOverTimeChart(filters, iterationId),
      this.getKPISanityChart(filters, iterationId),
      this.getUsersSanityChart(filters, iterationId),
      this.getUsersPerDayChart(filters, iterationId)
    ]);

    const payload = {
      [ChartType.KPI_RATE]: kpiRate,
      [ChartType.KPI_CONFIDENCE]: kpiConfidence,
      [ChartType.LOSS_OVER_TIME]: lossOverTime,
      [ChartType.KPI_SANITY]: kpiSanity,
      [ChartType.USER_SANITY]: userSanity,
      [ChartType.USERS_PER_DAY]: usersPerDay
    };

    return { cacheKey, result: payload, isNew: true };
  }
}
