import { Service } from 'typedi';
import { BackendHttpClient } from '@infrastructure/http/BackendHttpClient';
import { GameStatsDto } from '@domain/models/experiment/GameStatsDto';
import { ResponseMapper } from '@app/mappers/ResponseMapper';
import { UniqueId } from '@domain/types';
import { GameInstallsStatsDto } from '@domain/models/game/GameInstallsStatsDto';

@Service()
export class GameApi {
  constructor(protected readonly http: BackendHttpClient) {}

  async getGameStats(applicationId: UniqueId): Promise<GameStatsDto[]> {
    return this.http
      .get(`/intops/admin/gamestats/${applicationId}/revenue`)
      .then((list) => list?.map(ResponseMapper.mapToGameStatsDto) || []);
  }

  async getGameInstallsByRegion(applicationId: UniqueId, regionId: UniqueId): Promise<GameInstallsStatsDto> {
    return this.http
      .get(`/intops/admin/gamestats/${applicationId}/installs`, { regionId })
      .then(ResponseMapper.mapToGameInstallsStatsDto);
  }

  async getGameInstallsByCountries(applicationId: UniqueId, countries: string[]): Promise<GameInstallsStatsDto> {
    return this.http
      .get(`/intops/admin/gamestats/${applicationId}/installs`, { countries })
      .then(ResponseMapper.mapToGameInstallsStatsDto);
  }
}
