import React from 'react';

import KpiConfidenceChartContainer from '@pages/experimentDetails/components/stats/components/result/components/charts/kpiConfidence/KpiConfidenceChartContainer';
import KpiRateChartContainer from '@pages/experimentDetails/components/stats/components/result/components/charts/kpiRate/KpiRateChartContainer';
import ExpectedLossChartContainer from '@pages/experimentDetails/components/stats/components/result/components/charts/expectedLoss/ExpectedLossChartContainer';
import { UserPerDayChartContainer } from '@pages/experimentDetails/components/stats/components/result/components/charts/usersPerDay/UserPerDayChartContainer';

import styles from '@pages/experimentDetails/components/stats/components/result/components/charts/Charts.module.scss';

export function Charts() {
  return (
    <div className={styles.wrapper}>
      <KpiConfidenceChartContainer />
      <KpiRateChartContainer />
      <ExpectedLossChartContainer />
      <UserPerDayChartContainer />
    </div>
  );
}
