import { Dto } from '@domain/models/Dto';
import { UniqueId } from '@domain/types';

export interface UserProperty {
  id: UniqueId;
  displayName: string;
}

export class UserPropertiesDto extends Dto {
  userProperties: UserProperty[];
}
