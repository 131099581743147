import { uniq } from 'lodash-es';
import { cloneDeep } from 'lodash-es';

import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import { IterationAvailableMeta } from '@domain/models/iteration/IterationAvailableMeta';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';
import { ExperimentRegion, ExperimentRegionName } from '@domain/enums/ExperimentRegion';

export class ExperimentMapper {
  static extractRegions(objectives: ExperimentObjectiveDto[], includeSticky: boolean): string[] {
    // list of regions should go first. US -> WW -> other
    // note: ExperimentRegion.US is used for an old experiments as backward compatible
    let initRegions = [ExperimentRegionName.US, ExperimentRegion.US, ExperimentRegionName.WW];
    let regions: string[] = [];

    // extract regions from objectives
    objectives.forEach((objective) => {
      // include objective to test result, when it's check for a new users or DAU tests with enabled sticky parameter
      const includeObjective = includeSticky || objective.newUsers || (!objective.newUsers && objective.sticky);

      if (includeObjective) {
        objective.regions.forEach((region) => {
          !regions.includes(region.name) && regions.push(region.name);
        });
      }
    });

    const cloneRegions = cloneDeep(regions);

    const result: string[] = [];

    // check and exclude init regions when not match with regions from objectives
    initRegions.forEach((regionName) => {
      if (cloneRegions.includes(regionName)) {
        regions = regions.filter((region) => regionName !== region);
        result.push(regionName);
      }
    });

    return [...result, ...regions];
  }

  static getAvailableMeta(experiment: ExperimentDto): IterationAvailableMeta {
    const data = IterationAvailableMeta.getInitial();

    const regions = ExperimentMapper.extractRegions(experiment.experimentObjectives, false);

    data.availableARPU = uniq([...experiment.reportingArpu, experiment.arpu]);
    data.defaultARPU = experiment.arpu;
    data.availableVersions = experiment.experimentObjectives[0].appVersions;
    data.availableRegions = regions;
    data.defaultRegion = regions[0];

    return data;
  }
}
