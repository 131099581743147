import { createReducer } from '@reduxjs/toolkit';

import { clearAll, fetchRegions, setGame } from '@infrastructure/store/createExperiment/createExperimentActions';
import { RegionDto } from '@domain/models/RegionDto';

type StateType = {
  defaultRegions: RegionDto[];
  recommendedRegions: RegionDto[];
};

const regionsInitial: StateType = {
  defaultRegions: [],
  recommendedRegions: []
};

export const regionsReducer = createReducer(regionsInitial, (qb) => {
  qb.addCase(fetchRegions.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(setGame, () => {
    return regionsInitial;
  });
  qb.addCase(clearAll, () => {
    return regionsInitial;
  });
});
