import React from 'react';
import { Button } from 'crazy-ui';
import { Routing } from '@infrastructure/routing';

import { ReactComponent as EditSvg } from '@assets/svg/edit.svg';
import { RoleMetaMap } from '@pages/userManagement/const/RoleMetaMap';
import { UserRoleType } from '@domain/enums/UserRoleType';
import styles from './RoleManagerModule.module.scss';

type RoleManagerProps = {
  roles: UserRoleType[];
  userId: number;
};

type RoleLabelProps = {
  role: UserRoleType;
};

function RoleLabel({ role }: RoleLabelProps) {
  const { title, color } = RoleMetaMap[role] || {};

  return (
    <li className={styles.roleItem} style={{ backgroundColor: color }}>
      <span className={styles.name}>{title || role}</span>
    </li>
  );
}

export function RoleManager({ roles, userId }: RoleManagerProps) {
  return (
    <div className="d-flex flex-grow-1 justify-content-between" data-roles={roles.join(',')}>
      <ul className={styles.roles}>
        {roles.map((role) => (
          <RoleLabel role={role} key={role} />
        ))}
      </ul>
      <div className="d-flex align-items-center">
        <Button
          variant={Button.OUTLINE}
          color={Button.GRAY}
          className={styles.addRoleBtn}
          svgMargin={false}
          tooltip="Edit User"
          type={Button.NAV_LINK}
          to={Routing.getEditUser(userId)}
        >
          <EditSvg />
        </Button>
      </div>
    </div>
  );
}
