import React from 'react';

import { UpdateWinnerConfigParams } from '@domain/models/winnerConfig/WinnerConfigFormParams';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';
import { GamePlatform } from '@domain/enums/GamePlatform';
import { Controls } from '@pages/winnerConfigModal/form/Controls';
import { Header } from '@pages/winnerConfigModal/form/Header';
import { AudienceInputs } from '@pages/winnerConfigModal/form/inputs/AudienceInputs';
import { ListWrapper } from '@pages/winnerConfigModal/list/ListWrapper';
import { FormComponent } from '@ui/hooks/form';

type Props = FormComponent<UpdateWinnerConfigParams> & {
  objective: ExperimentObjectiveDto;
  handleCloseModal: () => void;
  controlsDisabled: boolean;
  isLoading: boolean;
  platform: GamePlatform;
};

export function Form({
  handleSubmit,
  control,
  register,
  errors,
  handleCloseModal,
  controlsDisabled,
  objective,
  isLoading,
  platform
}: Props) {
  return (
    <form onSubmit={handleSubmit}>
      <Header objective={objective} platform={platform} />

      <ListWrapper
        control={control}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        objective={objective}
      />
      <AudienceInputs handleSubmit={handleSubmit} register={register} errors={errors} control={control} />

      <Controls handleCancel={handleCloseModal} disabled={controlsDisabled} isLoading={isLoading} />
    </form>
  );
}
