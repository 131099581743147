import React from 'react';

import CheckPermission from '@components/layout/CheckPermissions';
import { StatusFilter } from '@pages/appliedConfig/filters/StatusFilter';
import { PlatformFilter } from '@pages/appliedConfig/filters/PlatformFilter';
import { TypeFilter } from '@pages/appliedConfig/filters/TypeFilter';
import { SearchFilter } from '@pages/appliedConfig/filters/SearchFilter';
import { DateFilter } from '@pages/appliedConfig/filters/AppliedDateFilter';

export function AppliedConfigFilter() {
  return (
    <section className="my-4">
      <div className="row justify-content-between">
        <div className="col-4">
          <SearchFilter />
        </div>
        <div className="col-6 d-flex justify-content-end">
          <DateFilter />
        </div>
        <div className="col-2">
          <PlatformFilter />
        </div>
        <CheckPermission admin analyst owner>
          <div className="col-2">
            <StatusFilter />
          </div>
        </CheckPermission>
        <CheckPermission admin analyst owner>
          <div className="col-2">
            <TypeFilter />
          </div>
        </CheckPermission>
      </div>
    </section>
  );
}
