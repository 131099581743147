import React, { useCallback } from 'react';

import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { toggleMetricKey } from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { RequestStage } from '@infrastructure/store/types/actions';
import ToggleColumnsDrop from './ToggleColumnsDrop';
import {
  metricKeyOrder,
  metricMeta
} from '@pages/experimentDetails/components/stats/components/result/components/filters/components/toggleColumns/utils/metricKeys';
import { useDispatch, useSelector } from '@ui/hooks/redux';

export default function ToggleColumnsButton() {
  const dispatch = useDispatch();
  const activeKeys = useSelector(experimentDetailsSelectors.getMetricKeys);
  const status = useSelector(experimentDetailsSelectors.getIterationResultStatus);
  const isSuccess = status === RequestStage.SUCCESS;

  const columns = metricKeyOrder.map((key) => {
    const { isFixed, type, title } = metricMeta[key] || {};
    return {
      key,
      type,
      title: title || key,
      isActive: activeKeys.includes(key),
      isFixed: Boolean(isFixed)
    };
  });

  const handleColumn = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { currentTarget } = e;
      const { key } = currentTarget.dataset;

      dispatch(toggleMetricKey(key));
    },
    [dispatch]
  );

  return <ToggleColumnsDrop columns={columns} toggleColumn={handleColumn} disabled={!isSuccess} />;
}
