import { uniq } from 'lodash-es';
import { DropdownOption } from 'crazy-ui-next';

import { UserAllocationService } from '@app/services/UserAllocationService';

import { AppState } from '@infrastructure/store';
import { createSimpleOptions } from '@infrastructure/utils/createOptions.utils';
import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { appPropertiesSelectors } from '@infrastructure/store/applicationProperties/appPropertiesSelectors';

import { GamePlatform, GamePlatformTitleMap } from '@domain/enums/GamePlatform';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { RegionDto } from '@domain/models/RegionDto';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { GoalConfigParams } from '@domain/models/createExperiment/GoalConfigParams';

const getStep = (state: AppState) => state.createExperiment.formStep.step;
const getFinalStep = (state: AppState) => state.createExperiment.formStep.finalStep;
const isMatchStep = (step: number) => (state: AppState) => {
  const currentStep = getStep(state);
  return step === currentStep;
};

const getIsFormReady = (state: AppState) => {
  const step = getStep(state);
  const finalStep = getFinalStep(state);

  return step >= finalStep;
};

const getStatus = (state: AppState) => state.createExperiment.status;

const getForm = (state: AppState) => state.createExperiment.form;
const getABObjectiveByRegion =
  (region: string) =>
  (state: AppState): ObjectiveConfigParams => {
    const { objectiveABConfig } = getForm(state);

    return objectiveABConfig[region];
  };
const getGoalConfigInitialValues = (state: AppState): GoalConfigParams => {
  const reportingArpu = appPropertiesSelectors.getReportingArpu(state);
  return GoalConfigParams.getInitialFromProperties(reportingArpu);
};

const getGameNameOptions = (state) => {
  const games = configSelectors.getGameApplicationList(state);
  const gameNames = games.map((game) => game.gameName);
  const onlyUniqueNames = uniq(gameNames);

  const sortedNames = onlyUniqueNames.sort((a, b) => a.localeCompare(b));

  return createSimpleOptions(sortedNames);
};

const getGamesByNameAndPlatform = (gameName: string, platform: GamePlatform, state: AppState) => {
  const games = configSelectors.getGameApplicationList(state);
  return games.filter((game) => game.gameName === gameName && game.platform === platform);
};

const getPlatformOptions = (gameName: string) => (state: AppState) => {
  const options: DropdownOption<GamePlatform>[] = [
    { value: GamePlatform.IOS, label: GamePlatformTitleMap[GamePlatform.IOS] },
    { value: GamePlatform.ANDROID, label: GamePlatformTitleMap[GamePlatform.ANDROID] }
  ];
  const games = configSelectors.getGameApplicationList(state);
  const filteredGames = games.filter((game) => game.gameName === gameName);
  const platforms = filteredGames.map(({ platform }) => platform);

  return options.filter(({ value }) => platforms.includes(value));
};

const getBundleIdOptions = (gameName: string, platform: GamePlatform) => (state: AppState) => {
  const games = getGamesByNameAndPlatform(gameName, platform, state);
  const bundleIds = games.map(({ bundleId }) => bundleId);
  const onlyUniqueIds = uniq(bundleIds);

  return createSimpleOptions(onlyUniqueIds);
};

const getFirebaseOptions = (gameName: string, platform: GamePlatform, bundleId: string) => (state: AppState) => {
  const games = getGamesByNameAndPlatform(gameName, platform, state);
  const filteredGamesByBundleId = games.filter((game) => game.bundleId === bundleId);

  return filteredGamesByBundleId.map((game) => ({
    label: game.firebaseProjectName,
    value: game.firebaseProjectId
  }));
};

const getRegions = (state: AppState) => state.createExperiment.regions;

const getRegionByName =
  (region: string) =>
  (state: AppState): RegionDto | null => {
    const { defaultRegions, recommendedRegions } = getRegions(state);
    const allRegions = [...defaultRegions, ...recommendedRegions];

    return allRegions.find(({ name }) => region === name) || null;
  };

const getGameStats = (state: AppState) => state.createExperiment.gameStats.data;
const getGameInstalls = (state: AppState) => state.createExperiment.gameStats.installs;

const getGameStatsByPlatform = (state: AppState) => {
  const stats = getGameStats(state);
  const { platform } = getForm(state)[CreateExperimentForm.BASIC_INFO];

  return stats.filter((stat) => stat.platform === platform);
};
const getGameStatsFilter = (state: AppState) => state.createExperiment.gameStats.filter;
const getControlGroups = (state: AppState) => state.createExperiment.controlGroups.data;
const getControlGroupsStatus = (state: AppState) => state.createExperiment.controlGroups.status;

const getConfigList = (state: AppState) => state.createExperiment.configList;

const getConfigEntriesByRegion =
  (region: string) =>
  (state: AppState): GenericConfigEntry[] => {
    const list = getConfigList(state);
    return list[region];
  };

const getABConfigParamsList = (state: AppState) => {
  const { objectiveABConfig } = getForm(state);

  return objectiveABConfig;
};

const getIsAllABConfigsSubmitted = (state: AppState) => {
  const { regions } = getForm(state)[CreateExperimentForm.TARGET_CONFIG];
  const configList = getConfigList(state);

  const configLength = Object.keys(configList).length;

  return configLength === regions?.length && configLength !== 0;
};

const getIsABConfigSubmitted = (region: string) => (state: AppState) => {
  const configList = getABConfigParamsList(state);

  return Boolean(configList[region]);
};

const getUserAllocationSummary =
  (region: string, groupCount: number, usersAllocationPercent: string, builtInGroup?: boolean) =>
  (state: AppState): Record<string, string>[] => {
    const installs = getGameInstalls(state)[region];
    const averageInstalls = installs?.averageInstalls || null;

    const data = UserAllocationService.calcUserGroups(
      groupCount,
      averageInstalls,
      usersAllocationPercent,
      builtInGroup
    );

    return [
      { title: 'Used number of groups', value: `${data.groupCount}` },
      { title: 'Used daily installs per group', value: `${data.usedDailyInstallsPerGroup || '-'}` }
    ];
  };

const isClone = (state: AppState) => state.createExperiment.clone.isClone;

const cloneStatus = (state: AppState) => {
  return state.createExperiment.clone.status;
};

const getOverlappedConfigs = (state: AppState) => state.createExperiment.overlappedConfigs;
const getGLDParams = (state: AppState) => state.createExperiment.params.gld.data;

export const createExperimentSelectors = {
  getStatus,
  getStep,
  getFinalStep,
  isMatchStep,
  getIsFormReady,

  getForm,
  getABObjectiveByRegion,
  getGoalConfigInitialValues,

  getRegions,
  getRegionByName,
  getGameStats,
  getGameStatsByPlatform,
  getGameStatsFilter,
  getGameInstalls,
  getUserAllocationSummary,
  getControlGroups,
  getOverlappedConfigs,

  getPlatformOptions,
  getBundleIdOptions,
  getGameNameOptions,
  getFirebaseOptions,
  getControlGroupsStatus,
  getConfigList,
  getConfigEntriesByRegion,
  getABConfigParamsList,
  getIsAllABConfigsSubmitted,
  getIsABConfigSubmitted,
  cloneStatus,
  isClone,
  getGLDParams
};
