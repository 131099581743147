import React from 'react';

import { PageTitle } from '../layout/PageTitle';
import { SideMenuContainer } from './sideMenu/SideMenuContainer';

export default function Wrapper({ children, pageName }) {
  return (
    <>
      <SideMenuContainer />
      {pageName && <PageTitle label={pageName} />}
      {children}
    </>
  );
}
