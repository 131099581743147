import React from 'react';
import { Link } from 'react-router-dom';

import { Routing } from '@infrastructure/routing';
import { OverlappedConfigDto } from '@domain/models/experiment/OverlappedConfigDto';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { ExpTypeTitleMap } from '@ui/const/ExpTypeTitleMap';
import { GamePlatformTitleMap } from '@domain/enums/GamePlatform';
import { OverlappingTypeTitleMap } from '@ui/const/OverlappingTypeTitleMap';
import { ExperimentOverlappingType } from '@domain/enums/ExperimentOverlappingType';
import { ExternalRouting } from '@infrastructure/routing/external';
import { ExternalLink } from '@components/layout/ExternalLink';

type Props = {
  config: OverlappedConfigDto;
  firebaseId: string;
};

export function Row({ config, firebaseId }: Props) {
  const { id, name, type, appVersions, platform, overlappingType, primaryRegion, regions, inDevRegions, manualParams } =
    config;
  const isGLD = type === ExperimentType.GLD_TEST;

  return (
    <li>
      {name && (
        <div>
          <span>Experiment name: </span>
          <span>
            <Link to={Routing.getExperiment(id)}>{name}</Link>
          </span>
        </div>
      )}

      {type && (
        <div>
          <span>Experiment type: </span>
          <span>{ExpTypeTitleMap[type]}</span>
        </div>
      )}

      {appVersions && (
        <div>
          <span>Version: </span>
          <span>{appVersions}</span>
        </div>
      )}

      {platform && (
        <div>
          <span>Platform: </span>
          <span>{GamePlatformTitleMap[platform]}</span>
        </div>
      )}

      {overlappingType && (
        <div>
          <span>Overlapping type: </span>
          <span>{OverlappingTypeTitleMap[overlappingType]}</span>
        </div>
      )}

      {overlappingType === ExperimentOverlappingType.EXTERNAL_CONFIG && (
        <div>
          <span>Firebase config: </span>
          <Link to={Routing.getExperiment(id)}>{name}</Link>
          <span>
            <ExternalLink href={ExternalRouting.getFirebaseConfig(firebaseId)} labelText={firebaseId} />
          </span>
        </div>
      )}

      {primaryRegion && (
        <div>
          <span>Primary region: </span>
          <span>{primaryRegion}</span>
        </div>
      )}

      {regions?.length && (
        <div>
          <span>Live regions: </span>
          <span>{regions.join(', ')}</span>
        </div>
      )}

      {isGLD && inDevRegions?.length && (
        <div>
          <span>Dev regions: </span>
          <span>{inDevRegions.join(', ')}</span>
        </div>
      )}

      {manualParams?.length && (
        <div>
          <span>Parameters: </span>
          <span>{manualParams.join(', ')}</span>
        </div>
      )}
    </li>
  );
}
