import React from 'react';
import { TextInput } from 'crazy-ui-next';

import { FormComponent, getInputProps } from '@ui/hooks/form';

import { ExpDefinitionParams } from '@domain/models/experimentDetails/ExpDefinitionParams';

import styles from '@pages/experimentDetails/ExperimentDetails.module.scss';

type Props = Pick<FormComponent<ExpDefinitionParams>, 'register' | 'errors'>;

export function ArpuInput({ register, errors }: Props) {
  return (
    <>
      <div className={styles.editorInputLabel}>
        <p>Target ARPU:</p>
      </div>
      <TextInput className={styles.editorInput} {...getInputProps<ExpDefinitionParams>('arpu', register, errors)} />
    </>
  );
}
