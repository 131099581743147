import React from 'react';

import { ReactComponent as CompleteSvg } from '@assets/svg/accept.svg';
import { ReactComponent as ProgressSvg } from '@assets/svg/dot.svg';
import { ReactComponent as DisableSvg } from '@assets/svg/circle.svg';

import styles from '@pages/createExperiment/atoms/StatusCircle/StatusCircle.module.scss';

type Props = {
  isCompleted: boolean;
  isCurrent: boolean;
  isRequest: boolean;
};

export function StatusCircle({ isCompleted, isRequest, isCurrent }: Props) {
  if (isCompleted) {
    return <CompleteSvg data-status="completed" className={styles.status} />;
  }

  if (isCurrent && isRequest) {
    return <ProgressSvg data-status="isRequest" className={styles.status} />;
  }

  if (isCurrent) {
    return <ProgressSvg data-status="inProgress" className={styles.status} />;
  }

  return <DisableSvg data-status="disabled" className={styles.status} />;
}
