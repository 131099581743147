import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './ButtonGroup.module.scss';

export default function ButtonGroup({ name, options, selected, handleChange, disabled = false }) {
  const isMultiple = Array.isArray(selected);

  const onChange = useCallback(
    ({ currentTarget }) => {
      handleChange({ field: name, value: currentTarget.value });
    },
    [handleChange, name]
  );

  const buttons = options.map((option) => {
    const { value, label } = option;

    const isActive = isMultiple ? selected.includes(value) : value === selected;

    return (
      <label
        className={cn(styles.button, isActive && styles.active, disabled && styles.disabled)}
        key={`${name}_${value}`}
      >
        <input
          className={styles.input}
          type={isMultiple ? 'checkbox' : 'radio'}
          name={name}
          value={value}
          autoComplete="off"
          onChange={onChange}
          checked={isActive}
          disabled={disabled}
        />
        {label}
      </label>
    );
  });

  return (
    <div className={styles.group} data-name={name} data-value={selected}>
      {buttons}
    </div>
  );
}
