import { AppliedConfigViewDto } from '@domain/models/appliedConfig/AppliedConfigViewDto';
import { AppliedConfigDto } from '@domain/models/appliedConfig/AppliedConfigDto';
import { AppliedConfigStatsDto } from '@domain/models/appliedConfig/AppliedConfigStatsDto';
import { GameApplicationDto } from '@domain/models/game/GameApplicationDto';

export class AppliedConfigMapper {
  static mapEntitiesToViewDto(
    game: GameApplicationDto,
    configs: AppliedConfigDto[],
    stats?: AppliedConfigStatsDto
  ): AppliedConfigViewDto {
    const dto = new AppliedConfigViewDto();

    dto.configs = configs;
    dto.game = game;

    if (!stats) {
      const fallback = new AppliedConfigStatsDto();

      fallback.gameId = game.id;
      fallback.platform = game.platform;
      fallback.countMap = {};
      dto.stats = fallback;
    } else {
      dto.stats = stats;
    }

    return dto;
  }
}
