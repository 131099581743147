import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';

import { AppPropertyForm } from '@domain/models/appProperty/AppPropertyInput';
import { FormComponent } from '@ui/hooks/form';
import { EmailList } from '@pages/appProperties/emailList/EmailList';
import { EmailAddInput } from '@pages/appProperties/emailList/EmailAddInput';

import styles from './AppProperties.module.scss';

type Props = Pick<FormComponent<AppPropertyForm>, 'control'> & {
  index: number;
  label: string;
  hint: string;
};

export function AppPropertiesEmailInput({ label, control, index, hint }: Props) {
  const { field } = useController({ control, name: `property.${index}.value` });
  const emails = Array.isArray(field.value) ? field.value : field.value.split(',');

  const handleAdd = useCallback(
    (email: string) => {
      const formattedValue = [field.value, email].join(',');

      field.onChange(formattedValue);
    },
    [field]
  );

  const handleRemove = useCallback(
    (event) => {
      const { email } = event.target.dataset;
      const formattedValue = emails.filter((e) => e !== email).join(',');

      field.onChange(formattedValue);
    },
    [field, emails]
  );

  return (
    <div className={styles.emailRow}>
      <EmailAddInput label={label} handleAdd={handleAdd} emails={emails} hint={hint} />
      <EmailList index={index} control={control} handleRemove={handleRemove} />
    </div>
  );
}
