import { Service } from 'typedi';

import { ExperimentListUseCase } from '@domain/useCases/ExperimentListUseCase';
import { ExperimentApi } from '@infrastructure/api/ExperimentApi';
import { OffsetPagination } from '@domain/models/local/OffsetPagination';
import { Paginate } from '@domain/models/Paginate';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import { ReduxRepo } from '@infrastructure/repositories/ReduxRepo';
import { experimentListSelectors } from '@infrastructure/store/experimentList/experimentListSelectors';
import { EnvConfig } from '@app/config';

@Service()
export class ExperimentListService implements ExperimentListUseCase {
  constructor(private readonly experimentApi: ExperimentApi, private readonly reduxRepo: ReduxRepo) {}

  async getExperiments(page?: number): Promise<Paginate<ExperimentDto>> {
    const pagination = new OffsetPagination(0, EnvConfig.PER_PAGE);

    if (page) {
      pagination.setPage(page);
    }

    const filters = this.reduxRepo.findBy(experimentListSelectors.getFormatFilters);
    return this.experimentApi.getExperimentList(filters, pagination);
  }
}
