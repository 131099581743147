import React from 'react';

import { WinnerConfigDto } from '@domain/models/winnerConfig/WinnerConfigDto';

import styles from '../WinnerStatus.module.scss';

type Props = {
  winner: WinnerConfigDto | null;
};

export function WinnerStatusInfo({ winner }: Props) {
  if (!winner) {
    return null;
  }

  const { version, regionalConfigs } = winner;
  const versionView = version.join(', ');

  const isMultiple = regionalConfigs.length > 1;

  const rows = regionalConfigs.map(({ group, region }) => {
    if (isMultiple) {
      return (
        <p key={region}>
          Region: <span>{region}</span>, Group: <span>{group}</span>
        </p>
      );
    }

    return (
      <p key={region}>
        Group: <span>{group}</span>, Version: <span>{versionView}</span>
      </p>
    );
  });

  return (
    <div className={styles.info}>
      {rows}

      {isMultiple && (
        <p className={styles.versionsRow}>
          Version: <span>{versionView}</span>
        </p>
      )}
    </div>
  );
}
