import React, { useCallback } from 'react';
import { InputLabel, TextInput } from 'crazy-ui-next';
import { Controller, useFieldArray } from 'react-hook-form';

import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { MultiTextInputContainer } from '@pages/winnerConfigModal/form/inputs/multiTextInput/MultiTextInputContainer';
import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/inputGroup/InputGroup.module.scss';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'control' | 'register'> & {
  sessionIndex: number;
  sectionIndex: number;
  name: string;
  labelText: string;
  defaultValue: number | boolean | string;
};

const maxInputsLength = 5;

export function ControlledTextInput({ control, name, labelText, sessionIndex, sectionIndex, defaultValue }: Props) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `input.${sectionIndex}.section.${sessionIndex}.${name}`
  });

  const onAdd = useCallback(() => {
    // @ts-ignore react-hook-form doesn't support dynamic name path and return type never
    append({ value: defaultValue.toString() });
  }, [defaultValue, append]);

  const onRemove = useCallback(() => {
    remove(fields.length - 1);
  }, [remove, fields]);

  const inputs = fields.map((input, index) => {
    return (
      <Controller
        name={`input.${sectionIndex}.section.${sessionIndex}.${name}.${index}.value`}
        // @ts-ignore react-hook-form doesn't support dynamic name path and return type never
        key={input.id}
        control={control}
        render={({ field }) => {
          return (
            <TextInput
              className={styles.input}
              name={field.name}
              type="text"
              value={field.value}
              onChange={field.onChange}
            />
          );
        }}
      />
    );
  });

  return (
    <>
      <InputLabel labelText={labelText} className={styles.label} />
      <MultiTextInputContainer inputs={inputs} onAddItem={onAdd} onRemoveItem={onRemove} maxItems={maxInputsLength} />
    </>
  );
}
