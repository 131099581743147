import React from 'react';
import { TableCellType } from '@components/layout/table/types/TableCellType';

type HeaderCellProps = {
  labelText: string;
  type: string;
};

function HeaderCell({ labelText, type }: HeaderCellProps) {
  return <th data-cell={type}>{labelText}</th>;
}

export function Header() {
  return (
    <tr>
      <HeaderCell type={TableCellType.TITLE} labelText="Key" />
      <HeaderCell type={TableCellType.TEXT} labelText="Displayed Name" />
      <HeaderCell type={TableCellType.LABEL} labelText="Type" />
      <HeaderCell type={TableCellType.TEXT} labelText="Default Value" />
      <HeaderCell type={TableCellType.TEXT} labelText="Min Value" />
      <HeaderCell type={TableCellType.TEXT} labelText="Max Value" />
      <HeaderCell type={TableCellType.FLAG} labelText="Sessions" />
      <HeaderCell type={TableCellType.FLAG} labelText="Active" />
    </tr>
  );
}
