import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchAppProperties,
  updateAppProperties
} from '@infrastructure/store/applicationProperties/appPropertiesActions';
import { appPropertiesSelectors } from '@infrastructure/store/applicationProperties/appPropertiesSelectors';
import { RequestStage } from '@infrastructure/store/types/actions';
import { useForm } from '@ui/hooks/form';
import { AppPropertyForm } from '@domain/models/appProperty/AppPropertyInput';
import { AppPropertiesForm } from '@pages/appProperties/AppPropertiesForm';
import { PageLoader } from 'crazy-ui';

const getInitialState = () => new AppPropertyForm().setDefaultValues();

export function AppPropertiesPageContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppProperties.trigger());
  }, [dispatch]);

  const status = useSelector(appPropertiesSelectors.getStatus);
  const formList = useSelector(appPropertiesSelectors.getFormList);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<AppPropertyForm>({ schema: AppPropertyForm, defaultValues: getInitialState(), mode: 'onChange' });

  const isLoading = status === RequestStage.REQUEST;

  const handleFormSubmit = handleSubmit((form: AppPropertyForm) => dispatch(updateAppProperties.trigger(form)));

  useEffect(() => {
    if (formList.property?.length) {
      reset(formList);
    }
  }, [reset, formList]);

  if (isLoading && !formList.property?.length) {
    return <PageLoader />;
  }

  return (
    <AppPropertiesForm
      register={register}
      handleSubmit={handleFormSubmit}
      control={control}
      errors={errors}
      isLoading={isLoading}
    />
  );
}
