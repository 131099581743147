import { useSelector } from '@ui/hooks/redux';

import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { ReactComponent as WarningSvg } from '@assets/svg/warning.svg';
import { List } from '@pages/createExperiment/overlappedConfigs/list/List';

import styles from './OverlappedConfigs.module.scss';

export function OverlappedConfigsContainer() {
  const configs = useSelector(createExperimentSelectors.getOverlappedConfigs);
  const { basicInfo } = useSelector(createExperimentSelectors.getForm);

  if (!configs.length) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.header}>
          <WarningSvg />
          <h4>Overlapping configuration found</h4>
        </div>
        <List configs={configs} firebaseId={basicInfo.firebaseProjectId} />
      </div>
    </div>
  );
}
