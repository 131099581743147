import React from 'react';
import { Button, TextInput } from 'crazy-ui';
import FormBottomControls from '@components/wrappers/bottomControls/FormBottomControls';
import { Routing } from '@infrastructure/routing';
import { RoleInput } from './RoleInput';
import { FormComponent, getInputProps } from '@ui/hooks/form';
import { UserFormParams } from '@domain/models/user/UserReqParams';
import { UserFormMode } from '@pages/userManagement/const/UserFormMode';
import { UserStatusInput } from '@pages/userManagement/userForm/UserStatusInput';
import { CancelBtnAttr, SubmitBtnAttr } from '@ui/const/DataAttributes';

type Props = FormComponent<UserFormParams> & {
  mode: UserFormMode;
  isLoading?: boolean;
};

export function UserForm({ mode, isLoading, handleSubmit, register, errors, control }: Props) {
  return (
    <main className="container d-flex justify-content-center">
      <form className="paper paper-75 form">
        <div className="row">
          <div className="col-xl-6">
            <TextInput
              labelText="First Name *"
              placeholder="Enter first name"
              disabled={isLoading}
              {...getInputProps<UserFormParams>('firstName', register, errors)}
            />
            <TextInput
              labelText="Last Name *"
              placeholder="Enter last name"
              disabled={isLoading}
              {...getInputProps<UserFormParams>('lastName', register, errors)}
            />
            <TextInput
              labelText="Email *"
              placeholder="Enter email"
              disabled={isLoading}
              {...getInputProps<UserFormParams>('email', register, errors)}
            />
            <RoleInput control={control} register={register} disabled={isLoading} />
          </div>
          <div className="col">
            <div className="d-flex justify-content-end">
              <UserStatusInput control={control} disabled={isLoading} />
            </div>
          </div>
        </div>
        <FormBottomControls>
          <Button
            to={Routing.getUsers()}
            color={Button.GRAY}
            variant={Button.OUTLINE}
            withMargin={false}
            type={Button.NAV_LINK}
            {...CancelBtnAttr}
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} color={Button.BLUE} disabled={isLoading} {...SubmitBtnAttr}>
            {mode === UserFormMode.CREATE ? 'Create' : 'Save'}
          </Button>
        </FormBottomControls>
      </form>
    </main>
  );
}
