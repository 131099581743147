import React from 'react';
import {
  metricMeta,
  metricKeyOrder
} from '@pages/experimentDetails/components/stats/components/result/components/filters/components/toggleColumns/utils/metricKeys';
import { IterationResultDto } from '@domain/models/iteration/IterationResultDto';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';

type Props = {
  result: IterationResultDto[];
  metricKeys: string[];
  variables: ExperimentVariableDto[];
  isAB: boolean;
};
export function THead({ metricKeys, result, variables, isAB }: Props) {
  const {
    parametersConfig: { entry }
  } = result[0];
  const metricKeysOrdered = metricKeyOrder.filter((key) => metricKeys.includes(key));

  return (
    <thead>
      <tr>
        <th>Select</th>
        <th>Group</th>
        {!isAB && <th data-wider={!isAB}>Description</th>}
        {isAB &&
          Object.keys(entry).map((key) => {
            const variable = variables.find(({ name }) => name === key);
            return <th key={key}>{variable?.displayName}</th>;
          })}
        {metricKeysOrdered.map((key) => (
          <th key={key}>{metricMeta[key].title}</th>
        ))}
      </tr>
    </thead>
  );
}
