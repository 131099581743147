import { combineReducers } from '@reduxjs/toolkit';

import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import { fetchAppliedConfigList, updateAppliedConfig } from '@infrastructure/store/appliedConfig/appliedConfigActions';
import { listReducer } from '@infrastructure/store/appliedConfig/reducers/listReducer';
import { filterReducer } from '@infrastructure/store/appliedConfig/reducers/filterReducer';
import { experimentReducer } from '@infrastructure/store/appliedConfig/reducers/experimentReducer';

export const appliedConfig = combineReducers({
  status: ReducerFactory.makeStatus(fetchAppliedConfigList),
  updateStatus: ReducerFactory.makeStatus(updateAppliedConfig),
  list: listReducer,
  filters: filterReducer,
  experiment: experimentReducer
});
