import { ChartType } from '@domain/enums/ChartType';
import { combineReducers, createReducer } from '@reduxjs/toolkit';
import {
  clearAll,
  fetchCharts,
  setCacheCharts
} from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import { ChartDto } from '@domain/models/ChartDto';

const chartsInitial: Record<ChartType, ChartDto> = {
  [ChartType.KPI_CONFIDENCE]: {} as ChartDto,
  [ChartType.KPI_RATE]: {} as ChartDto,
  [ChartType.KPI_SANITY]: {} as ChartDto,
  [ChartType.USER_SANITY]: {} as ChartDto,
  [ChartType.USERS_PER_DAY]: {} as ChartDto,
  [ChartType.LOSS_OVER_TIME]: {} as ChartDto
};

const chartsDataReducer = createReducer(chartsInitial, (qb) => {
  qb.addCase(fetchCharts.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return chartsInitial;
  });
});

const chartsCacheInitial = {};

const chartsCacheReducer = createReducer(chartsCacheInitial, (qb) => {
  qb.addCase(setCacheCharts, (state, { payload }) => {
    return Object.assign(state, payload);
  });
  qb.addCase(clearAll, () => {
    return chartsCacheInitial;
  });
});

export const chartsReducer = combineReducers({
  data: chartsDataReducer,
  cache: chartsCacheReducer,

  status: ReducerFactory.makeStatus(fetchCharts, clearAll)
});
