import React from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';

import { ReactComponent as AcceptSvg } from '@assets/svg/accept.svg';

import styles from '@pages/winnerConfigModal/WinnerConfigModal.module.scss';

type Props = {
  disabled: boolean;
  isLoading: boolean;
  handleCancel: () => void;
};

export function Controls({ handleCancel, disabled, isLoading }: Props) {
  return (
    <div className={styles.controls}>
      <Button variant={ButtonVariant.SECONDARY} onClick={handleCancel}>
        Cancel
      </Button>
      <Button id="submit-winner-btn" type="submit" disabled={disabled} icon={<AcceptSvg />} isLoading={isLoading}>
        Apply
      </Button>
    </div>
  );
}
