import { createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  pushSetStep,
  validateOverlapping
} from '@infrastructure/store/createExperiment/createExperimentActions';
import { OverlappedConfigDto } from '@domain/models/experiment/OverlappedConfigDto';
import { steps } from '@pages/createExperiment/steps/const/Steps';

const initial: OverlappedConfigDto[] = [];

export const overlappedConfigsReducer = createReducer(initial, (qb) => {
  qb.addCase(validateOverlapping.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(pushSetStep, (state, { payload: step }) => {
    if (step <= steps[1].index) {
      return initial;
    }

    return state;
  });
  qb.addCase(clearAll, () => {
    return initial;
  });
});
