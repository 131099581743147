import React from 'react';
import { TextInput } from 'crazy-ui-next';
import { Controller } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';
import { ConfigsParams } from '@domain/models/experiment/ConfigsParams';
import { getLabelText } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/configModal/form/ValueInputsContainer';

import styles from '../EditableConfigModal.module.scss';

type Props = Pick<FormComponent<ConfigsParams>, 'control'> & {
  index: number;
};

export function SessionNumberInput({ control, index }: Props) {
  return (
    <Controller
      control={control}
      name={`configs.${index}.session`}
      render={({ field }) => {
        const handleBlurTweak = ({ currentTarget }) => {
          const { value: strSession } = currentTarget;
          const session = Number(strSession);

          const min = 1;
          const max = 99;

          if (session < min) {
            field.onChange(min.toString());
            return;
          }

          if (session > max) {
            field.onChange(max.toString());
            return;
          }

          field.onChange(session);
        };

        return (
          <TextInput
            name={field.name}
            onChange={field.onChange}
            type="number"
            value={String(field.value)}
            labelText={getLabelText(index, 'session')}
            className={styles.textInput}
            onBlur={handleBlurTweak}
          />
        );
      }}
    />
  );
}
