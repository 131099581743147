import { ActionFactory } from '@infrastructure/store/helpers/ActionFactory';
import { AppliedConfigViewDto } from '@domain/models/appliedConfig/AppliedConfigViewDto';
import { DateRangeEvent } from '@components/lib/DateRangePicker/DateRangePicker';
import { ACFilter } from '@infrastructure/store/appliedConfig/types/filters';
import { UpdateWinnerConfigParams } from '@domain/models/winnerConfig/WinnerConfigFormParams';
import { UniqueId } from '@domain/types';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';

const namespace = 'appliedConfig';

export const fetchAppliedConfigList = ActionFactory.createRequestRoutine<void, AppliedConfigViewDto[]>(
  'APPLIED_CONFIG_FETCH',
  namespace
);

export const updateAppliedConfig = ActionFactory.createRequestRoutine<UpdateWinnerConfigParams>(
  'APPLIED_CONFIG_UPDATE',
  namespace
);

export const deleteAppliedConfig = ActionFactory.createRequestRoutine<{
  experimentId: UniqueId;
  objectiveId: UniqueId;
}>('APPLIED_CONFIG_DELETE', namespace);

export const fetchExperiment = ActionFactory.createRequestRoutine<UniqueId, ExperimentDto>(
  'APPLIED_CONFIG_EXPERIMENT_FETCH',
  namespace
);

export const setSearchPhraseFilter = ActionFactory.createAction<string>('SEARCH_FILTER', namespace);
export const setStatusFilter = ActionFactory.createAction<ACFilter.Status>('STATUS_FILTER', namespace);
export const setTypeFilter = ActionFactory.createAction<ACFilter.Type>('EXP_TYPE_FILTER', namespace);
export const setPlatformFilter = ActionFactory.createAction<ACFilter.Platform>('PLATFORM_FILTER', namespace);
export const setDateFilter = ActionFactory.createAction<DateRangeEvent>('APPLIED_DATE_FILTER', namespace);

export const clearAll = ActionFactory.createAction('CLEAR_ALL', namespace);
