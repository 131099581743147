import React from 'react';

import cn from 'classnames';
import { CheckboxInput } from 'crazy-ui-next';

import styles from '@pages/winnerConfigModal/list/List.module.scss';

type Props = {
  name: string;
  value: boolean;
  onChange: () => void;
  isHeaderRow: boolean;
};

export function AppliedCheckbox({ name, value, onChange, isHeaderRow }: Props) {
  return (
    <div className={cn(styles.col, styles.applied)}>
      <CheckboxInput name={name} id={`checkbox_${name}`} checked={value} onChange={onChange} />
      {isHeaderRow && (
        <div className={styles.heading}>
          <p>Apply</p>
        </div>
      )}
    </div>
  );
}
