import React, { useCallback } from 'react';
import { DropdownEvent, DropdownInput, DropdownOption } from 'crazy-ui-next';

import { ReactComponent as BagSvg } from '@assets/svg/bag.svg';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { appliedConfigSelectors } from '@infrastructure/store/appliedConfig/appliedConfigSelectors';
import { setPlatformFilter } from '@infrastructure/store/appliedConfig/appliedConfigActions';
import { ACFilter } from '@infrastructure/store/appliedConfig/types/filters';

const options: DropdownOption<ACFilter.Platform>[] = [
  { value: ACFilter.Platform.ALL, label: 'All' },
  { value: ACFilter.Platform.IOS, label: 'IOS' },
  { value: ACFilter.Platform.ANDROID, label: 'Android' }
];

export function PlatformFilter() {
  const dispatch = useDispatch();
  const { platform } = useSelector(appliedConfigSelectors.getFilters);

  const handlePlatform = useCallback(
    ({ value }: DropdownEvent<ACFilter.Platform>) => {
      dispatch(setPlatformFilter(value));
    },
    [dispatch]
  );

  return (
    <DropdownInput
      name="platform"
      inputIcon={<BagSvg />}
      value={platform}
      options={options}
      handleChange={handlePlatform}
      labelText="Store"
    />
  );
}
