import { UserRoleType } from '@domain/enums/UserRoleType';

export enum UserSortValue {
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC'
}

export enum UserStatusFilterValue {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  ALL = 'ALL'
}

/** @see UserRoleType */
export enum UserRoleFilterValue {
  ADMIN = 'ADMIN',
  ANALYST = 'ANALYST',
  GAME_OWNER = 'GAME_OWNER',
  QA = 'QA',
  ALL = 'ALL'
}

export const mapUserRoleValueToType = (key: UserRoleFilterValue): UserRoleType =>
  ({
    [UserRoleFilterValue.ADMIN]: UserRoleType.ADMIN,
    [UserRoleFilterValue.ANALYST]: UserRoleType.ANALYST,
    [UserRoleFilterValue.GAME_OWNER]: UserRoleType.GAME_OWNER,
    [UserRoleFilterValue.QA]: UserRoleType.QA
  }[key]);
