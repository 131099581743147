import { Service } from 'typedi';
import { BackendHttpClient } from '@infrastructure/http/BackendHttpClient';
import { UniqueId } from '@domain/types';
import { LogDto } from '@domain/models/LogDto';

@Service()
export class LogsApi {
  constructor(protected readonly http: BackendHttpClient) {}

  async getExperimentLogList(
    firebaseProjectId: string,
    experimentId: UniqueId,
    iterationId: UniqueId
  ): Promise<string[]> {
    return this.http.get(`/intops/admin/logs/${firebaseProjectId}/${experimentId}/${iterationId}`);
  }
  async getExperimentLog(
    firebaseProjectId: string,
    experimentId: UniqueId,
    iterationId: UniqueId,
    name: string
  ): Promise<LogDto> {
    return this.http.get(`/intops/admin/logs/${firebaseProjectId}/${experimentId}/${iterationId}/${name}`);
  }
}
