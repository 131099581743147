import React, { useCallback } from 'react';
import { ButtonGroupEvent, DropdownOption } from 'crazy-ui-next';

import ButtonGroup from '@components/layout/buttonGroup/ButtonGroup';
import { UserDto } from '@domain/models/user/UserDto';
import { useLoader } from '@ui/hooks/useLoader';

const options: DropdownOption<boolean>[] = [
  { value: true, label: 'Active' },
  { value: false, label: 'Block' }
];

type Props = {
  handleSetStatus: (e: { id: number; isEnabled: boolean }) => void;
  user: UserDto;
};

export function UserStatusSwitch({ handleSetStatus, user }: Props) {
  const { isPending, setStart } = useLoader({ watch: user });

  const handleToggle = useCallback(
    (e: ButtonGroupEvent<'true' | 'false'>) => {
      const isEnabled = e.value === 'true';

      if (isEnabled !== user.enabled) {
        const payload = { id: user.id, isEnabled };
        handleSetStatus(payload);
        setStart();
      }
    },
    [setStart, handleSetStatus, user.id, user.enabled]
  );

  return (
    <ButtonGroup
      name="status"
      selected={user.enabled}
      handleChange={handleToggle}
      options={options}
      disabled={isPending}
    />
  );
}
