import React, { useCallback } from 'react';
import { TextInput } from 'crazy-ui-next';
import { useFieldArray } from 'react-hook-form';

import { useSelector } from '@ui/hooks/redux';
import { FormComponent, getInputProps } from '@ui/hooks/form';
import { MAX_REP_ARPU_VALUES } from '@domain/constants';

import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { ExpDefinitionParams } from '@domain/models/experimentDetails/ExpDefinitionParams';

import { MultiTextInputContainer } from '@pages/winnerConfigModal/form/inputs/multiTextInput/MultiTextInputContainer';

import styles from '@pages/experimentDetails/ExperimentDetails.module.scss';

type Props = Pick<FormComponent<ExpDefinitionParams>, 'register' | 'errors' | 'control'>;

export function ReportingArpuInput({ register, errors, control }: Props) {
  const { reportingArpu } = useSelector(experimentDetailsSelectors.getExperiment);

  const maxInputsLength = MAX_REP_ARPU_VALUES;
  const minInputsLength = reportingArpu.length;

  const errorMessage = errors.reportingArpu?.message;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'reportingArpu'
  });

  const onAdd = useCallback(() => {
    append({ value: '' });
  }, [append]);

  const onRemove = useCallback(() => {
    remove(fields.length - 1);
  }, [remove, fields]);

  const inputs = fields.map((field, index) => (
    <TextInput
      key={field.id}
      disabled={index < minInputsLength}
      className={styles.editorInput}
      {...getInputProps<ExpDefinitionParams>(`reportingArpu.${index}.value`, register, errors)}
    />
  ));

  return (
    <>
      <div className={styles.editorInputLabel}>
        <p>Reporting ARPU:</p>
      </div>
      <MultiTextInputContainer
        inputs={inputs}
        onAddItem={onAdd}
        onRemoveItem={onRemove}
        maxItems={maxInputsLength}
        minItems={minInputsLength}
        className={styles.multiInput}
        isValid={!Boolean(errorMessage)}
      />
    </>
  );
}
