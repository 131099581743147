import React from 'react';

import styles from './ChartLegend.module.scss';

export default function ChartLegend({ title, legend = {}, colorMap }) {
  const legendList = Object.keys(legend).map((key) => {
    return (
      <li className={styles.item} key={`legend_${title.slice(0, 10)}_${key}`}>
        <div className={styles.symbol} style={{ backgroundColor: colorMap[key] }} />
        Group {legend[key].title}
      </li>
    );
  });

  return (
    <div className={styles.info}>
      {title && <p className={styles.title}>{title}</p>}
      {legendList.length !== 0 && <ul className={styles.legend}>{legendList}</ul>}
    </div>
  );
}
