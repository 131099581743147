import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useForm } from '@ui/hooks/form';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { RequestStage } from '@infrastructure/store/types/actions';
import {
  CreateExpVariableParams,
  UpdateExpVariableParams
} from '@domain/models/experimentVariable/ExperimentVariableParams';
import { ExpVariableFormMode } from '@pages/experimentVariables/types/ExpVariableFormMode';
import { ExpVariableForm } from '@pages/experimentVariables/form/ExpVariableForm';
import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { pushUpdateVariable } from '@infrastructure/store/config/configActions';
import { RouteParams } from '@infrastructure/routing';

const getInitialState = () => new CreateExpVariableParams().setDefaultValues();

export function EditExpVariableContainer() {
  const dispatch = useDispatch();
  const params = useParams<RouteParams.GetEditExpVariable>();
  const varId = Number(params.varId);

  const status = useSelector(configSelectors.getEditConfigStatus);
  const variable = useSelector(configSelectors.getEditVariableById(varId), (a, b) => a?.id === b?.id);

  const {
    register,
    watch,
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<UpdateExpVariableParams>({ schema: UpdateExpVariableParams, defaultValues: getInitialState() });

  const handleFormSubmit = handleSubmit((form) => dispatch(pushUpdateVariable.trigger(form)));
  const isLoading = status === RequestStage.REQUEST;

  useEffect(() => {
    if (variable?.id) {
      reset(variable);
    }
  }, [reset, variable]);

  return (
    <ExpVariableForm
      mode={ExpVariableFormMode.EDIT}
      isLoading={isLoading}
      handleSubmit={handleFormSubmit}
      errors={errors}
      register={register}
      control={control}
      watch={watch}
    />
  );
}
