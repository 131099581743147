import React from 'react';
import { Button } from 'crazy-ui-next';

import { PageTitle } from '@components/layout/PageTitle';
import { VariableTableContainer } from '@pages/experimentVariables/table/VariableTableContainer';

import CheckPermission from '@components/layout/CheckPermissions';
import { Routing } from '@infrastructure/routing';

import { ReactComponent as PlusSvg } from '@assets/svg/plus.svg';

export function ExpVariablesPage() {
  return (
    <>
      <PageTitle label="Ad Profile Variables">
        <CheckPermission admin analyst qa>
          <Button href={Routing.getCreateExpVariable()} icon={<PlusSvg />}>
            Create Variable
          </Button>
        </CheckPermission>
      </PageTitle>
      <main className="container">
        <VariableTableContainer />
      </main>
    </>
  );
}
