import React, { useCallback } from 'react';

import { PageSortDir } from '@domain/models/Paginate';
import { SortByEvent } from '@ui/types/shared';
import { ReactComponent as UnfoldSvg } from '@assets/svg/unfold.svg';
import styles from './SortColumn.module.scss';

type Props<T> = {
  children: React.ReactNode;
  sortBy: SortByEvent<T>;
  fieldName: T & string;
  handleSort: (payload: SortByEvent<T>) => void;
};

export function SortColumn<T>({ children, sortBy, fieldName, handleSort }: Props<T>) {
  const { key, order } = sortBy;
  const isActive = key === fieldName;

  const handleSwitch = useCallback(() => {
    const payload: SortByEvent<T> = { key: fieldName, order: PageSortDir.ASC };

    if (isActive && order === PageSortDir.ASC) {
      payload.order = PageSortDir.DESC;
    }

    handleSort(payload);
  }, [fieldName, handleSort, isActive, order]);

  return (
    <button
      type="button"
      className={styles.main}
      data-active={isActive}
      data-field={fieldName}
      data-dir={isActive ? sortBy.order : null}
      onClick={handleSwitch}
    >
      {children}
      <UnfoldSvg />
    </button>
  );
}
