import React from 'react';
import { DropdownEvent, DropdownInput, DropdownOption } from 'crazy-ui-next';

import { ExpPlatformFilterValue } from '@infrastructure/store/experimentList/types/filters';
import { ReactComponent as BagSvg } from '@assets/svg/bag.svg';

const options: DropdownOption<ExpPlatformFilterValue>[] = [
  { value: ExpPlatformFilterValue.ALL, label: 'All' },
  { value: ExpPlatformFilterValue.IOS, label: 'IOS' },
  { value: ExpPlatformFilterValue.ANDROID, label: 'Android' }
];

type Props = {
  selected: ExpPlatformFilterValue;
  onChange: (e: DropdownEvent<ExpPlatformFilterValue>) => void;
};

export function PlatformFilter({ onChange, selected }: Props) {
  return (
    <DropdownInput
      name="platform"
      inputIcon={<BagSvg />}
      value={selected}
      options={options}
      handleChange={onChange}
      labelText="Store"
    />
  );
}
