export class IterationAvailableMeta {
  availableARPU: number[];
  defaultARPU: number;
  availableRegions: string[];
  defaultRegion: string;
  availableVersions: string[];

  static getInitial() {
    const params = new IterationAvailableMeta();
    params.availableARPU = [];
    params.defaultARPU = 0;
    params.availableRegions = [];
    params.defaultRegion = '';
    params.availableVersions = [];

    return params;
  }
}
