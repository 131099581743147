import { Type } from 'class-transformer';
import { Dto } from '@domain/models/Dto';
import { ExperimentName, UniqueId, UnixTimestamp } from '@domain/types';
import { ExperimentState } from '@domain/enums/ExperimentState';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { ExperimentPeriodicity } from '@domain/enums/ExperimentPeriodicity';
import { GameApplicationDto } from '@domain/models/game/GameApplicationDto';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';
import { WinnerStatus } from '@domain/enums/WinnerStatus';
import { ExperimentUserProperty } from '@domain/models/experiment/ExperimentUserProperty';

export class ExperimentDto extends Dto {
  public experimentId: UniqueId;
  public experimentName: ExperimentName;
  public state: ExperimentState;

  @Type(() => GameApplicationDto)
  public gameApp: GameApplicationDto;

  public experimentType: ExperimentType;
  public arpu: number;
  public reportingArpu: number[];
  public appVersions: string[];
  public checkPeriod: ExperimentPeriodicity;
  public automaticMode: boolean;
  public kpi: { name: string; available: boolean };

  public minInstallDate: UnixTimestamp;
  public createdDate: UnixTimestamp;
  public startDate: UnixTimestamp;
  public endDate: UnixTimestamp;
  public ageMin: number;
  public ageMax: number;
  public createdBy: string;
  public isRecommendedProfile: boolean;
  public importedSegments: string;
  public winnerStatus: WinnerStatus[];
  public userProperties: ExperimentUserProperty[];

  @Type(() => ExperimentObjectiveDto)
  public experimentObjectives: ExperimentObjectiveDto[];
}
