import React from 'react';
import { Tooltip } from 'crazy-ui';
import { WinnerStatus } from '@domain/enums/WinnerStatus';

import { ReactComponent as TrophySvg } from '@assets/svg/trophy.svg';
import { ReactComponent as AwardFoundSvg } from '@assets/svg/award_found.svg';

type Props = {
  winnerStatus?: WinnerStatus | null;
};

export function WinnerStatusIcon({ winnerStatus }: Props) {
  switch (winnerStatus) {
    case WinnerStatus.APPLIED:
      return (
        <Tooltip text="Winner applied">
          <AwardFoundSvg />
        </Tooltip>
      );

    case WinnerStatus.FOUND:
      return (
        <Tooltip text="Winner found">
          <TrophySvg />
        </Tooltip>
      );
    default:
      return null;
  }
}
