import { RequestStage } from '@infrastructure/store/types/actions';
import React from 'react';
import { useSelector } from '@ui/hooks/redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { authSelectors } from '@infrastructure/store/auth/authSelectors';
import { configSelectors } from '@infrastructure/store/config/configSelectors';

import { Routing } from '@infrastructure/routing';

import Wrapper from '../wrappers/Wrapper';
import { PageLoader } from '../layout/Loader';

type Props = {
  pageName?: string;
} & RouteProps;

export function AuthRoute({ pageName = '', ...rest }: Props) {
  const isAuth = useSelector(authSelectors.getIsAuth);
  const status = useSelector(authSelectors.getStatus);
  const configStatus = useSelector(configSelectors.getConfigStatus);

  if (status === RequestStage.REQUEST || configStatus === RequestStage.REQUEST) {
    return (
      <Wrapper pageName={pageName}>
        <PageLoader />
      </Wrapper>
    );
  }

  if (!isAuth) {
    return <Redirect to={Routing.getLogin()} />;
  }

  return (
    <Wrapper pageName={pageName}>
      <Route {...rest} />
    </Wrapper>
  );
}
