import React from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';

import { ReactComponent as PlusSvg } from '@assets/svg/plus.svg';

type Props = {
  handleClick: () => void;
  disabled?: boolean;
};

export function AddSessionBtn({ handleClick, disabled }: Props) {
  return (
    <Button
      id="add-session-btn"
      variant={ButtonVariant.TERTIARY}
      onClick={handleClick}
      disabled={disabled}
      icon={<PlusSvg />}
    />
  );
}
