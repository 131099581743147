import React from 'react';
import { VictoryChart, VictoryArea, VictoryLine, VictoryAxis } from 'victory';

import { axisTickFormat } from '../utils/helpers';
import { axisStyle, config } from '../common/lineChart/utils/config';
import { generateColorMap } from '../utils/colors';

import ChartLegend from '../common/chartLegend/ChartLegend';

import styles from '../common/lineChart/LineChart.module.scss';
import { ChartFullscreenIcon } from '../common/chartFullscreenIcon/ChartFullscreenIcon';

const { sizeX, sizeY, padding } = config;

export default function KpiConfidenceChart({
  chartData,
  title,
  yAxisLabel,
  sizeModifier = 4,
  handleFullscreen,
  fullscreen
}) {
  if (!chartData || !chartData.data) {
    return null;
  }

  const { data, legend } = chartData;

  const groups = Object.keys(data);
  const colorMap = generateColorMap(groups);

  const areaList = [];
  const lineList = [];

  groups.forEach((group) => {
    const points = data[group];
    const color = colorMap[group];

    const Area = (
      <VictoryArea
        key={`kpi_conf_a_${group}`}
        data={points}
        style={{
          data: { fill: color, opacity: 0.2 }
        }}
        y="y0"
        y0="y1"
      />
    );
    const Line = (
      <VictoryLine
        key={`kpi_conf_l_${group}`}
        data={points}
        style={{
          data: {
            stroke: color
          }
        }}
      />
    );

    areaList.push(Area);
    lineList.push(Line);
  });

  return (
    <div className={styles.chartWrapper} onClick={!fullscreen ? handleFullscreen : null}>
      {!fullscreen && handleFullscreen && <ChartFullscreenIcon />}
      <ChartLegend title={title} colorMap={colorMap} legend={legend} />
      <VictoryChart
        width={sizeX * sizeModifier}
        height={sizeY * sizeModifier}
        padding={padding}
        singleQuadrantDomainPadding={false}
        scale={{
          x: 'time',
          y: 'linear'
        }}
      >
        <VictoryAxis
          dependentAxis
          fixLabelOverlap={true}
          style={axisStyle}
          tickCount={20}
          tickFormat={axisTickFormat}
          label={yAxisLabel || 'MyLabel'}
        />
        <VictoryAxis fixLabelOverlap={true} style={axisStyle} label={yAxisLabel || 'MyLabel'} />

        {areaList}
        {lineList}
      </VictoryChart>
    </div>
  );
}
