import { format } from 'date-fns';

export function formatDate(date, withTime = false) {
  let formatString = 'dd/MM/yyyy';

  if (withTime) {
    formatString += ' HH:mm:ss';
  }

  if (!(date instanceof Date)) {
    return formatDate(new Date(date), withTime);
  }

  try {
    return format(date, formatString);
  } catch (err) {
    return '';
  }
}

const fallbackValue = '-';
const notAvailableValue = 'N/A';

const formatObjectValue = (object) => Object.entries(object).map(([key, data]) => ({ [key]: data.value }));

export function formatNumber(value, digits = 1) {
  try {
    const isObjectValue = typeof value === 'object';

    if (value === null) {
      return notAvailableValue;
    }

    if (isObjectValue) {
      return formatObjectValue(value);
    }

    if (isNaN(value)) {
      return fallbackValue;
    }

    return value.toFixed(digits);
  } catch (err) {
    console.error(err);
    return fallbackValue;
  }
}

export function formatPercent(number, digits = 1) {
  if (number === null) {
    return notAvailableValue;
  }

  if (isNaN(number)) {
    return fallbackValue;
  }

  try {
    return `${number.toFixed(digits)}%`;
  } catch (err) {
    console.error(err);
    return fallbackValue;
  }
}

export function formatMoney(number, digits = 2) {
  if (number === null) {
    return notAvailableValue;
  }

  if (isNaN(number)) {
    return fallbackValue;
  }

  try {
    return `$${number.toFixed(digits)}`;
  } catch (err) {
    console.error(err);
    return fallbackValue;
  }
}
