import React, { useCallback } from 'react';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { TableCellType } from '@components/layout/table/types/TableCellType';
import { SortColumn } from '@components/layout/sortColumn/SortColumn';
import { experimentListSelectors } from '@infrastructure/store/experimentList/experimentListSelectors';
import { ExpSortByPayload } from '@infrastructure/store/experimentList/types/filters';
import { handleSortBy } from '@infrastructure/store/experimentList/experimentListActions';
import { ExperimentKey } from '@domain/entities/Experiment';

type HeaderCellProps = {
  type: string;
  labelText: string;
  fieldName?: ExperimentKey;
  sortBy?: ExpSortByPayload;
  handleSort?: (payload: ExpSortByPayload) => void;
};

function HeaderCell({ type, labelText, fieldName, sortBy, handleSort }: HeaderCellProps) {
  if (fieldName && sortBy && handleSort) {
    return (
      <th data-cell={type}>
        <SortColumn fieldName={fieldName} sortBy={sortBy} handleSort={handleSort}>
          {labelText}
        </SortColumn>
      </th>
    );
  }

  return <th data-cell={type}>{labelText}</th>;
}

export function ExperimentHeader() {
  const dispatch = useDispatch();
  const sortBy = useSelector(experimentListSelectors.getSortBy);

  const handleSort = useCallback(
    (payload: ExpSortByPayload) => {
      dispatch(handleSortBy(payload));
    },
    [dispatch]
  );

  return (
    <tr>
      <HeaderCell type={TableCellType.TITLE} labelText="App Name" />
      <HeaderCell
        type={TableCellType.TEXT}
        labelText="Rec."
        fieldName="isRecommendedProfile"
        sortBy={sortBy}
        handleSort={handleSort}
      />
      <HeaderCell
        type={TableCellType.TITLE}
        labelText="Test Name"
        fieldName="name"
        sortBy={sortBy}
        handleSort={handleSort}
      />
      <HeaderCell type={TableCellType.TEXT} labelText="Store" />
      <HeaderCell type={TableCellType.TEXT} labelText="Test Type" />
      <HeaderCell
        type={TableCellType.LABEL}
        labelText="Status"
        fieldName="state"
        sortBy={sortBy}
        handleSort={handleSort}
      />
      <HeaderCell
        type={TableCellType.DATE}
        labelText="Date"
        fieldName="startDate"
        sortBy={sortBy}
        handleSort={handleSort}
      />
      <HeaderCell type={TableCellType.LABEL} labelText="Country" />
      <HeaderCell type={TableCellType.ICON} labelText="Winner" fieldName="winnerStatus" />
    </tr>
  );
}
