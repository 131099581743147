import { RegionDto } from '@domain/models/RegionDto';
import { uniqBy } from 'lodash-es';
import { RegionOption } from '@domain/models/createExperiment/TargetConfigParams';
import { ExperimentRegion, ExperimentRegionName } from '@domain/enums/ExperimentRegion';

export class RegionMapper {
  static createOptions(regions: RegionDto[], exclude?: string[]): RegionOption[] {
    let filteredRegions = regions;
    if (Array.isArray(exclude)) {
      filteredRegions = regions.filter(({ name }) => !exclude.includes(name));
    }

    const rawOptions = filteredRegions.map((region) => {
      // backward compatibility with old experiments, should be simplified in a future ~6 month(?)
      const name = region.name === ExperimentRegion.US ? ExperimentRegionName.US : region.name;

      return { value: name, label: name };
    });

    return uniqBy(rawOptions, 'value');
  }

  static getRegionsByNames(regions: RegionDto[], names: string[]): RegionDto[] {
    const lowerCaseNames = names.map((name) => name.toLowerCase());

    return regions.filter((region) => lowerCaseNames.includes(region.name.toLowerCase()));
  }
}
