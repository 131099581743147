import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';

import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { FormComponent } from '@ui/hooks/form';
import { useSelector } from '@ui/hooks/redux';
import { ReactComponent as CrossSvg } from '@assets/svg/cross.svg';

import styles from './VariableList.module.scss';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'control'>;

export function VariableList({ control }: Props) {
  const variables = useSelector(configSelectors.getVariableList);
  const { field } = useController({ control, name: 'params' });

  const handleRemove = useCallback(
    (event) => {
      const { name } = event.target.dataset;

      const payload = field.value.filter((item) => item !== name);
      field.onChange(payload);
    },
    [field]
  );

  const variablesSorted = variables.filter((item) => field.value.includes(item.name));
  const labels = variablesSorted.map(({ name, displayName }) => (
    <li key={name} className={styles.label}>
      {displayName}
      <button type="button" data-name={name}>
        <CrossSvg />
      </button>
    </li>
  ));

  return (
    <ul className={styles.list} onClick={handleRemove}>
      {labels}
    </ul>
  );
}
