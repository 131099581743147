import React from 'react';
import { TextInput } from 'crazy-ui-next';
import { Controller } from 'react-hook-form';

import { GoalConfigParams } from '@domain/models/createExperiment/GoalConfigParams';
import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/goalConfig/GoalConfig.module.scss';

type Props = Pick<FormComponent<GoalConfigParams>, 'control' | 'errors'>;

export function TargetARPUInput({ errors, control }: Props) {
  return (
    <Controller
      name="arpu"
      control={control}
      render={({ field }) => {
        const displayValue = field.value === 0 ? '' : String(field.value);

        return (
          <TextInput
            name="arpu"
            labelText="Target ARPU"
            placeholder="ARPU"
            className={styles.targetArpu}
            type="number"
            value={displayValue}
            onChange={field.onChange}
            error={errors?.arpu?.message}
          />
        );
      }}
    />
  );
}
