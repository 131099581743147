import React from 'react';
import cn from 'classnames';
import { Controller } from 'react-hook-form';

import { UpdateWinnerConfigParams } from '@domain/models/winnerConfig/WinnerConfigFormParams';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';
import { GroupDropdown } from '@pages/winnerConfigModal/atoms/GroupDropdown';
import { Region } from '@pages/winnerConfigModal/atoms/Region';
import { AppliedCheckbox } from '@pages/winnerConfigModal/atoms/AppliedCheckbox';
import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/winnerConfigModal/list/List.module.scss';

type Props = FormComponent<UpdateWinnerConfigParams> & {
  index: number;
  regionCode: string;
  objective: ExperimentObjectiveDto;
};

export function Row({ index, regionCode, control, handleSubmit, register, errors, objective }: Props) {
  const isHeaderRow = index === 0;

  return (
    <Controller
      name={`winners.${index}.applied`}
      control={control}
      render={({ field }) => {
        return (
          <li className={cn(styles.winnerRow, field.value && styles.active)}>
            <div className="d-flex">
              <AppliedCheckbox
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                isHeaderRow={isHeaderRow}
              />

              <Region region={regionCode} isHeaderRow={isHeaderRow} />

              <GroupDropdown
                handleSubmit={handleSubmit}
                register={register}
                errors={errors}
                index={index}
                control={control}
                isHeaderRow={isHeaderRow}
                objective={objective}
              />
            </div>
          </li>
        );
      }}
    />
  );
}
