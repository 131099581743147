import { ActionFactory } from '@infrastructure/store/helpers/ActionFactory';
import { MyUserDto } from '@domain/models/user/MyUserDto';
import { LoginRequestDto } from '@domain/models/user/LoginRequestDto';

const namespace = 'auth';

export const pushLogin = ActionFactory.createRequestRoutine<void, MyUserDto>('LOGIN_PUSH', namespace);

export const pushLoginByCreds = ActionFactory.createRequestRoutine<LoginRequestDto, MyUserDto>(
  'LOGIN_BY_CREDS_PUSH',
  namespace
);

export const pushRestoreSession = ActionFactory.createRequestRoutine<void, MyUserDto | null>(
  'RESTORE_SESSION_PUSH',
  namespace
);

export const pushLogout = ActionFactory.createRequestRoutine('LOGOUT_PUSH', namespace);

export const clearAll = ActionFactory.createAction('CLEAR_ALL', namespace);
