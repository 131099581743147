import React from 'react';

import { PageTitle } from '@components/layout/PageTitle';
import { AppliedConfigListContainer } from '@pages/appliedConfig/list/AppliedConfigListContainer';
import { AppliedConfigFilter } from '@pages/appliedConfig/filters/AppliedConfigFilter';

export function AppliedConfigPage() {
  return (
    <>
      <PageTitle label="Applied Configurations" />
      <main className="container">
        <AppliedConfigFilter />
        <AppliedConfigListContainer />
      </main>
    </>
  );
}
