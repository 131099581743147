import React from 'react';
import cn from 'classnames';

import styles from './SignIn.module.scss';

/**
 *
 * @property {string} props.className
 * @property {string} props.text
 * @return {JSX.Element}
 */
export default function DividerWithText({ className, text = '' }) {
  return (
    <div className="row justify-content-center">
      <div className={cn(styles.separator, className)}>{text.toUpperCase()}</div>
    </div>
  );
}
