import React from 'react';
import cn from 'classnames';

import styles from './DividerLine.module.scss';

type Props = {
  className?: string;
};

export default function DividerLine({ className }: Props) {
  return (
    <div className="row">
      <div className="col">
        <span className={cn(styles.underline, className)} />
      </div>
    </div>
  );
}
