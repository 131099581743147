type RoutePath = string;
type RouteArg = string | number;

export class ExternalRouting {
  static getFirebaseProject = (firebaseId: RouteArg): RoutePath =>
    `https://console.firebase.google.com/project/${firebaseId}`;

  static getFirebaseConfig = (firebaseId: RouteArg): RoutePath =>
    `https://console.firebase.google.com/project/${firebaseId}/config`;
}
