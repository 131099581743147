import { Saga } from 'redux-saga';
import { all, fork, ForkEffect } from 'redux-saga/effects';
import { Service } from 'typedi';

@Service()
export class RootSagaWatcher {
  private watchers: ForkEffect[];

  constructor() {
    this.watchers = [];
  }

  public register(saga: Saga): void {
    this.watchers.push(fork(saga));
  }

  public getRootSaga() {
    const { watchers } = this;

    return function* rootSaga() {
      yield all(watchers);
    };
  }
}
