import React from 'react';

import { RequestStage } from '@infrastructure/store/types/actions';

import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { IterationResultDto } from '@domain/models/iteration/IterationResultDto';

import { THead } from '@pages/experimentDetails/components/stats/components/result/components/tableResult/THead';
import { TBody } from '@pages/experimentDetails/components/stats/components/result/components/tableResult/TBody';

import { BlockLoader } from '@components/layout/Loader';
import { NoData } from '@components/layout/noData/NoData';

import styles from '@pages/experimentDetails/components/stats/components/result/components/tableResult/TableResult.module.scss';

type Props = {
  result: IterationResultDto[];
  metricKeys: string[];
  status: RequestStage;
  variables: ExperimentVariableDto[];
  isAB: boolean;
};

export function TableResult({ result, metricKeys, variables, status, isAB }: Props) {
  const isLoading = status === RequestStage.REQUEST;

  if (isLoading) {
    return (
      <div className={styles.wrapper}>
        <BlockLoader />;
      </div>
    );
  }

  if (!result.length) {
    return (
      <div className={styles.wrapper}>
        <NoData />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <table>
        <THead result={result} metricKeys={metricKeys} variables={variables} isAB={isAB} />
        <TBody result={result} metricKeys={metricKeys} isAB={isAB} />
      </table>
    </div>
  );
}
