import React, { useEffect } from 'react';
import { ModalWrapper } from 'crazy-ui-next';
import { useSelector } from 'react-redux';

import { fetchExperiment } from '@infrastructure/store/appliedConfig/appliedConfigActions';
import { appliedConfigSelectors } from '@infrastructure/store/appliedConfig/appliedConfigSelectors';
import { RequestStage } from '@infrastructure/store/types/actions';
import { UniqueId } from '@domain/types';
import { UpdateWinnerConfigParams } from '@domain/models/winnerConfig/WinnerConfigFormParams';
import { FormContainer } from '@pages/winnerConfigModal/form/FormContainer';
import { useDispatch } from '@ui/hooks/redux';

import styles from '@pages/winnerConfigModal/WinnerConfigModal.module.scss';

type Props = {
  handleCloseModal: () => void;
  experimentId: UniqueId;
  objectiveId: UniqueId;
  handleApplyConfig: (form: UpdateWinnerConfigParams) => void;
};

export function WinnerConfigModalContainer({ handleCloseModal, experimentId, objectiveId, handleApplyConfig }: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchExperiment.trigger(experimentId));
  }, [dispatch, experimentId]);

  const experiment = useSelector(appliedConfigSelectors.getExperiment);
  const experimentStatus = useSelector(appliedConfigSelectors.getExperimentStatus);
  const updateStatus = useSelector(appliedConfigSelectors.getUpdateStatus);

  const isLoading = updateStatus === RequestStage.REQUEST;

  if (experimentStatus !== RequestStage.SUCCESS) {
    return null;
  }

  const objective = experiment.experimentObjectives.find(({ id }) => id === objectiveId);

  if (!objective) {
    return null;
  }

  return (
    <ModalWrapper handleModal={handleCloseModal} className={styles.modalWrapper}>
      <FormContainer
        handleCloseModal={handleCloseModal}
        handleApplyConfig={handleApplyConfig}
        handleEditWinner={handleApplyConfig}
        experimentId={experimentId}
        objective={objective}
        platform={experiment.gameApp.platform}
        isLoading={isLoading}
      />
    </ModalWrapper>
  );
}
