import React, { ReactElement } from 'react';

import { UniqueId } from '@domain/types';
import { BasicInfoContainer } from '@pages/createExperiment/basicInfo/BasicInfoContainer';
import { TargetingConfigContainer } from '@pages/createExperiment/targetConfig/TargetingConfigContainer';
import { ObjectiveConfigContainer } from '@pages/createExperiment/objectiveConfig/ObjectiveConfigContainer';
import { GoalConfigContainer } from '@pages/createExperiment/goalConfig/GoalConfigContainer';
import { DateConfigContainer } from '@pages/createExperiment/dateConfig/DateConfigContainer';

export type ExpFormStep = {
  title: string;
  container: ReactElement;
  index: UniqueId;
};

export const steps: ExpFormStep[] = [
  {
    title: 'Basic Info',
    container: <BasicInfoContainer />,
    index: 1
  },
  {
    title: 'Targeting Config',
    container: <TargetingConfigContainer />,
    index: 2
  },
  {
    title: 'Objective Config',
    container: <ObjectiveConfigContainer />,
    index: 3
  },
  {
    title: 'Goal Config',
    container: <GoalConfigContainer />,
    index: 4
  },
  {
    title: 'Dates Config',
    container: <DateConfigContainer />,
    index: 5
  }
];

// todo: Enable this section in future releases
// {
//   title: 'Advanced Config',
//   container: <AdvancedConfigContainer />,
//   index: 6
// }
