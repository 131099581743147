import { CreateUserDto, UpdateUserDto, UserDto } from '@domain/models/user/UserDto';
import { CreateUserParams, UpdateUserParams } from '@domain/models/user/UserReqParams';

export class UserMapper {
  static mapCreateParamsToDto(body: CreateUserParams): CreateUserDto {
    const [username] = body.email.split('@');

    return {
      username,
      firstName: body.firstName,
      lastName: body.lastName,
      email: body.email,
      roles: [body.role],
      enabled: body.enabled
    };
  }

  static mapUpdateParamsToDto(body: Partial<UpdateUserParams>, target: UserDto): UpdateUserDto {
    const dto = new UserDto();

    dto.id = body.id || target.id;
    dto.firstName = body.firstName || target.firstName;
    dto.lastName = body.lastName || target.lastName;
    dto.username = target.username;
    dto.email = target.email;
    dto.roles = body.role ? [body.role] : target.roles;
    dto.enabled = typeof body.enabled === 'boolean' ? body.enabled : target.enabled;

    if (body.email) {
      const [username] = body.email.split('@');
      dto.email = body.email;
      dto.username = username;
    }

    return dto;
  }

  static mapDtoToUpdateParams(dto: UserDto): UpdateUserParams {
    const body = new UpdateUserParams();

    if (!dto) {
      return body.setDefaultValues();
    }

    body.id = dto.id;
    body.firstName = dto.firstName;
    body.lastName = dto.lastName;
    body.email = dto.email;
    body.role = dto.roles[0];
    body.enabled = dto.enabled;

    return body;
  }
}
