import React from 'react';

function getLetters(alt) {
  const words = alt
    .split(' ')
    .slice(0, 2)
    .filter((str) => str.length)
    .map((str) => str[0].toUpperCase());

  const [firstLetter = null, lastLetter = null] = words;

  return { firstLetter, lastLetter };
}

export default function RoundImage({ className = '', src, alt = '' }) {
  if (src && src !== 'undefined') {
    return (
      <div className={`round-image ${className}`}>
        <img src={src} alt={alt} />
      </div>
    );
  }

  const { firstLetter, lastLetter } = getLetters(alt);

  return (
    <div className={`round-image empty ${className}`}>
      <span className="round-image__letters">
        {firstLetter}
        {lastLetter}
      </span>
    </div>
  );
}
