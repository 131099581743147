import { Service } from 'typedi';
import { IStateRepo, ISelector, IAction } from '@app/ports/IStateRepo';
import { AppState, ReduxStore } from '@infrastructure/store';

@Service()
export class ReduxRepo implements IStateRepo<AppState> {
  constructor(private readonly store: ReduxStore) {}

  findBy<T>(selector: ISelector<AppState, T>): T {
    const state = this.getState();
    return selector(state);
  }

  dispatch<T>(action: IAction<T>): void {
    this.store.dispatch(action);
  }

  getState(): AppState {
    return this.store.getState();
  }
}
