import React from 'react';

import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { RequestStage } from '@infrastructure/store/types/actions';
import { WinnerStatus } from '@pages/experimentDetails/components/winnerStatus/WinnerStatus';
import { useSelector } from '@ui/hooks/redux';

export function WinnerStatusContainer() {
  const experiment = useSelector(experimentDetailsSelectors.getExperiment);
  const status = useSelector(experimentDetailsSelectors.getWinnerStatus);
  const isLoading = status === RequestStage.REQUEST;

  return <WinnerStatus experiment={experiment} isLoading={isLoading} />;
}
