import { createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  pushAdvancedConfigForm,
  pushExperimentClone
} from '@infrastructure/store/createExperiment/createExperimentActions';
import { AdvancedConfigParams } from '@domain/models/createExperiment/AdvancedConfigParams';

const advancedConfigInitial: AdvancedConfigParams = AdvancedConfigParams.ofInitial();

export const advancedConfigReducer = createReducer(advancedConfigInitial, (qb) => {
  qb.addCase(pushAdvancedConfigForm, (state, { payload }) => {
    return payload;
  });
  qb.addCase(pushExperimentClone.success, (state, { payload }) => {
    if (payload) {
      return payload.advancedConfig;
    }

    return state;
  });
  qb.addCase(clearAll, () => {
    return AdvancedConfigParams.ofInitial();
  });
});
