import React from 'react';
import { memoize } from 'proxy-memoize';

import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { useSelector } from '@ui/hooks/redux';

import styles from '@pages/createExperiment/CreateExperiment.module.scss';

type Props = {
  step: Exclude<CreateExperimentForm, CreateExperimentForm.GLD_OBJECTIVE_CONFIG>;
  region: string;
};

export function Summary({ step, region }: Props) {
  const form = useSelector(createExperimentSelectors.getForm);

  const { experimentType } = form[CreateExperimentForm.BASIC_INFO];
  const { usersAllocationPercent, builtInGroup } = form[CreateExperimentForm.AB_OBJECTIVE_CONFIG][region];

  const groupCount = useSelector(createExperimentSelectors.getConfigList)[region].length;
  const allocationSummary = useSelector(
    memoize(
      createExperimentSelectors.getUserAllocationSummary(region, groupCount, usersAllocationPercent, builtInGroup)
    )
  );

  const params = form[step][region];
  const stepSummary = params.getSummary(experimentType);

  const summary = [...stepSummary, ...allocationSummary];

  return (
    <ul className={styles.summary}>
      {summary.map(({ title, value }, index) => {
        return (
          <li key={`summary_item_${index}`}>
            <p>
              {title}: <span>{value}</span>
            </p>
          </li>
        );
      })}
    </ul>
  );
}
