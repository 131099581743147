import React, { useEffect } from 'react';
import { Control, useController, UseFormWatch } from 'react-hook-form';
import { memoize } from 'proxy-memoize';

import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { setGame } from '@infrastructure/store/createExperiment/createExperimentActions';
import { BasicInfoParams } from '@domain/models/createExperiment/BasicInfoParams';
import { useSelector, useDispatch } from '@ui/hooks/redux';
import { SelectInput } from '@components/lib/Select/SelectInput';

import styles from '@pages/createExperiment/basicInfo/BasicInfo.module.scss';

const name = 'gameName';

type Props = {
  control?: Control<BasicInfoParams>;
  watch: UseFormWatch<BasicInfoParams>;
};

export function GameNameInput({ control, watch }: Props) {
  const dispatch = useDispatch();
  const options = useSelector(memoize(createExperimentSelectors.getGameNameOptions));
  const { field, formState } = useController({ control, name });

  useEffect(() => {
    const subscription = watch((value, { name: watchName, type }) => {
      if (watchName === name && type === 'change') {
        dispatch(setGame());
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, dispatch]);

  return (
    <SelectInput
      name={name}
      labelText="Game Name"
      placeholder="Select game"
      value={field.value.length ? field.value : undefined}
      options={options}
      handleChange={(event) => {
        field.onChange(event.value);
      }}
      error={formState.errors?.gameName?.message}
      className={styles.input}
    />
  );
}
