import React, { useCallback } from 'react';
import { FieldArray, useFieldArray } from 'react-hook-form';
import { Button } from 'crazy-ui-next';
import cn from 'classnames';
import { isEmpty } from 'lodash-es';

import { FormComponent } from '@ui/hooks/form';
import { AppPropertyForm } from '@domain/models/appProperty/AppPropertyInput';
import FormBottomControls from '@components/wrappers/bottomControls/FormBottomControls';
import { SubmitBtnAttr } from '@ui/const/DataAttributes';
import { AppPropertiesField } from '@pages/appProperties/AppPropertiesField';
import { AppPropertyType } from '@domain/enums/AppPropertyType';

import styles from './AppProperties.module.scss';

type Props = FormComponent<AppPropertyForm> & {
  isLoading: boolean;
};

export function AppPropertiesForm({ errors, isLoading, handleSubmit, control, register }: Props) {
  const { fields } = useFieldArray({
    name: 'property',
    control
  });

  const emailConfig: { field: FieldArray<AppPropertyForm>; index: number }[] = [];
  const expConfig: { field: FieldArray<AppPropertyForm>; index: number }[] = [];
  const recommendationConfig: { field: FieldArray<AppPropertyForm>; index: number }[] = [];

  fields.forEach((field, index) => {
    if (field.name.includes('recommendation')) {
      if (field.name.includes('required-number-of-users')) {
        recommendationConfig.push({ field, index });
      }
    } else if (field.type === AppPropertyType.EMAILS_ARRAY) {
      emailConfig.push({ field, index });
    } else {
      expConfig.push({ field, index });
    }
  });

  const handleSubmitClick = useCallback(
    (e) => {
      handleSubmit(e);
    },
    [handleSubmit]
  );

  return (
    <main className="container d-flex justify-content-center">
      <form className="paper paper-75 d-flex flex-column">
        <p className={styles.subtitle}>Recommendation Config:</p>

        {recommendationConfig.map(({ field, index }) => (
          <AppPropertiesField
            handleSubmit={handleSubmit}
            control={control}
            register={register}
            errors={errors}
            field={field}
            index={index}
            key={field.name}
          />
        ))}
        <p className={cn(styles.subtitle, 'mt-4')}>Experiment Config:</p>

        {expConfig.map(({ field, index }) => (
          <AppPropertiesField
            handleSubmit={handleSubmit}
            control={control}
            register={register}
            errors={errors}
            field={field}
            index={index}
            key={field.name}
          />
        ))}

        <p className={cn(styles.subtitle, 'mt-4')}>Emails Config:</p>

        {emailConfig.map(({ field, index }) => (
          <AppPropertiesField
            handleSubmit={handleSubmit}
            control={control}
            register={register}
            errors={errors}
            field={field}
            index={index}
            key={field.name}
          />
        ))}

        <FormBottomControls>
          <Button
            type="button"
            onClick={handleSubmitClick}
            isLoading={isLoading}
            disabled={!isEmpty(errors)}
            {...SubmitBtnAttr}
          >
            Save
          </Button>
        </FormBottomControls>
      </form>
    </main>
  );
}
