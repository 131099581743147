import React from 'react';
import cn from 'classnames';
import UserCircle from '@components/layout/userFilters/UserCircle';

import styles from './UserManagementModule.module.scss';
import { RoleManager } from './atoms/RoleManager';
import { UserStatusSwitch } from './atoms/UserStatusSwitch';

const baseClassName = 'py-1 d-flex align-items-center';

export function UserManagementRow({ user, handleSetStatus }) {
  const { id, email, roles, fullName } = user;

  return (
    <li className={cn('mb-1 row', styles.block)} data-user-id={id} data-email={email}>
      <div className={cn('col-1 justify-content-center', baseClassName, styles.logo)}>
        <UserCircle name={fullName} readOnly />
      </div>
      <div className={cn('col-3', baseClassName, styles.userName)}>
        <p className={styles.name}>{fullName}</p>
        <p className={styles.email}>{email}</p>
      </div>
      <div className={cn('col-9', baseClassName)}>
        <RoleManager roles={roles} userId={id} />
      </div>
      <div className={cn('col-3', baseClassName)}>
        <UserStatusSwitch handleSetStatus={handleSetStatus} user={user} />
      </div>
    </li>
  );
}
