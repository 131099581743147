import { createReducer } from '@reduxjs/toolkit';
import { BasicAction, RequestAction, RequestStage } from '@infrastructure/store/types/actions';

/* eslint-disable no-param-reassign */
export class ReducerFactory {
  static makeStatus(
    actions: RequestAction<any, any, any, any> | RequestAction<any, any, any, any>[],
    resetAction?: BasicAction<any>
  ) {
    const triggers = {};

    const addTriggers = (map: object, act: RequestAction) => {
      map[act.REQUEST] = (state) => (state = RequestStage.REQUEST);
      map[act.SUCCESS] = (state) => (state = RequestStage.SUCCESS);
      map[act.FAILURE] = (state) => (state = RequestStage.FAILURE);
    };

    if (Array.isArray(actions)) {
      actions.forEach((act) => addTriggers(triggers, act));
    } else {
      addTriggers(triggers, actions);
    }

    if (resetAction) {
      triggers[resetAction.toString()] = (state) => (state = RequestStage.NONE);
    }

    return createReducer(RequestStage.NONE, triggers);
  }

  static makeIsFetching(action: RequestAction, resetAction?: BasicAction<any>) {
    const triggers = {
      [action.REQUEST]: (state) => (state = true),
      [action.SUCCESS]: (state) => (state = false),
      [action.FAILURE]: (state) => (state = false)
    };

    if (resetAction) {
      triggers[resetAction.toString()] = (state) => (state = false);
    }

    return createReducer(false, triggers);
  }
}
