import React from 'react';
import { useSelector } from 'react-redux';
import { SvgLoader } from 'crazy-ui';

import useModal from '@ui/hooks/useModal';
import { colorMap } from '../utils/colors';

import { FullScreenChartModal } from '@pages/experimentDetails/components/modals/FullScreenChartModal';
import { generateDomainWithExtra } from '../utils/generateDomainWithExtra';
import BarChart from '../common/barChart/BarChart';
import styles from '../common/lineChart/LineChart.module.scss';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { ChartType } from '@domain/enums/ChartType';
import { RequestStage } from '@infrastructure/store/types/actions';
import { NoData } from '@components/layout/noData/NoData';

const chartName = 'Users Per Group';
const accentColor = colorMap.BLUE;
const tickFormat = (tick) => tick.toFixed(0);

export default function UsersSanityCheckChartContainer({ sizeModifier = 2 }) {
  const { showModal, handleOpenModal, handleCloseModal } = useModal();
  const rawData = useSelector(experimentDetailsSelectors.getChartByType(ChartType.USER_SANITY));
  const status = useSelector(experimentDetailsSelectors.getChartsStatus);

  if (status === RequestStage.REQUEST) {
    return (
      <div className={styles.chartPlaceholder}>
        <SvgLoader />
      </div>
    );
  }

  if (!rawData.data || !rawData.data.Users) {
    return <NoData hideIcon />;
  }

  const normalizedData = {
    config: rawData.config,
    data: rawData.data.Users,
    legend: rawData.legend.Users
  };

  const domain = {
    y: [
      0,
      generateDomainWithExtra({
        points: normalizedData.data,
        key: 'y',
        extraPercent: 30,
        fallback: 100,
        roundRank: 3
      })
    ]
  };

  return (
    <>
      <BarChart
        domain={domain}
        tickFormat={tickFormat}
        chartData={normalizedData}
        sizeModifier={sizeModifier}
        handleFullscreen={handleOpenModal}
        accentColor={accentColor}
        autoHeight
      />
      {showModal && (
        <FullScreenChartModal
          Chart={
            <BarChart
              title={chartName}
              domain={domain}
              tickFormat={tickFormat}
              chartData={normalizedData}
              sizeModifier={4}
              accentColor={accentColor}
              fullscreen
            />
          }
          handleModal={handleCloseModal}
        />
      )}
    </>
  );
}
