import React, { useEffect } from 'react';

import { Audit } from '@pages/experimentDetails/components/stats/components/audit/Audit';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { fetchExperimentAudit } from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { RequestStage } from '@infrastructure/store/types/actions';
import { BlockLoader } from '@components/layout/Loader';
import { NoData } from '@components/layout/noData/NoData';

export function AuditContainer() {
  const dispatch = useDispatch();

  const audit = useSelector(experimentDetailsSelectors.getAudit);
  const status = useSelector(experimentDetailsSelectors.getAuditStatus);

  useEffect(() => {
    dispatch(fetchExperimentAudit.trigger());
  }, [dispatch]);

  if (status !== RequestStage.SUCCESS) {
    return <BlockLoader />;
  }

  if (!audit.length) {
    return <NoData subject="audit logs" />;
  }

  return <Audit audit={audit} />;
}
