import React from 'react';

import { ReactComponent as CancelSvg } from '@assets/svg/cancel.svg';

import styles from '@components/layout/noData/NoData.module.scss';

type Props = {
  subject?: string;
  hideIcon?: boolean;
};

export function NoData({ subject, hideIcon }: Props) {
  const text = subject || 'data';

  return (
    <div className={styles.wrapper}>
      {!hideIcon && <CancelSvg />}
      <span>No available {text}</span>
    </div>
  );
}
