import { Service } from 'typedi';

import { RegionApi } from '@infrastructure/api/RegionApi';
import { RegionUseCase } from '@domain/useCases/RegionUseCase';
import { UniqueId } from '@domain/types';

@Service()
export class RegionService implements RegionUseCase {
  constructor(private readonly regionApi: RegionApi) {}

  async getDefaultRegions() {
    return this.regionApi.getDefaultRegions();
  }

  async getRecommendedRegions(applicationId: UniqueId) {
    return this.regionApi.getRecommendedRegions(applicationId);
  }
}
