import React, { ReactElement, useRef } from 'react';
import cn from 'classnames';
import { Button, ButtonVariant } from 'crazy-ui-next';
import { useSelector } from '@ui/hooks/redux';

import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { UniqueId } from '@domain/types';

import { StatusCircle } from '@pages/createExperiment/atoms/StatusCircle/StatusCircle';
import { steps } from '@pages/createExperiment/steps/const/Steps';

import styles from '@pages/createExperiment/CreateExperiment.module.scss';

type StepsProps = {
  currentStep: UniqueId;
  handleSetStep: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isRequest: boolean;
};

type StepProps = {
  handleSetStep: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isRequest: boolean;
  isCompleted: boolean;
  isNext: boolean;
  isCurrent: boolean;
  index: number;
  title: string;
  container: ReactElement;
};

function Step({ isCompleted, index, isCurrent, isNext, handleSetStep, title, isRequest, container }: StepProps) {
  const { isRecommendedProfile } = useSelector(createExperimentSelectors.getForm)[CreateExperimentForm.BASIC_INFO];

  const ref = useRef<HTMLAreaElement>(null);

  React.useEffect(() => {
    if (ref.current && isCompleted) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isCompleted]);

  const isFirstStep = index === 1;

  const isStepDisabled = (isRecommendedProfile && !isFirstStep) || !isCompleted;

  return (
    <section className={styles.section} key={index} ref={ref}>
      <div className={styles.legend}>
        <Button
          variant={ButtonVariant.TERTIARY}
          icon={<StatusCircle isRequest={isRequest} isCompleted={isCompleted} isCurrent={isCurrent} />}
          disabled={isStepDisabled}
          onClick={handleSetStep}
          data-step={index}
        />
        <h4>{title}</h4>
      </div>

      {!isNext && (
        <div className={cn(isRequest && isCurrent && styles.hidden, styles.content)}>
          {React.cloneElement(container, { isCompleted })}
        </div>
      )}
    </section>
  );
}

export function Steps({ currentStep, handleSetStep, isRequest }: StepsProps) {
  return (
    <>
      {steps.map(({ container, title, index }) => {
        const isCompleted = index < currentStep;
        const isCurrent = index === currentStep;
        const isNext = index > currentStep;

        return (
          <Step
            index={index}
            isCompleted={isCompleted}
            isCurrent={isCurrent}
            handleSetStep={handleSetStep}
            container={container}
            isNext={isNext}
            isRequest={isRequest}
            title={title}
            key={title}
          />
        );
      })}
    </>
  );
}
