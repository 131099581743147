import React from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';

import { ReactComponent as ArrowSvg } from '@assets/svg/arrow-up-circle.svg';

import styles from '@pages/createExperiment/CreateExperiment.module.scss';

type Props = {
  disabled?: boolean;
};

export function NextButton({ disabled }: Props) {
  return (
    <Button
      type="submit"
      variant={ButtonVariant.PRIMARY}
      icon={<ArrowSvg />}
      className={styles.nextBtn}
      disabled={disabled}
    >
      Next
    </Button>
  );
}
