import { combineReducers, createReducer } from '@reduxjs/toolkit';

import { clearAll, fetchGLDParams } from '@infrastructure/store/createExperiment/createExperimentActions';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import { GLDParamDto } from '@domain/enums/GLDParamDto';

const gldInitial: GLDParamDto[] = [];

export const gldDataReducer = createReducer(gldInitial, (qb) => {
  qb.addCase(fetchGLDParams.success, (state, { payload }) => {
    return payload || [];
  });
  qb.addCase(clearAll, () => {
    return gldInitial;
  });
});

export const gldReducer = combineReducers({
  status: ReducerFactory.makeStatus([fetchGLDParams], clearAll),
  data: gldDataReducer
});

export const paramsReducer = combineReducers({
  gld: gldReducer
});
