import { IsNotEmpty, IsString } from 'class-validator';

export class DevBasicInfoParams {
  @IsString()
  @IsNotEmpty()
  public experimentName: string;

  @IsString()
  @IsNotEmpty()
  public arpu: string;

  @IsString()
  public description: string;

  @IsString()
  public importedSegments: string;

  static ofInitial() {
    const params = new DevBasicInfoParams();

    params.experimentName = '';
    params.arpu = '';
    params.description = '';
    params.importedSegments = '';

    return params;
  }
}
