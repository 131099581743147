import React from 'react';
import cn from 'classnames';
import { DropdownInput } from 'crazy-ui-next';
import { Controller } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';
import { UpdateWinnerConfigParams } from '@domain/models/winnerConfig/WinnerConfigFormParams';

import styles from '@pages/winnerConfigModal/list/List.module.scss';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';

type Props = FormComponent<UpdateWinnerConfigParams> & {
  index: number;
  isHeaderRow: boolean;
  objective: ExperimentObjectiveDto;
};

export function GroupDropdown({ control, index, isHeaderRow, objective }: Props) {
  const options = objective.configs.map((config) => {
    const { name } = config;

    return { label: name, value: name };
  });

  return (
    <div className={cn(styles.col, styles.group)}>
      <Controller
        name={`winners.${index}.group`}
        control={control}
        render={({ field }) => {
          return (
            <DropdownInput
              id={`winner-group-dropdown-${index}`}
              name={field.name}
              value={field.value}
              options={options}
              handleChange={(event) => field.onChange(event.value)}
              className={styles.drop}
            />
          );
        }}
      />
      {isHeaderRow && (
        <span className={styles.heading}>
          <p>Group</p>
        </span>
      )}
    </div>
  );
}
