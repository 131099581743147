import React from 'react';
import cn from 'classnames';

import { ReactComponent as ExpandSvg } from '@assets/svg/expand.alt.svg';

import styles from '../lineChart/LineChart.module.scss';

type Props = {
  center?: boolean;
};

export function ChartFullscreenIcon({ center }: Props) {
  return (
    <div className={cn(styles.chartFullscreenIcon, center && styles.center)}>
      <ExpandSvg />
    </div>
  );
}
