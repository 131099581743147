import React from 'react';
import { InputLabel, RadioInput } from 'crazy-ui';
import { useController, Control, UseFormRegister } from 'react-hook-form';

import { getInputProps } from '@ui/hooks/form';
import { UserRoleType } from '@domain/enums/UserRoleType';
import { UserFormParams } from '@domain/models/user/UserReqParams';
import { RoleMetaMap } from '@pages/userManagement/const/RoleMetaMap';
import styles from './EditUserForm.module.scss';

const name = 'role';

type Props = {
  control?: Control<UserFormParams, 'role'>;
  register: UseFormRegister<UserFormParams>;
  disabled?: boolean;
};

export function RoleInput({ control, register, disabled }: Props) {
  const { field, formState } = useController({ control, name });
  const inputProps = getInputProps<UserFormParams>(name, register, formState.errors);

  const options = Object.keys(UserRoleType).map((role) => {
    return (
      <div key={`r_${role}`} className={styles.roleItem}>
        <RadioInput
          checked={role === field.value}
          labelText={RoleMetaMap[role]?.title || role}
          value={role}
          disabled={disabled}
          {...inputProps}
        />
      </div>
    );
  });

  return (
    <div className={styles.rolesBlock}>
      <InputLabel labelText="Role" error={inputProps.error} />
      <div className={styles.roleList}>{options}</div>
    </div>
  );
}
