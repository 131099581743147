import { IsString, IsNotEmpty } from 'class-validator';
import { Dto } from '@domain/models/Dto';

export class LoginRequestDto extends Dto {
  @IsString()
  @IsNotEmpty()
  username: string;

  @IsString()
  @IsNotEmpty()
  password: string;
}
