import React, { useEffect } from 'react';
import { LazyLog } from 'react-lazylog/build';

import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { fetchExperimentLog } from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { RequestStage } from '@infrastructure/store/types/actions';
import { BlockLoader } from '@components/layout/Loader';
import { NoData } from '@components/layout/noData/NoData';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import styles from '@pages/experimentDetails/components/stats/components/logs/Logs.module.scss';

export function LogViewContainer() {
  const dispatch = useDispatch();
  const log = useSelector(experimentDetailsSelectors.getSelectedLog);
  const status = useSelector(experimentDetailsSelectors.getSelectedLogStatus);

  useEffect(() => {
    dispatch(fetchExperimentLog.trigger());
  }, [dispatch]);

  if (status !== RequestStage.SUCCESS) {
    return <BlockLoader />;
  }

  if (!log) {
    return <NoData subject="experiment log" />;
  }

  return (
    <div className={styles.view}>
      <LazyLog text={log} extraLines={1} enableSearch caseInsensitive height={500} selectableLines />
    </div>
  );
}
