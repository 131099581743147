import React, { useEffect } from 'react';

import { useDispatch } from '@ui/hooks/redux';
import { fetchExperiments, clearAll } from '@infrastructure/store/experimentList/experimentListActions';
import { ExperimentListPage } from './ExperimentListPage';

export function ExperimentListPageContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchExperiments.trigger());

    return () => {
      dispatch(clearAll());
    };
  }, [dispatch]);

  return <ExperimentListPage />;
}
