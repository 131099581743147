import { Type } from 'class-transformer';

import { Dto } from '@domain/models/Dto';
import { ExperimentPeriodicity } from '@domain/enums/ExperimentPeriodicity';
import { GamePlatform } from '@domain/enums/GamePlatform';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';

export class UIConfigDto extends Dto {
  appVersion: string;
  periodicities: ExperimentPeriodicity[];
  platforms: GamePlatform[];
  experimentTypes: ExperimentType[];
  queryPlaceholders: string[];
  kpis: { name: string; available: boolean }[];
  minUsersPerGroup: number;

  @Type(() => ExperimentVariableDto)
  experimentVariables: ExperimentVariableDto[];
  countries: string[]; // not used
  variables: object[]; // replaced by experimentVariables
}
