import React from 'react';

import { GLDFormConfig } from '@domain/models/createExperiment/GLDConfigParams';

type Props = {
  controlGroup?: GLDFormConfig;
};

export function THead({ controlGroup }: Props) {
  if (!controlGroup) {
    return null;
  }

  return (
    <thead>
      <th />
      <th>Group Name</th>
      {Object.values(controlGroup.input).map(({ key }) => (
        <th key={key}>{key}</th>
      ))}
    </thead>
  );
}
