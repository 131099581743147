import React from 'react';
import { Control, useController } from 'react-hook-form';
import { DropdownInput } from 'crazy-ui-next';

import { createSimpleOptions } from '@infrastructure/utils/createOptions.utils';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';
import { ExperimentRegion } from '@domain/enums/ExperimentRegion';

import styles from '@pages/createExperiment/targetConfig/TargetingConfig.module.scss';

const name: keyof TargetConfigParams = 'regionType';

type Props = {
  control: Control<TargetConfigParams>;
};

const options = createSimpleOptions([ExperimentRegion.US, ExperimentRegion.WW]);

export function RegionTypeInput({ control }: Props) {
  const { field, formState } = useController({ control, name });

  return (
    <DropdownInput
      labelText="Region Type"
      placeholder="Select region"
      options={options}
      className={styles.input}
      value={field.value}
      handleChange={(event) => field.onChange(event.value)}
      name={name}
      error={formState.errors?.regionType?.message}
    />
  );
}
