export enum ExperimentRegion {
  WW = 'WW', // World Wide
  MK = 'MK', // North Macedonia
  US = 'US', // United States
  NON_US = 'NON_US'
}

export enum ExperimentRegionName {
  WW = 'WW',
  US = 'United States',
  MK = 'North Macedonia'
}
