import { Dto } from '@domain/models/Dto';
import { UniqueId } from '@domain/types';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { GamePlatform } from '@domain/enums/GamePlatform';
import { ExperimentOverlappingType } from '@domain/enums/ExperimentOverlappingType';

export class OverlappedConfigDto extends Dto {
  public id: UniqueId;
  public name: string;
  public type: ExperimentType;
  public platform: GamePlatform;
  public primaryRegion: string;
  public regions: string[];
  public inDevRegions: string[];
  public appVersions: string[];
  public overlappingType: ExperimentOverlappingType;
  public manualParams: string[];
}
