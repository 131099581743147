import React from 'react';

import styles from './VariableType.module.scss';
import { ExperimentVariableType } from '@domain/enums/ExperimentVariableType';

type Props = {
  type: ExperimentVariableType;
};

const mapStatusToLabel = (type: ExperimentVariableType) => {
  const value = {
    [ExperimentVariableType.STRING]: 'STRING',
    [ExperimentVariableType.INTEGER]: 'INTEGER',
    [ExperimentVariableType.BOOLEAN]: 'BOOLEAN',
    [ExperimentVariableType.INT_LIST]: 'INT LIST'
  }[type];

  if (!value) {
    throw new TypeError(`Cannot map "${type}" to "LabelText"`);
  }

  return value;
};

export function VariableType({ type }: Props) {
  const labelText = mapStatusToLabel(type);

  return (
    <span data-status={type} className={styles.label}>
      {labelText}
    </span>
  );
}
