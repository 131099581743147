import { Service } from 'typedi';

import { BackendHttpClient } from '@infrastructure/http/BackendHttpClient';
import { ResponseMapper } from '@app/mappers/ResponseMapper';
import { UserDto, CreateUserDto, UpdateUserDto } from '@domain/models/user/UserDto';
import { MyUserDto } from '@domain/models/user/MyUserDto';

@Service()
export class UserApi {
  constructor(protected readonly http: BackendHttpClient) {}

  async getCurrentUser(): Promise<MyUserDto> {
    return this.http.get('/intops/admin/me').then(ResponseMapper.mapToMyUserDto);
  }

  async getUserList(): Promise<UserDto[]> {
    return this.http.get('/intops/admin/user').then((list) => list.map(ResponseMapper.mapToUserDto));
  }

  async createUser(user: CreateUserDto): Promise<UserDto> {
    return this.http.post('/intops/admin/user', user).then(ResponseMapper.mapToUserDto);
  }

  async updateUser(user: UpdateUserDto): Promise<UserDto> {
    return this.http.put('/intops/admin/user', user).then(ResponseMapper.mapToUserDto);
  }
}
