import { GameStatsType } from '@domain/enums/GameStatsType';

type DataType = {
  prev: GameStatsType | null;
  next: GameStatsType | null;
  title: string;
};

export const mapGameStatsTypeToData = (key: GameStatsType): DataType => {
  const value = {
    [GameStatsType.USER_PER_DAY]: { prev: GameStatsType.REVENUE, next: null, title: 'User per Day' },
    [GameStatsType.REV_PER_USER]: { prev: null, next: GameStatsType.REVENUE, title: 'Rev per User' },
    [GameStatsType.REVENUE]: {
      prev: GameStatsType.REV_PER_USER,
      next: GameStatsType.USER_PER_DAY,
      title: 'Revenue last week'
    }
  }[key];

  if (!value) {
    throw new TypeError(`Cannot map "${key}" to "GameStatsType"`);
  }

  return value;
};
