import React from 'react';
import { useController } from 'react-hook-form';

import { AppPropertyForm } from '@domain/models/appProperty/AppPropertyInput';
import { FormComponent } from '@ui/hooks/form';
import { ReactComponent as CrossSvg } from '@assets/svg/cross.svg';

import styles from './EmailList.module.scss';

type Props = Pick<FormComponent<AppPropertyForm>, 'control'> & {
  index: number;
  handleRemove: (e) => void;
};
export function EmailList({ control, index, handleRemove }: Props) {
  const { field } = useController({ control, name: `property.${index}.value` });

  const emails = Array.isArray(field.value) ? field.value : field.value.split(',');

  const labels = emails.map((email) => (
    <li key={email} className={styles.label}>
      {email}
      <button type="button" data-email={email}>
        <CrossSvg />
      </button>
    </li>
  ));

  return (
    <ul className={styles.list} onClick={handleRemove}>
      {labels}
    </ul>
  );
}
