import { IterationDto } from '@domain/models/iteration/IterationDto';
import { UniqueId } from '@domain/types';

export class IterationMapper {
  static mapByObjectiveId(iterations: IterationDto[] | null): Record<UniqueId, IterationDto[]> {
    const map = {};

    if (iterations?.length) {
      iterations.forEach((iteration) => {
        const { objectiveId } = iteration;

        if (!map[objectiveId]) {
          map[objectiveId] = [];
        }
        map[objectiveId].push(iteration);
      });
    }

    return map;
  }
}
