import React from 'react';
import { Button } from 'crazy-ui-next';
import cn from 'classnames';

import styles from '@pages/common/ErrorPage.module.scss';

const hardRefreshPage = () => window.location.reload();

type Props = {
  stack: string;
};

export function FallbackUI({ stack }: Props) {
  return (
    <main className={cn('container', styles.wrapper, styles.error)}>
      <div className="paper">
        <h2>Something Went Wrong</h2>
        <h3>An error occurred</h3>
        <p>Please send this error stacktrace to the developer and try to reload page.</p>
        <div className={styles.actionBlock}>
          <textarea
            className="my-4"
            name="stack"
            value={stack || ''}
            style={{ height: '380px', width: '1100px' }}
            readOnly
          />
          <Button onClick={hardRefreshPage} className="btn">
            Reload
          </Button>
        </div>
      </div>
    </main>
  );
}
