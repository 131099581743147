import { combineReducers } from '@reduxjs/toolkit';
import { fetchExperiments, clearAll } from '@infrastructure/store/experimentList/experimentListActions';

import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import { paginationReducer } from '@infrastructure/store/experimentList/reducers/paginationReducer';
import { byIdReducer, idListReducer } from '@infrastructure/store/experimentList/reducers/mapReducer';
import { filterReducer } from '@infrastructure/store/experimentList/reducers/filterReducer';

export const experimentList = combineReducers({
  status: ReducerFactory.makeStatus(fetchExperiments, clearAll),
  byId: byIdReducer,
  idList: idListReducer,
  pagination: paginationReducer,
  filters: filterReducer
});
