import React, { useCallback } from 'react';
import cn from 'classnames';
import { useFieldArray, useWatch } from 'react-hook-form';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { InputGroupSessionForm, ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { AddSessionBtn } from '@pages/createExperiment/objectiveConfig/atoms/AddSessionBtn';
import { Session } from '@pages/createExperiment/objectiveConfig/forms/AB/components/inputGroup/Session';
import { EditableSessionIndex } from '@pages/createExperiment/objectiveConfig/atoms/EditableSessionIndex';
import { useSelector } from '@ui/hooks/redux';
import { FormComponent } from '@ui/hooks/form';
import { ReactComponent as CrossSvg } from '@assets/svg/cross.svg';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/inputGroup/InputGroup.module.scss';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'control' | 'register'> & {
  sectionIndex: number;
  handleRemove: (number) => void;
  defaultInputGroup: InputGroupSessionForm;
};

const maxSessions = 7;

export function Section({
  control,
  register,
  sectionIndex,
  defaultInputGroup,
  handleRemove: handleRemoveSection
}: Props) {
  const params = useWatch({
    control,
    name: 'params'
  });
  const watchSection = useWatch({
    control,
    name: `input.${sectionIndex}.section`
  });
  const {
    fields: sessions,
    append,
    remove,
    update
  } = useFieldArray({
    control,
    name: `input.${sectionIndex}.section`
  });

  const addSessionDisabled = sessions.length >= maxSessions;

  const variables = useSelector(configSelectors.getVariableList);
  const filteredVariables = variables.filter(({ name }) => params.includes(name));

  const handleAdd = useCallback(() => {
    const sessionIndexes = watchSection.map(({ sessionIndex }) => Number(sessionIndex)).sort((a, b) => a - b);
    const maxSessionIndex = Math.max(...sessionIndexes);
    const newSessionIndex = maxSessionIndex + 1;

    append({ ...defaultInputGroup, sessionIndex: newSessionIndex.toString() });
  }, [append, watchSection, defaultInputGroup]);

  const handleRemoveSession = useCallback(
    (e) => {
      const { sessionIndex } = e.target.dataset;

      if (sessionIndex) {
        const index = sessions.map((session) => session.sessionIndex).indexOf(sessionIndex);

        remove(index);
      }
    },
    [remove, sessions]
  );

  const handleChangeSessionIndex = useCallback(
    (index, sessionIndex) => {
      if (!parseInt(sessionIndex)) {
        return;
      }

      const formattedSessionIndex = parseInt(sessionIndex).toString();
      const sessionIndexes = watchSection.map((session) => session.sessionIndex);

      if (sessionIndexes.includes(formattedSessionIndex)) {
        return;
      }

      const field = watchSection[index];
      const updatedField = { ...field, sessionIndex: formattedSessionIndex };

      update(index, updatedField);
    },
    [update, watchSection]
  );

  const showRemoveBtn = sessions.length > 1;
  const maskTitle = sessions.length > 4;
  const tabs = sessions.map((field, index) => (
    <Tab
      key={`tab_${field.id}`}
      className={styles.tab}
      onClick={handleRemoveSession}
      style={{ order: Number(field.sessionIndex) }}
    >
      <span className={cn(styles.tabTitle, maskTitle && styles.masked)}>Session</span>
      <EditableSessionIndex
        index={index}
        viewOnly={index === 0}
        sessionIndex={field.sessionIndex}
        handleSave={handleChangeSessionIndex}
      />
      {showRemoveBtn && (
        <button type="button" data-session-index={field.sessionIndex}>
          <CrossSvg />
        </button>
      )}
    </Tab>
  ));
  const form = sessions.map((field, sessionIndex) => {
    return (
      <TabPanel key={`tab-panel_${field.id}`} className={styles.form} selectedClassName={styles.selected}>
        <Session
          sessionIndex={sessionIndex}
          sectionIndex={sectionIndex}
          register={register}
          control={control}
          variables={filteredVariables}
          handleRemoveSection={handleRemoveSection}
          defaultInputGroup={defaultInputGroup}
        />
      </TabPanel>
    );
  });
  return (
    <Tabs>
      <TabList className={styles.tabList}>
        {tabs}
        <li>
          <AddSessionBtn handleClick={handleAdd} disabled={addSessionDisabled} />
        </li>
      </TabList>
      {form}
    </Tabs>
  );
}
