import { IsString, ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator';

import { GoalConfigParams } from '@domain/models/createExperiment/GoalConfigParams';

class ReportingArpu {
  @IsString()
  value: string;
}

@ValidatorConstraint()
export class IsValidReportingArpu implements ValidatorConstraintInterface {
  public async validate(reportingArpu: ReportingArpu[], args: ValidationArguments) {
    const { automaticMode, arpu } = args?.object as GoalConfigParams;

    if (!automaticMode) {
      return true;
    }

    const greaterThanTarget = reportingArpu.find(({ value }) => Number(value) > arpu);

    if (greaterThanTarget) {
      return false;
    }

    return true;
  }
}
