import React from 'react';
import { useController, Control } from 'react-hook-form';
import { DropdownInput } from 'crazy-ui-next';

import { UserPropertyOperator } from '@domain/models/createExperiment/userProperties/UserPropertyOperatorsDto';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';

type Props = {
  inputIndex: number;
  control: Control<TargetConfigParams>;
  operators: UserPropertyOperator[];
  disabled: boolean;
  watchName: string;
};

export function UserPropertyOperatorInput({ inputIndex, control, operators, disabled, watchName }: Props) {
  const inputName = `${watchName}.${inputIndex}.operatorId`;

  const options = operators.map((operator) => ({
    label: operator.displayName,
    value: String(operator.id)
  }));

  const { field } = useController({
    // @ts-ignore-next-line
    name: inputName,
    control
  });

  return (
    <DropdownInput
      options={options}
      name={inputName}
      value={field.value}
      handleChange={(event) => field.onChange(event.value)}
      disabled={disabled}
    />
  );
}
