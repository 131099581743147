import React, { useCallback } from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { removeGroupFromConfig } from '@infrastructure/store/createExperiment/createExperimentActions';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { CONFIG_GROUP_NAME } from '@domain/enums/ConfigGroupName';

import { ReactComponent as MinusSvg } from '@assets/svg/minus.svg';

import styles from './GroupNameCell.module.scss';

type Props = {
  groupName: string;
  index: number;
  region: string;
};

export function GroupNameCell({ groupName, index, region }: Props) {
  const dispatch = useDispatch();

  const { isRecommendedProfile } = useSelector(createExperimentSelectors.getForm)[CreateExperimentForm.BASIC_INFO];

  const handleRemoveGroup = useCallback(() => {
    dispatch(removeGroupFromConfig({ index, region }));
  }, [dispatch, index, region]);

  const showControls =
    !isRecommendedProfile && !Object.values(CONFIG_GROUP_NAME).includes(groupName as CONFIG_GROUP_NAME);

  return (
    <td className={styles.cell}>
      {groupName}
      {showControls && (
        <div className={styles.controls}>
          <Button variant={ButtonVariant.TERTIARY} onClick={handleRemoveGroup} icon={<MinusSvg />} />
        </div>
      )}
    </td>
  );
}
