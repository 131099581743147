import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { clearAll, fetchExperiment } from '@infrastructure/store/appliedConfig/appliedConfigActions';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';

const experimentInitial: ExperimentDto = {} as ExperimentDto;

const experiment = createReducer(experimentInitial, (qb) => {
  qb.addCase(fetchExperiment.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return experimentInitial;
  });
});

export const experimentReducer = combineReducers({
  status: ReducerFactory.makeStatus(fetchExperiment),
  data: experiment
});
