import { IsBoolean, IsEnum, IsNotEmpty, IsNumber, IsNumberString, IsString, ValidateIf } from 'class-validator';
import { IsGreaterThanOrEqual, IsLessThanOrEqual } from '@infrastructure/validation/decorators';

import { ExperimentVariableType } from '@domain/enums/ExperimentVariableType';

const isNumeric = (o) => [ExperimentVariableType.INT_LIST, ExperimentVariableType.INTEGER].includes(o.type);

export class ExpVariableParams {
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsNotEmpty()
  displayName: string;

  @IsEnum(ExperimentVariableType)
  @IsNotEmpty()
  type: ExperimentVariableType;

  @IsLessThanOrEqual('maxValue')
  @IsNumberString()
  @ValidateIf(isNumeric)
  minValue: string;

  @IsGreaterThanOrEqual('minValue')
  @IsNumberString()
  @ValidateIf(isNumeric)
  maxValue: string;

  @IsNumberString()
  @ValidateIf(isNumeric)
  defaultDisplayValue: string;

  @IsBoolean()
  sessionsSupported: boolean;

  @IsBoolean()
  enabled: boolean;

  setDefaultValues() {
    this.name = '';
    this.displayName = '';
    this.type = ExperimentVariableType.INTEGER;
    this.minValue = '0';
    this.maxValue = '100';
    this.defaultDisplayValue = '1';
    this.sessionsSupported = false;
    this.enabled = true;
    return this;
  }
}

export class CreateExpVariableParams extends ExpVariableParams {}

export class UpdateExpVariableParams extends ExpVariableParams {
  @IsNumber()
  @IsNotEmpty()
  id?: number;
}
