import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownEvent, DropdownInput, DropdownOption } from 'crazy-ui-next';

import { appliedConfigSelectors } from '@infrastructure/store/appliedConfig/appliedConfigSelectors';
import { setStatusFilter } from '@infrastructure/store/appliedConfig/appliedConfigActions';
import { ACFilter } from '@infrastructure/store/appliedConfig/types/filters';
import { ReactComponent as CheckListSvg } from '@assets/svg/check-list.svg';

const options: DropdownOption<ACFilter.Status>[] = [
  { value: ACFilter.Status.ALL, label: 'All' },
  { value: ACFilter.Status.APPLIED, label: 'Applied' },
  { value: ACFilter.Status.HISTORY, label: 'History' }
];

export function StatusFilter() {
  const dispatch = useDispatch();
  const { status } = useSelector(appliedConfigSelectors.getFilters);

  const handleStatus = useCallback(
    ({ value }: DropdownEvent<ACFilter.Status>) => {
      dispatch(setStatusFilter(value));
    },
    [dispatch]
  );

  return (
    <DropdownInput
      name="status"
      inputIcon={<CheckListSvg />}
      value={status}
      options={options}
      handleChange={handleStatus}
      labelText="Status"
    />
  );
}
