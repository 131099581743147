import { BaseError } from '@app/errors/BaseError';

export class NotFoundError extends BaseError {
  constructor(resource: string, param?: number | string) {
    if (param) {
      super(`${resource} with param "${param}" was not found`);
    } else {
      super(`${resource} was not found`);
    }
  }
}
