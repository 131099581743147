import { combineReducers } from '@reduxjs/toolkit';

import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import {
  fetchUsers,
  pushUserUpdate,
  pushUserCreate,
  clearAll
} from '@infrastructure/store/userManagement/userManagementActions';
import { byIdReducer, idListReducer } from '@infrastructure/store/userManagement/reducers/mapReducer';
import { filterReducer } from '@infrastructure/store/userManagement/reducers/filterReducer';

export const userManagement = combineReducers({
  status: ReducerFactory.makeStatus(fetchUsers, clearAll),
  editStatus: ReducerFactory.makeStatus([pushUserCreate, pushUserUpdate], clearAll),
  byId: byIdReducer,
  idList: idListReducer,
  filters: filterReducer
});
