import { Type } from 'class-transformer';
import { Dto } from '@domain/models/Dto';
import { Email, UniqueId } from '@domain/types';
import { RegionDto } from '@domain/models/RegionDto';
import { TDateISODate } from '@domain/types/date.types';
import { ExperimentType } from '@domain/enums/ExperimentType';

export class AppliedConfigDto extends Dto {
  id: UniqueId;
  gameId: UniqueId;
  isActive: boolean;
  version: string[];

  @Type(() => RegionDto)
  region: RegionDto;
  appliedGroup: string;
  appliedDate: TDateISODate;
  deactivationDate: TDateISODate;
  appliedBy: Email;
  config: Record<string, object>;

  experiment: {
    id: UniqueId;
    experimentObjectiveId: UniqueId;
    name: string;
    type: ExperimentType;
  };
}
