import { combineReducers, createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  setSearchPhraseFilter,
  setStatusFilter,
  setRoleFilter,
  setSortBy,
  resetFilters
} from '@infrastructure/store/userManagement/userManagementActions';
import {
  UserRoleFilterValue,
  UserSortValue,
  UserStatusFilterValue
} from '@infrastructure/store/userManagement/types/filters';

const searchPhraseInitial: string = '';

const searchPhraseFilter = createReducer(searchPhraseInitial, (qb) => {
  qb.addCase(setSearchPhraseFilter, (state, { payload }) => {
    return payload;
  });
  qb.addCase(resetFilters, () => {
    return searchPhraseInitial;
  });
  qb.addCase(clearAll, () => {
    return searchPhraseInitial;
  });
});

const statusInitial = UserStatusFilterValue.ALL;

const statusFilter = createReducer(statusInitial, (qb) => {
  qb.addCase(setStatusFilter, (state, { payload }) => {
    return payload;
  });
  qb.addCase(resetFilters, () => {
    return statusInitial;
  });
  qb.addCase(clearAll, () => {
    return statusInitial;
  });
});

const roleInitial = UserRoleFilterValue.ALL;

const roleFilter = createReducer(roleInitial, (qb) => {
  qb.addCase(setRoleFilter, (state, { payload }) => {
    return payload;
  });
  qb.addCase(resetFilters, () => {
    return roleInitial;
  });
  qb.addCase(clearAll, () => {
    return roleInitial;
  });
});

const sortByInitial = UserSortValue.NAME_ASC;

const sortByReducer = createReducer(sortByInitial, (qb) => {
  qb.addCase(setSortBy, (state, { payload }) => {
    return payload;
  });
  qb.addCase(resetFilters, () => {
    return sortByInitial;
  });
  qb.addCase(clearAll, () => {
    return sortByInitial;
  });
});

export const filterReducer = combineReducers({
  searchPhrase: searchPhraseFilter,
  status: statusFilter,
  role: roleFilter,
  sortBy: sortByReducer
});
