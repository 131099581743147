import React from 'react';

import { FormComponent } from '@ui/hooks/form';
import { ExpObjectiveConfigParams } from '@domain/models/experimentDetails/ExpObjectiveConfigParams';

import { ExpObjectiveSelect } from '@pages/experimentDetails/components/summary/config/objective/ExpObjectiveSelect';
import { NewUsersInput } from '@pages/experimentDetails/components/summary/config/objective/inputs/NewUsersInput';
import { AdjustablePercentInput } from '@pages/experimentDetails/components/summary/config/objective/inputs/AdjustablePercentInput';
import { UsersAllocationInput } from '@pages/experimentDetails/components/summary/config/objective/inputs/UsersAllocationInput';

import styles from '../../../../ExperimentDetails.module.scss';

type Props = Pick<FormComponent<ExpObjectiveConfigParams>, 'control' | 'register' | 'errors'> & {
  regionName: string;
  usersAllocationPercent: number;
  handleSetObjective: (e) => void;
};

export function ExpObjectiveConfigEditor({ control, register, errors, regionName, handleSetObjective }: Props) {
  return (
    <div>
      <ExpObjectiveSelect handleSet={handleSetObjective} />
      <ul className={styles.editorList}>
        <li>
          Decisioning Region:<span>{regionName}</span>
        </li>
        <li>
          <NewUsersInput register={register} errors={errors} />
        </li>
        <li>
          <AdjustablePercentInput register={register} errors={errors} />
        </li>
        <li>
          <UsersAllocationInput control={control} />
        </li>
      </ul>
    </div>
  );
}
