import React from 'react';
import { ModalWrapper } from 'crazy-ui-next';

import { UpdateWinnerConfigParams } from '@domain/models/winnerConfig/WinnerConfigFormParams';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';
import { UniqueId } from '@domain/types';
import { GamePlatform } from '@domain/enums/GamePlatform';
import styles from '@pages/winnerConfigModal/WinnerConfigModal.module.scss';
import { FormContainer } from '@pages/winnerConfigModal/form/FormContainer';

type Props = {
  objective: ExperimentObjectiveDto;
  experimentId: UniqueId;
  handleCloseModal: () => void;
  handleApplyWinner: (form: UpdateWinnerConfigParams) => void;
  handleEditWinner: (form: UpdateWinnerConfigParams) => void;
  platform: GamePlatform;
  isLoading: boolean;
};

export function WinnerStatusModal({
  handleApplyWinner,
  handleCloseModal,
  handleEditWinner,
  objective,
  experimentId,
  platform,
  isLoading
}: Props) {
  return (
    <ModalWrapper handleModal={handleCloseModal} className={styles.modalWrapper}>
      <FormContainer
        handleCloseModal={handleCloseModal}
        handleApplyConfig={handleApplyWinner}
        handleEditWinner={handleEditWinner}
        objective={objective}
        experimentId={experimentId}
        platform={platform}
        isLoading={isLoading}
      />
    </ModalWrapper>
  );
}
