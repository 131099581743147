import React from 'react';

import { DateConfigParams } from '@domain/models/createExperiment/DateConfigParams';
import { NextButton } from '@pages/createExperiment/atoms/NextButton/NextButton';
import { StartDateInput } from '@pages/createExperiment/dateConfig/inputs/StartDateInput';
import { MinInstallsDateInput } from '@pages/createExperiment/dateConfig/inputs/MinInstallsDateInput';
import { DeadlineDateInput } from '@pages/createExperiment/dateConfig/inputs/DeadlineDateInput';
import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/dateConfig/DateConfig.module.scss';

export function DateConfig({ control, handleSubmit }: Omit<FormComponent<DateConfigParams>, 'errors'>) {
  return (
    <form onSubmit={handleSubmit} className={styles.datesConfig}>
      <StartDateInput control={control} />
      <MinInstallsDateInput control={control} />
      <DeadlineDateInput control={control} />

      <NextButton />
    </form>
  );
}
