import React from 'react';

import { ExternalRouting } from '@infrastructure/routing/external';
import { ExternalLink } from '@components/layout/ExternalLink';

import styles from '@pages/experimentDetails/atoms/labels/Labels.module.scss';

type Props = {
  firebaseId: string;
  firebaseName: string;
};

export function FirebaseId({ firebaseId, firebaseName }: Props) {
  return (
    <div className={styles.firebase}>
      <div className={styles.title}>Firebase ID:</div>
      <div className={styles.value}>
        <ExternalLink href={ExternalRouting.getFirebaseProject(firebaseId)} labelText={firebaseName} />
      </div>
    </div>
  );
}
