import React from 'react';
import cn from 'classnames';

import { RequestStage } from '@infrastructure/store/types/actions';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { SkeletonRow } from '@pages/experimentVariables/table/SkeletonRow';
import { Header } from '@pages/experimentVariables/table/Header';
import { Row } from '@pages/experimentVariables/table/Row';

import baseStyles from '@components/layout/table/Table.module.scss';
import styles from './VariableTable.module.scss';

type Props = {
  list: ExperimentVariableDto[];
  status: RequestStage;
};

export function Table({ list, status }: Props | any) {
  const isFetching = status === RequestStage.REQUEST;
  const rows = list.map((variable) => <Row key={variable.id} variable={variable} />);

  return (
    <table id="variable-list" className={cn(baseStyles.table, styles.table)}>
      <thead>
        <Header />
      </thead>
      <tbody>
        {rows}
        {isFetching && (
          <>
            <SkeletonRow />
            <SkeletonRow />
            <SkeletonRow />
          </>
        )}
      </tbody>
    </table>
  );
}
