import React, { useCallback } from 'react';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { removeConfig } from '@infrastructure/store/createExperiment/createExperimentActions';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { CONFIG_GROUP_NAME } from '@domain/enums/ConfigGroupName';
import { THead } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/atoms/THead';
import { TBody } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/atoms/TBody';
import { Summary } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/Summary';

import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';

import styles from './ABFormSummary.module.scss';

type Props = {
  region: string;
  isObjectiveConfigStep: boolean;
};

export function ABFormSummary({ region, isObjectiveConfigStep }: Props) {
  const dispatch = useDispatch();

  const configList = useSelector(createExperimentSelectors.getConfigEntriesByRegion(region));
  const variables = useSelector(configSelectors.getVariableList);
  const controlGroup = configList.find((config) => config.name === CONFIG_GROUP_NAME.CONTROL);

  const handleRemoveConfig = useCallback(() => {
    dispatch(removeConfig(region));
  }, [dispatch, region]);

  if (!controlGroup) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <button type="button" onClick={handleRemoveConfig} disabled={!isObjectiveConfigStep}>
        Region: {region}
      </button>
      <Summary step={CreateExperimentForm.AB_OBJECTIVE_CONFIG} region={region} />
      <table className={styles.table}>
        <THead controlGroup={controlGroup} variables={variables} />
        <TBody configList={configList} region={region} />
      </table>
    </div>
  );
}
