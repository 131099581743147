import React from 'react';
import { DropdownEvent, DropdownInput, DropdownOption } from 'crazy-ui-next';

import { ExpTypeFilterValue } from '@infrastructure/store/experimentList/types/filters';
import { ReactComponent as StackLineSvg } from '@assets/svg/stack-line.svg';

const options: DropdownOption<ExpTypeFilterValue>[] = [
  { value: ExpTypeFilterValue.ALL, label: 'All' },
  { value: ExpTypeFilterValue.AB_TESTING, label: 'AB Test' },
  { value: ExpTypeFilterValue.AB_OLD_USERS_TESTING, label: 'AB Old Test' },
  { value: ExpTypeFilterValue.GLD_TEST, label: 'GLD Test' }
];

type Props = {
  selected: ExpTypeFilterValue;
  onChange: (e: DropdownEvent<ExpTypeFilterValue>) => void;
};

export function TypeFilter({ onChange, selected }: Props) {
  return (
    <DropdownInput
      name="experimentType"
      inputIcon={<StackLineSvg />}
      value={selected}
      options={options}
      handleChange={onChange}
      labelText="Test Type"
    />
  );
}
