import { combineReducers, createReducer } from '@reduxjs/toolkit';

import {
  fetchExperiment,
  fetchExperimentIterations,
  setIterationFilters,
  setSelectedIteration,
  fetchIterationResult,
  setCacheIterationResult,
  toggleMetricKey,
  clearAll,
  toggleSelectedRow,
  pushRequestAnalysis
} from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import { IterationDto } from '@domain/models/iteration/IterationDto';
import { IterationRequestParams } from '@domain/models/iteration/IterationRequestParams';
import { IterationAvailableMeta } from '@domain/models/iteration/IterationAvailableMeta';
import { ExperimentMapper } from '@app/mappers/ExperimentMapper';
import { UniqueId } from '@domain/types';
import { IterationMapper } from '@app/mappers/IterationMapper';
import { IterationResultDto } from '@domain/models/iteration/IterationResultDto';

import { metricDefaultKeys as metricKeysInitial } from '@pages/experimentDetails/components/stats/components/result/components/filters/components/toggleColumns/utils/metricKeys';

const iterationsInitial: Record<UniqueId, IterationDto[]> = {};

const iterationsListReducer = createReducer(iterationsInitial, (qb) => {
  qb.addCase(fetchExperimentIterations.success, (state, { payload }) => {
    return IterationMapper.mapByObjectiveId(payload);
  });
  qb.addCase(clearAll, () => {
    return iterationsInitial;
  });
});

const iterationFiltersInitial: IterationRequestParams = IterationRequestParams.getInitial();

const iterationFiltersReducer = createReducer(iterationFiltersInitial, (qb) => {
  qb.addCase(fetchExperiment.success, (state, { payload }) => {
    const meta = ExperimentMapper.getAvailableMeta(payload);

    return IterationRequestParams.getFromMeta(meta);
  });
  qb.addCase(setIterationFilters, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return iterationFiltersInitial;
  });
});

const metaInitial = IterationAvailableMeta.getInitial();

const metaReducer = createReducer(metaInitial, (qb) => {
  qb.addCase(fetchExperiment.success, (state, { payload }) => {
    return ExperimentMapper.getAvailableMeta(payload);
  });
  qb.addCase(clearAll, () => {
    return metaInitial;
  });
});

const selectedIdInitial = {} as IterationDto;

const selectedIterationReducer = createReducer(selectedIdInitial, (qb) => {
  qb.addCase(setSelectedIteration, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return selectedIdInitial;
  });
});

const nextCheckDateInitial = 0;

const nextCheckDateReducer = createReducer(nextCheckDateInitial, (qb) => {
  qb.addCase(setSelectedIteration, (state, { payload }) => {
    return payload.nextCheckDate;
  });
  qb.addCase(clearAll, () => {
    return nextCheckDateInitial;
  });
});

const iterationResultInitial: IterationResultDto[] = [];

const iterationResultDataReducer = createReducer(iterationResultInitial, (qb) => {
  qb.addCase(fetchIterationResult.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return iterationResultInitial;
  });
});

const iterationCacheResultInitial: Record<string, IterationResultDto[]> = {};

const iterationCacheResultReducer = createReducer(iterationCacheResultInitial, (qb) => {
  qb.addCase(setCacheIterationResult, (state, { payload }) => {
    return Object.assign(state, payload);
  });
  qb.addCase(clearAll, () => {
    return iterationCacheResultInitial;
  });
});

export const iterationResultReducer = combineReducers({
  data: iterationResultDataReducer,
  cache: iterationCacheResultReducer,

  status: ReducerFactory.makeStatus(fetchIterationResult, clearAll)
});

const metricKeysReducer = createReducer(metricKeysInitial, (qb) => {
  qb.addCase(toggleMetricKey, (state, { payload }) => {
    if (state.includes(payload)) {
      return state.filter((item) => item !== payload);
    }

    return [...state, payload];
  });
  qb.addCase(clearAll, () => {
    return metricKeysInitial;
  });
});

const selectedRowsInitial: string[] = [];

const selectedRowsReducer = createReducer(selectedRowsInitial, (qb) => {
  qb.addCase(fetchIterationResult.success, (state, { payload }) => {
    return payload.map((result) => result.group);
  });
  qb.addCase(toggleSelectedRow, (state, { payload }) => {
    if (state.includes(payload)) {
      return state.filter((item) => item !== payload);
    }

    return [...state, payload];
  });
  qb.addCase(clearAll, () => {
    return selectedRowsInitial;
  });
});

export const iterationsReducer = combineReducers({
  data: iterationsListReducer,
  filters: iterationFiltersReducer,
  meta: metaReducer,
  selected: selectedIterationReducer,
  nextCheckDate: nextCheckDateReducer,
  result: iterationResultReducer,
  metricKeys: metricKeysReducer,
  selectedRows: selectedRowsReducer,

  status: ReducerFactory.makeStatus(fetchExperimentIterations, clearAll),
  requestAnalysisStatus: ReducerFactory.makeStatus(pushRequestAnalysis, clearAll)
});
