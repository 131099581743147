type RoutePath = string;
type RouteArg = string | number;
type ToParam<T extends string> = { [k in T]?: string };

// TODO: Figure out how to generate Params type based on Routing.method() arguments
export namespace RouteParams {
  export type GetEditUser = ToParam<'userId'>;
  export type GetExperiment = ToParam<'experimentId'>;
  export type GetCloneExperiment = ToParam<'experimentId'>;
  export type GetEditExpVariable = ToParam<'varId'>;
}

export class Routing {
  static getAny = (): RoutePath => '/*';
  static getRoot = (): RoutePath => '/';

  static getLogin = (): RoutePath => '/login';
  static getExperimentList = (): RoutePath => '/experiment/list';
  static getCreateExperiment = (): RoutePath => '/experiment/create';

  static getAppliedConfigs = (): RoutePath => `/experiment/applied-configs`;
  /* prettier-ignore */
  static getCloneExperiment = (experimentId?: RouteArg): RoutePath => `/experiment/create?clone=${experimentId || ':experimentId'}`;
  /* prettier-ignore */
  static getExperiment = (experimentId?: RouteArg): RoutePath => `/experiment/view/${experimentId || ':experimentId'}`;
  static getUsers = (): RoutePath => '/users';
  static getCreateUser = (): RoutePath => '/users/create';
  static getEditUser = (userId?: RouteArg): RoutePath => `/users/${userId || ':userId'}`;

  static getSystemConfig = (): RoutePath => `/config`;

  static getExpVariables = (): RoutePath => `/config/variables`;
  static getCreateExpVariable = (): RoutePath => `/config/variables/create`;
  static getEditExpVariable = (varId?: RouteArg): RoutePath => `/config/variables/${varId || ':varId'}`;

  static getNotFound = (): RoutePath => `/error/not-found`;
  static getPermissionDenied = (): RoutePath => `/error/permission-denied`;
}
