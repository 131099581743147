import React from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';

import styles from '../EditableConfigModal.module.scss';

type Props = {
  handleCloseModal: () => void;
};

export function FormControls({ handleCloseModal }: Props) {
  return (
    <div className={styles.controls}>
      <Button variant={ButtonVariant.SECONDARY} onClick={handleCloseModal}>
        Cancel
      </Button>
      <Button id="submit-config-value-btn" type="submit">
        Save
      </Button>
    </div>
  );
}
