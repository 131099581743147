import React, { useState, useEffect, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';

import grayPlaceholder from '@assets/images/gray_placeholder.png';
import { Link } from '@domain/types';
import { EnvConfig } from '@app/config';

type Props = {
  src?: Link;
  alt: string;
  className?: string;
};

export function Image({ src, alt, className }: Props) {
  const isValid = typeof src === 'string' && src.startsWith('http');

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '200px',
    triggerOnce: true
  });

  const [isError, handleError] = useState(!isValid);
  const handleFallback = useCallback(() => handleError(true), []);

  useEffect(() => {
    if (isValid) {
      handleError(false);
    }
  }, [src, isValid]);

  const normalizedSrc = isError ? grayPlaceholder : src;

  if (EnvConfig.USE_LAZY_LOAD && !inView) {
    return <img ref={ref} className={className} alt={alt} />;
  }

  return <img ref={ref} className={className} src={normalizedSrc} alt={alt} onError={handleFallback} loading="lazy" />;
}
