import { Dto } from '@domain/models/Dto';
import { GamePlatform } from '@domain/enums/GamePlatform';
import { BundleId, Link, UniqueId } from '@domain/types';
import { BusinessLine } from '@domain/enums/BusinessLine';

export class GameApplicationDto extends Dto {
  id: UniqueId;
  gameName: string;
  bundleId: BundleId;
  firebaseProjectName: string;
  firebaseProjectId: string;
  storeVersion: string;
  businessUnit: BusinessLine;
  platform: GamePlatform;
  iconUrl: Link;
}
