import React, { useRef, useState, useEffect } from 'react';
import { VictoryChart, VictoryBar, VictoryAxis } from 'victory';

import styles from '../lineChart/LineChart.module.scss';
import { axisStyle, config } from './utils/config';
import ChartLegend from '../chartLegend/ChartLegend';
import { ChartFullscreenIcon } from '../chartFullscreenIcon/ChartFullscreenIcon';

const { sizeX, sizeY, padding } = config;

export default function BarChart({
  chartData,
  title,
  domain,
  accentColor,
  tickFormat,
  sizeModifier = 2,
  handleFullscreen,
  fullscreen,
  autoHeight = false
}) {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  if (!chartData || !chartData.data) {
    return null;
  }

  const { data } = chartData;

  return (
    <div ref={ref} className={styles.chartWrapper} onClick={!fullscreen ? handleFullscreen : null}>
      {!fullscreen && handleFullscreen && <ChartFullscreenIcon />}
      <ChartLegend title={title} />
      <VictoryChart
        width={sizeX * sizeModifier}
        height={autoHeight ? height : sizeY * sizeModifier}
        padding={padding}
        domainPadding={{ x: 15 * sizeModifier }}
        singleQuadrantDomainPadding={true}
        domain={domain}
      >
        <VictoryAxis name="valueAxis" dependentAxis fixLabelOverlap={true} style={axisStyle} tickFormat={tickFormat} />
        <VictoryAxis name="groupAxis" style={axisStyle} />
        <VictoryBar
          style={{
            data: {
              fill: accentColor
            }
          }}
          barRatio={data.length > 12 ? 0.85 : 1.2}
          barWidth={data.length <= 12 && 22 * sizeModifier}
          data={data}
        />
      </VictoryChart>
    </div>
  );
}
