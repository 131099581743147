import { Dto } from '@domain/models/Dto';
import { GamePlatform } from '@domain/enums/GamePlatform';

export class GameStatsDto extends Dto {
  public country: string;
  public platform: GamePlatform;
  public revPerUser: number;
  public revenue: number;
  public usersPerDay: number;
}
