import React, { useEffect } from 'react';
import { plainToInstance } from 'class-transformer';

import { initDatesConfig, pushDatesConfigForm } from '@infrastructure/store/createExperiment/createExperimentActions';
import { DateConfigParams } from '@domain/models/createExperiment/DateConfigParams';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { DateConfig } from '@pages/createExperiment/dateConfig/DateConfig';
import { Summary } from '@pages/createExperiment/atoms/Summary/Summary';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { useForm } from '@ui/hooks/form';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';

type Props = {
  isCompleted?: boolean;
};

export function DateConfigContainer({ isCompleted }: Props) {
  const dispatch = useDispatch();
  const { dateConfig } = useSelector(createExperimentSelectors.getForm);

  const { register, control, handleSubmit, reset } = useForm<DateConfigParams>({
    schema: DateConfigParams,
    defaultValues: dateConfig
  });

  useEffect(() => {
    dispatch(initDatesConfig());
  }, [dispatch]);

  useEffect(() => {
    !isCompleted && reset(dateConfig);
  }, [reset, dateConfig, isCompleted]);

  const handleNext = handleSubmit((body) => {
    const form = plainToInstance(DateConfigParams, body);
    dispatch(pushDatesConfigForm(form));
  });

  if (isCompleted) {
    return <Summary step={CreateExperimentForm.DATE_CONFIG} />;
  }

  return <DateConfig register={register} control={control} handleSubmit={handleNext} />;
}
