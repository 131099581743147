import React, { useCallback } from 'react';

import {
  deleteExperimentWinner,
  updateExperimentWinner,
  pushRestoreObjective,
  pushStopObjective,
  applyExperimentWinner
} from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { RequestStage } from '@infrastructure/store/types/actions';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { ExperimentObjectiveState } from '@domain/enums/ExperimentObjectiveState';
import { UniqueId } from '@domain/types';
import { GamePlatform } from '@domain/enums/GamePlatform';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';
import { WinnerStatusControls } from '@pages/experimentDetails/components/winnerStatus/atoms/WinnerStatusControls';
import { WinnerStatusModal } from '@pages/experimentDetails/components/winnerStatus/atoms/WinnerStatusModal';
import useModal from '@ui/hooks/useModal';
import { useDispatch, useSelector } from '@ui/hooks/redux';

type Props = {
  state: ExperimentObjectiveState;
  objective: ExperimentObjectiveDto;
  experimentId: UniqueId;
  platform: GamePlatform;
};

export function WinnerStatusActions({ state, objective, experimentId, platform }: Props) {
  const dispatch = useDispatch();
  const { showModal, handleOpenModal, handleCloseModal } = useModal();

  const status = useSelector(experimentDetailsSelectors.getWinnerStatus);
  const isLoading = status === RequestStage.REQUEST;

  const { winnerStatus, id: objectiveId } = objective;

  const handleApplyWinner = useCallback(
    (form) => {
      dispatch(applyExperimentWinner.trigger(form));
    },
    [dispatch]
  );

  const handleEditWinner = useCallback(
    (form) => {
      dispatch(updateExperimentWinner.trigger(form));
    },
    [dispatch]
  );

  const handleCancelWinner = useCallback(() => {
    dispatch(deleteExperimentWinner.trigger({ experimentId, objectiveId }));
  }, [dispatch, experimentId, objectiveId]);

  const handleStopObjective = useCallback(() => {
    dispatch(pushStopObjective.trigger({ experimentId, objectiveId }));
  }, [dispatch, experimentId, objectiveId]);

  const handleRestoreObjective = useCallback(() => {
    dispatch(pushRestoreObjective.trigger({ experimentId, objectiveId }));
  }, [dispatch, experimentId, objectiveId]);

  return (
    <>
      {showModal && (
        <WinnerStatusModal
          handleCloseModal={handleCloseModal}
          handleApplyWinner={handleApplyWinner}
          handleEditWinner={handleEditWinner}
          objective={objective}
          experimentId={experimentId}
          platform={platform}
          isLoading={isLoading}
        />
      )}
      <WinnerStatusControls
        winnerStatus={winnerStatus}
        state={state}
        handleOpenModal={handleOpenModal}
        handleRestoreObjective={handleRestoreObjective}
        handleCancelWinner={handleCancelWinner}
        handleStopObjective={handleStopObjective}
      />
    </>
  );
}
