import React from 'react';
import { CheckboxInput } from 'crazy-ui-next';
import { Tooltip } from 'crazy-ui';

import { ExpDefinitionParams } from '@domain/models/experimentDetails/ExpDefinitionParams';
import { FormComponent, getInputProps } from '@ui/hooks/form';
import { ReactComponent as InfoSvg } from '@assets/svg/info.svg';

import styles from '@pages/experimentDetails/ExperimentDetails.module.scss';

type Props = Pick<FormComponent<ExpDefinitionParams>, 'register' | 'errors'>;

export function AutomaticModeInput({ register, errors }: Props) {
  return (
    <>
      <div className={styles.editorInputLabel}>
        <p>Automatic mode:</p>
      </div>
      <CheckboxInput {...getInputProps<ExpDefinitionParams>('automaticMode', register, errors)} />
      <Tooltip text="The test decisions are automatically made based on Bayesian results">
        <InfoSvg className={styles.hint} />
      </Tooltip>
    </>
  );
}
