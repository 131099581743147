import { Service } from 'typedi';
import { cloneDeep, isArray } from 'lodash-es';

import { ReduxRepo } from '@infrastructure/repositories/ReduxRepo';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { CreateExperimentUseCase } from '@domain/useCases/CreateExperimentUseCase';
import { ExperimentApi } from '@infrastructure/api/ExperimentApi';
import { GameStatsDto } from '@domain/models/experiment/GameStatsDto';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { RegionService } from '@app/services/RegionService';
import { GameService } from '@app/services/GameService';
import { ConfigService } from '@app/services/ConfigService';
import { ControlGroupByCountry } from '@domain/models/ControlGroup';
import { ExperimentFormMapper } from '@app/mappers/experiment/ExperimentFormMapper';
import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import { GameInstallsStatsDto } from '@domain/models/game/GameInstallsStatsDto';
import { RegionDto } from '@domain/models/RegionDto';
import { Routing } from '@infrastructure/routing';
import { HistoryService } from '@infrastructure/browser/HistoryService';
import { ExperimentFormState } from '@infrastructure/store/createExperiment/createExperimentReducer';
import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { OverlappedConfigDto } from '@domain/models/experiment/OverlappedConfigDto';
import { ConfigMapper } from '@app/mappers/ConfigMapper';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { ExperimentRegion, ExperimentRegionName } from '@domain/enums/ExperimentRegion';
import { Notification } from '@infrastructure/api/notifications';
import { RegionMapper } from '@app/mappers/experiment/RegionMapper';
import { ValidationConfigSummary } from '@domain/enums/ValidationConfigSummary';
import { GoalConfigParams } from '@domain/models/createExperiment/GoalConfigParams';
import { DateConfigParams } from '@domain/models/createExperiment/DateConfigParams';
import { RecommendationReqParams } from '@domain/models/experiment/RecommendationReqParams';
import { GLDParamDto } from '@domain/enums/GLDParamDto';
import { UserPropertiesDto } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';

@Service()
export class CreateExperimentService implements CreateExperimentUseCase {
  constructor(
    private readonly reduxRepo: ReduxRepo,
    private readonly experimentApi: ExperimentApi,
    private readonly regionService: RegionService,
    private readonly gameService: GameService,
    private readonly configService: ConfigService,
    private readonly historyService: HistoryService
  ) {}

  async fetchRegions() {
    const form = this.reduxRepo.findBy(createExperimentSelectors.getForm);
    const { gameId } = form[CreateExperimentForm.BASIC_INFO];

    const [defaultRegions, recommendedRegions] = await Promise.all([
      this.regionService.getDefaultRegions(),
      this.regionService.getRecommendedRegions(gameId)
    ]);

    return { defaultRegions, recommendedRegions };
  }

  async fetchGameStats(): Promise<GameStatsDto[]> {
    const form = this.reduxRepo.findBy(createExperimentSelectors.getForm);
    const { gameId } = form[CreateExperimentForm.BASIC_INFO];

    return this.gameService.fetchGameStats(gameId);
  }

  async fetchGameInstallsStats(regionData: RegionDto): Promise<{ region: string; stats: GameInstallsStatsDto }> {
    const form = this.reduxRepo.findBy(createExperimentSelectors.getForm);
    const { gameId } = form[CreateExperimentForm.BASIC_INFO];

    const stats = await this.gameService.fetchInstallsByRegion(gameId, regionData.id);

    return { region: regionData.name, stats };
  }

  async fetchControlGroups(): Promise<ControlGroupByCountry> {
    const form = this.reduxRepo.findBy(createExperimentSelectors.getForm);
    const { gameId } = form[CreateExperimentForm.BASIC_INFO];

    return this.configService.getControlGroups(gameId);
  }

  isValidGroupCount(form: ObjectiveConfigParams) {
    const { controlGroup, input, params } = form;
    const mergedValuesByKeys: { [key: string]: string[] } = {};

    // merge all control group session values
    params.forEach((key) => {
      controlGroup.forEach((session) => {
        mergedValuesByKeys[key] = mergedValuesByKeys[key]
          ? mergedValuesByKeys[key].concat(session[key])
          : [session[key]];
      });
    });

    // merge all sections session values
    const sections = input.map(({ section }) => {
      const mergedSection = {};

      section.forEach((session) => {
        params.forEach((key) => {
          const sessionValue = session[key];
          let values: string[];

          if (isArray(sessionValue)) {
            values = sessionValue.map(({ value }) => value);
          } else {
            values = [sessionValue];
          }

          mergedSection[key] = mergedSection[key] ? mergedSection[key].concat(values) : values;
        });
      });

      return mergedSection;
    });

    // merge control and section values
    params.forEach((key) => {
      const mergedSections = {};

      sections.forEach((section) => {
        const values = section[key];
        mergedSections[key] = mergedSections[key] ? mergedSections[key].concat(values) : values;
      });
      mergedValuesByKeys[key] = mergedValuesByKeys[key].concat(mergedSections[key]);
    });

    let isValid = false;

    // if some point value is different from others config is valid
    params.forEach((key) => {
      const variableValues = mergedValuesByKeys[key];

      if (!variableValues.every((value) => value === variableValues[0])) {
        isValid = true;
      }
    });

    return isValid;
  }

  async validateABConfig(body: ObjectiveConfigParams): Promise<GenericConfigEntry[]> {
    if (!this.isValidGroupCount(body)) {
      throw new Error('Experiment should have at least 2 groups');
    }

    const experimentVariables = this.reduxRepo.findBy(configSelectors.getVariableList);
    const form = this.reduxRepo.findBy(createExperimentSelectors.getForm);
    const { experimentType } = form[CreateExperimentForm.BASIC_INFO];
    const payload = ExperimentFormMapper.mapObjectiveFormToValidateConfigPayload(
      body,
      experimentVariables,
      experimentType
    );

    const { configList, validationSummary, message } = await this.experimentApi.validateConfig(payload);

    if (validationSummary === ValidationConfigSummary.CONFIG_REJECTED) {
      throw new Error(message);
    }

    return ConfigMapper.sortByName(configList);
  }

  async validateGLDConfig(body: GLDConfigParams) {
    const { cloneControlGroup, config } = body;
    const minWithClone = 3;
    const minWithoutClone = 2;
    const min = cloneControlGroup ? minWithClone : minWithoutClone;

    const isValid = config.length >= min;

    if (!isValid) {
      throw new Error(`Experiment should have at least ${min} groups`);
    }
  }

  async cloneExperiment(): Promise<ExperimentFormState | null> {
    const { clone: experimentId } = this.historyService.getSearchParams();

    if (!experimentId) {
      return null;
    }

    const experiment = await this.experimentApi.getExperiment(Number(experimentId));
    const experimentVariables = this.reduxRepo.findBy(configSelectors.getVariableList);
    const userPropertyOperators = this.reduxRepo.findBy(configSelectors.getUserPropertyOperators);

    return ExperimentFormMapper.mapExperimentDtoToForm(experiment, experimentVariables, userPropertyOperators);
  }

  async createExperiment(): Promise<ExperimentDto> {
    const experimentVariables = this.reduxRepo.findBy(configSelectors.getVariableList);
    const configList = this.reduxRepo.findBy(createExperimentSelectors.getConfigList);
    const form = this.reduxRepo.findBy(createExperimentSelectors.getForm);
    const { defaultRegions } = this.reduxRepo.findBy(createExperimentSelectors.getRegions);

    const payload = ExperimentFormMapper.mapFormToNewExperiment(form, configList, defaultRegions, experimentVariables);

    return this.experimentApi.createExperiment(payload);
  }

  async validateOverlappingExperiments(): Promise<OverlappedConfigDto[]> {
    const form = this.reduxRepo.findBy(createExperimentSelectors.getForm);

    const { gameId, experimentType } = form.basicInfo;
    const { regions: regionOptions, versions } = form.targetConfig;
    const regions = regionOptions.map((option) => option.value);

    const appVersions = versions.map(({ value }) => value);

    return this.experimentApi.validateOverlappingExperiment(gameId, regions, appVersions, experimentType);
  }

  generateTargetConfig(): { form: TargetConfigParams; isRecommended: boolean } {
    const { basicInfo, targetConfig } = this.reduxRepo.findBy(createExperimentSelectors.getForm);
    const isClone = this.reduxRepo.findBy(createExperimentSelectors.isClone);
    const gameStats = this.reduxRepo.findBy(createExperimentSelectors.getGameStats);
    const { experimentType, gameId, isRecommendedProfile } = basicInfo;

    const userProperties = this.reduxRepo.findBy(configSelectors.getUserProperties);
    const operators = this.reduxRepo.findBy(configSelectors.getUserPropertyOperators);
    const game = this.reduxRepo.findBy(configSelectors.getGameById(gameId));
    const minUsersPerGroup = this.reduxRepo.findBy(configSelectors.getMinUsersPerGroup);

    const isGLD = experimentType === ExperimentType.GLD_TEST;

    if (isClone && !isRecommendedProfile) {
      return { form: targetConfig, isRecommended: false };
    }

    const form = TargetConfigParams.ofInitial();
    const payload = { form, isRecommended: false };

    payload.form.userProperties = ExperimentFormMapper.mapUserPropertiesToForm(userProperties, operators);

    if (game?.storeVersion) {
      payload.form.setVersion(game.storeVersion);
    }

    if (isGLD) {
      const WWRegionOption = { value: ExperimentRegionName.WW, label: ExperimentRegionName.WW };

      payload.form.pushRegions(WWRegionOption);

      return payload;
    }

    if (!isRecommendedProfile) {
      return payload;
    }

    // recommended means we have users at least for 2 groups
    const recommendedGameStats = gameStats.filter(({ usersPerDay }) => usersPerDay >= minUsersPerGroup * 2);

    if (!recommendedGameStats.length) {
      Notification.warning('Not enough users to recommend profiles');
      return payload;
    }
    payload.isRecommended = true;

    if (recommendedGameStats.length === 1 && recommendedGameStats[0].country === ExperimentRegionName.US) {
      return payload;
    }

    const recommendedRegionNames = recommendedGameStats.map(({ country }) => country);
    payload.form.setRegionType(ExperimentRegion.WW);
    payload.form.setRegions(recommendedRegionNames);

    return payload;
  }

  generateGoalConfig(): { form: GoalConfigParams; isRecommended: boolean } {
    const { basicInfo } = this.reduxRepo.findBy(createExperimentSelectors.getForm);
    const { isRecommendedProfile } = basicInfo;

    const form = GoalConfigParams.ofInitial();
    return { form, isRecommended: isRecommendedProfile };
  }

  generateDatesConfig(): { form: DateConfigParams; isRecommended: boolean } {
    const { basicInfo, goalConfig } = this.reduxRepo.findBy(createExperimentSelectors.getForm);
    const { isRecommendedProfile } = basicInfo;
    const { arpu } = goalConfig;

    const form = DateConfigParams.getDefault(arpu);

    return { form, isRecommended: isRecommendedProfile };
  }

  async generateABObjectiveConfig(): Promise<{
    configList: Record<string, GenericConfigEntry[]>;
    form: Record<string, ObjectiveConfigParams>;
    isRecommended: boolean;
  }> {
    const { basicInfo, targetConfig, objectiveABConfig } = this.reduxRepo.findBy(createExperimentSelectors.getForm);
    const controlGroups = this.reduxRepo.findBy(createExperimentSelectors.getControlGroups);
    const { defaultRegions } = this.reduxRepo.findBy(createExperimentSelectors.getRegions);
    const minUsersPerGroup = this.reduxRepo.findBy(configSelectors.getMinUsersPerGroup);
    const gameStats = this.reduxRepo.findBy(createExperimentSelectors.getGameStats);
    const experimentVariables = this.reduxRepo.findBy(configSelectors.getVariableList);
    const { isRecommendedProfile, gameId } = basicInfo;
    const { regions } = targetConfig;

    const payload = { configList: {}, form: cloneDeep(objectiveABConfig), isRecommended: false };

    if (!isRecommendedProfile) {
      return payload;
    }

    payload.isRecommended = true;

    const regionNames = regions.map((option) => option.value);

    const regionDtos = RegionMapper.getRegionsByNames(defaultRegions, regionNames);

    for (const regionDto of regionDtos) {
      const controlGroup = controlGroups[regionDto.countryCodes[0]] || controlGroups.Default;
      const gameStatsByRegion = gameStats.find((stats) => stats.country === regionDto.name);
      const numberOfUsers = gameStatsByRegion?.usersPerDay || minUsersPerGroup * 2;
      const regionName = regionDto.name;
      const requestPayload: RecommendationReqParams = {
        gameId,
        regionName,
        numberOfUsers,
        currentProfile: controlGroup,
        requiredNumberOfUsersPerGroup: minUsersPerGroup
      };

      const profile = await this.experimentApi.getRecommendedProfile(requestPayload);
      const configList = ConfigMapper.mapRecommendedProfileToConfigs(profile.recommendedGroups, experimentVariables);

      payload.configList[regionDto.name] = ConfigMapper.sortByName(configList);
      payload.form[regionDto.name] = ObjectiveConfigParams.ofInitial();
    }

    return payload;
  }

  validateObjectiveConfigsLength() {
    const configList = this.reduxRepo.findBy(createExperimentSelectors.getConfigList);

    const maxConfigsLength = 490;
    const configs: GenericConfigEntry[] = [];

    Object.keys(configList).forEach((region) => configs.push(...configList[region]));

    if (configs.length > maxConfigsLength) {
      throw new Error(`Experiment should have maximum ${maxConfigsLength} groups`);
    }
  }

  navigateToMainPage() {
    this.historyService.goTo(Routing.getExperimentList());
  }

  getGLDParams(): Promise<GLDParamDto[] | null> {
    const { basicInfo } = this.reduxRepo.findBy(createExperimentSelectors.getForm);
    const { gameId } = basicInfo;

    return this.configService.getGLDParams(gameId);
  }

  async fetchUserProperties(): Promise<UserPropertiesDto> {
    const { basicInfo } = this.reduxRepo.findBy(createExperimentSelectors.getForm);

    return this.configService.getUserProperties(basicInfo.firebaseProjectId);
  }
}
