import React from 'react';

import { useDispatch } from '@ui/hooks/redux';
import { useForm } from '@ui/hooks/form';
import { LoginRequestDto } from '@domain/models/user/LoginRequestDto';

import { pushLoginByCreds } from '@infrastructure/store/auth/authActions';
import { SignInForm } from './SignInForm';

export function SignInContainer() {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginRequestDto>({ schema: LoginRequestDto });

  const handleFormSubmit = handleSubmit((form) => dispatch(pushLoginByCreds.trigger(form)));

  return <SignInForm handleSubmit={handleFormSubmit} errors={errors} register={register} />;
}
