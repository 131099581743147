import React from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';

import useModal from '@ui/hooks/useModal';
import { UniqueId } from '@domain/types';

import { ReactComponent as EditSvg } from '@assets/svg/edit.svg';
import { ReactComponent as DeleteSvg } from '@assets/svg/delete.svg';

import { WinnerConfigModalContainer } from '@pages/winnerConfigModal/WinnerConfigModalContainer';
import { UpdateWinnerConfigParams } from '@domain/models/winnerConfig/WinnerConfigFormParams';
import { DeleteConfigModal } from '@pages/appliedConfig/table/editConfigCell/DeleteConfigModal';

type Props = {
  handleCancelWinner: () => void;
  experimentId: UniqueId;
  objectiveId: UniqueId;
  handleApplyConfig: (form: UpdateWinnerConfigParams) => void;
  experimentName: string;
  isActiveConfig: boolean;
};

export function EditConfigModalWrapper({
  handleCancelWinner,
  experimentId,
  objectiveId,
  handleApplyConfig,
  experimentName,
  isActiveConfig
}: Props) {
  const {
    showModal: showWinnerModal,
    handleOpenModal: openWinnerModal,
    handleCloseModal: closeWinnerModal
  } = useModal();

  const {
    showModal: showAcceptModal,
    handleOpenModal: openAcceptModal,
    handleCloseModal: closeAcceptModal
  } = useModal();

  return (
    <>
      {showWinnerModal && (
        <WinnerConfigModalContainer
          handleCloseModal={closeWinnerModal}
          experimentId={experimentId}
          objectiveId={objectiveId}
          handleApplyConfig={handleApplyConfig}
        />
      )}

      {showAcceptModal && (
        <DeleteConfigModal
          handleCloseModal={closeAcceptModal}
          handleCancelWinner={handleCancelWinner}
          experimentName={experimentName}
        />
      )}

      <Button
        id="edit-winner-btn"
        variant={ButtonVariant.TERTIARY}
        onClick={openWinnerModal}
        icon={<EditSvg />}
        disabled={!isActiveConfig}
      />
      <Button
        id="remove-winner-btn"
        variant={ButtonVariant.TERTIARY}
        onClick={openAcceptModal}
        icon={<DeleteSvg />}
        disabled={!isActiveConfig}
      />
    </>
  );
}
