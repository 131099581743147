import React from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';

import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import { Platform } from '@pages/experimentDetails/atoms/labels/Platform';
import { Type } from '@pages/experimentDetails/atoms/labels/Type';
import { Dates } from '@pages/experimentDetails/atoms/labels/Dates';
import { FirebaseId } from '@pages/experimentDetails/atoms/labels/FirebaseId';
import { CreatedBy } from '@pages/experimentDetails/atoms/labels/CreatedBy';

import { ReactComponent as ArrowsFoldSvg } from '@assets/svg/arrows_fold.svg';
import { ReactComponent as ArrowsSvg } from '@assets/svg/arrows.svg';

import styles from './Summary.module.scss';

type Props = {
  experiment: ExperimentDto;
  isActive: boolean;
  handleToggle: () => void;
  isExpandableSummary: boolean;
};

export function Info({ experiment, isActive, handleToggle, isExpandableSummary }: Props) {
  const { experimentType, startDate, endDate, createdBy, gameApp } = experiment;
  const { firebaseProjectName, platform, firebaseProjectId } = gameApp;

  return (
    <div className={styles.info}>
      <h5>SUMMARY</h5>
      <Platform platform={platform} />
      <Type type={experimentType} />
      <Dates startDate={startDate} endDate={endDate} />
      <FirebaseId firebaseName={firebaseProjectName} firebaseId={firebaseProjectId} />
      <CreatedBy createdBy={createdBy} />
      {isExpandableSummary && (
        <Button name="summary-toggler" variant={ButtonVariant.TERTIARY} onClick={handleToggle}>
          {isActive ? <ArrowsFoldSvg /> : <ArrowsSvg />}
        </Button>
      )}
    </div>
  );
}
