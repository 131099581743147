import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';
import { UpdateWinnerConfigParams } from '@domain/models/winnerConfig/WinnerConfigFormParams';

import { Row } from '@pages/winnerConfigModal/list/Row';
import { Variables } from '@pages/winnerConfigModal/atoms/Variables';

import styles from '@pages/winnerConfigModal/list/List.module.scss';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';

type Props = FormComponent<UpdateWinnerConfigParams> & {
  objective: ExperimentObjectiveDto;
};

export function ListWrapper({ handleSubmit, register, control, errors, objective }: Props) {
  const { fields: winnerGroupFields } = useFieldArray({
    name: 'winners',
    control
  });

  const winners = winnerGroupFields.map((winner, index) => {
    return (
      <Row
        key={`winner_row_${winner.id}`}
        index={index}
        regionCode={winner.regionCode}
        control={control}
        handleSubmit={handleSubmit}
        register={register}
        errors={errors}
        objective={objective}
      />
    );
  });

  const variables = winnerGroupFields.map((winner, index) => (
    <li className={styles.variableRow} key={`variable_row_${winner.id}`}>
      <Controller
        name={`winners.${index}.group`}
        control={control}
        render={({ field: group }) => (
          <Variables selectedGroup={group.value} isHeaderRow={index === 0} objective={objective} />
        )}
      />
    </li>
  ));

  return (
    <div className={styles.wrapper}>
      <ul id="winners-config-list" className={styles.winnersList}>
        {winners}
      </ul>
      <ul id="variables-config-list" className={styles.variablesList}>
        {variables}
      </ul>
    </div>
  );
}
