import React from 'react';
import { Controller } from 'react-hook-form';

import { RangeInput } from '@components/layout/rangeInput/RangeInput';
import { FormComponent } from '@ui/hooks/form';
import { ExpObjectiveConfigParams } from '@domain/models/experimentDetails/ExpObjectiveConfigParams';

import styles from '../../../../../ExperimentDetails.module.scss';

type Props = Pick<FormComponent<ExpObjectiveConfigParams>, 'control'>;

export function UsersAllocationInput({ control }: Props) {
  return (
    <Controller
      name="usersAllocationPercent"
      control={control}
      render={({ field }) => {
        return (
          <div className={styles.summaryPercentWrapper}>
            <p>
              Engaged Users:<span>{field.value}%</span>
            </p>
            <RangeInput
              value={String(field.value)}
              onChange={({ currentTarget }) => field.onChange(currentTarget.value)}
            />
          </div>
        );
      }}
    />
  );
}
