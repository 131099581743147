import { sortBy } from 'lodash-es';

import { DateUtils } from '@app/utils/DateUtils';
import { AppState } from '@infrastructure/store';
import { ACFilter } from '@infrastructure/store/appliedConfig/types/filters';
import { RequestStage } from '@infrastructure/store/types/actions';
import { UniqueId } from '@domain/types';
import { AppliedConfigListReqParams } from '@domain/models/appliedConfig/AppliedConfigListReqParams';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';

const getConfigStatus = (state: AppState) => state.appliedConfig.status;
const getConfigList = (state: AppState) => state.appliedConfig.list;
const getFilters = (state: AppState) => state.appliedConfig.filters;

const getFormatFilters = (state: AppState) => {
  const { searchPhrase, status, type, platform, dates } = getFilters(state);

  let payload: AppliedConfigListReqParams = {
    fromDate: DateUtils.formatToDateISODate(dates.startDate),
    toDate: DateUtils.formatToDateISODate(dates.endDate),
    isActive: ACFilter.mapStatusToPayload(status),
    sort: 'activationDate,desc'
  };

  if (searchPhrase.length) {
    payload.searchPhrase = searchPhrase.trim();
  }

  if (type !== ACFilter.Type.ALL) {
    payload.experimentType = ACFilter.mapTypeToPayload(type);
  }

  if (platform !== ACFilter.Platform.ALL) {
    payload.platform = ACFilter.mapPlatformToPayload(platform);
  }

  return payload;
};

const getGameIdList = (state: AppState) => {
  const list = getConfigList(state);
  const sortedList = sortBy(list, 'game.gameName');
  const onlyIds = sortedList.map((x) => x.game.id);

  return [...new Set(onlyIds)];
};

const getConfigByGameId = (gameId: UniqueId) => (state: AppState) => {
  const list = getConfigList(state);
  return list.find((x) => x.game.id === gameId);
};

const getExperiment = (state: AppState): ExperimentDto => {
  return state.appliedConfig.experiment.data;
};

const getExperimentStatus = (state: AppState): RequestStage => {
  return state.appliedConfig.experiment.status;
};
const getUpdateStatus = (state: AppState): RequestStage => {
  return state.appliedConfig.updateStatus;
};

export const appliedConfigSelectors = {
  getConfigStatus,
  getConfigList,
  getGameIdList,
  getConfigByGameId,
  getFilters,
  getFormatFilters,
  getExperiment,
  getExperimentStatus,
  getUpdateStatus
};
