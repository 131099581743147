import React from 'react';
import { useController } from 'react-hook-form';

import { DateConfigParams } from '@domain/models/createExperiment/DateConfigParams';
import { DatePicker } from '@components/lib/DatePicker/DatePicker';
import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/dateConfig/DateConfig.module.scss';

const name = 'startDateTime';

type Props = Pick<FormComponent<DateConfigParams>, 'control'>;

export function StartDateInput({ control }: Props) {
  const { field } = useController({ control, name });

  return (
    <DatePicker
      showTimeSelect
      labelText="Start Date"
      selected={field.value}
      onChange={field.onChange}
      name={name}
      className={styles.input}
    />
  );
}
