import { Service } from 'typedi';
import { AxiosResponse } from 'axios';
import qs from 'qs';

import { HttpClient } from '@infrastructure/http/HttpClient';

@Service()
export class BackendHttpClient extends HttpClient {
  constructor() {
    super({
      baseURL: '/api',
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    });
  }

  mapResponse(res: AxiosResponse) {
    return res.data.payload || null;
  }
}
