import React, { useCallback } from 'react';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { appliedConfigSelectors } from '@infrastructure/store/appliedConfig/appliedConfigSelectors';
import { setDateFilter } from '@infrastructure/store/appliedConfig/appliedConfigActions';
import { DateRangeEvent, DateRangePicker } from '@components/lib/DateRangePicker/DateRangePicker';

export function DateFilter() {
  const dispatch = useDispatch();
  const { dates } = useSelector(appliedConfigSelectors.getFilters);

  const handleDateRange = useCallback(
    (e: DateRangeEvent) => {
      dispatch(setDateFilter(e));
    },
    [dispatch]
  );

  return (
    <DateRangePicker
      name="appliedDate"
      startDate={dates.startDate}
      endDate={dates.endDate}
      handleChange={handleDateRange}
      labelText="Applied Date"
    />
  );
}
