import React from 'react';

import { ExperimentObjectiveState } from '@domain/enums/ExperimentObjectiveState';
import { WinnerStatus } from '@domain/enums/WinnerStatus';

import styles from '../WinnerStatus.module.scss';

type Props = {
  state: ExperimentObjectiveState;
  winnerStatus: WinnerStatus | null;
};

export function WinnerStatusState({ state, winnerStatus }: Props) {
  if (winnerStatus === WinnerStatus.APPLIED) {
    return <p className={styles.state}>Winner Applied</p>;
  }

  let subStatus = '';

  if (state === ExperimentObjectiveState.STOPPED) {
    subStatus = winnerStatus === WinnerStatus.FOUND ? ': Winner found' : ': Winner not found';
  }

  return (
    <p className={styles.state}>
      {state}
      <span>{subStatus}</span>
    </p>
  );
}
