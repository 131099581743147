import React from 'react';
import { memoize } from 'proxy-memoize';

import { useSelector } from '@ui/hooks/redux';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';

import styles from '../../../../ExperimentDetails.module.scss';

export function ExpDefinitionSummary() {
  const params = useSelector(memoize(experimentDetailsSelectors.getExpDefinitionParams));
  const items = params.getSummary().map(({ title, value }) => (
    <li key={title}>
      {title}:<span>{value}</span>
    </li>
  ));

  return (
    <div>
      <ul className={styles.summaryList}>{items}</ul>
    </div>
  );
}
