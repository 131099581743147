import { UserRoleType } from '@domain/enums/UserRoleType';
import { authSelectors } from '@infrastructure/store/auth/authSelectors';
import { AppState } from '@infrastructure/store';
import { UniqueId } from '@domain/types';
import { ExperimentListReqParams } from '@domain/models/experiment/ExperimentListReqParams';
import { ExperimentState } from '@domain/enums/ExperimentState';
import { ExperimentType } from '@domain/enums/ExperimentType';
import {
  ExpBusinessLineFilterValue,
  ExpPlatformFilterValue,
  ExpStatusFilterValue,
  ExpTypeFilterValue,
  mapExpBusinessLineValueToType,
  mapExpPlatformValueToType,
  mapExpSortByPayloadToParams,
  mapExpStatusValueToType,
  mapExpTypeValueToType
} from '@infrastructure/store/experimentList/types/filters';
import { DateUtils } from '@app/utils/DateUtils';

const getPagination = (state: AppState) => state.experimentList.pagination;
const getExperimentsStatus = (state: AppState) => state.experimentList.status;
const getExperimentsMap = (state: AppState) => state.experimentList.byId;
const getExperimentsIdList = (state: AppState) => state.experimentList.idList;
const getExperiment = (id: UniqueId) => (state: AppState) => state.experimentList.byId[id];

const getFilters = (state: AppState) => state.experimentList.filters;
const getSortBy = (state: AppState) => state.experimentList.filters.sortBy;

const getFormatFilters = (state: AppState) => {
  const { searchPhrase, statuses, type, businessLines, platform, dates, sortBy } = getFilters(state);

  let payload: ExperimentListReqParams = {
    sort: mapExpSortByPayloadToParams(sortBy),
    fromDate: DateUtils.formatToDateISODate(dates.startDate),
    toDate: DateUtils.formatToDateISODate(dates.endDate)
  };

  if (searchPhrase.length) {
    payload.searchPhrase = searchPhrase.trim();
  }

  if (type !== ExpTypeFilterValue.ALL) {
    payload.experimentType = mapExpTypeValueToType(type);
  }

  if (platform !== ExpPlatformFilterValue.ALL) {
    payload.platform = mapExpPlatformValueToType(platform);
  }

  if (businessLines !== ExpBusinessLineFilterValue.ALL) {
    payload.businessLines = mapExpBusinessLineValueToType(businessLines);
  }

  if (statuses.length && !statuses.includes(ExpStatusFilterValue.ALL)) {
    payload.status = statuses.map((value) => mapExpStatusValueToType(value)).flat();
  }

  const currentUserRole = authSelectors.getCurrentRole(state);
  const isQAUser = currentUserRole === UserRoleType.QA;

  if (isQAUser) {
    payload.status = [ExperimentState.IN_DEV];
    payload.experimentType = ExperimentType.GLD_TEST;
  }

  return payload;
};

export const experimentListSelectors = {
  getPagination,
  getExperimentsStatus,
  getExperimentsMap,
  getExperimentsIdList,
  getExperiment,
  getFilters,
  getFormatFilters,
  getSortBy
};
