import { ExperimentPeriodicity } from '@domain/enums/ExperimentPeriodicity';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { GamePlatform } from '@domain/enums/GamePlatform';

export const REACT_ROOT = 'root';

export const LIST_PER_PAGE = 24;

export const MAX_REP_ARPU_VALUES = 5;

export const REQUEST = 'request';
export const SUCCESS = 'success';
export const FAILURE = 'failure';
export const NONE = 'none';

export const E_DAU_PREFIX = '_DAU';

/* EXPERIMENT STATES */
export const E_STATUS_SCHEDULED = 'SCHEDULED';
export const E_STATUS_RUNNING = 'RUNNING';
export const E_STATUS_CANCELED = 'CANCELED';
export const E_STATUS_ERROR = 'ERROR';
export const E_STATUS_STOPPED = 'STOPPED';
export const E_STATUS_IN_DEV = 'IN_DEV';

/* EXPERIMENT CONFIG VALIDATION */
export const E_CONFIG_ACCEPTED = 'CONFIG_ACCEPTED';
export const E_CONFIG_ADJUSTED = 'CONFIG_ADJUSTED';
export const E_CONFIG_REJECTED = 'CONFIG_REJECTED';

/* ITERATION STATES */
export const I_STATUS_DONE = 'DONE';
export const I_STATUS_ERROR = 'ERROR';

/* EXPERIMENT TYPE */
/** @deprecated use ExperimentType enum */
export const E_TYPE_AB_TESTING = ExperimentType.AB_TESTING;
/** @deprecated use ExperimentType enum */
export const E_TYPE_AB_OLD_TESTING = ExperimentType.AB_OLD_USERS_TESTING;
/** @deprecated use ExperimentType enum */
export const E_TYPE_ITERATIVE = ExperimentType.ITERATIVE_OPTIMISATION;
/** @deprecated use ExperimentType enum */
export const E_TYPE_GLD_TESTING = ExperimentType.GLD_TEST;

export const QUERY_PLACEHOLDER_REGEXP = /\$\{\w+\}/gm; // ->> ${start_date}
export const EMPTY_STRING_REGEXP = /^$/;
export const DIGITS_AND_COMMA_REGEXP = /^[,0-9]+$/; // -> for INT_LIST text

/* GAME STATS FILTER OPTIONS */
const FOP_TOTAL_REV = 'Total rev';
const FOP_REV_PER_USER = 'Rev per user';
const FOP_USERS_PER_DAY = 'Users per day';

export const GAME_STATS_FILTER_OPTIONS = [
  { label: FOP_TOTAL_REV, value: FOP_TOTAL_REV },
  { label: FOP_REV_PER_USER, value: FOP_REV_PER_USER },
  { label: FOP_USERS_PER_DAY, value: FOP_USERS_PER_DAY }
];

export const GAME_STATS_LIST_KEYS_MAP = {
  [FOP_TOTAL_REV]: 'revenue',
  [FOP_REV_PER_USER]: 'revPerUser',
  [FOP_USERS_PER_DAY]: 'usersPerDay'
};

/* REGIONS */
export const REG_WW = 'WW';
export const REG_US = 'US';
export const REG_OTHER_WORLD = 'OTHER WORLD';
export const REG_NON_US = 'NON US';

export const CH_TITLES = {
  [ExperimentPeriodicity.DAILY]: 'Daily',
  [ExperimentPeriodicity.TWICE_PER_DAY]: 'Twice Per Day'
};

/* PLATFORMS */
/** @deprecated use GamePlatform enum */
export const PLATFORM = {
  IOS: GamePlatform.IOS,
  ANDROID: GamePlatform.ANDROID
};

/* FORM */
export const FORM_KEY = {
  EXPERIMENT_NAME: 'experimentName',
  EXPERIMENT_TYPE: 'experimentType',
  FIREBASE_ID: 'firebaseProjectId',
  GAME_NAME: 'gameName',
  PLATFORM: 'platform',
  BUNDLE_ID: 'bundleId',
  START_DATE: 'startDateTime',
  CREATED_DATE: 'createdDate',
  AGE_MAX: 'ageMax',
  DESCRIPTION: 'description',
  ARPU: 'arpu',
  USER_PERCENT: 'userAllocationPercent',
  COUNTRY: 'country'
};

/* ACCURACY STATUS */
export const ACC_STATUS = {
  ACCURATE: 'ACCURATE',
  INACCURATE: 'INACCURATE',
  UNKNOWN: 'UNKNOWN'
};

/* ERRORS */
export const MESSAGE_FAILED_TO_FETCH_DATA = 'Server: Failed to fetch data';
