import React from 'react';

import { useSelector } from '@ui/hooks/redux';

import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { RequestStage } from '@infrastructure/store/types/actions';

import { GroupsConfig } from '@pages/experimentDetails/components/summary/config/groups/GroupsConfig';

export function GroupsConfigContainer() {
  const objectiveId = useSelector(experimentDetailsSelectors.getSelectedObjectiveId);
  const { groupsUpdate: status } = useSelector(experimentDetailsSelectors.getSummaryEditorStatus);

  const isLoading = status === RequestStage.REQUEST;

  return <GroupsConfig objectiveId={objectiveId} isLoading={isLoading} />;
}
