import React from 'react';

import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';

import { EditableCell } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/atoms/editableCell/EditableCell';
import { GroupNameCell } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/atoms/groupNameCell/GroupNameCell';

type Props = { config: GenericConfigEntry; region: string; index: number };

export function TRow({ config, index, region }: Props) {
  return (
    <tr>
      <GroupNameCell groupName={config.name} index={index} region={region} />
      {Object.keys(config.entry).map((key) => {
        return (
          <EditableCell
            key={`cell_${index}_${key}`}
            variableKey={key}
            region={region}
            configIndex={index}
            groupName={config.name}
            entry={config.entry[key]}
          />
        );
      })}
    </tr>
  );
}
