import { isEqual, differenceWith, isEmpty } from 'lodash-es';

export class ArrayUtils {
  static isEqual(base: any[], compare: any[]): boolean {
    let result = true;
    compare.forEach((item) => {
      if (!base.includes(item)) {
        result = false;
      }
    });

    return result;
  }

  static isEqualObjects(base: object[], compare: object[]): boolean {
    const diff = differenceWith(base, compare, isEqual);

    return isEmpty(diff);
  }

  static includesObject(array: object[], object: object): boolean {
    const result = array.filter((item) => isEqual(item, object));
    return !isEmpty(result);
  }
}
