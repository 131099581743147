import { RegionDto } from '@domain/models/RegionDto';
import { UserConfig } from '@domain/models/createExperiment/AdvancedConfigParams';
import { ExperimentVariableType } from '@domain/enums/ExperimentVariableType';

type ArrayInput = {
  value: string;
};

export class ExperimentFormFormatter {
  static inputArrayToStringArray(input: ArrayInput[]): string[] {
    if (!Array.isArray(input)) {
      throw TypeError("Can't map non array input");
    }

    return input.map(({ value }) => value);
  }

  static inputArrayToNumberArray(input: ArrayInput[]): number[] {
    if (!Array.isArray(input)) {
      throw TypeError("Can't map non array input");
    }

    return input.map(({ value }) => Number(value));
  }

  static mapRegionsToRegionsIds(regions: RegionDto[], countryCodes: string[]): number[] {
    const filteredRegions = regions.filter((region) => countryCodes.includes(region.countryCodes[0]));

    return filteredRegions.map((region) => region.id);
  }

  static mapUserConfigToPayload(data: UserConfig[]): Record<string, string> {
    return data.reduce((acc, item) => {
      if (item.key) {
        acc[item.key] = item.value;
      }
      return acc;
    }, {});
  }

  static formatGLDValue(value: any): string {
    switch (value) {
      case '':
        return '<empty string>';
      case null:
        return '<no value>';
      default:
        return value.toString();
    }
  }

  static formatBooleanToString(value: boolean): string {
    return value ? '1' : '0';
  }

  static formatIntegerToString(value: number): string {
    return value.toString();
  }

  static formatIntegerListToString(value: number[]): string {
    return value.join(',');
  }

  static formatIntegerToArrayValue(value: number): { value: string }[] {
    return [{ value: value.toString() }];
  }

  static formatIntegerListToArrayValue(value: number[]): { value: string }[] {
    return [{ value: value.join(',') }];
  }

  static formatStringToArrayValue(value: string): { value: string }[] {
    return [{ value }];
  }

  static formatDefaultControlGroupValue(value: any, type: ExperimentVariableType): string {
    switch (type) {
      case ExperimentVariableType.BOOLEAN:
        return ExperimentFormFormatter.formatBooleanToString(value);
      case ExperimentVariableType.INTEGER:
      case ExperimentVariableType.INT_LIST:
        return ExperimentFormFormatter.formatIntegerToString(value);
      case ExperimentVariableType.STRING:
        return value;
    }
  }

  static formatControlGroupValue(value: any, type: ExperimentVariableType): string {
    switch (type) {
      case ExperimentVariableType.BOOLEAN:
        return ExperimentFormFormatter.formatBooleanToString(value);
      case ExperimentVariableType.INTEGER:
        return ExperimentFormFormatter.formatIntegerToString(value);
      case ExperimentVariableType.INT_LIST:
        return ExperimentFormFormatter.formatIntegerListToString(value);
      case ExperimentVariableType.STRING:
        return value;
    }
  }

  static formatDefaultInputGroupValue(value: any, type: ExperimentVariableType) {
    switch (type) {
      case ExperimentVariableType.BOOLEAN:
        return ExperimentFormFormatter.formatBooleanToString(value);
      case ExperimentVariableType.INTEGER:
      case ExperimentVariableType.INT_LIST:
        return ExperimentFormFormatter.formatIntegerToArrayValue(value);
      case ExperimentVariableType.STRING:
        return ExperimentFormFormatter.formatStringToArrayValue(value);
    }
  }
  static formatInputGroupValue(value: any, type: ExperimentVariableType) {
    switch (type) {
      case ExperimentVariableType.BOOLEAN:
        return ExperimentFormFormatter.formatBooleanToString(value);
      case ExperimentVariableType.INTEGER:
        return ExperimentFormFormatter.formatIntegerToArrayValue(value);
      case ExperimentVariableType.INT_LIST:
        return ExperimentFormFormatter.formatIntegerListToArrayValue(value);
      case ExperimentVariableType.STRING:
        return ExperimentFormFormatter.formatStringToArrayValue(value);
    }
  }
}
