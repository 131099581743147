import React from 'react';
import { CheckboxInput } from 'crazy-ui-next';
import { FieldArray } from 'react-hook-form';

import { FormComponent, getInputProps } from '@ui/hooks/form';

import { CONFIG_GROUP_NAME } from '@domain/enums/ConfigGroupName';
import { ExpGroupsConfigParams } from '@domain/models/experimentDetails/ExpGroupsConfigParams';

import styles from '../GroupsConfig.module.scss';

type Props = Pick<FormComponent<ExpGroupsConfigParams>, 'register' | 'errors'> & {
  field: FieldArray<ExpGroupsConfigParams>;
  editorEnabled: boolean;
  index: number;
};

export function NameCell({ field, editorEnabled, index, register, errors }: Props) {
  const { name, active } = field;

  const isControl = name === CONFIG_GROUP_NAME.CONTROL || name === CONFIG_GROUP_NAME.CONTROL_CLONE;
  const displayInput = active && editorEnabled && !isControl;

  return (
    <td>
      <div className={styles.groupNameCell}>
        {displayInput && (
          <CheckboxInput {...getInputProps<ExpGroupsConfigParams>(`groups.${index}.checked`, register, errors)} />
        )}
        <span>{name}</span>
      </div>
    </td>
  );
}
