import { UserRoleType } from '@domain/enums/UserRoleType';

export class UserRole {
  public authority: UserRoleType;

  constructor(authority: UserRoleType) {
    this.authority = authority;
  }

  public isAdminType() {
    return this.authority === UserRoleType.ADMIN;
  }

  public isQAType() {
    return this.authority === UserRoleType.QA;
  }
}
