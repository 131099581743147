import React from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  useForm as useReactHookForm,
  useWatch,
  UseFormWatch,
  UseFormReturn,
  FieldValues,
  UseFormProps,
  UseFormRegister,
  UseFormRegisterReturn,
  Control,
  FieldErrors,
  FieldPath,
  RefCallBack
} from 'react-hook-form';
import { Schema } from 'joi';
import { ClassConstructor } from 'class-transformer';

import { joi } from '@infrastructure/validation/common';

export { useWatch };

export const useForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props?: Omit<UseFormProps<TFieldValues, TContext>, 'resolver'> & { schema?: Schema | ClassConstructor<any> }
): UseFormReturn<TFieldValues, TContext> => {
  let resolver;

  if (props?.schema && joi.isSchema(props.schema)) {
    resolver = joiResolver(props.schema);
  } else if (typeof props?.schema?.constructor === 'function') {
    // @ts-ignore
    resolver = classValidatorResolver(props.schema);
  }

  return useReactHookForm({ resolver, ...props });
};

export type FormComponent<Fields extends FieldValues> = {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  register: UseFormRegister<Fields>;
  errors: FieldErrors<Fields>;
  control?: Control<Fields>;
  watch?: UseFormWatch<Fields>;
  isValid?: boolean;
};

export function getInputProps<Fields extends FieldValues>(
  name: FieldPath<Fields>,
  register: UseFormRegister<Fields>,
  errors: FieldErrors<Fields>
): Omit<UseFormRegisterReturn<FieldPath<Fields>>, 'ref'> & { forwardRef: RefCallBack; error?: string } {
  const props = register(name) as any;
  props.forwardRef = props.ref;
  props.error = errors?.[name]?.message || '';

  if (props.ref) {
    delete props.ref;
  }

  return props;
}

export function getFieldArrayInputProps<Fields extends FieldValues>(
  name: FieldPath<Fields>,
  register: UseFormRegister<Fields>,
  errors: FieldErrors<Fields>
): Omit<UseFormRegisterReturn<FieldPath<Fields>>, 'ref'> & { forwardRef: RefCallBack; error?: string } {
  const props = register(name) as any;
  props.forwardRef = props.ref;

  const [param] = name.split('.');
  const errorsList = errors?.[param];

  if (Array.isArray(errorsList)) {
    errorsList.forEach((error) => {
      if (error) {
        const currentError = error.value;
        const refId = currentError.ref.id;

        if (refId === name) {
          props.error = currentError.message;
        }
      }
    });
  } else {
    props.error = '';
  }

  if (props.ref) {
    delete props.ref;
  }

  return props;
}
