import { call, put, takeLatest } from 'redux-saga/effects';
import { Service } from 'typedi';

import { RootSagaWatcher } from '@infrastructure/sagas/RootSagaWatcher';
import { BaseSagaWatcher } from '@infrastructure/sagas/BaseSagaWatcher';
import { pushLogin, pushLoginByCreds, pushLogout, pushRestoreSession } from '@infrastructure/store/auth/authActions';
import { AuthService } from '@app/services/AuthService';
import { MyUserDto } from '@domain/models/user/MyUserDto';
import { Notification } from '@infrastructure/api/notifications';

@Service()
export class AuthSaga extends BaseSagaWatcher {
  constructor(private authService: AuthService, protected rootWatcher: RootSagaWatcher) {
    super(rootWatcher);
  }

  public getEffects() {
    return [
      takeLatest(pushLoginByCreds.TRIGGER, this.pushLoginForm.bind(this)),
      takeLatest(pushLogin.SUCCESS, this.afterLogin.bind(this)),
      takeLatest(pushRestoreSession.TRIGGER, this.pushRestoreSession.bind(this)),
      takeLatest(pushLogout.TRIGGER, this.pushLogout.bind(this))
    ];
  }

  public *afterLogin() {
    yield call([this.authService, this.authService.navigateToMainPage]);
  }

  public *pushLogout() {
    yield call([this.authService, this.authService.logout]);
  }

  public *pushLoginForm(action: ReturnType<typeof pushLoginByCreds.trigger>) {
    try {
      const form = action.payload;
      yield put(pushLogin.request());
      const user: MyUserDto = yield call(
        [this.authService, this.authService.authorizeByCreds],
        form.username,
        form.password
      );

      if (!user) {
        yield put(pushLogin.failure());
        return;
      }

      yield put(pushLogin.success(user));
    } catch (err) {
      yield put(pushLogin.failure());
      Notification.error(err);
    }
  }

  public *pushRestoreSession() {
    try {
      yield put(pushLogin.request());
      const user: MyUserDto = yield call([this.authService, this.authService.getPreviousSession]);

      if (!user) {
        yield put(pushLogin.failure());
        return;
      }

      yield put(pushLogin.success(user));
    } catch (err) {
      yield put(pushLogin.failure());
      Notification.error(err);
    }
  }
}
