import { Service } from 'typedi';
import { createBrowserHistory, History } from 'history';
import { matchPath } from 'react-router-dom';

import { Routing } from '@infrastructure/routing';

@Service()
export class HistoryService {
  protected readonly history: History = createBrowserHistory();

  getHistory() {
    return this.history;
  }

  getSearchParams() {
    const params = new URLSearchParams(window.location.search);
    const map: Record<string, string> = {};

    for (const [key, value] of params.entries()) {
      map[key] = value;
    }

    return map;
  }

  isMatch(path: string): boolean {
    const match = matchPath(window.location.pathname, { path, exact: true });
    return Boolean(match);
  }

  goTo(path: string) {
    this.history.push(path);
  }

  replaceTo(path: string) {
    this.history.replace(path);
  }

  replaceToRoot(): void {
    this.history.replace(Routing.getRoot());
  }

  goBack(fallbackPath: string): void {
    const isHistoryEmpty = window.history.length === 1;

    if (isHistoryEmpty) {
      this.history.push(fallbackPath);
    } else {
      this.history.goBack();
    }
  }
}
