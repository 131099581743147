import React from 'react';
import { InputLabel, TextInput } from 'crazy-ui-next';
import { Controller } from 'react-hook-form';

import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/controlGroup/ControlGroup.module.scss';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'control'> & {
  index: number;
  name: string;
  labelText: string;
};

export function ControlledTextInput({ control, name, labelText, index }: Props) {
  return (
    <Controller
      name={`controlGroup.${index}.${name}`}
      control={control}
      render={({ field }) => {
        return (
          <>
            <InputLabel labelText={labelText} className={styles.label} />
            <TextInput name={field.name} value={field.value} className={styles.input} onChange={field.onChange} />
          </>
        );
      }}
    />
  );
}
