import React from 'react';
import { Control, useController, UseFormRegister } from 'react-hook-form';
import { InputLabel, RadioInput } from 'crazy-ui-next';

import { BasicInfoParams } from '@domain/models/createExperiment/BasicInfoParams';
import { ExperimentType } from '@domain/enums/ExperimentType';
import styles from '@pages/createExperiment/basicInfo/BasicInfo.module.scss';
import { getInputProps } from '@ui/hooks/form';

const options = [
  { label: 'AB Testing', value: ExperimentType.AB_TESTING },
  { label: 'GLD Test', value: ExperimentType.GLD_TEST }
];
const name = 'experimentType';

type Props = {
  control?: Control<BasicInfoParams>;
  register: UseFormRegister<BasicInfoParams>;
};

export function ExperimentTypeInput({ control, register }: Props) {
  const { field, formState } = useController({ control, name });
  const inputProps = getInputProps<BasicInfoParams>(name, register, formState.errors);

  const inputs = options.map(({ label, value }) => {
    return (
      <li key={`r_${value}`}>
        <RadioInput checked={value === field.value} labelText={label} value={value} {...inputProps} />
      </li>
    );
  });

  return (
    <>
      <InputLabel labelText="Experiment Type" error={inputProps.error} />
      <ul className={styles.radioInputGroup}>{inputs}</ul>
    </>
  );
}
