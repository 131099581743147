import React from 'react';
import GoogleImg from '@assets/images/g-logo.png';

import styles from './GoogleSignIn.module.scss';

export default function GoogleSignInButton() {
  return (
    <a href="/oauth2/authorize/google" className={styles['g-sign-in-button']}>
      <div className={styles['content-wrapper']}>
        <div className={styles['logo-wrapper']}>
          <img src={GoogleImg} alt="google-logo" />
        </div>
        <span className={styles['text-container']}>
          <span>Sign in with Google</span>
        </span>
      </div>
    </a>
  );
}
