export const config = {
  sizeX: 600,
  sizeY: 300,
  padding: { top: 5, bottom: 30, left: 65, right: 35 },

  axisColor: '#546e7a',
  fontFamily: `'Roboto Mono', monospace`
};

export const axisStyle = {
  axisLabel: {
    fill: config.axisColor,
    padding: 50,
    fontSize: '14px',
    fontWeight: 600
  },
  axis: {
    stroke: config.axisColor
  },
  grid: {
    stroke: config.axisColor,
    strokeOpacity: 0.075
  },
  tickLabels: {
    fill: config.axisColor
  },
  ticks: { stroke: config.axisColor, size: 5 }
};
