import { Dto } from '@domain/models/Dto';
import { GenericConfigValue } from '@domain/models/GenericConfigValue';

export class ExpGroupsConfigParams extends Dto {
  public groups: {
    name: string;
    description: string;
    checked: boolean;
    entry: Record<string, GenericConfigValue>;
    active: boolean;
  }[];

  static ofInitial() {
    const form = new ExpGroupsConfigParams();

    form.groups = [];

    return form;
  }
}
