import React from 'react';
import { Controller } from 'react-hook-form';

import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { RangeInput } from '@components/layout/rangeInput/RangeInput';
import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/GLD/GLDForm.module.scss';

type Props = Pick<FormComponent<GLDConfigParams>, 'control'>;

export function PercentUsersInput({ control }: Props) {
  return (
    <Controller
      name="usersAllocationPercent"
      control={control}
      render={({ field }) => {
        return (
          <RangeInput
            labelText={`Percent of engaged users: ${field.value}%`}
            value={field.value}
            className={styles.input}
            onChange={({ currentTarget }) => field.onChange(currentTarget.value)}
          />
        );
      }}
    />
  );
}
