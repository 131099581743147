import { Service } from 'typedi';

import { StringUtils } from '@app/utils/StringUtils';
import { LogsApi } from '@infrastructure/api/LogsApi';
import { LogsUseCases } from '@domain/useCases/LogsUseCases';
import { ReduxRepo } from '@infrastructure/repositories/ReduxRepo';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { LogDto } from '@domain/models/LogDto';
import { JSONUtils } from '@app/utils/JSONUtils';

@Service()
export class LogsService implements LogsUseCases {
  constructor(private readonly reduxRepo: ReduxRepo, private readonly logsApi: LogsApi) {}

  async getExperimentLogList(): Promise<string[]> {
    const {
      experimentId,
      gameApp: { firebaseProjectId }
    } = this.reduxRepo.findBy(experimentDetailsSelectors.getExperiment);
    const { iterationId } = this.reduxRepo.findBy(experimentDetailsSelectors.getSelectedIteration);

    const list = await this.logsApi.getExperimentLogList(firebaseProjectId, experimentId, iterationId);

    return list ? list.map(StringUtils.getNameFromPath) : [];
  }

  async getExperimentLog(): Promise<LogDto> {
    const {
      experimentId,
      gameApp: { firebaseProjectId }
    } = this.reduxRepo.findBy(experimentDetailsSelectors.getExperiment);
    const { iterationId } = this.reduxRepo.findBy(experimentDetailsSelectors.getSelectedIteration);
    const name = this.reduxRepo.findBy(experimentDetailsSelectors.getSelectedLogName);

    const result = await this.logsApi.getExperimentLog(firebaseProjectId, experimentId, iterationId, name);

    if (name.includes('.json')) {
      result.content = JSONUtils.JSONToLogData(result.content);
    }

    return result;
  }
}
