import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'crazy-ui-next';

import { useForm } from '@ui/hooks/form';
import { useDispatch } from '@ui/hooks/redux';

import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';
import { pushUpdateDevPhase } from '@infrastructure/store/experimentDetails/experimentDetailsActions';

import { ConfigSection } from '@pages/experimentDetails/components/summary/config/atoms/ConfigSection';
import { BasicInfoSection } from '@pages/experimentDetails/components/summary/config/devPhase/sections/BasicInfoSection';
import { TargetingConfigSection } from '@pages/experimentDetails/components/summary/config/devPhase/sections/targetingConfig/TargetingConfigSection';
import { ConfigInputContainer } from '@pages/experimentDetails/components/summary/config/devPhase/sections/objectiveConfig/configInput/ConfigInputContainer';

import { RequestStage } from '@infrastructure/store/types/actions';
import { configSelectors } from '@infrastructure/store/config/configSelectors';

import styles from './DevPhase.module.scss';

export function DevPhaseForm() {
  const dispatch = useDispatch();

  const form = useSelector(experimentDetailsSelectors.getDevPhaseParams);
  const experimentDetails = useSelector(experimentDetailsSelectors.getExperiment);
  const status = useSelector(experimentDetailsSelectors.getDevPhaseStatus);
  const userProperties = useSelector(configSelectors.getUserProperties);
  const userPropertyOperators = useSelector(configSelectors.getUserPropertyOperators);

  const isLoading = status === RequestStage.REQUEST;

  const {
    register,
    formState: { errors, isValid },
    resetField,
    control,
    watch,
    handleSubmit,
    setFocus,
    setValue
  } = useForm<DevPhaseParams>({
    schema: DevPhaseParams,
    defaultValues: form
  });

  const handleFormSubmit = handleSubmit((params) => dispatch(pushUpdateDevPhase.trigger(params)));
  const selectedRegions = watch('targetingConfig.regions');
  const selectedDevRegions = watch('targetingConfig.regionsForInDev');
  const isSaveDisabled = !isValid || !selectedRegions.length || !selectedDevRegions.length;
  // If at least one objective is for new users, we should hide imported segments
  const isImportedSegmentsHidden = !!experimentDetails.experimentObjectives.find((config) => config.newUsers);

  return (
    <div className={styles.config}>
      <main>
        <ConfigSection
          className={styles.fullWidth}
          label="Basic Info:"
          children={
            <BasicInfoSection register={register} errors={errors} isImportedSegmentsHidden={isImportedSegmentsHidden} />
          }
        />
        <ConfigSection
          className={styles.fullWidth}
          label="Objective Config:"
          children={<ConfigInputContainer watch={watch} control={control} setFocus={setFocus} />}
        />
        <ConfigSection
          className={styles.fullWidth}
          label="Targeting Config:"
          children={
            <TargetingConfigSection
              control={control}
              register={register}
              resetField={resetField}
              watch={watch}
              setValue={setValue}
              userProperties={userProperties}
              operators={userPropertyOperators}
            />
          }
        />
        <div className={styles.formControls}>
          <Button onClick={handleFormSubmit} isLoading={isLoading} disabled={isSaveDisabled}>
            Save
          </Button>
        </div>
      </main>
    </div>
  );
}
