import React from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';

import { ReactComponent as PlusSvg } from '@assets/svg/plus.svg';

type Props = {
  handleAddConfig: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isShown: boolean;
};

export function AddConfigBtn({ handleAddConfig, isShown }: Props) {
  if (!isShown) {
    return null;
  }

  return (
    <Button variant={ButtonVariant.TERTIARY} onClick={handleAddConfig} icon={<PlusSvg />}>
      Group
    </Button>
  );
}
