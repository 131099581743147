import React from 'react';
import cn from 'classnames';
import { InputLabel } from 'crazy-ui-next';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { ReactComponent as CalendarSvg } from '@assets/svg/calendar.svg';

import pickerStyles from '../DateRangePicker/DatePickerCustom.module.scss';
import styles from './DatePicker.module.scss';
import { DateUtils } from '@app/utils/DateUtils';

interface Props extends ReactDatePickerProps {
  className?: string;
  labelText?: string;
  dateFormat?: string;
}

export function DatePicker({
  name,
  selected,
  className,
  labelText,
  dateFormat = DateUtils.getDateWithHoursPattern(),
  onChange,
  showTimeSelect = false,
  ...datePickerProps
}: Props) {
  return (
    <div id={name} className={cn(styles.wrapper, className)}>
      <InputLabel labelText={labelText} />
      <div className={styles.main}>
        <span className={styles.icon}>
          <CalendarSvg />
        </span>
        <div className={styles.display}>
          <ReactDatePicker
            selectsStart
            name={name}
            dateFormat={dateFormat}
            onChange={onChange}
            selected={selected}
            showTimeSelect={showTimeSelect}
            calendarClassName={pickerStyles.calendarCustom}
            {...datePickerProps}
          />
        </div>
      </div>
    </div>
  );
}
