export enum AccurateStatus {
  ACCURATE = 'ACCURATE',
  INACCURATE = 'INACCURATE',
  UNKNOWN = 'UNKNOWN'
}

export const AccurateStatusTitle = {
  [AccurateStatus.ACCURATE]: 'Data is valid',
  [AccurateStatus.INACCURATE]: 'Data is not valid',
  [AccurateStatus.UNKNOWN]: 'Unknown'
};
