import { PaginationState } from '@domain/models/local/PaginationState';
import { createReducer } from '@reduxjs/toolkit';
import { fetchExperiments } from '@infrastructure/store/experimentList/experimentListActions';

const paginationInitial: PaginationState = {
  isNextAvailable: false,
  currentPage: 0,
  lastPage: 0,
  count: 0
};

export const paginationReducer = createReducer(paginationInitial, (qb) => {
  qb.addCase(fetchExperiments.trigger, (state, { payload }) => {
    state.currentPage = payload || 0;
  });
  qb.addCase(fetchExperiments.success, (state, { payload }) => {
    state.isNextAvailable = payload.moreAvailable;
    state.lastPage = payload.lastPageIndex; // add + 1 ?
    state.count = payload.totalNumber;
  });
});
