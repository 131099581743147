import React from 'react';
import { CheckboxInput } from 'crazy-ui-next';

import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { FormComponent, getInputProps } from '@ui/hooks/form';

type Props = Pick<FormComponent<GLDConfigParams>, 'register'>;

export function CloneInput({ register }: Props) {
  return (
    <CheckboxInput labelText="Clone control" {...getInputProps<GLDConfigParams>(`cloneControlGroup`, register, {})} />
  );
}
