import { AppState } from '@infrastructure/store';
import { UniqueId } from '@domain/types';
import {
  mapUserRoleValueToType,
  UserRoleFilterValue,
  UserSortValue,
  UserStatusFilterValue
} from '@infrastructure/store/userManagement/types/filters';
import { UserRoleType } from '@domain/enums/UserRoleType';
import { UserDto } from '@domain/models/user/UserDto';
import { UserMapper } from '@app/mappers/UserMapper';

/** @private */
const byStatusFilter = (status: UserStatusFilterValue) => (user: UserDto) => {
  if (status === UserStatusFilterValue.BLOCKED) {
    return !user.enabled;
  }

  return user.enabled;
};

/** @private */
const byRoleFilter = (role: UserRoleType) => (user: UserDto) => {
  const [currentRole] = user.roles;
  return currentRole === role;
};

/** @private */
const bySearchFilter = (searchPhrase: string) => (user: UserDto) => {
  return user.fullName.toLowerCase().includes(searchPhrase.toLowerCase());
};

/** @private */
const applySelectedSort = (sortBy: UserSortValue) => (a: UserDto, b: UserDto) => {
  switch (sortBy) {
    case UserSortValue.NAME_ASC: {
      if (a.fullName === b.fullName) {
        return 0;
      }

      return a.fullName > b.fullName ? 1 : -1;
    }
    case UserSortValue.NAME_DESC: {
      if (a.fullName === b.fullName) {
        return 0;
      }

      return a.fullName > b.fullName ? -1 : 1;
    }
    default: {
      return 0;
    }
  }
};

const getProcessedUserList = (state: AppState) => {
  const userMap = getUserMap(state);
  const idList = getUserIdList(state);
  const { searchPhrase, status, role, sortBy } = getFilters(state);

  let list = idList.map((id) => userMap[id]);

  if (searchPhrase.length) {
    list = list.filter(bySearchFilter(searchPhrase));
  }

  if (status !== UserStatusFilterValue.ALL) {
    list = list.filter(byStatusFilter(status));
  }

  if (role !== UserRoleFilterValue.ALL) {
    const selectedRoleType = mapUserRoleValueToType(role);
    list = list.filter(byRoleFilter(selectedRoleType));
  }

  list.sort(applySelectedSort(sortBy));

  return list;
};

const getUsersStatus = (state: AppState) => state.userManagement.status;
const getUserMap = (state: AppState) => state.userManagement.byId;
const getUserIdList = (state: AppState) => state.userManagement.idList;

const getUser = (id: UniqueId) => (state: AppState) => {
  return state.userManagement.byId[id] || null;
};

const getEditUser = (id: UniqueId) => (state: AppState) => {
  const dto = getUser(id)(state);
  return UserMapper.mapDtoToUpdateParams(dto);
};

const getEditUserStatus = (state: AppState) => state.userManagement.editStatus;

const getFilters = (state: AppState) => state.userManagement.filters;

export const userManagementSelectors = {
  getUsersStatus,
  getUserMap,
  getUserIdList,
  getUser,
  getEditUserStatus,
  getEditUser,
  getProcessedUserList,
  getFilters
};
