import React from 'react';
import { Button } from 'crazy-ui';
import { ReactComponent as RefreshSvg } from '@assets/svg/refresh.svg';

import styles from './RefreshButtonModule.module.scss';

type Props = {
  tooltip?: string;
  onClick: () => void;
};

export function RefreshButton({ tooltip, onClick }: Props) {
  return (
    <Button
      className={styles.button}
      variant={Button.FILL}
      color={Button.BLUE}
      withMargin={false}
      svgMargin={false}
      tooltip={tooltip}
      onClick={onClick}
    >
      <RefreshSvg />
    </Button>
  );
}
