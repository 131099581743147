import React from 'react';

import RoundImage from '@components/layout/RoundImage';

import styles from './SideMenu.module.scss';
import { MyUserDto } from '@domain/models/user/MyUserDto';

type Props = {
  profile: MyUserDto | null;
  handleLogout: () => void;
  iconUrl: string | null;
};

export function Profile({ profile, handleLogout, iconUrl }: Props) {
  if (!profile) {
    return null;
  }

  return (
    <div className={styles.profile}>
      <RoundImage className={styles.avatar} src={iconUrl} alt={profile.username} />

      <div className={styles.authority}>
        <p className={styles.name}>{profile.username}</p>
        <p className={styles.role}>{profile.getCurrentRole()}</p>
      </div>

      <div className={styles.logout}>
        <button type="button" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
}
