import { createAction } from '@reduxjs/toolkit';
import { BasicAction, RequestAction, RequestStage } from '@infrastructure/store/types/actions';

export class ActionFactory {
  static readonly SEPARATOR: string = '/';

  static mergeSegments(target: string, segment: string): string {
    return `${target}${ActionFactory.SEPARATOR}${segment}`;
  }

  static createAction<Payload = void>(actionName: string, namespace?: string): BasicAction<Payload> {
    const prefixName = namespace ? ActionFactory.mergeSegments(namespace, actionName) : actionName;
    return createAction<Payload>(prefixName);
  }

  static createRequestRoutine<TriggerP = void, SuccessP = void, FailureP = void, RequestP = void>(
    actionName: string,
    namespace?: string
  ): RequestAction<TriggerP, SuccessP, FailureP, RequestP> {
    const prefixName = namespace ? ActionFactory.mergeSegments(namespace, actionName) : actionName;

    const trigger = ActionFactory.createAction<TriggerP>(RequestStage.TRIGGER, prefixName);
    const TRIGGER = trigger.toString();

    const request = ActionFactory.createAction<RequestP>(RequestStage.REQUEST, prefixName);
    const REQUEST = request.toString();

    const success = ActionFactory.createAction<SuccessP>(RequestStage.SUCCESS, prefixName);
    const SUCCESS = success.toString();

    const failure = ActionFactory.createAction<FailureP>(RequestStage.FAILURE, prefixName);
    const FAILURE = failure.toString();

    /* prettier-ignore */
    return { trigger, request, success, failure, TRIGGER, REQUEST, SUCCESS, FAILURE };
  }
}
