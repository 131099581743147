import React, { useCallback } from 'react';
import { DropdownEvent, DropdownInput, DropdownOption } from 'crazy-ui-next';

import { useDispatch, useSelector } from '@ui/hooks/redux';
import { appliedConfigSelectors } from '@infrastructure/store/appliedConfig/appliedConfigSelectors';
import { setTypeFilter } from '@infrastructure/store/appliedConfig/appliedConfigActions';
import { ACFilter } from '@infrastructure/store/appliedConfig/types/filters';
import { ReactComponent as StackLineSvg } from '@assets/svg/stack-line.svg';

const options: DropdownOption<ACFilter.Type>[] = [
  { value: ACFilter.Type.ALL, label: 'All' },
  { value: ACFilter.Type.AB_TESTING, label: 'AB Test' },
  { value: ACFilter.Type.AB_OLD_USERS_TESTING, label: 'AB Old Test' },
  { value: ACFilter.Type.GLD_TEST, label: 'GLD Test' }
];

export function TypeFilter() {
  const dispatch = useDispatch();
  const { type } = useSelector(appliedConfigSelectors.getFilters);

  const handleType = useCallback(
    ({ value }: DropdownEvent<ACFilter.Type>) => {
      dispatch(setTypeFilter(value));
    },
    [dispatch]
  );
  return (
    <DropdownInput
      name="experimentType"
      inputIcon={<StackLineSvg />}
      value={type}
      options={options}
      handleChange={handleType}
      labelText="Test Type"
    />
  );
}
