import React from 'react';

import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { TableResult } from '@pages/experimentDetails/components/stats/components/result/components/tableResult/TableResult';
import { useSelector } from '@ui/hooks/redux';
import { ExperimentType } from '@domain/enums/ExperimentType';

export function TableResultContainer() {
  const result = useSelector(experimentDetailsSelectors.getIterationResult);
  const status = useSelector(experimentDetailsSelectors.getIterationResultStatus);
  const metricKeys = useSelector(experimentDetailsSelectors.getMetricKeys);
  const { experimentType } = useSelector(experimentDetailsSelectors.getExperiment);
  const variables = useSelector(configSelectors.getVariableList);

  const isAB = experimentType === ExperimentType.AB_TESTING;

  return <TableResult result={result} metricKeys={metricKeys} variables={variables} status={status} isAB={isAB} />;
}
