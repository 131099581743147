import React, { useCallback } from 'react';
import { TextInput } from 'crazy-ui-next';

import { ExperimentConfigsMapper } from '@app/mappers/experiment/ExperimentConfigsMapper';
import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { DIGITS_AND_COMMA_REGEXP, EMPTY_STRING_REGEXP } from '@domain/constants';
import { ExperimentVariableWrapper } from '@domain/enums/ExperimentVariableWrapper';
import { getLabelText } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/configModal/form/ValueInputsContainer';
import { useSelector } from '@ui/hooks/redux';

import styles from '../EditableConfigModal.module.scss';

type Props = {
  variableKey: string;
  onChange: (e) => void;
  index: number;
  type: ExperimentVariableWrapper;
  value: string;
  name: string;
};

export function IntListValueInput({ variableKey, onChange, index, type, value, name }: Props) {
  const variableMap = useSelector(configSelectors.getVariableMap);

  const handleBlurTweak = ({ currentTarget }) => {
    const { value: targetValue } = currentTarget;
    const { minValue, maxValue } = variableMap[variableKey];
    const integers = targetValue.split(',').filter(Boolean);

    const formattedIntegers = integers.map((int) => {
      if (!int) {
        return minValue.toString();
      }
      if (int < minValue) {
        return minValue.toString();
      }
      if (int > maxValue) {
        return maxValue.toString();
      }
      return int;
    });

    onChange({ value: formattedIntegers, type });
  };

  const handleChange = useCallback(
    ({ currentTarget }) => {
      const allowChange =
        DIGITS_AND_COMMA_REGEXP.test(currentTarget.value) || EMPTY_STRING_REGEXP.test(currentTarget.value);

      allowChange &&
        onChange({
          value: ExperimentConfigsMapper.mapInputValueByType(currentTarget, type),
          type
        });
    },
    [onChange, type]
  );

  return (
    <TextInput
      labelText={getLabelText(index, 'Value')}
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={handleBlurTweak}
      className={styles.textInput}
    />
  );
}
