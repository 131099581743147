import { Service } from 'typedi';

import { WinnerConfigMapper } from '@app/mappers/WinnerConfigMapper';
import { WinnerConfigApi } from '@infrastructure/api/WinnerConfigApi';
import { WinnerConfigUseCase } from '@domain/useCases/WinnerConfigUseCase';
import { UniqueId } from '@domain/types';
import { UpdateWinnerConfigParams } from '@domain/models/winnerConfig/WinnerConfigFormParams';
import { MessageResponseDto } from '@domain/models/MessageResponseDto';

@Service()
export class WinnerConfigService implements WinnerConfigUseCase {
  constructor(private readonly winnerConfig: WinnerConfigApi) {}

  async apply(body: UpdateWinnerConfigParams): Promise<MessageResponseDto> {
    const updatedBody = WinnerConfigMapper.mapFormParamsToRequest(body);
    const { experimentId, objectiveId } = body;

    return this.winnerConfig.applyConfig(experimentId, objectiveId, updatedBody);
  }

  async update(body: UpdateWinnerConfigParams): Promise<MessageResponseDto> {
    const updatedBody = WinnerConfigMapper.mapFormParamsToRequest(body);
    const { experimentId, objectiveId } = body;

    return this.winnerConfig.updatedConfig(experimentId, objectiveId, updatedBody);
  }

  async delete(experimentId: UniqueId, objectiveId: UniqueId): Promise<MessageResponseDto> {
    return this.winnerConfig.deleteConfig(experimentId, objectiveId);
  }
}
