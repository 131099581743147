import { combineReducers } from '@reduxjs/toolkit';

import { basicInfoReducer } from '@infrastructure/store/createExperiment/reducers/basicInfoReducer';
import { targetConfigReducer } from '@infrastructure/store/createExperiment/reducers/targetConfigReducer';
import { objectiveABConfigReducer } from '@infrastructure/store/createExperiment/reducers/objectiveABReducer';
import { objectiveGLDConfigReducer } from '@infrastructure/store/createExperiment/reducers/objectiveGLDReducer';
import { goalConfigReducer } from '@infrastructure/store/createExperiment/reducers/goalConfigReducer';
import { dateConfigReducer } from '@infrastructure/store/createExperiment/reducers/dateConfigReducer';
import { advancedConfigReducer } from '@infrastructure/store/createExperiment/reducers/advancedConfigReducer';
import { formStepReducer } from '@infrastructure/store/createExperiment/reducers/formStepReducer';
import { regionsReducer } from '@infrastructure/store/createExperiment/reducers/regionsReducer';
import { gameStatsReducer } from '@infrastructure/store/createExperiment/reducers/gameStatsReducer';
import { controlGroupsReducer } from '@infrastructure/store/createExperiment/reducers/controlGroupsReducer';
import { configListReducer } from '@infrastructure/store/createExperiment/reducers/configListReducer';
import { cloneReducer } from '@infrastructure/store/createExperiment/reducers/cloneReducer';
import { paramsReducer } from '@infrastructure/store/createExperiment/reducers/paramsReducer';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import {
  fetchObjectiveConfig,
  clearAll,
  pushExperimentCreate,
  generateTargetConfig,
  generateABObjectiveConfig,
  initTargetConfig
} from '@infrastructure/store/createExperiment/createExperimentActions';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { overlappedConfigsReducer } from '@infrastructure/store/createExperiment/reducers/overlappedConfigsReducer';

export type ExperimentFormState = ReturnType<typeof formReducer>;

const formReducer = combineReducers({
  [CreateExperimentForm.BASIC_INFO]: basicInfoReducer,
  [CreateExperimentForm.TARGET_CONFIG]: targetConfigReducer,
  [CreateExperimentForm.AB_OBJECTIVE_CONFIG]: objectiveABConfigReducer,
  [CreateExperimentForm.GLD_OBJECTIVE_CONFIG]: objectiveGLDConfigReducer,
  [CreateExperimentForm.GOAL_CONFIG]: goalConfigReducer,
  [CreateExperimentForm.DATE_CONFIG]: dateConfigReducer,
  [CreateExperimentForm.ADVANCED_CONFIG]: advancedConfigReducer
});

export const createExperiment = combineReducers({
  status: ReducerFactory.makeStatus(
    [initTargetConfig, generateTargetConfig, fetchObjectiveConfig, generateABObjectiveConfig, pushExperimentCreate],
    clearAll
  ),
  form: formReducer,
  formStep: formStepReducer,
  regions: regionsReducer,
  gameStats: gameStatsReducer,
  controlGroups: controlGroupsReducer,
  configList: configListReducer,
  clone: cloneReducer,
  overlappedConfigs: overlappedConfigsReducer,
  params: paramsReducer
});
