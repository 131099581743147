import { AppliedConfigViewDto } from '@domain/models/appliedConfig/AppliedConfigViewDto';
import { createReducer } from '@reduxjs/toolkit';
import { clearAll, fetchAppliedConfigList } from '@infrastructure/store/appliedConfig/appliedConfigActions';

const listInitial: AppliedConfigViewDto[] = [];

export const listReducer = createReducer(listInitial, (qb) => {
  qb.addCase(fetchAppliedConfigList.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return listInitial;
  });
});
