import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { EditConfigModalWrapper } from '@pages/appliedConfig/table/editConfigCell/EditConfigModalWrapper';
import { UniqueId } from '@domain/types';
import { deleteAppliedConfig, updateAppliedConfig } from '@infrastructure/store/appliedConfig/appliedConfigActions';

type Props = {
  experimentId: UniqueId;
  objectiveId: UniqueId;
  experimentName: string;
  isActiveConfig: boolean;
};

export function EditConfigCellContainer({ experimentId, experimentName, isActiveConfig, objectiveId }: Props) {
  const dispatch = useDispatch();

  const handleCancelWinner = useCallback(() => {
    dispatch(deleteAppliedConfig.trigger({ experimentId, objectiveId }));
  }, [dispatch, experimentId, objectiveId]);

  const handleApplyConfig = useCallback(
    (form) => {
      dispatch(updateAppliedConfig.trigger(form));
    },
    [dispatch]
  );

  return (
    <EditConfigModalWrapper
      handleCancelWinner={handleCancelWinner}
      experimentId={experimentId}
      objectiveId={objectiveId}
      handleApplyConfig={handleApplyConfig}
      experimentName={experimentName}
      isActiveConfig={isActiveConfig}
    />
  );
}
