import React, { useCallback } from 'react';
import { InputLabel } from 'crazy-ui-next';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { UserPropertiesInput } from '@pages/createExperiment/targetConfig/userProperties/UserPropertiesInput';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';
import { UserProperty } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';
import { UserPropertyOperator } from '@domain/models/createExperiment/userProperties/UserPropertyOperatorsDto';

type Props = {
  watch: UseFormWatch<TargetConfigParams>;
  control: Control<TargetConfigParams>;
  setValue: UseFormSetValue<TargetConfigParams>;
  hideLabel?: boolean;
  userProperties: UserProperty[];
  operators: UserPropertyOperator[];
  watchName: string;
};

export function UserPropertiesContainer({
  userProperties,
  operators,
  watch,
  control,
  setValue,
  hideLabel = false,
  watchName
}: Props) {
  // @ts-ignore
  const inputs = watch(watchName);

  const onAddRow = useCallback(
    (currentRowIndex: number) => {
      const nextRowIndex = currentRowIndex + 1;
      const nextUserProperty = userProperties[nextRowIndex] || null;

      if (nextUserProperty) {
        // @ts-ignore
        setValue(`${watchName}.${nextRowIndex}`, {
          operatorId: String(operators[0].id),
          userPropertyId: '',
          value: ''
        });
      }
    },
    [watchName, setValue, userProperties, operators]
  );

  const onRemoveRow = useCallback(() => {
    const updatedInputs = inputs.slice(0, -1);

    // @ts-ignore
    setValue(watchName, updatedInputs);
  }, [watchName, setValue, inputs]);

  if (!inputs.length) {
    return null;
  }

  const rows = inputs.map((input, index) => {
    return (
      <UserPropertiesInput
        key={`user_properties_input_${index}`}
        userProperties={userProperties}
        operators={operators}
        control={control}
        inputIndex={index}
        onAddRow={onAddRow}
        onRemoveRow={onRemoveRow}
        watch={watch}
        watchName={watchName}
      />
    );
  });

  return (
    <>
      {!hideLabel && <InputLabel labelText="User Properties" />}
      {rows}
    </>
  );
}
