import React, { useState, useCallback } from 'react';
import { Tooltip } from 'crazy-ui';

import { StringUtils } from '@app/utils/StringUtils';
import styles from './CountryLabel.module.scss';

type Props = {
  countryCode: string;
};

const cdnDomain = 'https://flagcdn.com';

const getSrcSet = (code: string, ext: 'webp' | 'jpg' | 'png') => {
  return `${cdnDomain}/h80/${code}.${ext}, ${cdnDomain}/h120/${code}.${ext} 2x`;
};

export function CountryCircle({ countryCode }: Props) {
  const [hasIcon, setHasIcon] = useState(true);

  const onLoadFailure = useCallback(() => setHasIcon(false), [setHasIcon]);
  const onLoadSuccess = useCallback(() => setHasIcon(true), [setHasIcon]);

  const letters = StringUtils.getFirstLetters(countryCode);
  const code = countryCode.toLowerCase();

  return (
    <Tooltip text={countryCode}>
      <div className={styles.circle} data-code={countryCode}>
        {hasIcon && (
          <picture onError={onLoadFailure} onLoad={onLoadSuccess}>
            <source type="image/webp" srcSet={getSrcSet(code, 'webp')} />
            <source type="image/jpeg" srcSet={getSrcSet(code, 'jpg')} />
            <img src={`${cdnDomain}/h80/${code}.jpg`} alt={countryCode} />
          </picture>
        )}
        <p>{letters}</p>
      </div>
    </Tooltip>
  );
}
