import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';

import { WrappedRoute } from '@components/routes/WrappedRoute';
import { Routing } from '@infrastructure/routing';
import { useSelector } from '@ui/hooks/redux';
import { authSelectors } from '@infrastructure/store/auth/authSelectors';
import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { RequestStage } from '@infrastructure/store/types/actions';
import Wrapper from '@components/wrappers/Wrapper';
import { PageLoader } from '@components/layout/Loader';
import { UserRoleType } from '@domain/enums/UserRoleType';

type Props = {
  pageName?: string;
  forAdmin?: React.ComponentType;
  forNotAdmin?: React.ComponentType;
} & Omit<RouteProps, 'component' | 'render' | 'children'>;

export function RoleRoute({ pageName, forAdmin, forNotAdmin, ...rest }: Props) {
  const isAuth = useSelector(authSelectors.getIsAuth);
  const status = useSelector(authSelectors.getStatus);
  const configStatus = useSelector(configSelectors.getConfigStatus);
  const role = useSelector(authSelectors.getCurrentRole);

  if (status === RequestStage.REQUEST || configStatus === RequestStage.REQUEST) {
    return (
      <Wrapper pageName={pageName}>
        <PageLoader />
      </Wrapper>
    );
  }

  if (!isAuth) {
    return <Redirect to={Routing.getLogin()} />;
  }

  let RoleComponent;

  switch (role) {
    case UserRoleType.ADMIN: {
      RoleComponent = forAdmin;
      break;
    }
    case UserRoleType.QA:
    case UserRoleType.ANALYST:
    case UserRoleType.GAME_OWNER: {
      RoleComponent = forNotAdmin;
      break;
    }
    default:
      RoleComponent = null;
  }

  if (!RoleComponent) {
    return <Redirect to={Routing.getPermissionDenied()} />;
  }

  return <WrappedRoute pageName={pageName} {...rest} component={RoleComponent} />;
}
