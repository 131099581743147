import React from 'react';
import { useSelector } from 'react-redux';
import { memoize } from 'proxy-memoize';

import useModal from '@ui/hooks/useModal';

import { LineChart } from '../common/lineChart/LineChart';
import { FullScreenChartModal } from '@pages/experimentDetails/components/modals/FullScreenChartModal';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { ChartType } from '@domain/enums/ChartType';

const chartName = 'Expected Loss Over Time';
const yAxisLabel = 'Expected loss, %';

export default function ExpectedLossChartContainer() {
  const { showModal, handleOpenModal, handleCloseModal } = useModal();
  const rawData = useSelector(memoize(experimentDetailsSelectors.getFilteredChartByType(ChartType.LOSS_OVER_TIME)));

  if (!rawData.data || Object.keys(rawData.data).length === 0) {
    return null;
  }

  return (
    <>
      <LineChart
        title={chartName}
        yAxisLabel={yAxisLabel}
        formatAxis={(t) => (t < 0.05 ? t.toFixed(3) : t.toFixed(1))}
        chartData={rawData}
        sizeModifier={2}
        handleFullscreen={handleOpenModal}
      />
      {showModal && (
        <FullScreenChartModal
          Chart={
            <LineChart
              title={chartName}
              yAxisLabel={yAxisLabel}
              formatAxis={(t) => t.toFixed(3)}
              chartData={rawData}
              sizeModifier={4}
              fullscreen
            />
          }
          handleModal={handleCloseModal}
        />
      )}
    </>
  );
}
