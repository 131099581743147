import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { IHttp } from '@app/ports/IHttp';

export abstract class HttpClient implements IHttp {
  protected readonly client: AxiosInstance;

  constructor(config?: AxiosRequestConfig) {
    this.client = axios.create(config);
  }

  mapResponse(res: AxiosResponse) {
    return res.data;
  }

  setHeader(key: string, value: string): void {
    this.client.defaults.headers.common[key] = value;
  }

  async get<T = any>(path: string, params?: object, config?: AxiosRequestConfig): Promise<T> {
    return this.client.get(path, { ...config, params }).then(this.mapResponse);
  }

  async delete<T = any>(path: string, config?: AxiosRequestConfig): Promise<T> {
    return this.client.delete(path, config).then(this.mapResponse);
  }

  async post<T = any>(path: string, payload?: object, config?: AxiosRequestConfig): Promise<T> {
    return this.client.post(path, payload, config).then(this.mapResponse);
  }

  async put<T = any>(path: string, payload?: object, config?: AxiosRequestConfig): Promise<T> {
    return this.client.put(path, payload, config).then(this.mapResponse);
  }

  async patch<T = any>(path: string, payload?: object, config?: AxiosRequestConfig): Promise<T> {
    return this.client.patch(path, payload, config).then(this.mapResponse);
  }
}
