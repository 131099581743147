export function getErrorMessage(err) {
  try {
    if (err.response && err.response.data && err.response.data.errors) {
      let message = '';

      err.response.data.errors.forEach((item) => {
        message = `${message} ${item.errorType}: ${item.errorMessage};\n`;
      });

      return message;
    }

    return err.message;
  } catch (e) {
    return '';
  }
}
