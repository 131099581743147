import React, { useCallback } from 'react';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { DropdownEvent } from 'crazy-ui-next';

import { UserManagementFilter } from '@pages/userManagement/filters/UserManagementFilter';
import { userManagementSelectors } from '@infrastructure/store/userManagement/userManagementSelectors';
import {
  resetFilters,
  setRoleFilter,
  setSearchPhraseFilter,
  setSortBy,
  setStatusFilter
} from '@infrastructure/store/userManagement/userManagementActions';

import { TextEvent } from '@ui/types/shared';
import {
  UserRoleFilterValue,
  UserSortValue,
  UserStatusFilterValue
} from '@infrastructure/store/userManagement/types/filters';

export function UserManagementFilterContainer() {
  const dispatch = useDispatch();
  const { searchPhrase, status, role, sortBy } = useSelector(userManagementSelectors.getFilters);

  const handleSortBy = useCallback(
    (e: DropdownEvent<UserSortValue>) => {
      dispatch(setSortBy(e.value));
    },
    [dispatch]
  );

  const handleSearch = useCallback(
    (e: TextEvent) => {
      const { value } = e.currentTarget;
      dispatch(setSearchPhraseFilter(value));
    },
    [dispatch]
  );

  const handleChangeStatus = useCallback(
    (e: DropdownEvent<UserStatusFilterValue>) => {
      dispatch(setStatusFilter(e.value));
    },
    [dispatch]
  );

  const handleChangeRole = useCallback(
    (e: DropdownEvent<UserRoleFilterValue>) => {
      dispatch(setRoleFilter(e.value));
    },
    [dispatch]
  );

  const handleReset = useCallback(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  return (
    <UserManagementFilter
      sortBy={sortBy}
      searchFilter={searchPhrase}
      statusFilter={status}
      roleFilter={role}
      handleSortBy={handleSortBy}
      handleSearch={handleSearch}
      handleChangeStatus={handleChangeStatus}
      handleChangeRole={handleChangeRole}
      handleReset={handleReset}
    />
  );
}
