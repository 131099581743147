import React from 'react';

import { formatDate } from '@infrastructure/utils/libs/formatData';
import { UnixTimestamp } from '@domain/types';
import { ReactComponent as CalendarSvg } from '@assets/svg/calendar.svg';

import styles from '@pages/experimentDetails/atoms/labels/Labels.module.scss';

type Props = {
  startDate: UnixTimestamp;
  endDate: UnixTimestamp;
};

export function Dates({ startDate, endDate }: Props) {
  const formattedStartDate = formatDate(new Date(startDate));
  const formattedEndDate = formatDate(new Date(endDate));

  return (
    <div className={styles.dates}>
      <CalendarSvg className={styles.icon} />
      <div className={styles.startDate}>{startDate ? formattedStartDate : '-'}</div>
      <div className={styles.endDate}>{endDate ? formattedEndDate : '-'}</div>
    </div>
  );
}
