export class UserAllocationService {
  static calcUserGroups(
    groupCount: number,
    averageInstalls: number | null,
    usersAllocationPercent: string,
    builtInGroup?: boolean
  ): {
    groupCount: number;
    usedDailyInstallsPerGroup: number | null;
  } {
    const mappedGroupCount = builtInGroup ? groupCount + 1 : groupCount;

    if (!averageInstalls) {
      return {
        groupCount: mappedGroupCount,
        usedDailyInstallsPerGroup: null
      };
    }

    const usedDailyInstalls = (averageInstalls * Number(usersAllocationPercent)) / 100;
    const usedDailyInstallsPerGroup = usedDailyInstalls / mappedGroupCount;

    return {
      groupCount: mappedGroupCount,
      usedDailyInstallsPerGroup: Math.round(usedDailyInstallsPerGroup)
    };
  }

  static getRecGroupsNumber(averageInstalls: number, minUsersPerGroup: number): number {
    const recGroupsNumber = averageInstalls / minUsersPerGroup;

    return Math.floor(recGroupsNumber);
  }
}
