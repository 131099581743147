import { all, Effect } from 'redux-saga/effects';
import { RootSagaWatcher } from '@infrastructure/sagas/RootSagaWatcher';

export abstract class BaseSagaWatcher {
  constructor(protected rootWatcher: RootSagaWatcher) {
    this.rootWatcher.register(this.getWatcher());
  }

  private getWatcher() {
    const effects = this.getEffects();
    return function* sagaWatcher() {
      return yield all(effects);
    };
  }

  abstract getEffects(): Effect[];
}
