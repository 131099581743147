import { Type } from 'class-transformer';

import { Dto } from '@domain/models/Dto';
import { RegionalConfigDto } from '@domain/models/RegionalConfigDto';

export class WinnerConfigDto extends Dto {
  @Type(() => RegionalConfigDto)
  regionalConfigs: RegionalConfigDto[];

  version: string[];
}
