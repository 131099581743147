import createMigrate from 'redux-persist/lib/createMigrate';

export const filterMigration = createMigrate({
  2: (state) => {
    // @ts-ignore
    const { businessLines, ...rest } = state;
    return rest;
  },
  3: (state) => {
    // @ts-ignore
    const { statuses, type, businessLines, sortBy, ...rest } = state;
    return rest;
  },
  4: (state) => {
    // @ts-ignore
    const { sortBy, ...rest } = state;
    return rest;
  },
  5: () => {
    return {} as any;
  },
  6: () => {
    // Reset everything
    return {} as any;
  }
});
