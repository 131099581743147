import capitalizeString from '@infrastructure/utils/libs/capitalizeString';

export class VariableTitleMapper {
  static fromKey(key: string): string {
    const withSpaces = key.replace(/_/gi, ' ');
    const words = withSpaces.split(' ');

    const capitalWords = words.map((word) => capitalizeString(word));
    return capitalWords.join(' ');
  }
}
