import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { Service } from 'typedi';

import { AppliedConfigService } from '@app/services/AppliedConfigService';
import { WinnerConfigService } from '@app/services/WinnerConfigService';
import { RootSagaWatcher } from '@infrastructure/sagas/RootSagaWatcher';
import { BaseSagaWatcher } from '@infrastructure/sagas/BaseSagaWatcher';
import { Notification } from '@infrastructure/api/notifications';
import {
  deleteAppliedConfig,
  fetchAppliedConfigList,
  setDateFilter,
  setPlatformFilter,
  setSearchPhraseFilter,
  setStatusFilter,
  setTypeFilter,
  updateAppliedConfig,
  fetchExperiment
} from '@infrastructure/store/appliedConfig/appliedConfigActions';
import { AppliedConfigViewDto } from '@domain/models/appliedConfig/AppliedConfigViewDto';

@Service()
export class AppliedConfigSaga extends BaseSagaWatcher {
  private filterDelayMs = 300;

  constructor(
    private appliedConfigService: AppliedConfigService,
    private winnerConfigService: WinnerConfigService,
    rootWatcher: RootSagaWatcher
  ) {
    super(rootWatcher);
  }

  public getEffects() {
    return [
      takeLatest(fetchAppliedConfigList.TRIGGER, this.getList.bind(this)),
      takeLatest(fetchExperiment.TRIGGER, this.getExperiment.bind(this)),
      takeLatest(updateAppliedConfig.SUCCESS, this.getList.bind(this)),
      takeLatest(deleteAppliedConfig.SUCCESS, this.getList.bind(this)),

      takeLatest(updateAppliedConfig.TRIGGER, this.updateAppliedConfig.bind(this)),
      takeLatest(deleteAppliedConfig.TRIGGER, this.deleteAppliedConfig.bind(this)),

      /* Filters */
      takeLatest(setSearchPhraseFilter, this.handleFilterWithDelay.bind(this)),
      takeLatest(setStatusFilter, this.handleFilterImmediate.bind(this)),
      takeLatest(setTypeFilter, this.handleFilterImmediate.bind(this)),
      takeLatest(setPlatformFilter, this.handleFilterImmediate.bind(this)),
      takeLatest(setDateFilter, this.handleFilterImmediate.bind(this))
    ];
  }

  public *handleFilterWithDelay() {
    yield delay(this.filterDelayMs);
    yield put(fetchAppliedConfigList.trigger());
  }

  public *handleFilterImmediate() {
    yield put(fetchAppliedConfigList.trigger());
  }

  public *getList() {
    try {
      yield put(fetchAppliedConfigList.request());
      const list: AppliedConfigViewDto[] = yield call([
        this.appliedConfigService,
        this.appliedConfigService.getGamesWithConfigs
      ]);
      yield put(fetchAppliedConfigList.success(list));
    } catch (err) {
      Notification.error(err);
      yield put(fetchAppliedConfigList.failure());
    }
  }

  public *getExperiment(action: ReturnType<typeof fetchExperiment.trigger>) {
    try {
      yield put(fetchExperiment.request());

      const experiment = yield call(
        [this.appliedConfigService, this.appliedConfigService.getExperiment],
        action.payload
      );

      yield put(fetchExperiment.success(experiment));
    } catch (err) {
      Notification.error(err);
      yield put(fetchExperiment.failure());
    }
  }

  *updateAppliedConfig(action: ReturnType<typeof updateAppliedConfig.trigger>) {
    try {
      yield put(updateAppliedConfig.request());

      yield call([this.winnerConfigService, this.winnerConfigService.update], action.payload);

      yield put(updateAppliedConfig.success());
    } catch (err) {
      Notification.error(err);
      yield put(updateAppliedConfig.failure());
    }
  }

  *deleteAppliedConfig(action: ReturnType<typeof deleteAppliedConfig.trigger>) {
    try {
      yield put(deleteAppliedConfig.request());

      yield call(
        [this.winnerConfigService, this.winnerConfigService.delete],
        action.payload.experimentId,
        action.payload.objectiveId
      );

      yield put(deleteAppliedConfig.success());
    } catch (err) {
      Notification.error(err);
      yield put(deleteAppliedConfig.failure());
    }
  }
}
