import React from 'react';
import cn from 'classnames';
import { BlockLoader } from 'crazy-ui';

import styles from './FormBottomControls.module.scss';

export default function FormBottomControls({ children, className = '', showBorder = true, isLoading = false }) {
  if (isLoading) {
    return <BlockLoader />;
  }

  return <div className={cn(styles.controls, showBorder && styles.withBorder, className)}>{children}</div>;
}
