import { PageReqParams } from '@domain/models/Paginate';

export class OffsetPagination {
  private pageNumber: number = 0;
  private pageSize: number = 10;
  private total?: number;

  constructor(pageNumber?: number, pageSize?: number, total?: number) {
    if (pageNumber && !isNaN(pageNumber) && Number(pageNumber) > 0) {
      this.pageNumber = Number(pageNumber);
    }

    if (pageSize && !isNaN(pageSize) && Number(pageSize) > 0) {
      this.pageSize = Number(pageSize);
    }

    this.total = Number(total);
  }

  setPage(page: number) {
    this.pageNumber = Number(page);
    return this;
  }

  setPageSize(pageSize: number) {
    this.pageSize = Number(pageSize);
    return this;
  }

  setTotal(total) {
    this.total = Number(total);
    return this;
  }

  getParams(): PageReqParams {
    return {
      page: this.pageNumber,
      size: this.pageSize
    };
  }
}
