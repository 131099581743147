import React, { useCallback } from 'react';
import { useSelector, useDispatch } from '@ui/hooks/redux';

import { experimentListSelectors } from '@infrastructure/store/experimentList/experimentListSelectors';
import { fetchNextExperiments } from '@infrastructure/store/experimentList/experimentListActions';
import { ExperimentTable } from '@pages/experimentList/table/ExperimentTable';
import { InfiniteScrollAnchor } from '@components/layout/infiniteScroll/InfiniteScrollAnchor';

export function ExperimentTableContainer() {
  const dispatch = useDispatch();

  const status = useSelector(experimentListSelectors.getExperimentsStatus);
  const experimentsIdList = useSelector(experimentListSelectors.getExperimentsIdList);
  const { isNextAvailable } = useSelector(experimentListSelectors.getPagination);

  const handleFetchNext = useCallback(() => {
    dispatch(fetchNextExperiments());
  }, [dispatch]);

  return (
    <>
      <ExperimentTable status={status} idList={experimentsIdList} />
      <InfiniteScrollAnchor isNextAvailable={isNextAvailable} status={status} handleFetchNext={handleFetchNext} />
    </>
  );
}
