import React from 'react';
import { useSelector } from 'react-redux';
import { memoize } from 'proxy-memoize';

import useModal from '@ui/hooks/useModal';
import KpiConfidenceChart from './KpiConfidenceChart';

import { FullScreenChartModal } from '@pages/experimentDetails/components/modals/FullScreenChartModal';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { ChartType } from '@domain/enums/ChartType';

const chartName = 'KPI Rate With Confidence Interval Over Time';

export default function KpiConfidenceChartContainer() {
  const { showModal, handleOpenModal, handleCloseModal } = useModal();
  const rawData = useSelector(memoize(experimentDetailsSelectors.getFilteredChartByType(ChartType.KPI_CONFIDENCE)));

  if (!rawData.data || Object.keys(rawData.data).length === 0) {
    return null;
  }

  return (
    <>
      <KpiConfidenceChart
        title={chartName}
        yAxisLabel="Rate"
        chartData={rawData}
        sizeModifier={2}
        handleFullscreen={handleOpenModal}
      />
      {showModal && (
        <FullScreenChartModal
          Chart={
            <KpiConfidenceChart title={chartName} yAxisLabel="Rate" chartData={rawData} sizeModifier={4} fullscreen />
          }
          handleModal={handleCloseModal}
        />
      )}
    </>
  );
}
