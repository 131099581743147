import { ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

import { DevBasicInfoParams } from '@domain/models/experimentDetails/devPhase/DevBasicInfoParams';
import { DevTargetingParams } from '@domain/models/experimentDetails/devPhase/DevTargetingParams';
import { DevObjConfigParams } from '@domain/models/experimentDetails/devPhase/DevObjConfigParams';

export class DevPhaseParams {
  @ValidateNested()
  @Type(() => DevBasicInfoParams)
  public basicInfo: DevBasicInfoParams;

  @ValidateNested()
  @Type(() => DevTargetingParams)
  public targetingConfig: DevTargetingParams;

  @ValidateNested()
  @Type(() => DevObjConfigParams)
  public objectiveConfig: DevObjConfigParams;

  static ofInitial() {
    const form = new DevPhaseParams();

    form.basicInfo = DevBasicInfoParams.ofInitial();
    form.targetingConfig = DevTargetingParams.ofInitial();
    form.objectiveConfig = DevObjConfigParams.ofInitial();

    return form;
  }
}
