import React from 'react';
import { memoize } from 'proxy-memoize';

import { useToggle } from '@ui/hooks/useToggle';

import { ExpDefinitionSummary } from '@pages/experimentDetails/components/summary/config/definition/ExpDefinitionSummary';
import { ExpDefinitionEditor } from '@pages/experimentDetails/components/summary/config/definition/ExpDefinitionEditor';
import { FormControls } from '@pages/experimentDetails/components/summary/config/atoms/FormControls';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { useForm } from '@ui/hooks/form';
import { ExpDefinitionParams } from '@domain/models/experimentDetails/ExpDefinitionParams';
import { pushUpdateExperimentDefinition } from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { RequestStage } from '@infrastructure/store/types/actions';
import { configSelectors } from '@infrastructure/store/config/configSelectors';

export function ExpDefinitionContainer() {
  const dispatch = useDispatch();

  const { isActive: editorEnabled, handleOpen: openEditor, handleClose: closeEditor } = useToggle(false);

  const form = useSelector(memoize(experimentDetailsSelectors.getExpDefinitionParams));
  const { definitionUpdate: status } = useSelector(experimentDetailsSelectors.getSummaryEditorStatus);
  const isEditorLoading = useSelector(experimentDetailsSelectors.getIsSummaryEditorLoading);
  const userProperties = useSelector(configSelectors.getUserProperties);
  const userPropertyOperators = useSelector(configSelectors.getUserPropertyOperators);

  const isLoading = status === RequestStage.REQUEST;

  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    setValue
  } = useForm<ExpDefinitionParams>({
    schema: ExpDefinitionParams,
    defaultValues: form
  });

  const handleFormSubmit = handleSubmit((params) => {
    dispatch(pushUpdateExperimentDefinition.trigger(params));
    closeEditor();
  });

  const controlsDisabled: { submit: boolean; edit: boolean } = {
    submit: isEditorLoading || !isValid,
    edit: isEditorLoading
  };

  return (
    <>
      {editorEnabled ? (
        <ExpDefinitionEditor
          control={control}
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          userProperties={userProperties}
          operators={userPropertyOperators}
        />
      ) : (
        <ExpDefinitionSummary />
      )}
      <FormControls
        openEditor={openEditor}
        closeEditor={closeEditor}
        editorEnabled={editorEnabled}
        handleSubmit={handleFormSubmit}
        isLoading={isLoading}
        controlsDisabled={controlsDisabled}
      />
    </>
  );
}
