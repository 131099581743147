import React from 'react';
import { CheckboxInput } from 'crazy-ui-next';
import { FormComponent, getInputProps } from '@ui/hooks/form';
import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'register'>;

export function BuiltInConfigInput({ register }: Props) {
  return (
    <CheckboxInput
      labelText="Built-in config"
      {...getInputProps<ObjectiveConfigParams>(`builtInGroup`, register, {})}
    />
  );
}
