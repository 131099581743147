import React from 'react';
import { InputLabel } from 'crazy-ui-next';

import { ExperimentVariableType } from '@domain/enums/ExperimentVariableType';
import { InputGroupSessionForm, ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { ControlledIntegerListInput } from '@pages/createExperiment/objectiveConfig/forms/AB/components/inputGroup/inputs/ControlledIntegerListInput';
import { ControlledIntegerInput } from '@pages/createExperiment/objectiveConfig/forms/AB/components/inputGroup/inputs/ControlledIntegerInput';
import { ControlledBooleanInput } from '@pages/createExperiment/objectiveConfig/forms/AB/components/inputGroup/inputs/ControlledBooleanInput';
import { ControlledTextInput } from '@pages/createExperiment/objectiveConfig/forms/AB/components/inputGroup/inputs/ControlledTextInput';
import { FormComponent } from '@ui/hooks/form';
import DividerLine from '@components/layout/dividerLine/DividerLine';

import { ReactComponent as CrossSvg } from '@assets/svg/cross.svg';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/inputGroup/InputGroup.module.scss';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'control' | 'register'> & {
  sectionIndex: number;
  sessionIndex: number;
  variables: ExperimentVariableDto[];
  handleRemoveSection: (number) => void;
  defaultInputGroup: InputGroupSessionForm;
};

export function Session({
  control,
  register,
  sectionIndex,
  sessionIndex,
  variables,
  handleRemoveSection,
  defaultInputGroup
}: Props) {
  // session index 0 will be used if sessions not supported
  const inputs = variables.map(({ type, name, displayName, maxValue, minValue, sessionsSupported }) => {
    const value = defaultInputGroup[name];
    const defaultValue = Array.isArray(value) ? value[0].value : value;

    switch (type) {
      case ExperimentVariableType.INT_LIST:
        return (
          <div className={styles.inputRow} key={name}>
            <ControlledIntegerListInput
              control={control}
              name={name}
              sessionIndex={sessionIndex}
              sectionIndex={sectionIndex}
              labelText={displayName}
              min={minValue}
              max={maxValue}
              defaultValue={defaultValue}
            />
          </div>
        );
      case ExperimentVariableType.INTEGER:
        return (
          <div className={styles.inputRow} key={name}>
            <ControlledIntegerInput
              control={control}
              name={name}
              sessionIndex={sessionsSupported ? sessionIndex : 0}
              sectionIndex={sectionIndex}
              labelText={displayName}
              min={minValue}
              max={maxValue}
              defaultValue={defaultValue}
            />
          </div>
        );
      case ExperimentVariableType.BOOLEAN:
        return (
          <div className={styles.inputRow} key={name}>
            <ControlledBooleanInput
              control={control}
              name={name}
              sectionIndex={sectionIndex}
              sessionIndex={sessionsSupported ? sessionIndex : 0}
              labelText={displayName}
            />
          </div>
        );
      default:
        return (
          <div className={styles.inputRow} key={name}>
            <ControlledTextInput
              control={control}
              register={register}
              name={name}
              sectionIndex={sectionIndex}
              sessionIndex={sessionsSupported ? sessionIndex : 0}
              labelText={displayName}
              defaultValue={defaultValue}
            />
          </div>
        );
    }
  });

  return (
    <>
      <div>
        <div className={styles.sectionTitle}>
          <InputLabel labelText={`Section ${sectionIndex + 1}`} />
          {sectionIndex !== 0 && (
            <button type="button" data-index={sectionIndex} onClick={handleRemoveSection}>
              <CrossSvg />
            </button>
          )}
        </div>
        <DividerLine />
      </div>

      <fieldset>{inputs}</fieldset>
    </>
  );
}
