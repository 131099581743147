import React from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';

import { mapGameStatsTypeToData } from '@pages/createExperiment/targetConfig/components/gameStats/mapper/mapGameStatsTypeToData';
import { ReactComponent as ArrowSvg } from '@assets/svg/arrow-up-circle.svg';
import { GameStatsType } from '@domain/enums/GameStatsType';

import styles from '@pages/createExperiment/targetConfig/components/gameStats/GameStats.module.scss';

type Props = {
  stats: { country: string; value: number }[];
  filter: GameStatsType;
  handleSetFilter: (GameStatsType) => void;
};

export function GameStatsList({ handleSetFilter, filter, stats }: Props) {
  const { title, next, prev } = mapGameStatsTypeToData(filter);

  return (
    <div className={styles.stats}>
      <div className={styles.headers}>
        <p>{title}</p>
        <div className={styles.controls}>
          <Button
            className={styles.prev}
            variant={ButtonVariant.TERTIARY}
            onClick={() => handleSetFilter(prev)}
            icon={<ArrowSvg />}
            disabled={!prev}
          />
          <Button
            className={styles.next}
            variant={ButtonVariant.TERTIARY}
            onClick={() => handleSetFilter(next)}
            icon={<ArrowSvg />}
            disabled={!next}
          />
        </div>
      </div>
      <ul>
        {stats.map(({ country, value }) => {
          return (
            <li key={country}>
              <span>{country}</span>
              <span>{value.toFixed(2)}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
