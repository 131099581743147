import React from 'react';

import { ReactComponent as SpinnerSvg } from '@assets/svg/spinner.svg';

export function PageLoader() {
  return (
    <section className="container">
      <div className="loader-page">
        <SpinnerSvg />
      </div>
    </section>
  );
}

export function BlockLoader() {
  return (
    <div className="loader-block">
      <SpinnerSvg />
    </div>
  );
}

export function InlineLoader() {
  return (
    <span className="loader-inline">
      <SpinnerSvg />
    </span>
  );
}

export function SvgLoader() {
  return <SpinnerSvg />;
}
