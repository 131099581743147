import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';
import { useController } from 'react-hook-form';

import { ExperimentFormFormatter } from '@app/mappers/experiment/ExperimentFormFormatter';
import { FormComponent } from '@ui/hooks/form';
import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { ReactComponent as EditSvg } from '@assets/svg/edit.svg';
import { ReactComponent as DeleteSvg } from '@assets/svg/trash.svg';

import styles from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/InputGroup.module.scss';

type Props = Pick<FormComponent<GLDConfigParams>, 'control'> & {
  configIndex: number;
  inputIndex: number;
};

export function EditableCell({ configIndex, inputIndex, control }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEdit, setIsEdit] = useState(false);

  const { field } = useController({ control, name: `config.${configIndex}.input.${inputIndex}.value` });
  const { value, onChange } = field;

  const handleEdit = useCallback(() => {
    setIsEdit(true);
  }, [setIsEdit]);

  const handleBlur = useCallback(() => {
    setIsEdit(false);
  }, [setIsEdit]);

  const handleRemove = useCallback(() => {
    onChange(null);
  }, [onChange]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Escape'].includes(event.key)) {
      setIsEdit(false);
    }
  }, []);

  useEffect(() => {
    if (isEdit && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  const formattedValue = ExperimentFormFormatter.formatGLDValue(value);

  return (
    <td className={styles.editableCell} data-edit={isEdit} data-highlight={!value}>
      {isEdit ? (
        <input
          onKeyDown={handleKeyDown}
          ref={inputRef}
          type="text"
          value={value as string}
          onBlur={handleBlur}
          onChange={onChange}
        />
      ) : (
        <span>{formattedValue}</span>
      )}
      <div className={styles.controls}>
        <Button variant={ButtonVariant.TERTIARY} onClick={handleEdit} icon={<EditSvg />} />
        <Button variant={ButtonVariant.TERTIARY} onClick={handleRemove} icon={<DeleteSvg />} />
      </div>
    </td>
  );
}
