import React from 'react';
import { Button } from 'crazy-ui-next';
import { ExperimentTableContainer } from '@pages/experimentList/table/ExperimentTableContainer';
import { PageTitle } from '@components/layout/PageTitle';

import CheckPermission from '@components/layout/CheckPermissions';
import { Routing } from '@infrastructure/routing';
import { ReactComponent as PlusSvg } from '@assets/svg/plus.svg';
import ExperimentFilterContainer from './filters/ExperimentFilterContainer';

export function ExperimentListPage() {
  return (
    <>
      <PageTitle label="Experiment List">
        <CheckPermission admin analyst qa>
          <Button href={Routing.getCreateExperiment()} icon={<PlusSvg />}>
            Create Experiment
          </Button>
        </CheckPermission>
      </PageTitle>
      <main className="container">
        <ExperimentFilterContainer />
        <ExperimentTableContainer />
      </main>
    </>
  );
}
