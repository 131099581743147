import React from 'react';
import { CheckboxInput } from 'crazy-ui-next';

import { FormComponent, getInputProps } from '@ui/hooks/form';
import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';

import styles from '@pages/createExperiment/objectiveConfig/forms/GLD/GLDForm.module.scss';

type Props = Pick<FormComponent<GLDConfigParams>, 'register'>;

export function AdjustableUsersInput({ register }: Props) {
  return (
    <CheckboxInput
      labelText="Adjustable percent of users"
      className={styles.input}
      {...getInputProps<GLDConfigParams>(`adjustableUsersAllocation`, register, {})}
    />
  );
}
