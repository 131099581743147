import { combineReducers, createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  fetchGameInstallsStats,
  fetchGameStats,
  setGame,
  setGameStatsFilter
} from '@infrastructure/store/createExperiment/createExperimentActions';
import { GameStatsType } from '@domain/enums/GameStatsType';
import { GameStatsDto } from '@domain/models/experiment/GameStatsDto';
import { GameInstallsStatsDto } from '@domain/models/game/GameInstallsStatsDto';

const gameStatsDataInitial: GameStatsDto[] = [];

const gameStatsDataReducer = createReducer(gameStatsDataInitial, (qb) => {
  qb.addCase(fetchGameStats.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(fetchGameStats.failure, () => {
    return gameStatsDataInitial;
  });
  qb.addCase(setGame, () => {
    return gameStatsDataInitial;
  });
  qb.addCase(clearAll, () => {
    return gameStatsDataInitial;
  });
});

const gameStatsFilterInitial = GameStatsType.USER_PER_DAY;

const gameStatsFilterReducer = createReducer(gameStatsFilterInitial, (qb) => {
  qb.addCase(setGameStatsFilter, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return gameStatsFilterInitial;
  });
});

const gameInstallsStatsInitial: Record<string, GameInstallsStatsDto> = {};

const gameInstallsStatsReducer = createReducer(gameInstallsStatsInitial, (qb) => {
  qb.addCase(fetchGameInstallsStats.success, (state, { payload }) => {
    const { region, stats } = payload;
    state[region] = stats;
    return state;
  });

  qb.addCase(setGame, () => {
    return gameInstallsStatsInitial;
  });

  qb.addCase(clearAll, () => {
    return gameInstallsStatsInitial;
  });
});

export const gameStatsReducer = combineReducers({
  data: gameStatsDataReducer,
  installs: gameInstallsStatsReducer,
  filter: gameStatsFilterReducer
});
