import { ActionFactory } from '@infrastructure/store/helpers/ActionFactory';

import { UniqueId } from '@domain/types';
import { UpdateWinnerConfigParams } from '@domain/models/winnerConfig/WinnerConfigFormParams';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import { ExpDefinitionParams } from '@domain/models/experimentDetails/ExpDefinitionParams';
import { ExpObjectiveConfigParams } from '@domain/models/experimentDetails/ExpObjectiveConfigParams';
import { ExpGroupsConfigParams } from '@domain/models/experimentDetails/ExpGroupsConfigParams';
import { GameStatsDto } from '@domain/models/experiment/GameStatsDto';
import { GameStatsType } from '@domain/enums/GameStatsType';
import { RegionDto } from '@domain/models/RegionDto';
import { GameInstallsStatsDto } from '@domain/models/game/GameInstallsStatsDto';
import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';
import { IterationDto } from '@domain/models/iteration/IterationDto';
import { IterationRequestParams } from '@domain/models/iteration/IterationRequestParams';
import { IterationResultDto } from '@domain/models/iteration/IterationResultDto';
import { ChartDto } from '@domain/models/ChartDto';
import { ChartType } from '@domain/enums/ChartType';
import { AuditDto } from '@domain/models/experiment/AuditDto';
import { LogDto } from '@domain/models/LogDto';

const namespace = 'experimentDetails';

export const fetchExperiment = ActionFactory.createRequestRoutine<UniqueId, ExperimentDto>(
  'EXPERIMENT_FETCH',
  namespace
);

export const fetchDetailsView = ActionFactory.createRequestRoutine<UniqueId>('DETAILS_VIEW_FETCH', namespace);

export const fetchDefaultRegions = ActionFactory.createRequestRoutine<void, RegionDto[]>(
  'DEFAULT_REGIONS_FETCH',
  namespace
);

export const pushUpdateDevPhase = ActionFactory.createRequestRoutine<DevPhaseParams, UniqueId>(
  'EXPERIMENT_DEV_PHASE_UPDATE',
  namespace
);

export const pushUpdateExperimentDefinition = ActionFactory.createRequestRoutine<ExpDefinitionParams, UniqueId>(
  'EXPERIMENT_UPDATE',
  namespace
);

export const pushUpdateExpObjective = ActionFactory.createRequestRoutine<ExpObjectiveConfigParams, UniqueId>(
  'EXPERIMENT_OBJECTIVE_UPDATE',
  namespace
);

export const pushStopGroups = ActionFactory.createRequestRoutine<ExpGroupsConfigParams, UniqueId>(
  'EXPERIMENT_STOP_GROUPS',
  namespace
);

export const applyExperimentWinner = ActionFactory.createRequestRoutine<UpdateWinnerConfigParams, UniqueId>(
  'WINNER_APPLY',
  namespace
);

export const updateExperimentWinner = ActionFactory.createRequestRoutine<UpdateWinnerConfigParams, UniqueId>(
  'WINNER_UPDATE',
  namespace
);

export const deleteExperimentWinner = ActionFactory.createRequestRoutine<
  {
    experimentId: UniqueId;
    objectiveId: UniqueId;
  },
  UniqueId
>('WINNER_DELETE', namespace);

export const pushRestoreObjective = ActionFactory.createRequestRoutine<
  {
    experimentId: UniqueId;
    objectiveId: UniqueId;
  },
  UniqueId
>('RESTORE_OBJECTIVE_PUSH', namespace);

export const pushStopObjective = ActionFactory.createRequestRoutine<
  {
    experimentId: UniqueId;
    objectiveId: UniqueId;
  },
  UniqueId
>('STOP_OBJECTIVE_PUSH', namespace);

export const setSelectedObjective = ActionFactory.createAction<number>('SELECTED_OBJECTIVE_SET');

export const updateObjectives = ActionFactory.createAction<{
  experimentObjectives: ExperimentObjectiveDto[];
  selected: ExperimentObjectiveDto;
}>('OBJECTIVES_UPDATE', namespace);

export const fetchGameStats = ActionFactory.createRequestRoutine<void, GameStatsDto[]>('GAME_STATS_FETCH', namespace);

export const setGameStatsFilter = ActionFactory.createAction<GameStatsType>('GAME_STATS_FILTER_SET', namespace);

export const fetchGameInstallsStats = ActionFactory.createRequestRoutine<
  RegionDto,
  { region: string; stats: GameInstallsStatsDto }
>('GAME_INSTALLS_STATS_FETCH', namespace);

export const fetchExperimentIterations = ActionFactory.createRequestRoutine<UniqueId, IterationDto[] | null>(
  'EXPERIMENT_ITERATIONS_FETCH',
  namespace
);

export const setIterationFilters = ActionFactory.createAction<IterationRequestParams>(
  'ITERATION_FILTERS_SET',
  namespace
);

export const setSelectedIteration = ActionFactory.createAction<IterationDto>('CURRENT_ITERATION_SET', namespace);

export const fetchIterationResult = ActionFactory.createRequestRoutine<void, IterationResultDto[]>(
  'ITERATION_RESULT_FETCH',
  namespace
);
export const setCacheIterationResult = ActionFactory.createAction<Record<string, IterationResultDto[]>>(
  'ITERATION_RESULT_CACHE_SET',
  namespace
);
export const toggleMetricKey = ActionFactory.createAction<string>('METRIC_KEY_TOGGLE', namespace);
export const toggleSelectedRow = ActionFactory.createAction<string>('SELECTED_ROW_TOGGLE', namespace);

export const fetchCharts = ActionFactory.createRequestRoutine<string, Record<ChartType, ChartDto>>(
  'CHARTS_FETCH',
  namespace
);
export const setCacheCharts = ActionFactory.createAction<Record<string, Record<ChartType, ChartDto>>>(
  'CHARTS_CACHE_SET',
  namespace
);
export const fetchExperimentAudit = ActionFactory.createRequestRoutine<void, AuditDto[]>(
  'EXPERIMENT_AUDIT_FETCH',
  namespace
);
export const fetchExperimentLogList = ActionFactory.createRequestRoutine<void, string[]>(
  'EXPERIMENT_LOG_LIST_FETCH',
  namespace
);
export const fetchExperimentLog = ActionFactory.createRequestRoutine<void, LogDto>('EXPERIMENT_LOG_FETCH', namespace);
export const pushExperimentLogName = ActionFactory.createAction<string>('EXPERIMENT_LOG_NAME_PUSH', namespace);

export const pushRequestAnalysis = ActionFactory.createRequestRoutine('REQUEST_ANALYSIS_PUSH', namespace);

export const pushScheduleGLDExperiment = ActionFactory.createRequestRoutine<UniqueId, UniqueId>(
  'PUSH_SCHEDULE_GLD',
  namespace
);
export const pushCancelExperiment = ActionFactory.createRequestRoutine<UniqueId, UniqueId>('PUSH_CANCEL', namespace);

export const clearAll = ActionFactory.createAction('CLEAR_ALL', namespace);
