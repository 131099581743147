import React from 'react';
import cn from 'classnames';

import { UniqueId } from '@domain/types';
import { RequestStage } from '@infrastructure/store/types/actions';
import { ExperimentHeader } from '@pages/experimentList/table/ExperimentHeader';
import { ExperimentRow } from '@pages/experimentList/table/ExperimentRow';
import { ExperimentSkeletonRow } from '@pages/experimentList/table/ExperimentSkeletonRow';

import baseStyles from '@components/layout/table/Table.module.scss';
import styles from './ExperimentTable.module.scss';

type Props = {
  idList: UniqueId[];
  status: RequestStage;
};

export function ExperimentTable({ idList, status }: Props) {
  const isFetching = status === RequestStage.REQUEST;
  const rows = idList.map((experimentId) => <ExperimentRow key={`e_${experimentId}`} experimentId={experimentId} />);

  return (
    <table id="experiment-list" className={cn(baseStyles.table, styles.table)}>
      <thead>
        <ExperimentHeader />
      </thead>
      <tbody>
        {rows}
        {isFetching && (
          <>
            <ExperimentSkeletonRow />
            <ExperimentSkeletonRow />
            <ExperimentSkeletonRow />
          </>
        )}
      </tbody>
    </table>
  );
}
