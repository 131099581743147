import React from 'react';

import { Charts } from '@pages/experimentDetails/components/stats/components/result/components/charts/Charts';
import { useSelector } from 'react-redux';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { RequestStage } from '@infrastructure/store/types/actions';
import { BlockLoader } from '@components/layout/Loader';

export function ChartsContainer() {
  const status = useSelector(experimentDetailsSelectors.getChartsStatus);

  if (status === RequestStage.REQUEST) {
    return <BlockLoader />;
  }

  if (status === RequestStage.NONE) {
    return null;
  }

  return <Charts />;
}
