import { createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  generateDatesConfig,
  pushDatesConfigForm
} from '@infrastructure/store/createExperiment/createExperimentActions';
import { DateConfigParams } from '@domain/models/createExperiment/DateConfigParams';

const dateConfigInitial: DateConfigParams = DateConfigParams.ofInitial();

export const dateConfigReducer = createReducer(dateConfigInitial, (qb) => {
  qb.addCase(pushDatesConfigForm, (state, { payload }) => {
    return payload;
  });
  qb.addCase(generateDatesConfig.success, (state, { payload }) => {
    return payload.form;
  });
  qb.addCase(clearAll, () => {
    return DateConfigParams.ofInitial();
  });
});
