import { PayloadActionCreator } from '@reduxjs/toolkit';

export enum RequestStage {
  TRIGGER = 'TRIGGER',
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  NONE = 'NONE'
}

export type BasicAction<Payload> = PayloadActionCreator<Payload>;

export type RequestAction<TriggerP = void, SuccessP = void, FailureP = void, RequestP = void> = {
  trigger: BasicAction<TriggerP>;
  request: BasicAction<RequestP>;
  success: BasicAction<SuccessP>;
  failure: BasicAction<FailureP>;
  [RequestStage.TRIGGER]: string;
  [RequestStage.REQUEST]: string;
  [RequestStage.SUCCESS]: string;
  [RequestStage.FAILURE]: string;
};
