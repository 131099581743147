import { Service } from 'typedi';

import { UserManagementUseCase } from '@domain/useCases/UserManagementUseCase';
import { UserApi } from '@infrastructure/api/UserApi';
import { UserDto } from '@domain/models/user/UserDto';
import { UniqueId } from '@domain/types';
import { ReduxRepo } from '@infrastructure/repositories/ReduxRepo';
import { userManagementSelectors } from '@infrastructure/store/userManagement/userManagementSelectors';
import { CreateUserParams, UpdateUserParams } from '@domain/models/user/UserReqParams';
import { HistoryService } from '@infrastructure/browser/HistoryService';
import { Routing } from '@infrastructure/routing';
import { UserMapper } from '@app/mappers/UserMapper';
import { NotFoundError } from '@app/errors/NotFoundError';

@Service()
export class UserManagementService implements UserManagementUseCase {
  constructor(
    private readonly userApi: UserApi,
    private readonly historyService: HistoryService,
    private readonly reduxRepo: ReduxRepo
  ) {}

  navigateToMainPage() {
    this.historyService.goTo(Routing.getUsers());
  }

  async getUsers(): Promise<UserDto[]> {
    return this.userApi.getUserList();
  }

  async updateUser(body: UpdateUserParams): Promise<UserDto> {
    if (!body.id) {
      throw new NotFoundError('User', body.id);
    }

    const user = this.reduxRepo.findBy(userManagementSelectors.getUser(body.id));

    if (!user) {
      throw new NotFoundError('User', body.id);
    }

    const updatedBody = UserMapper.mapUpdateParamsToDto(body, user);
    return this.userApi.updateUser(updatedBody);
  }

  async setUserStatus(id: UniqueId, isEnabled: boolean): Promise<UserDto> {
    const body = new UpdateUserParams();
    body.id = id;
    body.enabled = isEnabled;
    return this.updateUser(body);
  }

  async createUser(body: CreateUserParams): Promise<UserDto> {
    const user = UserMapper.mapCreateParamsToDto(body);
    return this.userApi.createUser(user);
  }
}
