export enum StatsView {
  LOGS = 'LOGS',
  AUDIT = 'AUDIT',
  RESULT = 'RESULT'
}

export const StatsViewTitle = {
  [StatsView.RESULT]: 'Experiment Result',
  [StatsView.LOGS]: 'Experiment Logs',
  [StatsView.AUDIT]: 'Experiment Audit'
};
