import { Transform } from 'class-transformer';

import { Dto } from '@domain/models/Dto';
import { UniqueId } from '@domain/types';
import { ExperimentVariableType } from '@domain/enums/ExperimentVariableType';

type VarValue = number | boolean | string;

const mapValue = (value: any, type: ExperimentVariableType): VarValue => {
  switch (type) {
    case ExperimentVariableType.INT_LIST:
    case ExperimentVariableType.INTEGER:
      return Number(value);
    case ExperimentVariableType.BOOLEAN:
      return value === 'true';
    default:
      return value;
  }
};

export class ExperimentVariableDto extends Dto {
  id: UniqueId;
  name: string;
  displayName: string;
  type: ExperimentVariableType;

  @Transform(({ obj }) => mapValue(obj.minValue, obj.type))
  minValue: VarValue;

  @Transform(({ obj }) => mapValue(obj.maxValue, obj.type))
  maxValue: VarValue;

  @Transform(({ obj }) => mapValue(obj.defaultDisplayValue, obj.type))
  defaultDisplayValue: VarValue;
  sessionsSupported: boolean;

  enabled: boolean;
}
