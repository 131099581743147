import React, { memo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';

import { GeoJSON } from './Geo';

const colorScale = scaleLinear().domain([0, 1]).range(['#e5f5f9', '#00a3c6']);

// todo refactor/rewrite on ts
function MapChart({ gameStatsConfig, className }) {
  const [tooltip, setTooltipContent] = useState('');
  const installsData = gameStatsConfig.map(({ value }) => value);
  const maxInstalls = Math.max(...installsData);
  const opacityIndexes = installsData.map((number, index) => {
    return {
      name: gameStatsConfig[index].country,
      opacity: (number / maxInstalls).toFixed(1)
    };
  });

  return (
    <>
      <ComposableMap data-tip="" projectionConfig={{ scale: 200 }} className={className}>
        <ZoomableGroup zoom={1.2}>
          <Geographies geography={GeoJSON}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const { NAME } = geo.properties;
                const existingData = gameStatsConfig.find(({ country }) => country === NAME);
                const tooltipContent = existingData ? `${NAME} - ${existingData.value}` : NAME;

                const hasOpacityData = opacityIndexes.find(({ name }) => name === NAME);
                const opacityIndex = hasOpacityData ? hasOpacityData.opacity : 0;

                const countryFill = colorScale(opacityIndex);

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={() => {
                      setTooltipContent(tooltipContent);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent('');
                    }}
                    style={{
                      default: {
                        fill: countryFill,
                        outline: 'none'
                      },
                      hover: {
                        fill: '#49acdb',
                        outline: 'none'
                      },
                      pressed: {
                        fill: '#E42',
                        outline: 'none'
                      }
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <ReactTooltip>{tooltip}</ReactTooltip>
    </>
  );
}

export default memo(MapChart);
