import React, { useCallback, useState } from 'react';

import { StatsView, StatsViewTitle } from '@pages/experimentDetails/components/stats/types/StatsView';
import { Stats } from '@pages/experimentDetails/components/stats/Stats';

import styles from '@pages/experimentDetails/components/stats/Stats.module.scss';

export function StatsContainer() {
  const [value, setValue] = useState(StatsView.RESULT);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  return (
    <section className={styles.stats}>
      <select value={value} onChange={handleChange} className={styles.select}>
        <option value={StatsView.RESULT}>{StatsViewTitle[StatsView.RESULT]}</option>
        <option value={StatsView.LOGS}>{StatsViewTitle[StatsView.LOGS]}</option>
        <option value={StatsView.AUDIT}>{StatsViewTitle[StatsView.AUDIT]}</option>
      </select>

      <div className={styles.content}>
        <Stats view={value} />
      </div>
    </section>
  );
}
