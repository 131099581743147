import { Service } from 'typedi';
import { BackendHttpClient } from '@infrastructure/http/BackendHttpClient';
import { ResponseMapper } from '@app/mappers/ResponseMapper';
import { RegionDto } from '@domain/models/RegionDto';
import api from '@infrastructure/api/api';
import { UniqueId } from '@domain/types';

@Service()
export class RegionApi {
  constructor(protected readonly http: BackendHttpClient) {}

  async getRecommendedRegions(applicationId: UniqueId): Promise<RegionDto[]> {
    return this.http
      .get(`intops/admin/region/${applicationId}/recommended`)
      .then((list) => list?.map(ResponseMapper.mapToRegionDto) || []);
  }

  async getDefaultRegions(): Promise<RegionDto[]> {
    return this.http.get(`/intops/admin/region/`).then((list) => list?.map(ResponseMapper.mapToRegionDto) || []);
  }
}

/** @deprecated use RegionApi */
export default class OldRegionApi {
  static getRegion(firebaseProjectId) {
    return api.get(`/intops/admin/region/${firebaseProjectId}`);
  }

  static getRecommendedRegions({ firebaseProjectId, platform }) {
    return api.get(`intops/admin/region/recommended`, {
      params: { firebaseProjectId, platform }
    });
  }

  static postRegion({ firebaseProjectId, payload }) {
    return api.post(`/intops/admin/region/${firebaseProjectId}`, payload);
  }

  static deleteRegion({ regionId }) {
    return api.delete(`/intops/admin/region/${regionId}`);
  }

  static getDefaultRegions({ defaultsOnly = true, firebaseProjectId }) {
    return api.get(`/intops/admin/region/`, { params: { defaultsOnly, firebaseProjectId } });
  }

  static upsertRegions(payload) {
    return api.post(`/intops/admin/region/`, payload);
  }
}
