import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { DropdownMultiInput } from 'crazy-ui-next';

import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { FormComponent, getInputProps } from '@ui/hooks/form';
import { useSelector } from '@ui/hooks/redux';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/ABForm.module.scss';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'control' | 'register'>;

export function AdProfileParamsInput({ control, register }: Props) {
  const variables = useSelector(configSelectors.getVariableList);
  const options = variables.map(({ displayName, name }) => ({
    label: displayName,
    value: name
  }));
  const { field, formState } = useController({ control, name: 'params' });

  const handleChange = useCallback(
    (event) => {
      if (field.value.includes(event.value)) {
        field.onChange(field.value.filter((value) => value !== event.value));
      } else {
        field.onChange([...field.value, event.value]);
      }
    },
    [field]
  );

  return (
    <DropdownMultiInput
      labelText="Ad Profile Parameters"
      placeholder="Select parameters"
      value={field.value}
      options={options}
      className={styles.dropdown}
      handleChange={handleChange}
      {...getInputProps<ObjectiveConfigParams>('params', register, formState.errors)}
    />
  );
}
