import React from 'react';
import { Tooltip } from 'crazy-ui';
import cn from 'classnames';

import { StringUtils } from '@app/utils/StringUtils';

import styles from './UserFiltersModule.module.scss';

export default function UserCircle({
  name = '',
  userId = null,
  alterColor = null,
  isActive = false,
  onClick = () => {},
  readOnly = true,
  tooltipText = ''
} = {}) {
  const colorSet = alterColor ? styles['color-set-2'] : styles['color-set-1'];

  return (
    <Tooltip text={tooltipText || name} delay={200}>
      <div
        className={cn(styles.circle, colorSet, isActive && styles.active, readOnly && styles.readOnly)}
        data-user-id={userId}
        onClick={!readOnly && onClick ? onClick : undefined}
      >
        <span className={styles.letters}>{StringUtils.getFirstLetters(name)}</span>
      </div>
    </Tooltip>
  );
}
