import React, { useCallback, useEffect } from 'react';
import { useFieldArray, UseFormSetFocus, UseFormWatch } from 'react-hook-form';
import { cloneDeep } from 'lodash-es';

import { StringUtils } from '@app/utils/StringUtils';
import { GLDParamDto } from '@domain/enums/GLDParamDto';
import { GLDConfigParams, GLDFormConfig, GLDFormInput } from '@domain/models/createExperiment/GLDConfigParams';
import { DefaultValueInput } from '@pages/createExperiment/objectiveConfig/forms/GLD/inputs/DefaultValueInput';
import { CloneInput } from '@pages/createExperiment/objectiveConfig/forms/GLD/inputs/CloneInput';
import { Thead } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/atoms/Thead';
import { TBody } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/atoms/TBody';
import { AddParamInput } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/atoms/AddParamInput';
import { AddConfigBtn } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/atoms/AddConfigBtn';
import { FormComponent } from '@ui/hooks/form';

import styles from './InputGroup.module.scss';

type Props = Pick<FormComponent<GLDConfigParams>, 'control' | 'register'> & {
  watch: UseFormWatch<GLDConfigParams>;
  setFocus: UseFormSetFocus<GLDConfigParams>;
  gldParams: GLDParamDto[];
};

const MAX_CONFIGS_LIMIT = 26;
const MIN_CONFIGS_LIMIT = 2;

export function InputGroup({ register, control, watch, setFocus, gldParams }: Props) {
  /*
  nested field change doesn't trigger re-render of parent component,
  so in this case we need to watch configs to be sure we are working with updated data
  */
  const [defaultValue, watchConfigs] = watch(['defaultValue', 'config']);
  const {
    fields: configs,
    append: appendConfig,
    insert: insertConfig,
    remove: removeConfig,
    replace: replaceConfig
  } = useFieldArray({
    control,
    name: 'config',
    rules: {
      minLength: MIN_CONFIGS_LIMIT,
      maxLength: MAX_CONFIGS_LIMIT
    }
  });
  const {
    fields: params,
    append: appendParam,
    remove: removeParam,
    replace: replaceParams
  } = useFieldArray({
    control,
    name: 'params'
  });

  const isNewConfigAllowed = configs.length < MAX_CONFIGS_LIMIT;

  // add new config on add a new row in a table
  const handleAddConfig = useCallback(() => {
    const lastConfig = configs[configs.length - 1];
    const newName = StringUtils.getNextChar(lastConfig.name);

    const inputs = params.map((param) => {
      const variableDto = gldParams.find((dto) => dto.name === param.value);
      const value = variableDto ? variableDto.defaultValue : defaultValue;

      return new GLDFormInput(param.value, value);
    });

    const config = new GLDFormConfig(newName, inputs, '');

    appendConfig(config);
  }, [appendConfig, configs, defaultValue, params, gldParams]);

  // remove particular config on remove a particular row in a table
  const handleRemoveConfig = useCallback(
    ({ currentTarget }) => {
      const { index } = currentTarget.dataset;

      removeConfig(index);
    },
    [removeConfig]
  );

  // update(add) all configs on add a new param in a table header
  const updateConfig = useCallback(
    (param: string) => {
      const deepCopy = cloneDeep(watchConfigs);
      const updatedConfigs = deepCopy.map((config) => {
        const variableDto = gldParams.find((dto) => dto.name === param);
        const value = variableDto ? variableDto.defaultValue : defaultValue;

        const newInput = new GLDFormInput(param, value);
        config.input.push(newInput);
        return config;
      });

      replaceConfig(updatedConfigs);
    },
    [watchConfigs, replaceConfig, defaultValue, gldParams]
  );

  // update param name in config on update param name
  const updateConfigParam = useCallback(
    (newParam: string, index: number) => {
      const deepCopy = cloneDeep(watchConfigs);
      const updatedConfigs = deepCopy.map((config) => {
        config.input[index].key = newParam;
        return config;
      });

      replaceConfig(updatedConfigs);
    },
    [watchConfigs, replaceConfig]
  );

  // update(remove) all configs on remove a param in a table header
  const cleanUpConfig = useCallback(
    (param: string) => {
      const deepCopy = cloneDeep(configs);
      const updatedConfigs = deepCopy.map((config) => {
        config.input = config.input.filter(({ key }) => key !== param);
        return config;
      });

      replaceConfig(updatedConfigs);
    },
    [configs, replaceConfig]
  );

  // add a new param
  const handleAddParam = useCallback(
    (data) => {
      appendParam(data);
      updateConfig(data.value);
    },
    [appendParam, updateConfig]
  );

  // update param on edit
  const handleUpdateParam = useCallback(
    (newParam: string, index: number) => {
      const updatedParams = cloneDeep(params);
      updatedParams[index].value = newParam;

      replaceParams(updatedParams);
      updateConfigParam(newParam, index);
    },
    [params, replaceParams, updateConfigParam]
  );

  // remove param
  const handleRemoveParam = useCallback(
    ({ currentTarget }) => {
      const { index, value } = currentTarget.dataset;

      removeParam(index);
      cleanUpConfig(value);
    },
    [removeParam, cleanUpConfig]
  );

  // add or remove clone of control group
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name !== 'cloneControlGroup') {
        return;
      }
      const cloneIndex = 1;

      if (value.cloneControlGroup) {
        const controlGroup = configs[0];
        const clone = new GLDFormConfig('AA', controlGroup.input, 'Control Group Clone');

        insertConfig(cloneIndex, clone);
      } else {
        removeConfig(cloneIndex);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, insertConfig, removeConfig, configs]);

  return (
    <>
      <fieldset className={styles.fieldset}>
        <AddParamInput handleAddParam={handleAddParam} watch={watch} gldParams={gldParams} />
        <DefaultValueInput register={register} />
        <CloneInput register={register} />
      </fieldset>
      <div className={styles.inputGroup}>
        <table className={styles.table}>
          <Thead
            watch={watch}
            params={params}
            handleRemoveParam={handleRemoveParam}
            handleUpdateParam={handleUpdateParam}
          />
          <TBody control={control} configs={configs} handleRemoveConfig={handleRemoveConfig} setFocus={setFocus} />
        </table>
        <AddConfigBtn handleAddConfig={handleAddConfig} isShown={isNewConfigAllowed} />
      </div>
    </>
  );
}
