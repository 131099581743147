import React from 'react';

import { TableCellType } from '@components/layout/table/types/TableCellType';

export function SkeletonRow() {
  return (
    <tr data-loading="true">
      <td data-cell={TableCellType.TEXT} />
      <td data-cell={TableCellType.TEXT} />
      <td data-cell={TableCellType.LABEL} />
      <td data-cell={TableCellType.TEXT} />
      <td data-cell={TableCellType.TEXT} />
      <td data-cell={TableCellType.TEXT} />
      <td data-cell={TableCellType.FLAG} />
      <td data-cell={TableCellType.FLAG} />
    </tr>
  );
}
