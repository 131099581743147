import { useEffect, useState, useCallback, useRef } from 'react';
import { REACT_ROOT } from '@domain/constants';

export function useDropdown(dropWrapperClassName?: string, closeBtnClassName?: string) {
  const { current: root } = useRef(document.getElementById(REACT_ROOT));
  const [isOpen, handleDropdown] = useState(false);

  const openDropdown = useCallback((e) => {
    e.stopPropagation();
    handleDropdown(true);
  }, []);

  const closeDropdown = useCallback(
    (e) => {
      try {
        e.stopPropagation();
        const isOutside = !e.target.closest(`.${dropWrapperClassName}`);
        const isCloseTarget = e.target.classList.contains(closeBtnClassName);

        if (isOutside || isCloseTarget) {
          handleDropdown(false);
          root?.removeEventListener('click', closeDropdown);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [root, dropWrapperClassName, closeBtnClassName]
  );

  useEffect(() => {
    if (isOpen) {
      root?.addEventListener('click', closeDropdown);
    }
  }, [root, closeDropdown, isOpen]);

  useEffect(() => {
    return () => {
      root?.removeEventListener('click', closeDropdown);
    };
  }, [root, closeDropdown]);

  return { closeDropdown, openDropdown, isOpen };
}
