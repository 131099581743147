import React, { useEffect } from 'react';
import { Control, useController, UseFormResetField, UseFormWatch } from 'react-hook-form';

import { RegionMapper } from '@app/mappers/experiment/RegionMapper';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { ExperimentRegion, ExperimentRegionName } from '@domain/enums/ExperimentRegion';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';
import { useSelector } from '@ui/hooks/redux';
import { SelectInput } from '@components/lib/Select/SelectInput';

import styles from '@pages/createExperiment/targetConfig/TargetingConfig.module.scss';

const inputName = 'regions';
const watchName = 'regionType';

type Props = {
  control: Control<TargetConfigParams>;
  watch: UseFormWatch<TargetConfigParams>;
  resetField: UseFormResetField<TargetConfigParams>;
};

export function RegionsInput({ control, watch, resetField }: Props) {
  const country = watch(watchName);
  const { field, formState } = useController({ control, name: inputName });

  const isUS = country === ExperimentRegion.US;
  const { defaultRegions, recommendedRegions } = useSelector(createExperimentSelectors.getRegions);

  const options = RegionMapper.createOptions([...defaultRegions, ...recommendedRegions], [ExperimentRegionName.WW]);

  const disabled = isUS;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === watchName && type === 'change') {
        const defaultValue =
          value.regionType === ExperimentRegion.US
            ? [{ value: ExperimentRegionName.US, label: ExperimentRegionName.US }]
            : RegionMapper.createOptions(recommendedRegions);

        resetField(inputName, { defaultValue });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, resetField, defaultRegions, recommendedRegions]);

  return (
    <SelectInput
      name="regions"
      labelText="Live Regions"
      placeholder="Select regions"
      value={field.value}
      options={options}
      handleChange={field.onChange}
      isDisabled={disabled}
      error={formState.errors?.regions?.message}
      className={styles.input}
      isMulti
      isClearable
    />
  );
}
