import { format, isValid } from 'date-fns';
import { TDateISODate } from '@domain/types/date.types';

export class DateUtils {
  static getShortDatePattern() {
    return 'dd/MM/yy';
  }

  static getDatePattern() {
    return 'dd/MM/yyyy';
  }

  static getDateISODatePattern() {
    return 'yyyy-MM-dd';
  }

  static getDateWithHoursPattern() {
    return 'dd/MM/yyyy HH:mm';
  }

  static formatDate(date: Date | string | number | null, pattern: string = DateUtils.getShortDatePattern()) {
    if (!date) {
      return '';
    }

    if (!(date instanceof Date)) {
      return format(new Date(date), pattern);
    }

    try {
      return format(date, pattern);
    } catch (err) {
      return '';
    }
  }

  static formatToDateISODate(date: Date | string | number | null): TDateISODate {
    return DateUtils.formatDate(date, DateUtils.getDateISODatePattern()) as TDateISODate;
  }

  static isValidDate(date: any): boolean {
    if (!(date instanceof Date)) {
      return isValid(new Date(date));
    }

    return isValid(date);
  }
}
