import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { InputLabel } from 'crazy-ui-next';

import styles from './RangeInput.module.scss';

type Props = {
  min?: number;
  max?: number;
  step?: number;
  value: string;
  onChange?: (e) => void;
  labelText?: string;
  className?: string;
  readOnly?: boolean;
};

export function RangeInput({ min = 0, max = 100, step = 1, value, onChange, labelText, className, readOnly }: Props) {
  const [backgroundSize, setBackgroundSize] = useState('');
  useEffect(() => {
    const leftPosition = ((+value - min) * 100) / (max - min);
    const bgSize = `${leftPosition}% 100%`;

    setBackgroundSize(bgSize);
  }, [value, min, max]);

  return (
    <>
      <InputLabel labelText={labelText} />
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        className={cn(styles.range, className)}
        style={{ backgroundSize }}
        disabled={readOnly}
      />
    </>
  );
}
