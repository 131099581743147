import React from 'react';

import { TableCellType } from '@components/layout/table/types/TableCellType';

type HeaderCellProps = {
  type: string;
  labelText: string | React.ReactNode;
};

function HeaderCell({ type, labelText }: HeaderCellProps) {
  return <th data-cell={type}>{labelText}</th>;
}

type Props = {
  haveFullAccess: boolean;
};

export function Header({ haveFullAccess }: Props) {
  return (
    <tr>
      <HeaderCell type={TableCellType.TITLE} labelText="Test Name" />
      <HeaderCell type={TableCellType.TEXT} labelText="Test Type" />
      <HeaderCell
        type={TableCellType.TEXT}
        labelText={
          <>
            Applied
            <br />
            Group
          </>
        }
      />
      <HeaderCell type={TableCellType.LABEL} labelText="Region" />
      <HeaderCell type={TableCellType.TEXT} labelText="Version" />
      <HeaderCell
        type={TableCellType.DATE}
        labelText={
          <>
            Applied
            <br />
            Deactivated
          </>
        }
      />

      <HeaderCell type={TableCellType.FLAG} labelText="Status" />
      <HeaderCell type={TableCellType.LABEL} labelText="User" />
      {haveFullAccess && <HeaderCell type={TableCellType.CONTROL} labelText="Edit/Delete" />}
    </tr>
  );
}
