import React from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';

import { WinnerStatus } from '@domain/enums/WinnerStatus';
import { ExperimentObjectiveState } from '@domain/enums/ExperimentObjectiveState';
import { ReactComponent as EditSvg } from '@assets/svg/edit.svg';
import { ReactComponent as CancelSvg } from '@assets/svg/cancel.svg';
import { ReactComponent as StopSvg } from '@assets/svg/stop_round.svg';
import { ReactComponent as ApplySvg } from '@assets/svg/document-accept.svg';
import { ReactComponent as RestoreSvg } from '@assets/svg/restore.svg';
import { ReactComponent as InfoSvg } from '@assets/svg/info-rounded.svg';

type Props = {
  state: ExperimentObjectiveState;
  winnerStatus: WinnerStatus | null;
  handleOpenModal: () => void;
  handleRestoreObjective: () => void;
  handleCancelWinner: () => void;
  handleStopObjective: () => void;
};

export function WinnerStatusControls({
  state,
  winnerStatus,
  handleOpenModal,
  handleRestoreObjective,
  handleCancelWinner,
  handleStopObjective
}: Props) {
  if (winnerStatus === WinnerStatus.APPLIED) {
    return (
      <>
        <Button
          type="button"
          variant={ButtonVariant.TERTIARY}
          icon={<EditSvg />}
          onClick={handleOpenModal}
          tooltipText="Edit winner"
        />
        <Button
          type="button"
          variant={ButtonVariant.TERTIARY}
          icon={<CancelSvg />}
          onClick={handleCancelWinner}
          tooltipText="Cancel winner"
        />
      </>
    );
  }

  switch (state) {
    case ExperimentObjectiveState.RUNNING:
      return (
        <Button
          type="button"
          variant={ButtonVariant.TERTIARY}
          icon={<StopSvg />}
          onClick={handleStopObjective}
          tooltipText="Stop objective"
        />
      );
    case ExperimentObjectiveState.STOPPED:
      return (
        <Button
          type="button"
          variant={ButtonVariant.TERTIARY}
          icon={<ApplySvg />}
          onClick={handleOpenModal}
          tooltipText="Apply winner"
        />
      );
    case ExperimentObjectiveState.ERROR:
      return (
        <>
          <Button
            type="button"
            variant={ButtonVariant.TERTIARY}
            icon={<RestoreSvg />}
            onClick={handleRestoreObjective}
            tooltipText="Restore objective"
          />
          <Button type="button" variant={ButtonVariant.TERTIARY} icon={<InfoSvg />} tooltipText="Error" />
        </>
      );
    default:
      return null;
  }
}
