import { GamePlatform } from '@domain/enums/GamePlatform';
import { ExperimentType } from '@domain/enums/ExperimentType';

/* AC -> AppliedConfig */
export namespace ACFilter {
  export enum Status {
    ALL = 'ALL',
    APPLIED = 'APPLIED',
    HISTORY = 'HISTORY'
  }

  /** @see GamePlatform */
  export enum Platform {
    ALL = 'ALL',
    IOS = 'IOS_PLATFORM',
    ANDROID = 'ANDROID_PLATFORM'
  }

  /** @see ExperimentType */
  export enum Type {
    ALL = 'ALL',
    AB_TESTING = 'AB_TESTING',
    AB_OLD_USERS_TESTING = 'AB_OLD_USERS_TESTING',
    GLD_TEST = 'GLD_TEST'
  }

  export const mapStatusToPayload = (key: Status): boolean | undefined => {
    return {
      [Status.ALL]: undefined, // means do not apply the filter
      [Status.APPLIED]: true,
      [Status.HISTORY]: false
    }[key];
  };

  export const mapPlatformToPayload = (key: Platform): GamePlatform => {
    const value = {
      [Platform.IOS]: GamePlatform.IOS,
      [Platform.ANDROID]: GamePlatform.ANDROID
    }[key];

    if (!value) {
      throw new TypeError(`Cannot map "${key}" to "GamePlatform"`);
    }

    return value;
  };

  export const mapTypeToPayload = (key: Type): ExperimentType => {
    const value = {
      [Type.AB_TESTING]: ExperimentType.AB_TESTING,
      [Type.AB_OLD_USERS_TESTING]: ExperimentType.AB_OLD_USERS_TESTING,
      [Type.GLD_TEST]: ExperimentType.GLD_TEST
    }[key];

    if (!value) {
      throw new TypeError(`Cannot map "${key}" to "ExperimentType"`);
    }

    return value;
  };
}
