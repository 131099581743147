import { Type } from 'class-transformer';
import { Dto } from '@domain/models/Dto';
import { UniqueId } from '@domain/types';
import { RegionDto } from '@domain/models/RegionDto';
import { ExperimentObjectiveState } from '@domain/enums/ExperimentObjectiveState';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { ExperimentVariableWrapper } from '@domain/enums/ExperimentVariableWrapper';
import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { ExperimentVariableInputMode } from '@domain/enums/ExperimentVariableInputMode';
import { WinnerStatus } from '@domain/enums/WinnerStatus';
import { WinnerConfigDto } from '@domain/models/winnerConfig/WinnerConfigDto';

export type ExperimentObjectiveConfigValue = {
  type: ExperimentVariableWrapper;
  value: boolean | string | number | number[];
};

export type ExperimentObjectiveControlGroup = {
  type: ExperimentObjectiveSessionType;
  configValue: ExperimentObjectiveConfigValue | Record<string, ExperimentObjectiveConfigValue>;
};

export type ExperimentObjectiveInputValue = {
  type: ExperimentVariableInputMode;
  value: boolean | string | number;
  values?: number[];
};

export type ExperimentObjectiveSessionInputValue = Record<string, ExperimentObjectiveInputValue[]>;

export type ExperimentObjectiveInput = {
  plainInputs: Record<string, ExperimentObjectiveInputValue[]>;
  sessionsInputs: Record<string, ExperimentObjectiveSessionInputValue>;
};

export class ExperimentObjectiveDto extends Dto {
  public id: UniqueId;
  public state: ExperimentObjectiveState;
  public description: string;
  public configs: GenericConfigEntry[];
  public controlGroup: Record<string, ExperimentObjectiveControlGroup>;
  public newUsers: boolean;
  public sticky: boolean;
  public requiredUsersPerGroup: number;
  public usersAllocationPercent: number;
  public adjustableUsersAllocation: boolean;
  public input: ExperimentObjectiveInput[];
  public cloneControlGroup: boolean;
  public builtInGroup: boolean;

  @Type(() => RegionDto)
  public regions: RegionDto[];

  @Type(() => RegionDto)
  public regionsForInDev: RegionDto[];

  @Type(() => RegionDto)
  public primaryRegion: RegionDto;

  public appVersions: string[];

  public winnerStatus: WinnerStatus | null;
  public winnerInfo: WinnerConfigDto | null;
}
