import React, { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { CheckboxInput } from 'crazy-ui';

import { VariableType } from '@pages/experimentVariables/atoms/variableType/VariableType';
import { TableCellType } from '@components/layout/table/types/TableCellType';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { Routing } from '@infrastructure/routing';

type Props = {
  variable: ExperimentVariableDto;
};

export function Row({ variable }: Props) {
  const navLinkRef = useRef<HTMLAnchorElement>(null);

  const handleNavClick = useCallback((e) => {
    if (navLinkRef.current) {
      navLinkRef.current.click();
    }
  }, []);

  if (!variable) {
    return null;
  }

  return (
    <tr data-id={variable.id} onClick={handleNavClick}>
      <td data-cell={TableCellType.TITLE}>
        <Link to={Routing.getEditExpVariable(variable.id)} ref={navLinkRef}>
          {variable.name}
        </Link>
      </td>
      <td data-cell={TableCellType.TEXT}>
        <p>{variable.displayName}</p>
      </td>
      <td data-cell={TableCellType.LABEL}>
        <VariableType type={variable.type} />
      </td>
      <td data-cell={TableCellType.TEXT}>
        <p>{variable.defaultDisplayValue}</p>
      </td>
      <td data-cell={TableCellType.TEXT}>
        <p>{variable.minValue}</p>
      </td>

      <td data-cell={TableCellType.TEXT}>
        <p>{variable.maxValue}</p>
      </td>
      <td data-cell={TableCellType.FLAG}>
        <CheckboxInput name="sessionsSupported" checked={variable.sessionsSupported} readOnly />
      </td>
      <td data-cell={TableCellType.FLAG}>
        <CheckboxInput name="enabled" checked={variable.enabled} readOnly />
      </td>
    </tr>
  );
}
