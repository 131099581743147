import React from 'react';
import { FieldArrayWithId, UseFormSetFocus } from 'react-hook-form';
import cn from 'classnames';

import { FormComponent } from '@ui/hooks/form';

import { ConfigName } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/atoms/ConfigName';
import { EditableDescription } from '@pages/experimentDetails/components/summary/config/devPhase/sections/objectiveConfig/configInput/atoms/EditableDescription';
import { EditableCell } from '@pages/experimentDetails/components/summary/config/devPhase/sections/objectiveConfig/configInput/atoms/EditableCell';
import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';
import { ShutdownCheckbox } from '@pages/experimentDetails/components/summary/config/devPhase/sections/objectiveConfig/configInput/atoms/ShutdownCheckbox';

import styles from '../ConfigInput.module.scss';

type Props = Pick<FormComponent<DevPhaseParams>, 'control'> & {
  setFocus: UseFormSetFocus<DevPhaseParams>;
  configs: FieldArrayWithId<DevPhaseParams, 'objectiveConfig.config'>[];
  handleRemoveConfig: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export function TBody({ configs, handleRemoveConfig, control, setFocus }: Props) {
  return (
    <tbody>
      {configs.map((config, configIndex) => (
        <tr className={cn(!config.active && styles.inactiveRow)} key={config.id}>
          <ConfigName name={config.name} configIndex={configIndex} handleRemoveConfig={handleRemoveConfig} />
          <ShutdownCheckbox control={control} configIndex={configIndex} />
          <EditableDescription control={control} configIndex={configIndex} setFocus={setFocus} />
          {config.input.map((input, inputIndex) => (
            <EditableCell key={input.key} control={control} configIndex={configIndex} inputIndex={inputIndex} />
          ))}
        </tr>
      ))}
    </tbody>
  );
}
