import { call, delay, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { Service } from 'typedi';

import { RootSagaWatcher } from '@infrastructure/sagas/RootSagaWatcher';
import { BaseSagaWatcher } from '@infrastructure/sagas/BaseSagaWatcher';
import { Notification } from '@infrastructure/api/notifications';
import { ExperimentListService } from '@app/services/ExperimentListService';
import {
  fetchExperiments,
  fetchNextExperiments,
  handleBusinessLinesFilter,
  handleDateFilter,
  handlePlatformFilter,
  handleSearchPhraseFilter,
  handleSortBy,
  handleStatusFilter,
  handleTypeFilter
} from '@infrastructure/store/experimentList/experimentListActions';
import { Paginate } from '@domain/models/Paginate';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import { experimentListSelectors } from '@infrastructure/store/experimentList/experimentListSelectors';

@Service()
export class ExperimentListSaga extends BaseSagaWatcher {
  private filterDelayMs = 300;
  private fetchDelayMs = 500;

  constructor(private experimentListService: ExperimentListService, protected rootWatcher: RootSagaWatcher) {
    super(rootWatcher);
  }

  getEffects() {
    return [
      takeLatest(fetchExperiments.TRIGGER, this.getExperiments.bind(this)),
      takeLeading(fetchNextExperiments, this.handleNextExpFetch.bind(this)),
      /* Filters */
      takeLatest(handleSearchPhraseFilter, this.handleFilterWithDelay.bind(this)),
      takeLatest(handleStatusFilter, this.handleFilterImmediate.bind(this)),
      takeLatest(handleTypeFilter, this.handleFilterImmediate.bind(this)),
      takeLatest(handleBusinessLinesFilter, this.handleFilterImmediate.bind(this)),
      takeLatest(handlePlatformFilter, this.handleFilterImmediate.bind(this)),
      takeLatest(handleDateFilter, this.handleFilterImmediate.bind(this)),
      takeLatest(handleSortBy, this.handleFilterImmediate.bind(this))
    ];
  }

  *handleFilterWithDelay() {
    yield delay(this.filterDelayMs);
    yield put(fetchExperiments.trigger());
  }

  *handleFilterImmediate() {
    yield put(fetchExperiments.trigger());
  }

  *handleNextExpFetch() {
    const { isNextAvailable, currentPage } = yield select(experimentListSelectors.getPagination);

    if (isNextAvailable) {
      yield put(fetchExperiments.trigger(currentPage + 1));
      yield delay(this.fetchDelayMs);
    }
  }

  *getExperiments(action: ReturnType<typeof fetchExperiments.trigger>) {
    try {
      const page = action.payload;
      yield put(fetchExperiments.request());
      const result: Paginate<ExperimentDto> = yield call(
        [this.experimentListService, this.experimentListService.getExperiments],
        page
      );
      yield put(fetchExperiments.success(result));
    } catch (err) {
      yield put(fetchExperiments.failure());
      Notification.error(err);
    }
  }
}
