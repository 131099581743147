import React, { useCallback, useEffect, useState } from 'react';
import { InputLabel } from 'crazy-ui-next';

import { DateUtils } from '@app/utils/DateUtils';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { setSelectedIteration } from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { DataAccurateLabel } from '@pages/experimentDetails/components/stats/components/result/components/filters/atoms/DataAccurateLabel';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { ReactComponent as ArrowSvg } from '@assets/svg/arrow.svg';

import styles from '@pages/experimentDetails/components/stats/components/result/components/filters/Filters.module.scss';

enum Action {
  PREV = 'PREV',
  NEXT = 'NEXT'
}

export function DatePagination() {
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();

  const iterations = useSelector(experimentDetailsSelectors.getIterationsByFilter);
  const selectedIteration = useSelector(experimentDetailsSelectors.getSelectedIteration);

  useEffect(() => {
    const selectedIterationData =
      iterations?.find(({ iterationId }) => iterationId === selectedIteration.iterationId) || null;

    if (selectedIterationData) {
      const localIndex = iterations.indexOf(selectedIterationData);

      setIndex(localIndex);
    }
  }, [iterations, selectedIteration, dispatch]);

  let nextDisabled = true;
  let prevDisabled = true;
  let result = 'No Data';

  if (iterations?.[index]) {
    const { firstEventDate, nextCheckDate } = iterations[index];

    const formatStartDate = DateUtils.formatDate(firstEventDate, DateUtils.getDatePattern());
    const formatEndDate = DateUtils.formatDate(nextCheckDate, DateUtils.getDatePattern());

    nextDisabled = index === iterations.length - 1;
    prevDisabled = index === 0;
    result = `${formatStartDate} - ${formatEndDate}`;
  }

  const handleChange = useCallback(
    ({ currentTarget }) => {
      const { action } = currentTarget.dataset;

      if (action === Action.NEXT) {
        const nextIteration = iterations[index + 1];
        dispatch(setSelectedIteration(nextIteration));
        setIndex(index + 1);
      }

      if (action === Action.PREV) {
        const prevIteration = iterations[index - 1];
        dispatch(setSelectedIteration(prevIteration));
        setIndex(index - 1);
      }
    },
    [dispatch, index, setIndex, iterations]
  );

  return (
    <div className={styles.dateRangeWrapper}>
      <InputLabel labelText="Date Range" note={<DataAccurateLabel status={iterations?.[index]?.dataAccuracy} />} />
      <div className={styles.dateRange}>
        <button type="button" data-action={Action.PREV} onClick={handleChange} disabled={prevDisabled}>
          <ArrowSvg />
        </button>
        <span>{result}</span>
        <button
          type="button"
          data-action={Action.NEXT}
          onClick={handleChange}
          disabled={nextDisabled}
          className={styles.next}
        >
          <ArrowSvg />
        </button>
      </div>
    </div>
  );
}
