import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';

import { ObjectiveConfigParams, InputGroupSessionForm } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { AddSectionBtn } from '@pages/createExperiment/objectiveConfig/atoms/addSectionBtn/AddSectionBtn';
import { Section } from '@pages/createExperiment/objectiveConfig/forms/AB/components/inputGroup/Section';
import { FormComponent } from '@ui/hooks/form';

import styles from './InputGroup.module.scss';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'control' | 'register'> & {
  defaultInputGroup: InputGroupSessionForm;
};

const maxSectionCCount = 9;

export function InputGroup({ control, register, defaultInputGroup }: Props) {
  const {
    fields: sections,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'input'
  });

  const showAddSectionBtn = sections.length < maxSectionCCount;

  const handleAdd = useCallback(() => {
    append({ section: [defaultInputGroup] });
  }, [append, defaultInputGroup]);

  const handleRemove = useCallback(
    (e) => {
      const sectionIndex = e.currentTarget.dataset.index;

      remove(sectionIndex);
    },
    [remove]
  );

  const sectionList = sections.map((field, sectionIndex) => {
    return (
      <Section
        key={field.id}
        control={control}
        sectionIndex={sectionIndex}
        register={register}
        handleRemove={handleRemove}
        defaultInputGroup={defaultInputGroup}
      />
    );
  });

  return (
    <section className={styles.inputGroup}>
      {sectionList}
      {showAddSectionBtn && <AddSectionBtn handleClick={handleAdd} />}
    </section>
  );
}
