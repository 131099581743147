import React from 'react';

import styles from '@pages/experimentDetails/atoms/labels/Labels.module.scss';

type Props = {
  createdBy: string;
};

export function CreatedBy({ createdBy }: Props) {
  return (
    <div className={styles.type}>
      <div className={styles.title}>Created by:</div>
      <div className={styles.value}>{createdBy}</div>
    </div>
  );
}
