import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { TextInput } from 'crazy-ui-next';

import { FormComponent, getInputProps } from '@ui/hooks/form';
import { UpdateWinnerConfigParams } from '@domain/models/winnerConfig/WinnerConfigFormParams';
import { MultiTextInputContainer } from '@pages/winnerConfigModal/form/inputs/multiTextInput/MultiTextInputContainer';

import styles from '../../WinnerConfigModal.module.scss';

type Props = FormComponent<UpdateWinnerConfigParams> & {};

export function VersionInputContainer({ control, register, errors }: Props) {
  const maxVersionsLength = 5;

  const { fields, append, remove } = useFieldArray({
    name: 'version',
    control
  });

  const onAdd = useCallback(() => {
    append({ value: '' });
  }, [append]);

  const onRemove = useCallback(() => {
    remove(fields.length - 1);
  }, [remove, fields]);

  const inputs = fields.map((field, index) => (
    <TextInput
      className={styles.multiInputEl}
      id={`winner-version-input-${index}`}
      key={`version_input_${index}`}
      {...getInputProps<UpdateWinnerConfigParams>(`version.${index}.value`, register, errors)}
    />
  ));

  return (
    <MultiTextInputContainer inputs={inputs} onAddItem={onAdd} onRemoveItem={onRemove} maxItems={maxVersionsLength} />
  );
}
