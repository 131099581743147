import React from 'react';
import usePermission, { PermissionProps } from '@ui/hooks/usePermission';

type Props = PermissionProps & {
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

export default function CheckPermission({ fallback, children, required, admin, analyst, qa, owner }: Props) {
  const haveAccess = usePermission({ required, admin, analyst, qa, owner });

  if (haveAccess && children) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
}
