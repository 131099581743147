import React from 'react';

import { GLDFormConfig } from '@domain/models/createExperiment/GLDConfigParams';
import { TRow } from './TRow';

type Props = {
  config: GLDFormConfig[];
};

export function TBody({ config }: Props) {
  return (
    <tbody>
      {config.map((value) => (
        <TRow key={value.name} config={value} />
      ))}
    </tbody>
  );
}
