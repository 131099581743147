import React from 'react';

import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { ABFormContainer } from '@pages/createExperiment/objectiveConfig/forms/AB/ABFormContainer';
import { GLDFormContainer } from '@pages/createExperiment/objectiveConfig/forms/GLD/GLDFormContainer';
import { useSelector } from '@ui/hooks/redux';

type Props = {
  isCompleted?: boolean;
};

export function ObjectiveConfigContainer({ isCompleted }: Props) {
  const form = useSelector(createExperimentSelectors.getForm);
  const { experimentType } = form[CreateExperimentForm.BASIC_INFO];

  switch (experimentType) {
    case ExperimentType.AB_TESTING:
      return <ABFormContainer />;
    case ExperimentType.GLD_TEST:
      return <GLDFormContainer isCompleted={isCompleted} />;
    default:
      return null;
  }
}
