import React from 'react';
import cn from 'classnames';

import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';
import { GamePlatform } from '@domain/enums/GamePlatform';
import { UniqueId } from '@domain/types';
import { WinnerStatus } from '@domain/enums/WinnerStatus';
import { WinnerStatusIcon } from '@pages/winnerConfigModal/atoms/WinnerStatusIcon';
import { WinnerStatusActions } from '@pages/experimentDetails/components/winnerStatus/atoms/WinnerStatusActions';
import { WinnerStatusInfo } from '@pages/experimentDetails/components/winnerStatus/atoms/WinnerStatusInfo';
import { WinnerStatusState } from '@pages/experimentDetails/components/winnerStatus/atoms/WinnerStatusState';

import styles from '../WinnerStatus.module.scss';

type Props = {
  objective: ExperimentObjectiveDto;
  experimentId: UniqueId;
  platform: GamePlatform;
};

export function ObjectiveRow({ objective, experimentId, platform }: Props) {
  const { primaryRegion, state, winnerStatus, winnerInfo } = objective;

  const regionalConfigsLength = winnerInfo?.regionalConfigs.length;
  const isMultipleConfig = regionalConfigsLength && regionalConfigsLength > 1;

  return (
    <li className={cn(winnerStatus === WinnerStatus.APPLIED && styles.applied, isMultipleConfig && styles.multiple)}>
      <div className={cn(styles.item, styles.region)}>
        <p>{primaryRegion.name}</p>
        <WinnerStatusIcon winnerStatus={winnerStatus} />
      </div>
      <div className={cn(styles.item, styles.stateWrapper)}>
        <WinnerStatusState winnerStatus={winnerStatus} state={state} />
        <WinnerStatusInfo winner={winnerInfo} />
      </div>
      <div className={cn(styles.item, styles.actions)}>
        <WinnerStatusActions state={state} experimentId={experimentId} objective={objective} platform={platform} />
      </div>
    </li>
  );
}
