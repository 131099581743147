import React, { useCallback } from 'react';
import { TextInput, TextInputEvent } from 'crazy-ui-next';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { ReactComponent as SearchSvg } from '@assets/svg/search.svg';
import { appliedConfigSelectors } from '@infrastructure/store/appliedConfig/appliedConfigSelectors';
import { setSearchPhraseFilter } from '@infrastructure/store/appliedConfig/appliedConfigActions';

export function SearchFilter() {
  const dispatch = useDispatch();
  const { searchPhrase } = useSelector(appliedConfigSelectors.getFilters);

  const handleSearch = useCallback(
    ({ value }: TextInputEvent) => {
      dispatch(setSearchPhraseFilter(value));
    },
    [dispatch]
  );

  return (
    <TextInput
      name="searchPhrase"
      value={searchPhrase}
      onChangeCustom={handleSearch}
      labelText="Search"
      inputIcon={<SearchSvg />}
      placeholder="Test or App name"
      clear
    />
  );
}
