import React from 'react';
import { CheckboxInput } from 'crazy-ui-next';
import { useController, useWatch } from 'react-hook-form';

import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/GLD/GLDForm.module.scss';

type Props = Pick<FormComponent<GLDConfigParams>, 'control'> & {
  disabled?: boolean;
};

export function StickyInput({ control, disabled }: Props) {
  const { field } = useController({ control, name: 'sticky' });
  const newUsers = useWatch({ control, name: 'newUsers' });

  if (newUsers) {
    return null;
  }

  return (
    <CheckboxInput
      labelText="Sticky"
      className={styles.input}
      checked={field.value}
      onChange={field.onChange}
      name={field.name}
      disabled={disabled}
    />
  );
}
