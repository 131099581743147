import React from 'react';
import { InputLabel } from 'crazy-ui-next';

import styles from '@pages/createExperiment/targetConfig/components/gameStats/GameStats.module.scss';

export function GameStatsPlaceholder() {
  return (
    <>
      <InputLabel labelText="Game Stats Chart" />
      <div className={styles.placeholderWrapper}>
        <div className={styles.placeholder}>
          <span>No Data</span>
        </div>
        <div className={styles.placeholder}>
          <span>No Data</span>
        </div>
      </div>
    </>
  );
}
