import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from '@ui/hooks/redux';
import { clearAll, fetchUsers, pushUserUpdate } from '@infrastructure/store/userManagement/userManagementActions';
import { UserForm } from './UserForm';
import { useForm } from '@ui/hooks/form';
import { UpdateUserParams } from '@domain/models/user/UserReqParams';
import { UserFormMode } from '@pages/userManagement/const/UserFormMode';
import { userManagementSelectors } from '@infrastructure/store/userManagement/userManagementSelectors';
import { RequestStage } from '@infrastructure/store/types/actions';
import { RouteParams } from '@infrastructure/routing';

export function EditUserContainer() {
  const dispatch = useDispatch();

  const { userId } = useParams<RouteParams.GetEditUser>();

  const status = useSelector(userManagementSelectors.getEditUserStatus);
  const user = useSelector(userManagementSelectors.getEditUser(Number(userId)), (a, b) => a.id === b.id);

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<UpdateUserParams>({ schema: UpdateUserParams });

  const handleFormSubmit = handleSubmit((form) => dispatch(pushUserUpdate.trigger(form)));
  const isLoading = status === RequestStage.REQUEST;

  useEffect(() => {
    if (user.id) {
      reset(user);
    }
  }, [reset, user]);

  useEffect(() => {
    dispatch(fetchUsers.trigger());
    return () => {
      dispatch(clearAll());
    };
  }, [dispatch]);

  return (
    <UserForm
      mode={UserFormMode.EDIT}
      handleSubmit={handleFormSubmit}
      errors={errors}
      register={register}
      control={control}
      isLoading={isLoading}
    />
  );
}
