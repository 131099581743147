import React from 'react';
import { useController } from 'react-hook-form';
import { DropdownInput } from 'crazy-ui-next';

import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { createSimpleOptions } from '@infrastructure/utils/createOptions.utils';
import { IterationRequestParams } from '@domain/models/iteration/IterationRequestParams';
import { FormComponent } from '@ui/hooks/form';
import { ReactComponent as LayersSvg } from '@assets/svg/layers.svg';
import { useSelector } from '@ui/hooks/redux';

import styles from '@pages/experimentDetails/components/stats/components/result/components/filters/Filters.module.scss';

type Props = Pick<FormComponent<IterationRequestParams>, 'control'> & { disabled: boolean };

export function VersionInput({ control, disabled }: Props) {
  const { field } = useController({ control, name: 'version' });
  const { availableVersions } = useSelector(experimentDetailsSelectors.getIterationFiltersMeta);
  const options = createSimpleOptions(availableVersions);

  if (availableVersions.length !== 1) {
    options.unshift({ value: '', label: 'All versions' });
  }

  return (
    <DropdownInput
      labelText="Version"
      placeholder="Select version"
      name="version"
      value={field.value}
      options={options}
      className={styles.input}
      inputIcon={<LayersSvg />}
      handleChange={(event) => field.onChange(event.value)}
      disabled={disabled}
    />
  );
}
