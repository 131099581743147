import React from 'react';
import { TextInput } from 'crazy-ui-next';

import { FormComponent, getFieldArrayInputProps } from '@ui/hooks/form';

import { AppPropertyForm } from '@domain/models/appProperty/AppPropertyInput';

type Props = FormComponent<AppPropertyForm> & {
  index: number;
  labelText: string;
  className: string;
};

export function AppPropertyInput({ className, labelText, register, errors, index }: Props) {
  return (
    <div className={className}>
      <TextInput
        labelText={labelText}
        {...getFieldArrayInputProps<AppPropertyForm>(`property.${index}.value`, register, errors)}
      />
    </div>
  );
}
