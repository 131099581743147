import { combineReducers } from '@reduxjs/toolkit';

import { profileReducer } from '@infrastructure/store/auth/reducers/profileReducer';
import { isAuthReducer } from '@infrastructure/store/auth/reducers/isAuthReducer';
import { pushLogin, clearAll } from '@infrastructure/store/auth/authActions';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';

export const auth = combineReducers({
  status: ReducerFactory.makeStatus(pushLogin, clearAll),
  isAuth: isAuthReducer,
  profile: profileReducer
});
