import React from 'react';
import { Button, TextInput } from 'crazy-ui';
import cn from 'classnames';

import GoogleSignInButton from './GoogleSignIn/GoogleSignInButton';
import DividerWithText from './DividerWithText';
import styles from './SignIn.module.scss';
import { FormComponent, getInputProps } from '@ui/hooks/form';
import { LoginRequestDto } from '@domain/models/user/LoginRequestDto';

type Props = FormComponent<LoginRequestDto>;

export function SignInForm({ handleSubmit, register, errors }: Props) {
  return (
    <main className="container">
      <div className="paper paper-75 mx-auto login">
        <div className="row">
          <div className="col">
            <div className="login__head">
              <p className="text">Sign In</p>
            </div>
          </div>
        </div>
        <div className="row login__body">
          <div className="col-12 mx-auto">
            <form className="form__body-column" onSubmit={handleSubmit}>
              <div className="form__controls">
                <GoogleSignInButton />
              </div>
              <DividerWithText className={cn(styles.separator, 'mt-3 mb-4 col-6')} text="Or" />
              <ul>
                <TextInput
                  labelText="Username"
                  placeholder="Username"
                  {...getInputProps<LoginRequestDto>('username', register, errors)}
                />
                <TextInput
                  labelText="Password"
                  placeholder="Password"
                  type="password"
                  {...getInputProps<LoginRequestDto>('password', register, errors)}
                />
              </ul>
              <div className="form__controls">
                <Button type="submit" color={Button.BLUE}>
                  Sign In
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}
