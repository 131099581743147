import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { Routing } from '@infrastructure/routing';
import { MyUserDto } from '@domain/models/user/MyUserDto';

import { AppVersion } from '@components/wrappers/sideMenu/AppVersion';

import { ReactComponent as LogoSvg } from '@assets/svg/logo.svg';
import { ReactComponent as HouseSvg } from '@assets/svg/house.svg';
import { ReactComponent as UsersSvg } from '@assets/svg/users-menu.svg';
import { ReactComponent as SettingsSvg } from '@assets/svg/settings.svg';
import { ReactComponent as GearSvg } from '@assets/svg/gear.svg';
import { ReactComponent as CheckListSvg } from '@assets/svg/check-list.svg';

import styles from './SideMenu.module.scss';
import { Profile } from './Profile';
import { SideMenuItem } from './SideMenuItem';

type Props = {
  appVersion: string;
  iconUrl: string | null;
  isAuth: boolean;
  showSideMenu: boolean;
  profile: MyUserDto | null;
  handleLogout: () => void;
  handleOpen: () => void;
  handleClose: () => void;
};

export function SideMenu({
  isAuth,
  profile,
  appVersion,
  iconUrl,
  handleLogout,
  handleOpen,
  handleClose,
  showSideMenu
}: Props) {
  if (!isAuth || !profile) {
    return null;
  }

  const isAdmin = profile ? profile.isAdmin() : false;

  return (
    <nav
      className={cn(styles.sidebar, showSideMenu && styles.active)}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      onClick={handleClose}
    >
      <Link to={Routing.getRoot()} className={styles.logo}>
        <LogoSvg />
        <div className={styles.app}>
          <p className={styles.title}>CLOK Dashboard</p>
          <p className={styles.subtitle}>by CrazyLabs</p>
        </div>
      </Link>
      <div className={styles.innerBlock}>
        <p className={styles.sectionLabel}>Dashboard</p>
        <SideMenuItem text="Experiment List" to={Routing.getRoot()} icon={<HouseSvg />} />
        <SideMenuItem text="Applied Configurations" to={Routing.getAppliedConfigs()} icon={<CheckListSvg />} />
        {isAdmin && (
          <>
            <p className={styles.sectionLabel}>Settings</p>
            <SideMenuItem text="Ad Profile Variables" to={Routing.getExpVariables()} icon={<SettingsSvg />} />
            <SideMenuItem text="System Configuration" to={Routing.getSystemConfig()} icon={<GearSvg />} />
            <SideMenuItem text="Users Management" to={Routing.getUsers()} icon={<UsersSvg />} />
          </>
        )}
      </div>
      <div className={styles.bottomBlock}>
        <AppVersion appVersion={appVersion} />
        <Profile profile={profile} handleLogout={handleLogout} iconUrl={iconUrl} />
      </div>
    </nav>
  );
}
