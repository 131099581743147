import React from 'react';
import { Button } from 'crazy-ui-next';
import cn from 'classnames';

import { Routing } from '@infrastructure/routing';
import styles from '@pages/common/ErrorPage.module.scss';

export function PermissionDenied() {
  return (
    <main className={cn('container', styles.wrapper, styles.error)}>
      <div className="paper">
        <h2>403</h2>
        <h3>Access Denied</h3>
        <p>Sorry, but you don't have permissions to access this page.</p>
        <div className={styles.actionBlock}>
          <p>Fortunately, you can go back to:</p>
          <Button href={Routing.getRoot()}>Dashboard</Button>
        </div>
      </div>
    </main>
  );
}
