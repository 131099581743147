import React from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';
import { ReactComponent as MinusSvg } from '@assets/svg/minus.svg';

type Props = {
  name: string;
  configIndex: number;
  handleRemoveConfig: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export function ConfigName({ name, configIndex, handleRemoveConfig }: Props) {
  const isRemoveAllowed = !['A', 'AA'].includes(name);

  return (
    <td data-remove={isRemoveAllowed}>
      <div>
        <span>{name}</span>
        {isRemoveAllowed && (
          <Button
            variant={ButtonVariant.TERTIARY}
            data-index={configIndex}
            onClick={handleRemoveConfig}
            icon={<MinusSvg />}
          />
        )}
      </div>
    </td>
  );
}
