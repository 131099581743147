import { uniq } from 'lodash-es';
import { IsArray, IsNotEmpty, IsString, Min, Max, IsInt, IsBoolean, ValidateNested, Validate } from 'class-validator';
import { Transform } from 'class-transformer';

import { ExpFormSummary } from '@pages/createExperiment/atoms/Summary/Summary';
import { ExperimentPeriodicity } from '@domain/enums/ExperimentPeriodicity';
import { IsValidReportingArpu } from '@domain/models/validationConstraints/IsValidReportingArpu';

class ReportingArpu {
  @IsString()
  value: string;
}

export class GoalConfigParams {
  @IsString()
  @IsNotEmpty()
  checkPeriod: ExperimentPeriodicity;

  @IsArray()
  @Validate(IsValidReportingArpu, {
    message: 'Cannot be greater than target arpu'
  })
  @ValidateNested({ each: true })
  reportingArpu: ReportingArpu[];

  @Transform(({ value }) => Number(value))
  @IsInt()
  @Min(1)
  @Max(30)
  arpu: number;

  @IsBoolean()
  automaticMode: boolean;

  static ofInitial() {
    const form = new GoalConfigParams();
    form.checkPeriod = ExperimentPeriodicity.TWICE_PER_DAY;
    form.reportingArpu = [{ value: '1' }, { value: '3' }];
    form.arpu = 3;
    form.automaticMode = true;
    return form;
  }

  static getInitialFromProperties(reportingArpu: number[]) {
    const form = this.ofInitial();

    form.reportingArpu = reportingArpu.map((value) => ({ value: String(value) }));

    return form;
  }

  getSummary(): ExpFormSummary {
    return [
      { title: 'Target ARPU', value: this.arpu.toString() },
      { title: 'Reporting ARPU', value: uniq(this.reportingArpu.map(({ value }) => value)).join(', ') },
      { title: 'Check Period', value: this.checkPeriod },
      { title: 'Automatic mode', value: this.automaticMode.toString() }
    ];
  }
}
