import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { InputLabel } from 'crazy-ui-next';
import ReactDatePicker from 'react-datepicker';

import { ReactComponent as CalendarSvg } from '@assets/svg/calendar.svg';

import pickerStyles from './DatePickerCustom.module.scss';
import styles from './DateRangePicker.module.scss';

export type DateRangeEvent = {
  name: string;
  startDate: Date | null;
  endDate: Date | null;
  isStartChanged: boolean;
  isEndChanged: boolean;
};

type Props = {
  name: string;
  className?: string;
  labelText?: string;
  dateFormat?: string;
  startDate: Date | null;
  endDate: Date | null;
  handleChange: (e: DateRangeEvent) => void;
};

function DotsSvg() {
  return (
    <svg viewBox="0 -30 100 400">
      <line x1="50" x2="50" y1="0" y2="400" strokeWidth="70" strokeLinecap="round" strokeDasharray="0, 90" />
    </svg>
  );
}

export function DateRangePicker({
  name,
  className,
  labelText,
  startDate,
  endDate,
  dateFormat = 'dd/MM/yyyy',
  handleChange
}: Props) {
  const startDateInstance = useMemo(() => (startDate ? new Date(startDate) : null), [startDate]);
  const endDateInstance = useMemo(() => (endDate ? new Date(endDate) : null), [endDate]);

  const onChangeStart = useCallback(
    (value) => {
      handleChange({
        name,
        startDate: value,
        endDate: endDateInstance,
        isStartChanged: true,
        isEndChanged: false
      });
    },
    [name, endDateInstance, handleChange]
  );

  const onChangeEnd = useCallback(
    (value) => {
      handleChange({
        name,
        startDate: startDateInstance,
        endDate: value,
        isStartChanged: false,
        isEndChanged: true
      });
    },
    [name, startDateInstance, handleChange]
  );

  return (
    <div id={name} className={cn(styles.wrapper, className)}>
      {labelText && <InputLabel labelText={labelText} />}
      <div className={styles.main}>
        <span className={styles.icon}>
          <CalendarSvg />
        </span>
        <div className={styles.display}>
          <ReactDatePicker
            selectsStart
            name="startDate"
            todayButton="Today"
            dateFormat={dateFormat}
            selected={startDateInstance}
            startDate={startDateInstance}
            endDate={endDateInstance}
            maxDate={endDateInstance}
            onChange={onChangeStart}
            calendarClassName={pickerStyles.calendarCustom}
          />
          <DotsSvg />
          <ReactDatePicker
            selectsEnd
            name="endDate"
            todayButton="Today"
            dateFormat={dateFormat}
            selected={endDateInstance}
            startDate={startDateInstance}
            endDate={endDateInstance}
            minDate={startDateInstance}
            onChange={onChangeEnd}
            calendarClassName={pickerStyles.calendarCustom}
          />
        </div>
      </div>
    </div>
  );
}
