import { TextInput } from 'crazy-ui';
import React from 'react';

import { TextEvent } from '@ui/types/shared';

type Props = {
  handleChange: (e: TextEvent) => void;
  value: string;
};

export function UserSearchFilter({ handleChange, value }: Props) {
  return (
    <TextInput
      name="searchPhrase"
      labelText="Search"
      placeholder="Enter a person name"
      value={value}
      onChange={handleChange}
    />
  );
}
