import React from 'react';

import { StepsContainer } from '@pages/createExperiment/steps/StepsContainer';
import { FormControls } from '@pages/createExperiment/formControls/FormControls';

import styles from '@pages/createExperiment/CreateExperiment.module.scss';
import { OverlappedConfigsContainer } from '@pages/createExperiment/overlappedConfigs/OverlappedConfigsContainer';

export function CreateExperiment() {
  return (
    <main className={styles.wrapper}>
      <StepsContainer />
      <FormControls />
      <OverlappedConfigsContainer />
    </main>
  );
}
