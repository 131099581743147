import React from 'react';
import { ButtonVariant, Button } from 'crazy-ui-next';
import { CheckboxInput } from 'crazy-ui';
import { useDropdown } from '@ui/hooks/useDropdown';

import { ReactComponent as GearSvg } from '@assets/svg/gear.svg';

import styles from './ToggleColumnsDrop.module.scss';

// todo rewrite to ts
export default function ToggleColumnsDrop({ columns, toggleColumn, disabled }) {
  const { isOpen, openDropdown, closeDropdown } = useDropdown(styles.dropdown, styles.toggleButton);

  const options = columns.map((metric) => {
    const { key, title, type, isActive, isFixed } = metric;

    return (
      <li key={`drop_${key}`} className={styles.option} onClick={!isFixed ? toggleColumn : null} data-key={key}>
        <CheckboxInput name={key} labelText={title} checked={isActive} disabled={isFixed} readOnly />
        {type && (
          <span className={styles.dataType} data-model={type}>
            {type}
          </span>
        )}
      </li>
    );
  });

  return (
    <div className={styles.dropdown}>
      <Button
        variant={ButtonVariant.PRIMARY}
        icon={<GearSvg />}
        onClick={isOpen ? closeDropdown : openDropdown}
        disabled={disabled}
      />

      {isOpen && (
        <div className={styles.listWrapper}>
          <ul className={styles.list}>{options}</ul>
        </div>
      )}
    </div>
  );
}
