import React from 'react';

import { ResourceMapper } from '@app/mappers/ResourceMapper';
import { GameApplicationDto } from '@domain/models/game/GameApplicationDto';
import { ExperimentState } from '@domain/enums/ExperimentState';
import { ExperimentName } from '@domain/types';

import { ActionsContainer } from '@pages/experimentDetails/components/actions/ActionsContainer';
import { ExperimentStatus } from '@components/layout/experimentStatus/ExperimentStatus';
import { Image } from '@components/layout/Image';

import styles from '@pages/experimentDetails/components/header/Header.module.scss';

type Props = {
  game: GameApplicationDto;
  state: ExperimentState;
  name: ExperimentName;
};

export function Header({ game, name, state }: Props) {
  const iconUrlFormatted = ResourceMapper.mapS3UrlToCDN(game.iconUrl);

  return (
    <header className={styles.wrapper}>
      <div className={styles.header}>
        <Image src={iconUrlFormatted} alt="Game icon" className={styles.icon} />
        <ActionsContainer />
        <div className={styles.expName}>
          <p>{name}</p>
          <ExperimentStatus status={state} />
        </div>
        <div className={styles.gameName}>{game.gameName}</div>
      </div>
    </header>
  );
}
