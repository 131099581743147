import React from 'react';
import { useSelector } from '@ui/hooks/redux';

import { appliedConfigSelectors } from '@infrastructure/store/appliedConfig/appliedConfigSelectors';
import { UniqueId } from '@domain/types';
import { Row } from '@pages/appliedConfig/list/Row';

type Props = {
  gameId: UniqueId;
};

export function GameRowContainer({ gameId }: Props) {
  const viewDto = useSelector(appliedConfigSelectors.getConfigByGameId(gameId));

  if (!viewDto) {
    return null;
  }

  const { game, configs, stats } = viewDto;

  return <Row key={gameId} game={game} configs={configs} stats={stats} />;
}
