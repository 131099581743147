import React from 'react';
import cn from 'classnames';

import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import { ObjectiveRow } from '@pages/experimentDetails/components/winnerStatus/atoms/ObjectiveRow';
import { BlockLoader } from '@components/layout/Loader';

import styles from './WinnerStatus.module.scss';

type Props = {
  experiment: ExperimentDto;
  isLoading: boolean;
};

export function WinnerStatus({ experiment, isLoading }: Props) {
  const { experimentObjectives: objectives } = experiment;

  if (isLoading) {
    return (
      <div className={styles.wrapper}>
        <BlockLoader />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <h5>Status</h5>
      <div className={cn(objectives.length > 2 && styles.shadow)}>
        <div className={styles.listWrapper}>
          <ul>
            {objectives.map((objective) => (
              <ObjectiveRow
                objective={objective}
                experimentId={experiment.experimentId}
                key={objective.id}
                platform={experiment.gameApp.platform}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
