import React, { useCallback } from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';

import { useDispatch, useSelector } from '@ui/hooks/redux';
import { ReactComponent as PlusSvg } from '@assets/svg/play-analysis.svg';

import { ExperimentState } from '@domain/enums/ExperimentState';

import { pushRequestAnalysis } from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { RequestStage } from '@infrastructure/store/types/actions';

export function RequestAnalysisBtn() {
  const dispatch = useDispatch();

  const status = useSelector(experimentDetailsSelectors.getRequestAnalysisStatus);
  const { state } = useSelector(experimentDetailsSelectors.getExperiment);

  const isRequest = status === RequestStage.REQUEST;
  const disabled = state !== ExperimentState.RUNNING;

  const handleClick = useCallback(() => {
    dispatch(pushRequestAnalysis.trigger());
  }, [dispatch]);

  return (
    <Button
      variant={ButtonVariant.PRIMARY}
      icon={<PlusSvg />}
      onClick={handleClick}
      tooltipText="Request Analysis"
      isLoading={isRequest}
      disabled={disabled}
      loadingText=""
    />
  );
}
