import React from 'react';
import { InputLabel, SwitchInput } from 'crazy-ui-next';
import { Tooltip } from 'crazy-ui';

import { GoalConfigParams } from '@domain/models/createExperiment/GoalConfigParams';
import { FormComponent, getInputProps } from '@ui/hooks/form';
import { ReactComponent as InfoSvg } from '@assets/svg/info.svg';

import styles from '@pages/createExperiment/goalConfig/GoalConfig.module.scss';

const name: keyof GoalConfigParams = 'automaticMode';

type Props = Pick<FormComponent<GoalConfigParams>, 'register'>;

export function AutomaticModeInput({ register }: Props) {
  return (
    <div className={styles.inputWrapper}>
      <SwitchInput {...getInputProps<GoalConfigParams>(name, register, {})} />
      <InputLabel labelText="Automatic mode" />
      <Tooltip text="The test decisions are automatically made based on Bayesian results">
        <InfoSvg className={styles.hint} />
      </Tooltip>
    </div>
  );
}
