import { createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  pushValidateABObjectiveForm,
  removeConfig,
  generateABObjectiveConfig,
  removeGroupFromConfig,
  updateConfigEntry
} from '@infrastructure/store/createExperiment/createExperimentActions';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';

const configListInitial: Record<string, GenericConfigEntry[]> = {};

export const configListReducer = createReducer(configListInitial, (qb) => {
  qb.addCase(pushValidateABObjectiveForm.success, (state, { payload }) => {
    const { region, configList } = payload;

    state[region] = configList;
  });
  qb.addCase(removeConfig, (state, { payload: region }) => {
    delete state[region];
    return state;
  });
  qb.addCase(generateABObjectiveConfig.success, (state, { payload }) => {
    return payload.configList;
  });
  qb.addCase(removeGroupFromConfig, (state, { payload }) => {
    const { index: groupIndex, region } = payload;

    state[region].splice(groupIndex, 1);

    return state;
  });
  qb.addCase(updateConfigEntry, (state, { payload }) => {
    const { region, configIndex, variableKey, configValue } = payload;

    state[region][configIndex].entry[variableKey].configValue = configValue;
  });
  qb.addCase(clearAll, () => {
    return configListInitial;
  });
});
