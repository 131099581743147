import React from 'react';

import styles from './CountryLabel.module.scss';
import { CountryCircle } from '@components/layout/countryLabel/CountryCircle';

type Props = {
  countryCode: string | string[];
  visibleCount?: number;
};

export function CountryLabel({ countryCode, visibleCount = 5 }: Props) {
  if (Array.isArray(countryCode)) {
    if (!countryCode.length) {
      return null;
    }

    const visibleList = countryCode.slice(0, visibleCount);

    return (
      <div className={styles.wrapper} data-count={visibleList.length}>
        {visibleList.map((code) => (
          <CountryCircle key={code} countryCode={code} />
        ))}
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <CountryCircle countryCode={countryCode} />
    </div>
  );
}
