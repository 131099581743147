import React from 'react';

import { GameInstallsStatsDto } from '@domain/models/game/GameInstallsStatsDto';
import { UserAllocationService } from '@app/services/UserAllocationService';

import styles from '@pages/createExperiment/CreateExperiment.module.scss';

type Props = {
  gameInstalls: GameInstallsStatsDto | null;
  minUsersPerGroup: number;
};

export function UserAllocationFormBlock({ gameInstalls, minUsersPerGroup }: Props) {
  if (!gameInstalls) {
    return (
      <div className={styles.userAllocation}>
        <p>
          Average daily installs: <span>-</span>
        </p>
        <p>
          Recommended number groups: <span>-</span>
        </p>
      </div>
    );
  }

  const { averageInstalls } = gameInstalls;
  const recGroupsNumber = UserAllocationService.getRecGroupsNumber(averageInstalls, minUsersPerGroup);

  return (
    <div className={styles.userAllocation}>
      <p>
        Average daily installs: <span>{averageInstalls}</span>
      </p>
      <p>
        Recommended number groups: <span>{recGroupsNumber}</span>
      </p>
    </div>
  );
}
