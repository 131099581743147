import React from 'react';
import { useForm } from '@ui/hooks/form';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { RequestStage } from '@infrastructure/store/types/actions';
import { CreateExpVariableParams } from '@domain/models/experimentVariable/ExperimentVariableParams';
import { ExpVariableFormMode } from '@pages/experimentVariables/types/ExpVariableFormMode';
import { ExpVariableForm } from '@pages/experimentVariables/form/ExpVariableForm';
import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { pushCreateVariable } from '@infrastructure/store/config/configActions';

const getInitialState = () => new CreateExpVariableParams().setDefaultValues();

export function CreateExpVariableContainer() {
  const dispatch = useDispatch();
  const status = useSelector(configSelectors.getEditConfigStatus);

  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<CreateExpVariableParams>({ schema: CreateExpVariableParams, defaultValues: getInitialState() });

  const handleFormSubmit = handleSubmit((form) => dispatch(pushCreateVariable.trigger(form)));
  const isLoading = status === RequestStage.REQUEST;

  return (
    <ExpVariableForm
      mode={ExpVariableFormMode.CREATE}
      isLoading={isLoading}
      handleSubmit={handleFormSubmit}
      errors={errors}
      register={register}
      control={control}
      watch={watch}
    />
  );
}
