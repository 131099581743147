import React, { useCallback } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { TextInput, InputLabel } from 'crazy-ui-next';
import cn from 'classnames';

import { MultiTextInputContainer } from '@pages/winnerConfigModal/form/inputs/multiTextInput/MultiTextInputContainer';
import { GoalConfigParams } from '@domain/models/createExperiment/GoalConfigParams';
import { MAX_REP_ARPU_VALUES } from '@domain/constants';
import { FormComponent, getInputProps } from '@ui/hooks/form';

import styles from '@pages/createExperiment/goalConfig/GoalConfig.module.scss';

type Props = Pick<FormComponent<GoalConfigParams>, 'control' | 'register' | 'errors'>;

const maxInputsLength = MAX_REP_ARPU_VALUES;

export function ReportingARPUInput({ control, register, errors }: Props) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'reportingArpu'
  });

  const automaticMode = useWatch({
    control,
    name: 'automaticMode'
  });

  const onAdd = useCallback(() => {
    append({ value: '' });
  }, [append]);

  const onRemove = useCallback(() => {
    remove(fields.length - 1);
  }, [remove, fields]);

  const errorMessage = automaticMode ? errors.reportingArpu?.root?.message : '';

  const inputs = fields.map((field, index) => {
    return (
      <TextInput
        className={styles.multiInputEl}
        type="number"
        key={field.id}
        {...getInputProps<GoalConfigParams>(`reportingArpu.${index}.value`, register, errors)}
      />
    );
  });

  return (
    <>
      <InputLabel labelText="Reporting ARPU" error={errorMessage} className={styles.note} />
      <MultiTextInputContainer
        inputs={inputs}
        onAddItem={onAdd}
        onRemoveItem={onRemove}
        maxItems={maxInputsLength}
        className={cn(styles.inputWrapper, styles.reportingArpu)}
        isValid={!Boolean(errorMessage)}
      />
    </>
  );
}
