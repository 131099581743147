import { DropdownInput } from 'crazy-ui';
import React from 'react';

import { DropdownEvent, DropdownOption } from 'crazy-ui-next';
import { UserRoleFilterValue } from '@infrastructure/store/userManagement/types/filters';

const options: DropdownOption<UserRoleFilterValue>[] = [
  { value: UserRoleFilterValue.ALL, label: 'All' },
  { value: UserRoleFilterValue.ADMIN, label: 'Admin' },
  { value: UserRoleFilterValue.ANALYST, label: 'Analyst' },
  { value: UserRoleFilterValue.GAME_OWNER, label: 'Game Owner' },
  { value: UserRoleFilterValue.QA, label: 'QA' }
];

type Props = {
  handleChange: (e: DropdownEvent<UserRoleFilterValue>) => void;
  value: UserRoleFilterValue;
};

export function UserRoleFilter({ handleChange, value }: Props) {
  return <DropdownInput id="user-role" labelText="Role" options={options} value={value} handleChange={handleChange} />;
}
